import { useInfiniteQuery } from 'react-query'

import { api } from '@app/config'

import {
  LeadsFormsServerV1InterestSort,
  LeadsLeadsServerV1Category,
} from '../../api/api'

export interface useInfiniteInterestsProps {
  category?: LeadsLeadsServerV1Category[]
  search?: string
  sort?: LeadsFormsServerV1InterestSort
}

export const useInfiniteInterests = (props: useInfiniteInterestsProps) => {
  const { category, search, sort } = props

  return useInfiniteQuery(
    ['interests-infinite', props],
    ({ pageParam: pageToken }) =>
      api.LeadsApi.leadsAPIGetInterest(
        undefined,
        undefined,
        undefined,
        undefined,
        category,
        undefined,
        search ? [search] : undefined,
        undefined,
        10,
        pageToken,
        sort ? [sort] : undefined
      ),
    {
      getNextPageParam: (lastPage) => lastPage.data.count?.token || undefined,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  )
}
