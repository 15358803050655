export const dataSource = [
  {
    key: '1',
    eventType: 'Запрос предложения',
    changedBy: 'cmp@outsi.de',
    changeDate: '04.03.2021    11:46:20',
  },
  {
    key: '2',
    eventType: 'Запрос предложения',
    changedBy: 'cmp@outsi.de',
    changeDate: '04.03.2021    11:46:20',
  },
  {
    key: '3',
    eventType: 'Запрос предложения',
    changedBy: 'cmp@outsi.de',
    changeDate: '04.03.2021    11:46:20',
  },
  {
    key: '4',
    eventType: 'Запрос предложения',
    changedBy: 'cmp@outsi.de',
    changeDate: '04.03.2021    11:46:20',
  },
  {
    key: '5',
    eventType: 'Запрос предложения',
    changedBy: 'cmp@outsi.de',
    changeDate: '04.03.2021    11:46:20',
  },
]

export const columns = [
  {
    title: 'Тип события',
    dataIndex: 'eventType',
    key: 'eventType',
  },
  {
    title: 'Кем изменено',
    dataIndex: 'changedBy',
    key: 'changedBy',
  },
  {
    title: 'Дата изменений',
    dataIndex: 'changeDate',
    key: 'changeDate',
  },
]
