import { FC, useContext } from 'react'
import {
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  TreeSelect,
  TreeSelectProps,
} from 'antd'
import _set from 'lodash/set'

import { LeadsFormsServerV1LeadSource } from '@app/api'
import { useBrands } from '@app/hooks/useBrands/useBrands.hook'
import { useGenerations } from '@app/hooks/useGenerations/useGenerations.hook'
import { useModels } from '@app/hooks/useModels/useModels.hook'
import {
  FieldCard,
  FieldCardProps,
} from '@app/pages/FormEditPage/Fields/FieldCard/FieldCard'

import { AlignItem } from '../../components'
import { FormContext } from '../../formContext'

export const ModificationField: FC<FieldCardProps> = ({
  name,
  fieldKey,
  path,
  ...props
}) => {
  const { formState } = useContext(FormContext)

  const brandPath = [path, 'trim', 'generation', 'model', 'brand']
  const modelPath = [path, 'trim', 'generation', 'model']

  const selectedBrand =
    // @ts-ignore
    formState.fields[name]?.[path]?.trim.generation.model.brand.items?.map(
      ({ value }: any) => value
    )
  const selectedModels =
    // @ts-ignore
    formState.fields[name]?.[path]?.trim.generation.model.items?.map(
      ({ value }: any) => value
    )

  const { data: brands } = useBrands()
  const { data: models } = useModels({ brands: selectedBrand })
  const { data: generations } = useGenerations(selectedModels)

  const getValueProps = (value?: LeadsFormsServerV1LeadSource[]) =>
    (value || []).map(({ id }) => id)

  const getValueFromEvents = (list?: any[]) => (ids: string[]) =>
    (list || [])
      .filter((it) => ids.includes(it.id as string))
      .map((it) => ({ ...it, title: it.name, value: it.id }))

  return (
    <FieldCard
      name={name}
      fieldKey={fieldKey}
      path={path}
      hideDisclaimer
      hideFooter
      {...props}
      justify={'start'}
    >
      <Col span={24}>
        <Divider>Бренд</Divider>
      </Col>
      <ModificationItem
        name={name}
        path={brandPath}
        fieldKey={fieldKey}
        label="Бренд"
        selectOptions={{
          treeData: brands?.all?.map(({ name, id }) => ({
            label: name,
            value: id,
          })),
          placeholder: 'Выберите бренд',
        }}
        getValueFromEvent={getValueFromEvents(brands?.all)}
        getValueProps={getValueProps}
        {...props}
      />

      <Col span={24}>
        <Divider>Модель</Divider>
      </Col>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) => {
          const parentFieldValue = getFieldValue(['fields', name, ...brandPath])

          return (
            <ModificationItem
              name={name}
              path={modelPath}
              fieldKey={fieldKey}
              label="Модель"
              disabled={
                parentFieldValue?.allItems || !parentFieldValue?.items.length
              }
              selectOptions={{
                treeData: brands?.all
                  ?.map(({ name, id }) => ({
                    label: name,
                    value: id,
                    children: models
                      ?.filter((model) => model.brandId === id)
                      .map((model) => ({
                        value: model.id,
                        label: model.name,
                      })),
                  }))
                  .filter(({ children }) => Boolean(children?.length)),
                placeholder: 'Выберите модель',
              }}
              getValueFromEvent={getValueFromEvents(models)}
              getValueProps={getValueProps}
              {...props}
            />
          )
        }}
      </Form.Item>

      <Col span={24}>
        <Divider>Поколение</Divider>
      </Col>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) => {
          const parentFieldValue = getFieldValue(['fields', name, ...modelPath])

          return (
            <ModificationItem
              name={name}
              path={[path, 'trim', 'generation']}
              fieldKey={fieldKey}
              label="Поколение"
              disabled={
                parentFieldValue?.allItems || !parentFieldValue?.items.length
              }
              selectOptions={{
                treeData: models
                  ?.map(({ name, id }) => ({
                    label: name,
                    value: id,
                    children: generations
                      ?.filter((g) => g.modelId === id)
                      .map((g) => ({
                        value: g.id,
                        label: g.name,
                      })),
                  }))
                  .filter(({ children }) => Boolean(children?.length)),
                placeholder: 'Выберите поколение',
              }}
              getValueFromEvent={getValueFromEvents(generations)}
              getValueProps={getValueProps}
              {...props}
            />
          )
        }}
      </Form.Item>

      <Col span={24}>
        <Divider>Комплектация</Divider>
      </Col>
      <Form.Item noStyle shouldUpdate>
        {() => (
          <ModificationItem
            name={name}
            path={[path, 'trim']}
            fieldKey={fieldKey}
            label="Комплектация"
            disabled
            // disabled={getFieldValue(['fields', name, path, 'trim', 'generation'])?.allItems}
            selectOptions={{
              placeholder: 'Выберите комплектацию',
            }}
            {...props}
          />
        )}
      </Form.Item>

      <Col span={24}>
        <Divider>Конфигурация</Divider>
      </Col>
      <Form.Item noStyle shouldUpdate>
        {() => (
          <ModificationItem
            name={name}
            path={[path]}
            fieldKey={fieldKey}
            label="Конфигурация"
            disabled
            // disabled={getFieldValue(['fields', name, path, 'trim'])?.allItems}
            selectOptions={{
              placeholder: 'Выберите конфигурацию',
            }}
            {...props}
          />
        )}
      </Form.Item>
    </FieldCard>
  )
}

interface ModificationItemProps {
  path: string[]
  name: string | number
  fieldKey: string | number
  label: string
  selectOptions: TreeSelectProps<any>
  disabled?: boolean
  getValueFromEvent?: (ids: string[]) => any[]
  getValueProps?: () => any
}

export const ModificationItem: FC<ModificationItemProps> = ({
  path,
  name,
  fieldKey,
  label,
  selectOptions = {},
  disabled = false,
  getValueFromEvent,
  getValueProps,
  ...props
}) => {
  return (
    <>
      <Col span={24}>
        <Form.Item
          {...props}
          name={[name, ...path, 'title']}
          fieldKey={[fieldKey, ...path, 'title']}
          label="Название блока"
        >
          <Input placeholder="Введите название блока" />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => (
            <Form.Item
              {...props}
              name={[name, ...path, 'items']}
              fieldKey={[fieldKey, ...path, 'items']}
              label={label}
              getValueFromEvent={getValueFromEvent}
              getValueProps={getValueProps}
            >
              <TreeSelect
                multiple
                maxTagCount={5}
                treeCheckable
                disabled={
                  disabled ||
                  getFieldValue(['fields', name, ...path, 'allItems'])
                }
                filterTreeNode={(inputValue, option) =>
                  ((option?.label as string) || '')
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                }
                defaultValue={getFieldValue([
                  'fields',
                  name,
                  ...path,
                  'items',
                ])?.map(({ value }: any) => value)}
                {...selectOptions}
              />
            </Form.Item>
          )}
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => (
            <Form.Item
              {...props}
              name={[name, ...path, 'defaultValue']}
              fieldKey={[fieldKey, ...path, 'defaultValue']}
              label={'Значение по умолчанию'}
              getValueFromEvent={(value) => value[0]}
            >
              <TreeSelect
                treeCheckable
                filterTreeNode={(inputValue, option) =>
                  ((option?.title as string) || '')
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                }
                defaultValue={
                  getFieldValue(['fields', name, ...path, 'defaultValue'])
                    ?.value
                }
                placeholder={selectOptions.placeholder}
                treeData={
                  getFieldValue(['fields', name, ...path, 'allItems'])
                    ? selectOptions.treeData
                    : getFieldValue(['fields', name, ...path, 'items'])
                }
              />
            </Form.Item>
          )}
        </Form.Item>
      </Col>

      <Col span={12}>
        <Row gutter={24}>
          <Col>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldsValue, setFieldsValue }) => (
                <Form.Item
                  {...props}
                  name={[name, ...path, 'allItems']}
                  fieldKey={[fieldKey, ...path, 'allItems']}
                  valuePropName={'checked'}
                >
                  <Checkbox
                    disabled={disabled}
                    onChange={(e) => {
                      if (e.target.checked) {
                        const formValues = getFieldsValue(true)

                        path.forEach((_, i) => {
                          if (i === 0 || i === path.length - 1) return

                          _set(
                            formValues,
                            ['fields', name, ...path.slice(0, -i), 'allItems'],
                            true
                          )
                        })

                        setFieldsValue(formValues)
                      }
                    }}
                  >
                    Выбрать все
                  </Checkbox>
                </Form.Item>
              )}
            </Form.Item>
          </Col>

          <Col>
            <Form.Item
              {...props}
              name={[name, ...path, 'required']}
              fieldKey={[fieldKey, ...path, 'required']}
              valuePropName={'checked'}
            >
              <Checkbox>Обязательное поле</Checkbox>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              {...props}
              name={[name, ...path, 'displayed']}
              fieldKey={[fieldKey, ...path, 'displayed']}
              valuePropName={'checked'}
            >
              <Checkbox>Отображать поле</Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Col>

      <Col span={12}>
        <Col flex="auto">
          <AlignItem
            {...props}
            name={[name, ...path, 'align']}
            fieldKey={[fieldKey, ...path, 'align']}
          />
        </Col>
      </Col>

      <Col span={24}>
        <Form.Item
          {...props}
          name={[name, ...path, 'disclaimerId']}
          fieldKey={[fieldKey, ...path, 'disclaimerId']}
          label="Дисклеймер"
        >
          <Select allowClear placeholder="Выберите дисклеймер" />
        </Form.Item>
      </Col>
    </>
  )
}
