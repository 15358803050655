import { useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { EditFilled } from '@ant-design/icons'
import { Button, Col, Input, Layout, PageHeader, Row, Table } from 'antd'

import {
  LeadsFormsServerV1ModelField,
  LeadsModelsServerV1ModelSort,
} from '@app/api'
import { applySort } from '@app/helpers/applySort'
import { useBrands } from '@app/hooks/useBrands/useBrands.hook'
import { useDebounce } from '@app/hooks/useDebounce/useDebounce'
import { useInfiniteModels } from '@app/hooks/useModels/useInfiniteModels.hook'

import { ModelEdit } from './ModelEdit'

export const DictionaryModelsPage = () => {
  const { edit, modelId } = useParams<{ modelId?: string; edit?: string }>()
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce<string>(search, 200)
  const [sort, setSort] = useState(LeadsModelsServerV1ModelSort.None)
  const history = useHistory()

  const {
    data: models,
    refetch,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteModels({ search: debouncedSearch, sort })
  const { data: brands } = useBrands()

  const openModal = (id: string) => {
    history.push(`/dictionary/models/edit/${id}`)
  }

  const closeModal = () => {
    history.push('/dictionary/models')
  }

  return (
    <Layout.Content>
      <PageHeader
        title="Модели"
        ghost={false}
        extra={[
          <Input.Search
            style={{ width: 272 }}
            key="1"
            placeholder="Введите название модели"
            enterButton
            onChange={(e) => setSearch(e.target.value)}
            // @ts-ignore
            onSearch={refetch}
            value={search}
          />,
          <Button key="2" type="primary" data-testid="new-model-button">
            Новая модель
          </Button>,
        ]}
      />
      <Row style={{ padding: 24 }}>
        <Col span={24}>
          <Table
            dataSource={models?.pages.flatMap(
              ({ data }) =>
                data.models as unknown as LeadsFormsServerV1ModelField[]
            )}
            pagination={false}
          >
            <Table.Column
              key="1"
              title="Бренд"
              dataIndex="brandId"
              width={'20%'}
              render={(val: string) =>
                brands?.all?.find(({ id }) => id === val)?.name
              }
              sorter={() => 0}
              onHeaderCell={() => ({
                onClick: () =>
                  setSort(
                    applySort([
                      LeadsModelsServerV1ModelSort.BrandAsc,
                      LeadsModelsServerV1ModelSort.BrandDesc,
                      LeadsModelsServerV1ModelSort.None,
                    ])
                  ),
              })}
            />
            <Table.Column
              key="2"
              title="Название"
              dataIndex="name"
              width={'70%'}
              sorter={() => 0}
              onHeaderCell={() => ({
                onClick: () =>
                  setSort(
                    applySort([
                      LeadsModelsServerV1ModelSort.NameAsc,
                      LeadsModelsServerV1ModelSort.NameDesc,
                      LeadsModelsServerV1ModelSort.None,
                    ])
                  ),
              })}
            />
            <Table.Column
              key="3"
              width={'10%'}
              render={({ id }) => (
                <Row gutter={16}>
                  <Col>
                    <Button
                      type="link"
                      onClick={() => openModal(id)}
                      data-testid="edit-model-button"
                      icon={
                        <EditFilled
                          style={{ fontSize: 18, color: '#8C8C8C' }}
                        />
                      }
                    />
                  </Col>
                </Row>
              )}
            />
          </Table>

          {hasNextPage && (
            <Row justify="center" style={{ margin: '24px 0' }}>
              <Col>
                <Button
                  onClick={() => fetchNextPage()}
                  loading={isFetchingNextPage}
                >
                  Загрузить ещё
                </Button>
              </Col>
            </Row>
          )}
        </Col>
      </Row>

      <ModelEdit
        onSuccess={async () => {
          await refetch()
          closeModal()
        }}
        onCancel={closeModal}
        visible={Boolean(edit)}
        modelId={modelId}
      />
    </Layout.Content>
  )
}
