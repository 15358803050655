import {
  LeadsFormsServerV1Field,
  LeadsFormsServerV1ViewMode,
  LeadsLeadsServerV1Gender,
} from '@app/api'
import { sortBy } from '@app/helpers/sort'

export const STEPS = ['edit', 'preview', 'publish'] as const

export const menuItems = [
  {
    label: 'Имя',
    initialValue: { firstName: { comment: 'firstName', displayed: true } },
  },
  {
    label: 'Фамилия',
    initialValue: { lastName: { comment: 'lastName', displayed: true } },
  },
  {
    label: 'Отчество',
    initialValue: { middleName: { comment: 'middleName', displayed: true } },
  },
  {
    label: 'Город проживания',
    initialValue: { residence: { comment: 'residence', displayed: true } },
  },
  {
    label: 'E-mail',
    initialValue: { email: { comment: 'email', displayed: true } },
  },
  {
    label: 'Телефон',
    initialValue: { phone: { comment: 'phone', displayed: true } },
  },
  {
    label: 'Год выпуска',
    initialValue: { modelYear: { comment: 'modelYear', displayed: true } },
  },
  {
    label: 'Пробег',
    initialValue: { mileage: { comment: 'mileage', displayed: true } },
  },
  { label: 'VIN', initialValue: { vin: { comment: 'vin', displayed: true } } },
  {
    label: 'Регистрационный номер',
    initialValue: { plateNumber: { comment: 'plateNumber', displayed: true } },
  },
  {
    label: 'Год производства автомобиля',
    initialValue: {
      manufactureYear: { comment: 'manufactureYear', displayed: true },
    },
  },
  {
    label: 'Срок эксплуатации',
    initialValue: {
      term: { comment: 'term', displayed: true, pattern: '\\d+$' },
    },
  },
  {
    label: 'Организация',
    initialValue: {
      organization: { comment: 'organization', displayed: true },
    },
  },
  {
    label: 'Дата рождения',
    initialValue: { birthdate: { comment: 'birthdate', displayed: true } },
  },
  {
    label: 'Дата производства автомобиля',
    initialValue: {
      manufactureDate: { comment: 'manufactureDate', displayed: true },
    },
  },
  {
    label: 'Дата регистрации',
    initialValue: {
      registrationDate: { comment: 'registrationDate', displayed: true },
    },
  },
  {
    label: 'Дата покупки автомобиля',
    initialValue: {
      purchaseDate: { comment: 'purchaseDate', displayed: true },
    },
  },
  {
    label: 'Пол',
    initialValue: {
      gender: {
        comment: 'gender',
        displayed: true,
        items: [
          { title: 'Мужской', value: LeadsLeadsServerV1Gender.Male },
          { title: 'Женский', value: LeadsLeadsServerV1Gender.Female },
        ],
      },
    },
  },
  {
    label: 'Семейный тест-драйв',
    initialValue: {
      familyTestDrive: { comment: 'familyTestDrive', displayed: true },
    },
  },
  {
    label: 'Куплен в официальном дилерском центре',
    initialValue: {
      boughtAtOfficial: { comment: 'boughtAtOfficial', displayed: true },
    },
  },
  {
    label: 'Владелец автомобильной марки',
    initialValue: {
      carOwner: { comment: 'carOwner', displayed: true },
    },
  },
  {
    label: 'Согласия',
    initialValue: {
      consents: {
        requestConsent: {
          comment: 'requestConsent',
          title: 'Я согласен на обработку моих данных',
          displayed: true,
        },
        communicationConsent: {
          comment: 'communicationConsent',
          title: 'Я согласен на дальнейшую коммуникацию',
          displayed: true,
        },
      },
    },
  },
  {
    label: 'Комментарий',
    initialValue: {
      comment: {
        comment: 'comment',
        displayed: true,
        viewMode: LeadsFormsServerV1ViewMode.Textarea,
      },
    },
  },
  {
    label: 'Подпись',
    initialValue: {
      caption: { comment: 'caption', displayed: true },
    },
  },
  {
    label: 'Текстовая область с поддержкой rich-текста',
    initialValue: {
      richText: { comment: 'richText', displayed: true },
    },
  },
  {
    label: 'Выбор дилерского центра',
    initialValue: {
      dealerCenter: {
        cities: [],
        comment: 'dealerCenter',
        displayed: true,
        align: 'ALIGN_NONE',
      },
    },
  },
  { label: 'Шаг', initialValue: { stepBreak: { displayed: true } } },
  {
    label: 'Добавление файлов',
    initialValue: { multiUpload: { comment: 'multiUpload', displayed: true } },
  },
  {
    label: 'Откуда о нас узнали',
    initialValue: {
      leadSource: {
        comment: 'leadSource',
        displayed: true,
      },
    },
  },
  {
    label: 'Желаемый способ связи',
    initialValue: {
      communicationChannel: {
        comment: 'communicationChannel',
        displayed: true,
      },
    },
  },
  {
    label: 'Данные текущего автомобиля',
    initialValue: {
      currentCar: {
        comment: 'currentCar.modificationId',
        displayed: false,
        items: [],
        trim: {
          items: [],
          comment: 'currentCar.trimId',
          displayed: false,
          generation: {
            items: [],
            comment: 'currentCar.generationId',
            displayed: false,
            model: {
              items: [],
              comment: 'currentCar.modelId',
              displayed: false,
              brand: {
                items: [],
                comment: 'currentCar.brandId',
                displayed: false,
              },
            },
          },
        },
      },
    },
  },
  {
    label: 'Данные желаемого автомобиля',
    initialValue: {
      desiredCar: {
        comment: 'desiredCar.modificationId',
        displayed: false,
        items: [],
        trim: {
          items: [],
          comment: 'desiredCar.trimId',
          displayed: false,
          generation: {
            items: [],
            comment: 'desiredCar.generationId',
            displayed: false,
            model: {
              items: [],
              comment: 'desiredCar.modelId',
              displayed: false,
              brand: {
                items: [],
                comment: 'desiredCar.brandId',
                displayed: false,
              },
            },
          },
        },
      },
    },
  },
  {
    label: 'Желаемый автомобиль с фото',
    initialValue: {
      desiredCarWithPhoto: {
        comment: 'desiredCarWithPhoto.modificationId',
        displayed: false,
        showPhoto: true,
        items: [],
        trim: {
          items: [],
          comment: 'desiredCarWithPhoto.trimId',
          displayed: false,
          generation: {
            items: [],
            comment: 'desiredCarWithPhoto.generationId',
            displayed: false,
            model: {
              items: [],
              comment: 'desiredCarWithPhoto.modelId',
              displayed: false,
              brand: {
                items: [],
                comment: 'desiredCarWithPhoto.brandId',
                displayed: false,
              },
            },
          },
        },
      },
    },
  },
  {
    label: 'Желаемая дата тест-драйва',
    initialValue: {
      testDriveDate: { comment: 'testDriveDate', displayed: true },
    },
  },
  {
    label: 'Желаемая дата покупки',
    initialValue: {
      desiredPurchaseDate: { comment: 'desiredPurchaseDate', displayed: true },
    },
  },
  {
    label: 'Желаемое время тест-драйва',
    initialValue: {
      testDriveTime: { comment: 'testDriveTime', displayed: true },
    },
  },
  {
    label: 'Желаемое время дня тест-драйва',
    initialValue: {
      testDriveTimeOfDay: { comment: 'testDriveTimeOfDay', displayed: true },
    },
  },
  {
    label: 'Типы интересов',
    initialValue: {
      interests: {
        comment: 'interest',
        displayed: true,
        showSelect: true,
        items: [
          {
            title: null,
            photo: null,
            value: null,
            disclaimer: null,
          },
        ],
      },
    },
  },
  {
    label: 'Шапка',
    initialValue: {
      header: { background: { image: null } },
    },
  },
  {
    label: 'Планируемая дата покупки автомобиля',
    initialValue: {
      desiredPurchasePlannedPeriod: {
        displayed: true,
        comment: 'desiredPurchasePlannedPeriod',
      },
    },
  },
  {
    label: 'Планируемая дата тест-драйва',
    initialValue: {
      testDrivePlannedPeriod: {
        displayed: true,
        comment: 'testDrivePlannedPeriod',
      },
    },
  },
].sort(sortBy('label'))

export const isMenuItem = <T extends LeadsFormsServerV1Field>(
  field: LeadsFormsServerV1Field,
  path: keyof T
): field is T => (field as T)[path] !== undefined

export const getInitialValue = <T extends LeadsFormsServerV1Field>(
  path: keyof T
) =>
  menuItems.find((menuItem) =>
    isMenuItem<T>(menuItem.initialValue as LeadsFormsServerV1Field, path)
  )?.initialValue as T
