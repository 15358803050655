import { LeadsActivityV1Status } from '../../api/api'
import { vgrBrands } from '../../hooks/useBrands/useBrands.hook'

export const availableFilters = {
  status: {
    title: 'Статус',
    key: 'status',
    checkable: false,
    children: [
      { title: 'Не задан', key: LeadsActivityV1Status.None },
      { title: 'Опубликовано', key: LeadsActivityV1Status.Active },
      { title: 'Архив', key: LeadsActivityV1Status.Archived },
      { title: 'Черновик', key: LeadsActivityV1Status.Disabled },
    ],
  },
  brand: {
    title: 'Бренд',
    key: 'brand',
    checkable: false,
    children: vgrBrands.map(({ id, name }) => ({
      key: id,
      title: name,
    })),
  },
}
