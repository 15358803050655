import { FC, useContext } from 'react'
import { Col, Form, Input, Select } from 'antd'

import { LeadsFormsServerV1TimeOfDay } from '@app/api'
import {
  FieldCard,
  FieldCardProps,
} from '@app/pages/FormEditPage/Fields/FieldCard/FieldCard'

import { FormContext } from '../../formContext'

const PURCHASE_TIMES = [
  { title: 'Любое', value: LeadsFormsServerV1TimeOfDay.None },
  { title: 'Утро', value: LeadsFormsServerV1TimeOfDay.Morning },
  { title: 'Обед', value: LeadsFormsServerV1TimeOfDay.Afternoon },
  { title: 'Вечер', value: LeadsFormsServerV1TimeOfDay.Evening },
] as const

export const TimeOfDayField: FC<FieldCardProps> = ({
  name,
  fieldKey,
  path,
  ...props
}) => {
  const { getFieldValue } = useContext(FormContext)

  const getValueFromEvent = (codes: string[]) =>
    PURCHASE_TIMES?.filter((pt) => codes.includes(pt.value as string))

  const getValueProps = (value?: typeof PURCHASE_TIMES) =>
    (value || []).map(({ value }) => value)

  return (
    <FieldCard name={name} fieldKey={fieldKey} path={path} {...props}>
      <Col span={12}>
        <Form.Item
          {...props}
          name={[name, path, 'title']}
          fieldKey={[fieldKey, path, 'title']}
          label="Заголовок поля"
        >
          <Input placeholder="Введите заголовок поля" />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item
          {...props}
          name={[name, path, 'hint']}
          fieldKey={[fieldKey, path, 'hint']}
          label="Подсказка"
        >
          <Input placeholder="Введите подсказку" />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item
          {...props}
          name={[name, path, 'items']}
          fieldKey={[fieldKey, path, 'items']}
          label="Значения"
          getValueFromEvent={getValueFromEvent}
          getValueProps={getValueProps}
          data-testid="time-of-day-items"
        >
          <Select
            allowClear
            mode="multiple"
            maxTagCount={5}
            placeholder="Выберите значение из справочника"
            defaultValue={getFieldValue(['fields', name, path, 'items'])?.map(
              ({ value }: any) => value
            )}
            options={PURCHASE_TIMES.map(({ title, value }) => ({
              label: title,
              value,
            }))}
          />
        </Form.Item>
      </Col>
    </FieldCard>
  )
}
