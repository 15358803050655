import { FC, useContext } from 'react'
import { LeadsFormsServerV1Display } from '@leads/react-sdk/dist/declarations/src/api/api'
import { Card, Col, Form, Row, Select, Typography } from 'antd'

import { useDisplays } from '@app/hooks/useDisplays/useDisplays.hook'

import { FormContext } from './formContext'

export interface DisplaySettingsProps {
  title: string
  name: string
}

export const DisplaySettings: FC<DisplaySettingsProps> = ({ name, title }) => {
  const { getFieldValue, formState } = useContext(FormContext)
  const { data, isLoading } = useDisplays({
    filterBrandIdEq: formState.brandId ? [formState.brandId] : [],
  })

  const getValueFromEvent = (code: string) =>
    data?.find(({ id }) => id === code)

  return (
    <Card
      loading={isLoading}
      title={<Typography.Title level={4}>{title}</Typography.Title>}
      bordered={false}
    >
      <Row>
        <Col span={24}>
          <Form.Item
            getValueFromEvent={getValueFromEvent}
            name={name}
            label="Справочник отправок формы"
            getValueProps={(value?: LeadsFormsServerV1Display) => [value]}
            rules={[
              {
                required: true,
                message: 'Выберите вариант из справочника',
              },
            ]}
          >
            <Select
              allowClear
              options={data?.map(({ id, props: { title } }) => ({
                value: id,
                label: title,
              }))}
              defaultValue={getFieldValue(name)?.id}
              placeholder="Выберите вариант из справочника"
            />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  )
}
