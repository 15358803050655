import { useQuery } from 'react-query'

import { LeadsModelsServerV1Model } from '@app/api'
import { api } from '@app/config'

export const useModel = (
  id: string,
  onUpdate?: (data?: LeadsModelsServerV1Model) => void
) =>
  useQuery(`model/${id}`, () => api.ModelsApi.modelsAPIGetModel([id]), {
    select({ data }) {
      const model = data.models?.[0]

      onUpdate?.(model)
      return model
    },
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  })
