import { FC } from 'react'
import { Col, Form } from 'antd'

import { RichText } from '@app/components/RichText/RichText'
import {
  FieldCard,
  FieldCardProps,
} from '@app/pages/FormEditPage/Fields/FieldCard/FieldCard'

export const RichTextField: FC<FieldCardProps> = ({
  name,
  fieldKey,
  path,
  ...props
}) => (
  <FieldCard name={name} fieldKey={fieldKey} path={path} {...props}>
    <Col span={24}>
      <Form.Item
        {...props}
        name={[name, path, 'content']}
        fieldKey={[fieldKey, path, 'content']}
        label="Текст"
      >
        <RichText />
      </Form.Item>
    </Col>
  </FieldCard>
)
