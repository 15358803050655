import { FC, useContext } from 'react'
import { Col, Form, Input, Select } from 'antd'

import { LeadsLeadsServerV1CommunicationMethod } from '@app/api'
import {
  FieldCard,
  FieldCardProps,
} from '@app/pages/FormEditPage/Fields/FieldCard/FieldCard'

import { FormContext } from '../../formContext'

const COMMUNICATION_CHANNELS = [
  {
    title: 'Любой',
    value: LeadsLeadsServerV1CommunicationMethod.None,
  },
  {
    title: 'Мессенджер',
    value: LeadsLeadsServerV1CommunicationMethod.Messenger,
  },
  {
    title: 'Email',
    value: LeadsLeadsServerV1CommunicationMethod.Email,
  },
  {
    title: 'Телефон',
    value: LeadsLeadsServerV1CommunicationMethod.Phone,
  },
]

export const CommunicationChannelField: FC<FieldCardProps> = ({
  name,
  fieldKey,
  path,
  ...props
}) => {
  const { getFieldValue } = useContext(FormContext)

  const getValueFromEvent = (codes: string[]) =>
    COMMUNICATION_CHANNELS?.filter((ch) => codes.includes(ch.value as string))

  const getValueProps = (value?: typeof COMMUNICATION_CHANNELS) =>
    (value || []).map(({ value }) => value)

  return (
    <FieldCard name={name} fieldKey={fieldKey} path={path} {...props}>
      <Col span={12}>
        <Form.Item
          {...props}
          name={[name, path, 'title']}
          fieldKey={[fieldKey, path, 'title']}
          label="Заголовок поля"
        >
          <Input placeholder="Введите заголовок поля" />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item
          {...props}
          name={[name, path, 'items']}
          fieldKey={[fieldKey, path, 'items']}
          label="Значения"
          getValueFromEvent={getValueFromEvent}
          getValueProps={getValueProps}
        >
          <Select
            allowClear
            mode="multiple"
            maxTagCount={5}
            placeholder="Выберите значение из справочника"
            defaultValue={(
              getFieldValue(['fields', name, path, 'items']) || []
            ).map(({ value }: any) => value)}
            options={COMMUNICATION_CHANNELS.map(({ title, value }) => ({
              label: title,
              value,
            }))}
          />
        </Form.Item>
      </Col>
    </FieldCard>
  )
}
