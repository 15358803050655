import { useParams } from 'react-router-dom'
import { Col, PageHeader, Row, Table } from 'antd'
import Search from 'antd/lib/input/Search'
import { Content } from 'antd/lib/layout/layout'

import { RouteTabs } from '@app/components/RouteTabs'
import { columns, dataSource } from '@app/pages/FormPage/mocks'
import { routePaths } from '@app/Router/constants'

type MatchParams = {
  formId: string
}

export const FormPage = () => {
  const { formId }: MatchParams = useParams()

  const tabs = [
    {
      label: 'Страница размещений',
      url: `${routePaths.forms}/${[formId]}/placement`,
    },
    {
      label: 'Логи изменений',
      url: `${routePaths.forms}/${[formId]}/logs`,
    },
  ]

  return (
    <Content>
      <PageHeader
        title="New Jetta offer request"
        ghost={false}
        extra={[
          <Search
            style={{ width: 272 }}
            key="1"
            placeholder="Введите ID заявки или URL"
            enterButton
          />,
        ]}
      />
      <Row style={{ padding: 24 }}>
        <Col span={24}>
          <RouteTabs data={tabs} />
        </Col>

        <Col span={24}>
          <Table dataSource={dataSource} columns={columns} />
        </Col>
      </Row>
    </Content>
  )
}
