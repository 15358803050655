import {
  Configuration,
  ConfigurationParameters,
  DealersAPIApi,
  DisclaimersAPIApi,
  DisplaysAPIApi,
  FormsAPIApi,
  LeadsAPIApi, MediaAPIApi,
  ModelsAPIApi
} from "../api";

export const BASE_PATH = process.env.NODE_ENV === 'production' ? '/api' : process.env.REACT_APP_BASE_PATH

class Api {
  accessToken: string = ''
  configurationParams: ConfigurationParameters

  ModelsApi: ModelsAPIApi
  LeadsApi: LeadsAPIApi
  DealersApi: DealersAPIApi
  DisclaimersApi: DisclaimersAPIApi
  DisplaysApi: DisplaysAPIApi
  FormsApi: FormsAPIApi
  MediaApi: MediaAPIApi

  constructor(params: ConfigurationParameters) {
    const ApiConfiguration = new Configuration({
      baseOptions: { headers: { authorization: `Bearer ${this.accessToken}` } },
      ...params
    })

    this.configurationParams = params

    this.ModelsApi = new ModelsAPIApi(ApiConfiguration)
    this.LeadsApi = new LeadsAPIApi(ApiConfiguration)
    this.DealersApi = new DealersAPIApi(ApiConfiguration)
    this.DisclaimersApi = new DisclaimersAPIApi(ApiConfiguration)
    this.DisplaysApi = new DisplaysAPIApi(ApiConfiguration)
    this.FormsApi = new FormsAPIApi(ApiConfiguration)
    this.MediaApi = new MediaAPIApi(ApiConfiguration)
  }

  setAccessToken(token: string) {
    this.accessToken = token

    const ApiConfiguration = new Configuration({
      baseOptions: { headers: { authorization: `Bearer ${this.accessToken}` } },
      ...this.configurationParams
    })

    this.ModelsApi = new ModelsAPIApi(ApiConfiguration)
    this.LeadsApi = new LeadsAPIApi(ApiConfiguration)
    this.DealersApi = new DealersAPIApi(ApiConfiguration)
    this.DisclaimersApi = new DisclaimersAPIApi(ApiConfiguration)
    this.DisplaysApi = new DisplaysAPIApi(ApiConfiguration)
    this.FormsApi = new FormsAPIApi(ApiConfiguration)
    this.MediaApi = new MediaAPIApi(ApiConfiguration)
  }
}

export const api = new Api({ basePath: BASE_PATH })
