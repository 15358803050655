import { FC } from 'react'

import {
  FieldCard,
  FieldCardProps,
} from '@app/pages/FormEditPage/Fields/FieldCard/FieldCard'

export const UnknownField: FC<FieldCardProps> = ({
  name,
  fieldKey,
  path,
  ...props
}) => (
  <FieldCard
    name={name}
    fieldKey={fieldKey}
    path={path}
    hideDisclaimer
    hideFooter
    justify="start"
    {...props}
  >
    Поле не распознано: <b>{path}</b>
  </FieldCard>
)
