import { FC, useContext } from 'react'
import { Col, Form, Input, Select } from 'antd'

import { LeadsFormsServerV1PlannedPeriod } from '@app/api'
import {
  FieldCard,
  FieldCardProps,
} from '@app/pages/FormEditPage/Fields/FieldCard/FieldCard'

import { FormContext } from '../../formContext'

const PERIODS = [
  { title: 'Любое', value: LeadsFormsServerV1PlannedPeriod.None },
  {
    title: 'Две недели',
    value: LeadsFormsServerV1PlannedPeriod.TwoWeeksLater,
  },
  { title: 'Месяц', value: LeadsFormsServerV1PlannedPeriod.MonthLater },
  {
    title: 'Три месяца',
    value: LeadsFormsServerV1PlannedPeriod.ThreeMonthLater,
  },
  {
    title: 'Пол года',
    value: LeadsFormsServerV1PlannedPeriod.SixMonthLater,
  },
  { title: 'Год', value: LeadsFormsServerV1PlannedPeriod.YearLater },
  { title: 'Больше года', value: LeadsFormsServerV1PlannedPeriod.MoreThenYear },
]

export const PlannedPeriodField: FC<FieldCardProps> = ({
  name,
  fieldKey,
  path,
  ...props
}) => {
  const { getFieldValue } = useContext(FormContext)

  const getValueFromEvent = (codes: string[]) =>
    PERIODS?.filter((pt) => codes.includes(pt.value as string))

  const getValueProps = (value?: typeof PERIODS) =>
    (value || []).map(({ value }) => value)

  return (
    <FieldCard name={name} fieldKey={fieldKey} path={path} {...props}>
      <Col span={12}>
        <Form.Item
          {...props}
          name={[name, path, 'title']}
          fieldKey={[fieldKey, path, 'title']}
          label="Заголовок поля"
        >
          <Input placeholder="Введите заголовок поля" />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item
          {...props}
          name={[name, path, 'hint']}
          fieldKey={[fieldKey, path, 'hint']}
          label="Подсказка"
        >
          <Input placeholder="Введите подсказку" />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item
          {...props}
          name={[name, path, 'items']}
          fieldKey={[fieldKey, path, 'items']}
          label="Значения"
          getValueFromEvent={getValueFromEvent}
          getValueProps={getValueProps}
        >
          <Select
            allowClear
            mode="multiple"
            placeholder="Выберите значение из справочника"
            defaultValue={getFieldValue(['fields', name, path, 'items'])?.map(
              ({ value }: any) => value
            )}
            options={PERIODS.map(({ title, value }) => ({
              label: title,
              value,
            }))}
          />
        </Form.Item>
      </Col>
    </FieldCard>
  )
}
