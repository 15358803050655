import { useQuery } from 'react-query'

import { api } from '@app/config'

export const useDisclaimer = (id: string) =>
  useQuery(
    ['disclaimer', id],
    () => api.DisclaimersApi.disclaimersAPIGetDisclaimer([id]),
    {
      select({ data }) {
        return data.disclaimers?.[0]
      },
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      enabled: Boolean(id),
    }
  )
