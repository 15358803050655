import { useQuery } from 'react-query'

import { LeadsLeadsServerV1Brand } from '@app/api'
import { api } from '@app/config'

export const vgrBrands = [
  {
    id: LeadsLeadsServerV1Brand.Pkw,
    name: 'Volkswagen',
  },
  { id: LeadsLeadsServerV1Brand.Skoda, name: 'Skoda' },
  {
    id: LeadsLeadsServerV1Brand.Nfz,
    name: 'Volkswagen Commercial',
  },
  { id: LeadsLeadsServerV1Brand.Audi, name: 'Audi' },
] as const

export const useBrands = () =>
  useQuery(
    'brands',
    () =>
      api.ModelsApi.modelsAPIGetBrand(
        undefined,
        undefined,
        undefined,
        undefined,
        ['BRAND_SORT_NAME_ASC'],
        10000
      ),
    {
      select({ data }) {
        return {
          all: data.brands,
          vgr: vgrBrands,
        }
      },
    }
  )
