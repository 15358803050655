import React, { FC } from 'react'

import {
  DictionaryDisclaimersPage,
  DictionaryDisplaysPage,
  DictionaryInterestsPage,
  DictionaryModelsPage,
  FormEditPage,
  FormListPage,
  FormPage,
} from '@app/pages'

import { routePaths } from './constants'

type Route = {
  path: string
  exact?: boolean
  Component: FC<{}>
}

export const routes: Route[] = [
  {
    exact: true,
    path: routePaths.forms,
    Component: FormListPage,
  },
  {
    exact: true,
    path: routePaths.formLogs,
    Component: FormPage,
  },
  {
    exact: true,
    path: routePaths.formPlacement,
    Component: FormPage,
  },
  {
    exact: true,
    path: routePaths.formEdit,
    Component: FormEditPage,
  },
  {
    exact: true,
    path: routePaths.dictionaryDisplays,
    Component: DictionaryDisplaysPage,
  },
  {
    exact: true,
    path: routePaths.dictionaryInterests,
    Component: DictionaryInterestsPage,
  },
  {
    exact: true,
    path: routePaths.dictionaryDisclaimers,
    Component: DictionaryDisclaimersPage,
  },
  {
    exact: true,
    path: routePaths.dictionaryModels,
    Component: DictionaryModelsPage,
  },
  {
    exact: true,
    path: routePaths.accessControls,
    Component: React.Fragment,
  },
]
