import { FC, useContext } from 'react'
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Col, Dropdown, Form, Menu, Row, Typography } from 'antd'
import styled from 'styled-components'

import { BaseSettings } from '@app/pages/FormEditPage/BaseSettings/BaseSettings'

import { menuItems } from './constants'
import { DisplaySettings } from './DisplaySettings'
import { FormContext } from './formContext'
import { mapFields } from './mapFields'

const reorder = (list: unknown[], startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export interface EditStepProps {
  visible: boolean
}

export const EditStep: FC<EditStepProps> = ({ visible }) => {
  const form = useContext(FormContext)

  function onDragEnd(result: DropResult) {
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }

    const values = form.getFieldsValue(true)

    values.fields = reorder(
      values.fields,
      result.source.index,
      result.destination.index
    )

    form.setFieldsValue(values)
  }

  return (
    <Row
      style={{ padding: '24px', display: visible ? 'flex' : 'none' }}
      gutter={[0, 24]}
      justify={'center'}
    >
      <Col span={24}>
        <Typography.Title level={3}>Общие настройки</Typography.Title>
      </Col>
      <Col span={24}>
        <BaseSettings />
      </Col>
      <Col span={24}>
        <Typography.Title level={3}>Редактор формы</Typography.Title>
      </Col>
      <Form.List name="fields">
        {(fields, { add, remove }) => (
          <>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="list">
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className="ant-col ant-col-24"
                  >
                    {fields
                      .map(mapFields(form.getFieldsValue(true), remove))
                      .filter(Boolean)
                      .map((field, index) => (
                        <Draggable
                          draggableId={
                            field?.key?.toString() || index.toString()
                          }
                          index={index}
                          key={field?.key}
                        >
                          {(provided) => (
                            <FieldCol
                              span={24}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              {field}
                            </FieldCol>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <Col span={24} flex={'auto'}>
              <Row justify={'center'}>
                <Form.Item>
                  <Dropdown
                    overlay={
                      <Menu>
                        {menuItems.map(({ label, initialValue }) => (
                          <Menu.Item
                            key={label}
                            onClick={() => add(initialValue)}
                          >
                            {label}
                          </Menu.Item>
                        ))}
                      </Menu>
                    }
                    trigger={['click']}
                  >
                    <Button
                      type="dashed"
                      icon={<PlusOutlined />}
                      size={'large'}
                    >
                      Добавить блок
                    </Button>
                  </Dropdown>
                </Form.Item>
              </Row>
            </Col>
          </>
        )}
      </Form.List>
      <Col span={24}>
        <Typography.Title level={3}>Экран успешной отправки</Typography.Title>
      </Col>
      <Col span={24}>
        <DisplaySettings name="displaySuccess" title="Успешная отправка" />
      </Col>
      <Col span={24}>
        <Typography.Title level={3}>
          Экран с отправки с ошибкой
        </Typography.Title>
      </Col>
      <Col span={24}>
        <DisplaySettings name="displayError" title="Отправка с ошибкой" />
      </Col>
    </Row>
  )
}

const FieldCol = styled(Col)`
  padding-bottom: 24px;
`
