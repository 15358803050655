import { FC } from 'react'
import {
  AlignCenterOutlined,
  AlignLeftOutlined,
  AlignRightOutlined,
  MenuOutlined,
} from '@ant-design/icons'
import { Form, Radio } from 'antd'
import { FormListFieldData } from 'antd/es/form/FormList'

export interface AlignItemProps
  extends Omit<FormListFieldData, 'key' | 'name' | 'fieldKey'> {
  disabled?: boolean
  name: (string | number)[]
  fieldKey: (string | number)[]
}

export const AlignItem: FC<AlignItemProps> = ({
  disabled = false,
  ...restField
}) => (
  <Form.Item {...restField} initialValue={'ALIGN_CENTER'}>
    <Radio.Group
      defaultValue="ALIGN_CENTER"
      style={{ float: 'right' }}
      disabled={disabled}
    >
      <Radio.Button value="ALIGN_NONE">
        <MenuOutlined />
      </Radio.Button>
      <Radio.Button value="ALIGN_LEFT">
        <AlignLeftOutlined />
      </Radio.Button>
      <Radio.Button value="ALIGN_CENTER">
        <AlignCenterOutlined />
      </Radio.Button>
      <Radio.Button value="ALIGN_RIGHT">
        <AlignRightOutlined />
      </Radio.Button>
    </Radio.Group>
  </Form.Item>
)
