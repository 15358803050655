import { FC, useContext } from 'react'
import { Col, Form, Input, Select } from 'antd'

import { LeadsFormsServerV1LeadSource } from '@app/api'
import { useLeadSources } from '@app/hooks/useLeadSources/useLeadSources.hook'
import {
  FieldCard,
  FieldCardProps,
} from '@app/pages/FormEditPage/Fields/FieldCard/FieldCard'

import { FormContext } from '../../formContext'

export const LeadSourceField: FC<FieldCardProps> = ({
  name,
  fieldKey,
  path,
  ...props
}) => {
  const { getFieldValue } = useContext(FormContext)
  const { data: leadSources } = useLeadSources()

  const getValueFromEvent = (ids: string[]) =>
    leadSources
      ?.filter((ls) => ids.includes(ls.id as string))
      .map((ls) => ({ ...ls, title: ls.name, value: ls.id }))

  const getValueProps = (value?: LeadsFormsServerV1LeadSource[]) =>
    (value || []).map(({ id }) => id)

  return (
    <FieldCard name={name} fieldKey={fieldKey} path={path} {...props}>
      <Col span={12}>
        <Form.Item
          {...props}
          name={[name, path, 'title']}
          fieldKey={[fieldKey, path, 'title']}
          label="Заголовок поля"
        >
          <Input placeholder="Введите заголовок поля" />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item
          {...props}
          name={[name, path, 'items']}
          fieldKey={[fieldKey, path, 'items']}
          label="Значения"
          getValueFromEvent={getValueFromEvent}
          getValueProps={getValueProps}
        >
          <Select
            allowClear
            mode="multiple"
            maxTagCount={5}
            placeholder="Выберите значение из справочника"
            defaultValue={getFieldValue(['fields', name, path, 'items'])?.map(
              ({ value }: any) => value
            )}
            options={leadSources?.map(({ name, id }) => ({
              label: name,
              value: id,
            }))}
          />
        </Form.Item>
      </Col>
    </FieldCard>
  )
}
