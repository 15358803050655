import React from 'react'
import { useHistory } from 'react-router-dom'
import { Tabs } from 'antd'

type Tab = {
  label: string
  url: string
}
interface TabsProps {
  data: Tab[]
}

const { TabPane } = Tabs

export const RouteTabs: React.FC<TabsProps> = ({ data }) => {
  const history = useHistory()

  return (
    <Tabs activeKey={history.location.pathname} onTabClick={history.push}>
      {data.map(({ label, url }) => (
        <TabPane tab={label} key={url} />
      ))}
    </Tabs>
  )
}
