import { useQuery } from 'react-query'

import { api } from '@app/config'

export const useGenerations = (models?: string[]) =>
  useQuery(
    ['generations', models],
    () =>
      api.ModelsApi.modelsAPIGetGeneration(
        undefined,
        undefined,
        models,
        undefined,
        ['GENERATION_SORT_NAME_ASC'],
        10000
      ),
    {
      select({ data }) {
        return data.generations
      },
    }
  )
