import { FC, useContext } from 'react'
import { CloseCircleOutlined } from '@ant-design/icons'
import { Card, Checkbox, Col, Form, Row, Select, Typography } from 'antd'
import { FormListFieldData } from 'antd/es/form/FormList'

import { LeadsFormsServerV1Disclaimer } from '@app/api'
import { useDisclaimers } from '@app/hooks/useDisclaimers/useDisclaimers.hook'

import { AlignItem } from '../../components/AlignItem'
import { FormContext } from '../../formContext'

export interface FieldCardProps extends Omit<FormListFieldData, 'key'> {
  remove: (index: number | number[]) => void
  title: string
  path: string
  hideDisclaimer?: boolean
  hideFooter?: boolean
  pending?: boolean
  justify?: 'space-between' | 'start' | 'end' | 'center' | 'space-around'
}

export const FieldCard: FC<FieldCardProps> = ({
  children,
  remove,
  name,
  fieldKey,
  title,
  path,
  hideDisclaimer = false,
  hideFooter = false,
  pending = false,
  justify = 'space-between',
  ...restField
}) => {
  const { getFieldValue, formState } = useContext(FormContext)
  const { data: disclaimers } = useDisclaimers({
    filterBrandIdEq: formState.brandId ? [formState.brandId] : [],
  })

  const getValueFromEvent = (ids: string[]) =>
    (disclaimers || [])
      .filter((dc) => ids.includes(dc.id as string))
      .map((dc) => ({ ...dc, title: dc.props.title, value: dc.id }))?.[0]

  const getValueProps = (value?: LeadsFormsServerV1Disclaimer) => value?.id

  return (
    <Card
      bordered={false}
      title={<Typography.Title level={4}>{title}</Typography.Title>}
      extra={<CloseCircleOutlined onClick={() => remove(name)} />}
      loading={pending || !disclaimers}
    >
      <Row justify={justify} gutter={24}>
        {children}
        {!hideDisclaimer && (
          <Col span={24}>
            <Form.Item
              {...restField}
              name={[name, path, 'disclaimer']}
              fieldKey={[fieldKey, path, 'disclaimer']}
              label="Дисклеймер"
              getValueFromEvent={getValueFromEvent}
              getValueProps={getValueProps as any}
            >
              <Select
                allowClear
                placeholder="Выберите дисклеймер из справочника"
                defaultValue={
                  getFieldValue(['fields', name, path, 'disclaimer'])?.id
                }
                options={disclaimers?.map(({ id, props: { title } }) => ({
                  label: title,
                  value: id,
                }))}
              />
            </Form.Item>
          </Col>
        )}

        {!hideFooter && (
          <Col span={24}>
            <Row>
              <Col span={12}>
                <Row gutter={24}>
                  <Col>
                    <Form.Item
                      {...restField}
                      name={[name, path, 'required']}
                      fieldKey={[fieldKey, path, 'required']}
                      valuePropName="checked"
                    >
                      <Checkbox>Обязательное поле</Checkbox>
                    </Form.Item>
                  </Col>

                  <Col>
                    <Form.Item
                      {...restField}
                      name={[name, path, 'displayed']}
                      fieldKey={[fieldKey, path, 'displayed']}
                      valuePropName="checked"
                    >
                      <Checkbox>Отображать поле</Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <Col span={12}>
                <AlignItem
                  {...restField}
                  name={[name, path, 'align']}
                  fieldKey={[fieldKey, path, 'align']}
                />
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </Card>
  )
}
