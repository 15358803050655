import { FC, useContext, useMemo } from 'react'
import { Checkbox, Col, Form, Input, Row, TreeSelect } from 'antd'
import get from 'lodash/get'

import { LeadsDealersServerV1DealerCenter } from '@app/api'
import { useDealers } from '@app/hooks/useDealers/useDealers.hook'
import {
  FieldCard,
  FieldCardProps,
} from '@app/pages/FormEditPage/Fields/FieldCard/FieldCard'

import { filterOption } from '../../../../helpers/filterOption'
import { FormContext } from '../../formContext'

export const DealerCenterField: FC<FieldCardProps> = ({
  name,
  fieldKey,
  path,
  ...props
}) => {
  const { setFields, formState } = useContext(FormContext)
  const { data } = useDealers({
    brandId: formState.brandId ? [formState.brandId] : [],
  })

  const getValueFromEvent = (codes: string[]) => {
    const filteredDealerCenters = data?.dealerCenters?.filter((center) =>
      codes.includes(center.code as string)
    )

    const selectedCities =
      filteredDealerCenters?.map(({ cityId }) => cityId) || []

    setFields([
      {
        name: ['fields', name, path, 'cities'],
        value: data?.cities?.filter(({ id }) => selectedCities.includes(id)),
      },
    ])

    return filteredDealerCenters
  }

  const treeData = useMemo(
    () =>
      data?.cities?.map((city) => ({
        value: city.id,
        title: city.name || 'Импортер',
        children: data.dealerCenters
          ?.filter((center) => center.cityId === city.id)
          .map((center) => ({
            ...center,
            title: `${center.name} (${
              data.cities?.find(({ id }) => id === center.cityId)?.name || ''
            }, ${center.address}) (${center.brandId})`,
          })),
      })),
    [data]
  )

  return (
    <FieldCard
      name={name}
      fieldKey={fieldKey}
      path={path}
      pending={!data}
      {...props}
    >
      {data && (
        <>
          <Col span={24}>
            <Form.Item
              {...props}
              name={[name, path, 'title']}
              fieldKey={[fieldKey, path, 'title']}
              label="Наименование"
            >
              <Input placeholder="Введите наименование" />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              {...props}
              name={[name, path, 'items']}
              fieldKey={[fieldKey, path, 'items']}
              label="Дилеры"
              getValueFromEvent={getValueFromEvent}
              getValueProps={(value?: LeadsDealersServerV1DealerCenter[]) =>
                (value || []).map(({ code }) => code)
              }
            >
              <TreeSelect
                multiple
                treeCheckable
                disabled={get(formState, ['fields', name, path, 'allItems'])}
                filterTreeNode={(value, option) =>
                  filterOption(value, option, 'title')
                }
                defaultValue={get(formState, [
                  'fields',
                  name,
                  path,
                  'items',
                ])?.map(({ value }: any) => value)}
                treeData={treeData}
                placeholder="Выберите дилерские центры"
                maxTagCount={5}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Row gutter={24}>
              <Col>
                <Form.Item
                  {...props}
                  name={[name, path, 'showMap']}
                  fieldKey={[fieldKey, path, 'showMap']}
                  valuePropName="checked"
                >
                  <Checkbox>Включить отображение карты</Checkbox>
                </Form.Item>
              </Col>

              <Col>
                <Form.Item
                  {...props}
                  name={[name, path, 'allItems']}
                  fieldKey={[fieldKey, path, 'allItems']}
                  valuePropName="checked"
                >
                  <Checkbox>Выбрать всех дилеров</Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </>
      )}
    </FieldCard>
  )
}
