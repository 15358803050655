import { FC } from 'react'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { CopyOutlined } from '@ant-design/icons'
import { Button } from 'antd'

export const CodeBlock: FC<{ code: string }> = ({ code }) => (
  <>
    <Button
      type={'text'}
      icon={<CopyOutlined />}
      size={'small'}
      style={{ position: 'absolute', right: 10, top: 10 }}
      onClick={() => navigator.clipboard.writeText(code)}
      data-testid="code-copy-button"
    />
    <SyntaxHighlighter language="html" customStyle={{ marginBottom: 0 }}>
      {code}
    </SyntaxHighlighter>
  </>
)
