import { FC, useContext } from 'react'
import { Checkbox, Col, Form, Input, Row, Select } from 'antd'

import { LeadsFormsServerV1Disclaimer } from '@app/api'
import { useDisclaimers } from '@app/hooks/useDisclaimers/useDisclaimers.hook'
import {
  FieldCard,
  FieldCardProps,
} from '@app/pages/FormEditPage/Fields/FieldCard/FieldCard'

import { AlignItem } from '../../components/AlignItem'
import { FormContext } from '../../formContext'

export const ConsentsField: FC<FieldCardProps> = ({
  name,
  fieldKey,
  path,
  ...props
}) => {
  const { getFieldValue, formState } = useContext(FormContext)
  const { data: disclaimers } = useDisclaimers({
    filterBrandIdEq: formState.brandId ? [formState.brandId] : [],
  })

  const getValueFromEvent = (ids: string[]) =>
    (disclaimers || [])
      .filter((dc) => ids.includes(dc.id as string))
      .map((dc) => ({ ...dc, title: dc.props.title, value: dc.id }))?.[0]

  const getValueProps = (value?: LeadsFormsServerV1Disclaimer) => value?.id

  return (
    <FieldCard
      name={name}
      fieldKey={fieldKey}
      path={path}
      hideDisclaimer
      hideFooter
      {...props}
    >
      <Col span={24}>
        <Row>
          <Col span={24}>
            <Form.Item
              {...props}
              name={[name, path, 'requestConsent', 'title']}
              fieldKey={[fieldKey, path, 'requestConsent', 'title']}
              label="Наименование согласия"
            >
              <Input placeholder="Введите наименование" />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name={[name, path, 'requestConsent', 'disclaimer']}
              fieldKey={[fieldKey, path, 'requestConsent', 'disclaimer']}
              label="Дисклеймер"
              getValueFromEvent={getValueFromEvent}
              getValueProps={getValueProps as any}
              {...props}
            >
              <Select
                placeholder="Выберите дисклеймер из справочника"
                defaultValue={
                  getFieldValue([
                    'fields',
                    name,
                    path,
                    'requestConsent',
                    'disclaimer',
                  ])?.id
                }
                options={disclaimers?.map(({ id, props: { title } }) => ({
                  label: title,
                  value: id,
                }))}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Row gutter={24}>
              <Col>
                <Form.Item
                  {...props}
                  name={[name, path, 'requestConsent', 'required']}
                  fieldKey={[fieldKey, path, 'requestConsent', 'required']}
                  valuePropName="checked"
                >
                  <Checkbox>Обязательное поле</Checkbox>
                </Form.Item>
              </Col>

              <Col>
                <Form.Item
                  {...props}
                  name={[name, path, 'requestConsent', 'displayed']}
                  fieldKey={[fieldKey, path, 'requestConsent', 'displayed']}
                  valuePropName="checked"
                >
                  <Checkbox>Отображать поле</Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col span={12}>
            <AlignItem
              {...props}
              name={[name, path, 'requestConsent', 'align']}
              fieldKey={[fieldKey, path, 'requestConsent', 'align']}
            />
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Row>
          <Col span={24}>
            <Form.Item
              {...props}
              name={[name, path, 'communicationConsent', 'title']}
              fieldKey={[fieldKey, path, 'communicationConsent', 'title']}
              label="Наименование коммуникации"
            >
              <Input placeholder="Введите наименование" />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name={[name, path, 'communicationConsent', 'disclaimer']}
              fieldKey={[fieldKey, path, 'communicationConsent', 'disclaimer']}
              label="Дисклеймер"
              getValueFromEvent={getValueFromEvent}
              getValueProps={getValueProps as any}
              {...props}
            >
              <Select
                allowClear
                placeholder="Выберите дисклеймер из справочника"
                defaultValue={
                  getFieldValue([
                    'fields',
                    name,
                    path,
                    'communicationConsent',
                    'disclaimer',
                  ])?.id
                }
                options={disclaimers?.map(({ id, props: { title } }) => ({
                  label: title,
                  value: id,
                }))}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Row gutter={24}>
              <Col>
                <Form.Item
                  {...props}
                  name={[name, path, 'communicationConsent', 'required']}
                  fieldKey={[
                    fieldKey,
                    path,
                    'communicationConsent',
                    'required',
                  ]}
                  valuePropName="checked"
                >
                  <Checkbox>Обязательное поле</Checkbox>
                </Form.Item>
              </Col>

              <Col>
                <Form.Item
                  {...props}
                  name={[name, path, 'communicationConsent', 'displayed']}
                  fieldKey={[
                    fieldKey,
                    path,
                    'communicationConsent',
                    'displayed',
                  ]}
                  valuePropName="checked"
                >
                  <Checkbox>Отображать поле</Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col span={12}>
            <AlignItem
              {...props}
              name={[name, path, 'communicationConsent', 'align']}
              fieldKey={[fieldKey, path, 'communicationConsent', 'align']}
            />
          </Col>
        </Row>
      </Col>
    </FieldCard>
  )
}
