import { FC, useContext } from 'react'
import { useParams } from 'react-router'
import leadsReact from '@leads/react-sdk/package.json'
import { Button, Card, Checkbox, Col, Form, Row, Typography } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'

import { LeadsFormsServerV1PublishOn } from '@app/api'
import { FormContext } from '@app/pages/FormEditPage/formContext'

import { themeMap } from './PreviewStep/PreviewStep'
import { CodeBlock } from '../../components/CodeBlock/CodeBlock'

const FormNotSavedAlert = () => {
  const { submit } = useContext(FormContext)

  return (
    <>
      Форма не сохранена 🙄
      <Button type={'link'} onClick={submit}>
        Сохранить
      </Button>
    </>
  )
}

// Works until console and api located at the same origin
const basePath =
  process.env.NODE_ENV === 'production'
    ? `${window.location.origin}/api`
    : process.env.REACT_APP_BASE_PATH

export const PublishStep: FC<{ visible: boolean }> = ({ visible }) => {
  const { formId, version } = useParams<{ formId?: string; version?: string }>()
  const { formState, getFieldsValue } = useContext(FormContext)

  const themeKey = formState.brandId
    ? themeMap[formState.brandId as keyof typeof themeMap]
    : 'vw'

  const getPublishOnFromEvent =
    (platform: LeadsFormsServerV1PublishOn) => (e: CheckboxChangeEvent) => {
      const values = getFieldsValue(true)
      if (e.target.checked) {
        return Array.from(new Set([...(values.publishOn || []), platform]))
      } else {
        return values.publishOn.filter(
          (it: LeadsFormsServerV1PublishOn) => it !== platform
        )
      }
    }

  return (
    <Row
      style={{ padding: '24px', display: visible ? 'flex' : 'none' }}
      gutter={[0, 24]}
      justify={'center'}
    >
      <Col span={24}>
        <Typography.Title level={3}>Публикация</Typography.Title>
      </Col>
      <Col span={24}>
        <Card
          title={
            <Typography.Title level={4}>Виджет для Платформы</Typography.Title>
          }
          bordered={false}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                noStyle
                name="publishOn"
                valuePropName="checked"
                getValueProps={(value) => ({
                  checked: value.includes(LeadsFormsServerV1PublishOn.Vdp),
                })}
                getValueFromEvent={getPublishOnFromEvent(
                  LeadsFormsServerV1PublishOn.Vdp
                )}
              >
                <Checkbox>Добавить на платформу</Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Col>

      <Col span={24}>
        <Card
          title={
            <Typography.Title level={4}>JavaScript-компонент</Typography.Title>
          }
          bordered={false}
        >
          <Row>
            <Col span={24}>
              {formId && version ? (
                <CodeBlock
                  code={`<div\n  id="leads-form-${formId}"\n  data-name="${formId}"\n  data-version="${version}"\n  data-theme="${themeKey}"\n  data-basePath="${basePath}">\n</div>\n\n<!-- Нужен только один тэг script на странице -->\n<script src="https://s-dt2.cloud.gcore.lu/forms/leads-forms-widget-${leadsReact.version}.min.js"></script>`}
                />
              ) : (
                <FormNotSavedAlert />
              )}
            </Col>
          </Row>
        </Card>
      </Col>

      <Col span={24}>
        <Card
          title={<Typography.Title level={4}>iframe</Typography.Title>}
          bordered={false}
        >
          <Row>
            <Col span={24}>
              {formId && version ? (
                <CodeBlock
                  code={`<iframe\n  id="${formId}"\n  src="https://lgw.vwgroup.ru/embedded/?theme=${themeKey}&name=${formId}&version=${version}&basePath=${basePath}"\n  style="width: 100%; border: none; overflow:hidden;">\n</iframe>\n\n<!-- Нужен только один тэг script на странице -->\n<script>\n  window.addEventListener("message", (e) => document.getElementById(e.data.name).style.height = e.data.height + 'px', false);\n</script>`}
                />
              ) : (
                <FormNotSavedAlert />
              )}
            </Col>
          </Row>
        </Card>
      </Col>

      <Col span={24}>
        <Card
          title={<Typography.Title level={4}>Feature app</Typography.Title>}
          bordered={false}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                name="publishOn"
                valuePropName="checked"
                getValueProps={(value) => ({
                  checked: value.includes(
                    LeadsFormsServerV1PublishOn.FeatureApp
                  ),
                })}
                getValueFromEvent={getPublishOnFromEvent(
                  LeadsFormsServerV1PublishOn.FeatureApp
                )}
                noStyle
              >
                <Checkbox>Опубликовать через Feature app</Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  )
}
