import { FC } from 'react'
import { Col, Form, Input, Row } from 'antd'

import { FieldCardProps } from '@app/pages/FormEditPage/Fields/FieldCard/FieldCard'

export const ColorBackgroundConfiguration: FC<FieldCardProps> = ({
  name,
  path,
  fieldKey,
  ...props
}) => (
  <>
    <Col span={12}>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) => (
          <Row gutter={24}>
            <Col flex={'auto'}>
              <Form.Item
                {...props}
                name={[name, path, 'background', 'color']}
                fieldKey={[fieldKey, path, 'background', 'color']}
                label="Цвет фона"
                extra={
                  <>
                    Сгенерировать градиент можно{' '}
                    <a
                      href="https://cssgradient.io/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      тут
                    </a>
                    .
                  </>
                }
              >
                <Input placeholder="Цвет фона" />
              </Form.Item>
            </Col>
            <Col style={{ display: 'flex', alignItems: 'flex-end' }}>
              <div
                style={{
                  width: 104,
                  height: 104,
                  border: '1px dashed #ccc',
                  background: getFieldValue([
                    'fields',
                    name,
                    path,
                    'background',
                    'color',
                  ]),
                }}
              ></div>
            </Col>
          </Row>
        )}
      </Form.Item>
    </Col>
  </>
)
