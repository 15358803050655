import { useQuery } from 'react-query'

import { api } from '@app/config'

import {
  LeadsFormsServerV1DisplaySort,
  LeadsLeadsServerV1Brand,
} from '../../api/api'

export interface UseDisplaysProps {
  filterBrandIdEq?: LeadsLeadsServerV1Brand[]
  search?: string
  sort?: LeadsFormsServerV1DisplaySort
}

export const useDisplays = (props: UseDisplaysProps) => {
  const { filterBrandIdEq, search, sort } = props

  return useQuery(
    ['displays', props],
    () =>
      api.DisplaysApi.displaysAPIGetDisplay(
        undefined,
        undefined,
        filterBrandIdEq,
        undefined,
        search ? [search] : undefined,
        undefined,
        undefined,
        undefined,
        sort ? [sort] : undefined
      ),
    {
      select({ data }) {
        return data.displays
      },
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  )
}
