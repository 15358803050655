import { useState } from 'react'
import { Button, Col, Form, Input, message, Modal, Row, Select } from 'antd'

import { LeadsFormsServerV1DisclaimerProps } from '@app/api'
import { RichText } from '@app/components/RichText'
import { api } from '@app/config'
import { useBrands } from '@app/hooks/useBrands/useBrands.hook'
import { useDisclaimer } from '@app/hooks/useDisclaimer/useDisclaimer.hook'

interface DisclaimerEditProps {
  onSuccess: () => void
  onCancel: () => void
  visible: boolean
  disclaimerId?: string
}

export const DisclaimerEdit: React.FC<DisclaimerEditProps> = ({
  onSuccess,
  onCancel,
  visible,
  disclaimerId,
}) => {
  const { data: brands } = useBrands()

  const { data: disclaimer } = useDisclaimer(disclaimerId as string)

  const [savePending, setSavePending] = useState(false)

  const handleSave = async (values: LeadsFormsServerV1DisclaimerProps) => {
    setSavePending(true)

    try {
      if (disclaimerId) {
        await api.DisclaimersApi.disclaimersAPIUpdateDisclaimer(
          disclaimerId,
          values
        )
      } else {
        await api.DisclaimersApi.disclaimersAPICreateDisclaimer(values)
      }

      onSuccess()
      message.success('Дисклеймер успешно сохранен')
    } catch (e) {
      console.error(e)

      message.error('При сохранении произошла ошибка')
    } finally {
      setSavePending(false)
    }
  }

  if (disclaimerId && !disclaimer?.props) return null

  return (
    <Modal
      title={disclaimerId ? 'Редактирование дисклеймера' : 'Новый дисклеймер'}
      visible={visible}
      onCancel={onCancel}
      footer={null}
      confirmLoading={savePending}
      width={800}
      key={disclaimerId}
    >
      <Form
        name={disclaimerId}
        initialValues={disclaimer?.props}
        layout="vertical"
        onFinish={handleSave}
        preserve={false}
      >
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name="brandId"
              label="Бренд"
              rules={[{ required: true, message: 'Выберите бренд' }]}
            >
              <Select
                allowClear
                disabled={savePending}
                placeholder="Выберите бренд"
                options={brands?.vgr.map(({ name, id }) => ({
                  label: name,
                  value: id,
                }))}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name="title"
              label="Название дисклеймера"
              rules={[
                {
                  required: true,
                  message: 'Введите название',
                },
              ]}
            >
              <Input disabled={savePending} placeholder="Введите название" />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item name="comment" label="Комментарий">
              <Input disabled={savePending} placeholder="Введите комментарий" />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label="Формат"
              name="format"
              initialValue={disclaimer?.props.url ? 'url' : 'text'}
            >
              <Select
                allowClear={false}
                options={[
                  { label: 'Текст', value: 'text' },
                  { label: 'Ссылка', value: 'url' },
                ]}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => (
                <>
                  {getFieldValue('format') === 'text' && (
                    <Form.Item
                      name="text"
                      label="Текст дисклеймера"
                      rules={[
                        {
                          required: true,
                          message: 'Введите текст',
                        },
                      ]}
                    >
                      <RichText />
                    </Form.Item>
                  )}

                  {getFieldValue('format') === 'url' && (
                    <Form.Item
                      name="url"
                      label="Ссылка"
                      rules={[
                        {
                          required: true,
                          message: 'Введите ссылку',
                        },
                      ]}
                    >
                      <Input placeholder="Введите ссылку" />
                    </Form.Item>
                  )}
                </>
              )}
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Сохранить
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
