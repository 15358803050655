import { FC } from 'react'
import { Col, Form, Input, Row, Select } from 'antd'

import { MediaAPIApi } from '@app/api'
import { RichText } from '@app/components/RichText/RichText'
import { SingleUpload } from '@app/components/SingleUpload/SingleUpload'
import {
  FieldCard,
  FieldCardProps,
} from '@app/pages/FormEditPage/Fields/FieldCard/FieldCard'

import { ColorBackgroundConfiguration } from './Background/Color'
import { ImageBackgroundConfiguration } from './Background/Image'
import { VideoBackgroundConfiguration } from './Background/Video'

export const HeaderField: FC<FieldCardProps> = ({
  name,
  fieldKey,
  path,
  ...props
}) => {
  return (
    <FieldCard
      name={name}
      fieldKey={fieldKey}
      path={path}
      hideDisclaimer
      hideFooter
      {...props}
    >
      <Col span={24}>
        <Form.Item
          {...props}
          name={[name, path, 'description']}
          fieldKey={[fieldKey, path, 'description']}
          label="Текст"
        >
          <RichText />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue, setFieldsValue, getFieldsValue }) => (
            <Form.Item label="Фон">
              <Select
                allowClear={false}
                options={[
                  { label: 'Изображение', value: 'image' },
                  { label: 'Цвет', value: 'color' },
                  { label: 'Видео', value: 'video' },
                ]}
                onChange={(e: string) => {
                  const value = getFieldsValue(true)
                  value.fields[name][path].background = { [e]: null }
                  setFieldsValue(value)
                }}
                value={
                  Object.keys(
                    getFieldValue(['fields', name, path, 'background'])
                  )[0]
                }
              />
            </Form.Item>
          )}
        </Form.Item>
      </Col>

      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) => {
          const background = getFieldValue(['fields', name, path, 'background'])

          if ('image' in background) {
            return (
              <ImageBackgroundConfiguration
                name={name}
                path={path}
                fieldKey={fieldKey}
                {...props}
              />
            )
          } else if ('video' in background) {
            return (
              <VideoBackgroundConfiguration
                name={name}
                path={path}
                fieldKey={fieldKey}
                {...props}
              />
            )
          } else if ('color' in background) {
            return (
              <ColorBackgroundConfiguration
                name={name}
                path={path}
                fieldKey={fieldKey}
                {...props}
              />
            )
          }

          return null
        }}
      </Form.Item>

      <Col span={12}>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue, setFieldsValue, getFieldsValue }) => (
            <Row gutter={24}>
              <Col flex={'1 1'}>
                <Form.Item
                  {...props}
                  name={[name, path, 'modelPhoto']}
                  fieldKey={[fieldKey, path, 'modelPhoto']}
                  label="Изображение"
                  extra={'Изображение формата png, 16х9 c прозрачным фоном'}
                >
                  <Input placeholder="Изображение" />
                </Form.Item>
              </Col>
              <Col style={{ display: 'flex', alignItems: 'flex-start' }}>
                <SingleUpload
                  photoUrl={getFieldValue(['fields', name, path, 'modelPhoto'])}
                  customRequest={async ({ file, onSuccess }) => {
                    const mediaApi = new MediaAPIApi()

                    const res = await mediaApi.mediaAPICreateImage(file)
                    const value = getFieldsValue(true)
                    value.fields[name][
                      path
                    ].modelPhoto = `${process.env.REACT_APP_CDN_URL}${res.data.mediaId}`
                    setFieldsValue(value)

                    onSuccess?.({}, new XMLHttpRequest())
                  }}
                  onRemove={() => {
                    const value = getFieldsValue(true)
                    value.fields[name][path].modelPhoto = null
                    setFieldsValue(value)
                  }}
                />
              </Col>
            </Row>
          )}
        </Form.Item>
      </Col>
    </FieldCard>
  )
}
