import { useInfiniteQuery } from 'react-query'

import { api } from '@app/config'

import {
  LeadsActivityV1Status,
  LeadsFormsServerV1FormSort,
  LeadsLeadsServerV1Brand,
} from '../../api/api'

export interface useFormsProps {
  search?: string
  sort?: LeadsFormsServerV1FormSort
  brands?: LeadsLeadsServerV1Brand[]
  statuses?: LeadsActivityV1Status[]
}

export const useForms = (props: useFormsProps) => {
  const { search, sort, brands, statuses } = props

  return useInfiniteQuery(
    ['forms', props],
    ({ pageParam: pageToken }) =>
      api.FormsApi.formsAPIGetForms(
        undefined,
        undefined,
        brands,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        statuses,
        undefined,
        undefined,
        undefined,
        search ? [search] : undefined,
        undefined,
        10,
        pageToken,
        sort ? [sort] : undefined
      ),
    {
      getNextPageParam: (lastPage) => lastPage.data.count?.token,
      select(data) {
        return {
          ...data,
          pages: data.pages.map(({ data }) => ({
            ...data,
            forms: data.forms?.map((form) => ({ ...form, key: form.id })),
          })),
        }
      },
      keepPreviousData: true,
    }
  )
}
