import { useState } from 'react'
import { Upload as AntdUpload, UploadProps as AntdUploadProps } from 'antd'
import { UploadFile } from 'antd/lib/upload/interface'
import styled from 'styled-components'

export interface MultiUploadProps extends AntdUploadProps {
  value: string[]
  onRemoveIndex?: (index: number) => void
  photos?: string[]
}

export const MultiUpload: React.FC<MultiUploadProps> = ({
  maxCount = 5,
  value,
  onRemoveIndex,
  photos,
  ...rest
}) => {
  const [fileList, setFileList] = useState<UploadFile[]>([])

  return (
    <StyledAntdUpload
      showUploadList={{
        showPreviewIcon: false,
      }}
      defaultFileList={(photos || []).map((url, index) => ({
        uid: String(index),
        name: 'image.png',
        status: 'done',
        url,
      }))}
      listType="picture-card"
      onRemove={(file) => {
        if (onRemoveIndex) {
          const removeIndex = fileList.findIndex(({ uid }) => uid === file.uid)

          onRemoveIndex(removeIndex)
        }
      }}
      onChange={(info) => setFileList(info.fileList)}
      {...rest}
    >
      {value?.length <= maxCount && '+'}
    </StyledAntdUpload>
  )
}

const StyledAntdUpload = styled(AntdUpload)({
  '.ant-upload.ant-upload-select-picture-card': { margin: 0 },
})
