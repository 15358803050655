import { useQuery } from 'react-query'

import { api } from '@app/config'

import { sortBy } from '../../helpers/sort'

export const useLeadChannels = () =>
  useQuery('leadChannels', () => api.LeadsApi.leadsAPIGetLeadChannel(), {
    select({ data }) {
      return data.leadChannels?.sort(sortBy('name'))
    },
  })
