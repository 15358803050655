import { FC } from 'react'
import { Checkbox, Col, Form, Input } from 'antd'
import _set from 'lodash/set'

import { StepBreakField as StepBreakFieldType } from '@app/api'
import {
  FieldCard,
  FieldCardProps,
} from '@app/pages/FormEditPage/Fields/FieldCard/FieldCard'

import { LeadsFormsServerV1Field } from '../../../../api/api'
import { AlignItem } from '../../components/AlignItem'
import { isField } from '../../mapFields'

export const StepBreakField: FC<FieldCardProps> = ({
  name,
  fieldKey,
  path,
  ...props
}) => {
  return (
    <FieldCard
      name={name}
      fieldKey={fieldKey}
      path={path}
      hideFooter
      hideDisclaimer
      {...props}
    >
      <Col span={24}>
        <Form.Item
          {...props}
          name={[name, path, 'buttonName']}
          fieldKey={[fieldKey, path, 'buttonName']}
          label="Название кнопки для перехода на следующий шаг"
        >
          <Input placeholder="Введите название" />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldsValue, getFieldValue }) => (
            <Form.Item
              {...props}
              name={[name, path, 'showSteps']}
              fieldKey={[fieldKey, path, 'showSteps']}
              valuePropName="checked"
              initialValue={(
                getFieldValue(['fields']) as LeadsFormsServerV1Field[]
              ).some(
                (field) =>
                  isField<StepBreakFieldType>(field, 'stepBreak') &&
                  field.stepBreak.showSteps
              )}
            >
              <Checkbox
                onChange={(e) => {
                  const fields = getFieldValue([
                    'fields',
                  ]) as LeadsFormsServerV1Field[]

                  fields.forEach((field, index) => {
                    if (isField<StepBreakFieldType>(field, 'stepBreak')) {
                      _set(
                        getFieldsValue(true),
                        ['fields', index, 'stepBreak', 'showSteps'],
                        e.target.checked
                      )
                    }
                  })
                }}
              >
                Выводить номер шага (для всех шагов)
              </Checkbox>
            </Form.Item>
          )}
        </Form.Item>
      </Col>
      <Col span={12}>
        <AlignItem
          {...props}
          name={[name, path, 'align']}
          fieldKey={[fieldKey, path, 'align']}
        />
      </Col>
    </FieldCard>
  )
}
