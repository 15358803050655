import { useHistory, useLocation } from 'react-router-dom'
import {
  FileTextOutlined,
  TeamOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons'
import { Layout, Menu } from 'antd'

import { routePaths } from '@app/Router/constants'

import { Logo } from './styled'

export const Header = () => {
  const { pathname } = useLocation()
  const history = useHistory()

  return (
    <Layout.Header style={{ padding: '0 24px' }}>
      <Logo>Form builder</Logo>

      <Menu selectedKeys={[pathname]} theme="dark" mode="horizontal">
        <Menu.Item
          icon={<FileTextOutlined />}
          key={routePaths.forms}
          data-testid={routePaths.forms}
          onClick={() => history.push(routePaths.forms)}
        >
          Формы
        </Menu.Item>

        <Menu.Item
          icon={<TeamOutlined />}
          key={routePaths.accessControls}
          data-testid={routePaths.accessControls}
          onClick={() => history.push(routePaths.accessControls)}
        >
          Управление доступами
        </Menu.Item>

        <Menu.SubMenu
          icon={<UnorderedListOutlined />}
          data-testid={routePaths.dictionary}
          key={routePaths.dictionary}
          title="Справочники"
        >
          <Menu.Item
            key={'/dictionary/disclaimers'}
            data-testid={'/dictionary/disclaimers'}
            onClick={() => history.push('/dictionary/disclaimers')}
          >
            Дисклеймеры
          </Menu.Item>

          <Menu.Item
            key={'/dictionary/displays'}
            data-testid={'/dictionary/displays'}
            onClick={() => history.push('/dictionary/displays')}
          >
            Экран с отправкой форм
          </Menu.Item>

          <Menu.Item
            key={'/dictionary/interests'}
            data-testid={'/dictionary/interests'}
            onClick={() => history.push('/dictionary/interests')}
          >
            Типы интересов
          </Menu.Item>

          <Menu.Item
            key={'/dictionary/models'}
            data-testid={'/dictionary/models'}
            onClick={() => history.push('/dictionary/models')}
          >
            Модели
          </Menu.Item>

          <Menu.Item
            key={routePaths.dictionaryTestDrive}
            data-testid={routePaths.dictionaryTestDrive}
            onClick={() => history.push(routePaths.dictionaryTestDrive)}
          >
            Модели тест-драйв
          </Menu.Item>
        </Menu.SubMenu>
      </Menu>
    </Layout.Header>
  )
}
