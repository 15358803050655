import { FC } from 'react'
import { Col, Form, Input, Row } from 'antd'

export const ColorBackgroundConfiguration: FC = () => (
  <Form.Item noStyle shouldUpdate>
    {({ getFieldValue }) => (
      <Row gutter={24}>
        <Col flex={'auto'}>
          <Form.Item
            name={['background', 'color']}
            fieldKey={['background', 'color']}
            label="Цвет фона"
            extra={
              <>
                Сгенерировать градиент можно{' '}
                <a
                  href="https://cssgradient.io/"
                  target="_blank"
                  rel="noreferrer"
                >
                  тут
                </a>
                .
              </>
            }
          >
            <Input placeholder="Цвет фона" />
          </Form.Item>
        </Col>
        <Col style={{ display: 'flex', alignItems: 'flex-end' }}>
          <div
            style={{
              width: 104,
              height: 104,
              border: '1px dashed #ccc',
              background: getFieldValue(['background', 'color']),
            }}
          ></div>
        </Col>
      </Row>
    )}
  </Form.Item>
)
