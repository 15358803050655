import { STEPS } from '@app/pages/FormEditPage/constants'

export const routePaths = {
  startPage: '/',
  forms: '/forms',
  formEdit: `/forms/:formId?/:version?/:mode(${STEPS.join('|')})`,
  formLogs: '/forms/:formId/logs',
  formPlacement: '/forms/:formId/placement',
  dictionary: '/dictionary',
  dictionaryDisclaimers: '/dictionary/disclaimers/:edit?/:disclaimerId?',
  dictionaryModels: '/dictionary/models/:edit?/:modelId?',
  dictionaryTestDrive: '/dictionary/test-drive',
  dictionaryInterests: '/dictionary/interests/:edit?/:interestsId?',
  dictionaryDisplays: '/dictionary/displays/:edit?/:displayId?',
  accessControls: '/access-controls',
} as const
