import { useQuery } from 'react-query'

import { LeadsModelsServerV1ModelSort } from '@app/api'
import { api } from '@app/config'

export interface UseModelsProps {
  brands?: string[]
  search?: string
  sort?: LeadsModelsServerV1ModelSort
}

export const useModels = (props: UseModelsProps) => {
  const { brands, search, sort } = props

  return useQuery(
    ['models', props],
    () =>
      api.ModelsApi.modelsAPIGetModel(
        undefined,
        undefined,
        brands,
        undefined,
        search ? [search] : undefined,
        undefined,
        sort ? [sort] : [LeadsModelsServerV1ModelSort.NameAsc],
        10000
      ),
    {
      select({ data }) {
        return data.models
      },
      keepPreviousData: true,
    }
  )
}
