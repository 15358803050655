import { FC } from 'react'
import { Col, Form, Input, Row } from 'antd'

import { MediaAPIApi } from '@app/api'
import { SingleUpload } from '@app/components/SingleUpload/SingleUpload'
import { FieldCardProps } from '@app/pages/FormEditPage/Fields/FieldCard/FieldCard'

export const ImageBackgroundConfiguration: FC<FieldCardProps> = ({
  name,
  path,
  fieldKey,
  ...props
}) => (
  <>
    <Col span={12}>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue, getFieldsValue, setFieldsValue }) => (
          <Row gutter={24}>
            <Col flex={'auto'}>
              <Form.Item
                {...props}
                name={[name, path, 'background', 'image']}
                fieldKey={[fieldKey, path, 'background', 'image']}
                label="Изображение фона"
              >
                <Input placeholder="Изображение фона" />
              </Form.Item>
            </Col>
            <Col style={{ display: 'flex', alignItems: 'flex-end' }}>
              <SingleUpload
                photoUrl={getFieldValue([
                  'fields',
                  name,
                  path,
                  'background',
                  'image',
                ])}
                customRequest={async ({ file, onSuccess }) => {
                  const mediaApi = new MediaAPIApi()

                  const res = await mediaApi.mediaAPICreateImage(file)
                  const value = getFieldsValue(true)
                  value.fields[name][
                    path
                  ].background.image = `${process.env.REACT_APP_CDN_URL}${res.data.mediaId}`
                  setFieldsValue(value)

                  onSuccess?.({}, new XMLHttpRequest())
                }}
                onRemove={() => {
                  const value = getFieldsValue(true)
                  value.fields[name][path].background.image = null
                  setFieldsValue(value)
                }}
              />
            </Col>
          </Row>
        )}
      </Form.Item>
    </Col>
  </>
)
