import { useEffect, useState } from 'react'
import { Col, Form, message, Modal, Row, Select } from 'antd'

import { LeadsModelsServerV1ModelProps, MediaAPIApi } from '@app/api'
import { MultiUpload } from '@app/components/MultiUpload'
import { api } from '@app/config'
import { useBrands } from '@app/hooks/useBrands/useBrands.hook'
import { useModel } from '@app/hooks/useModel/useModel.hook'
import { useModels } from '@app/hooks/useModels/useModels.hook'

import { ColorBackgroundConfiguration } from './Background/Color'
import { ImageBackgroundConfiguration } from './Background/Image'
import { VideoBackgroundConfiguration } from './Background/Video'

interface ModelEditProps {
  onSuccess: () => void
  onCancel: () => void
  visible: boolean
  modelId?: string
}

export const ModelEdit: React.FC<ModelEditProps> = ({
  onSuccess,
  onCancel,
  visible,
  modelId,
}) => {
  const { data: brands } = useBrands()
  const { data: models } = useModels({})

  const [form] = Form.useForm<LeadsModelsServerV1ModelProps>()

  const [savePending, setSavePending] = useState(false)

  const { data: model } = useModel(modelId as string, (model) => {
    if (model?.id && model.brandId && modelId) {
      form.setFieldsValue({
        id: model.id,
        brandId: model.brandId,
        ...(model.props || {}),
      } as LeadsModelsServerV1ModelProps)
    }
  })

  useEffect(() => {
    if (visible) {
      form.resetFields()
    }
  }, [visible])

  const handleSave = async () => {
    setSavePending(true)

    try {
      const values = form.getFieldsValue(true)

      if (modelId) {
        await api.ModelsApi.modelsAPIUpdateModel(modelId, values)
      }

      onSuccess()
      form.resetFields()
      message.success('Модель успешно сохранена')
    } catch (e) {
      console.error(e)

      message.error('При сохранении произошла ошибка')
    } finally {
      setSavePending(false)
    }
  }

  if (modelId && !model) return null

  return (
    <Modal
      title={modelId ? 'Редактирование модели' : 'Новая модель'}
      visible={visible}
      onCancel={onCancel}
      onOk={() => form.validateFields().then(handleSave)}
      confirmLoading={savePending}
      width={800}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSave}
        initialValues={{ photos: [], background: { image: null } }}
      >
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item name="brandId" label="Бренд">
              <Select
                disabled
                placeholder="Бренд"
                options={brands?.all?.map(({ name, id }) => ({
                  label: name,
                  value: id,
                }))}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="id" label="Модель">
              <Select
                disabled
                placeholder="Модель"
                options={models?.map(({ name, id }) => ({
                  label: name,
                  value: id,
                }))}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item label="Изображение ракурса" shouldUpdate>
              {({ getFieldsValue, setFieldsValue, getFieldValue }) => (
                <MultiUpload
                  showUploadList={{
                    showPreviewIcon: false,
                  }}
                  listType="picture-card"
                  customRequest={async ({ file, onSuccess }) => {
                    const mediaApi = new MediaAPIApi()

                    const res = await mediaApi.mediaAPICreateImage(file)
                    const resUrl = `${process.env.REACT_APP_CDN_URL}${res.data.mediaId}`
                    const formValue = getFieldsValue(true)

                    formValue.photos.push(resUrl)

                    setFieldsValue(formValue)

                    onSuccess?.({}, new XMLHttpRequest())
                  }}
                  onRemoveIndex={(index) => {
                    if (index !== -1) {
                      const formValue = getFieldsValue(true)
                      formValue.photos.splice(index, 1)

                      setFieldsValue(formValue)
                    }
                  }}
                  value={getFieldValue('photos')}
                  photos={getFieldValue('photos')}
                />
              )}
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue, setFieldsValue, getFieldsValue }) => (
                <Form.Item label="Фон">
                  <Select
                    allowClear={false}
                    options={[
                      { label: 'Изображение', value: 'image' },
                      { label: 'Цвет', value: 'color' },
                      { label: 'Видео', value: 'video' },
                    ]}
                    onChange={(e: string) => {
                      const value = getFieldsValue(true)
                      value.background = { [e]: null }
                      setFieldsValue(value)
                    }}
                    value={Object.keys(getFieldValue(['background']))[0]}
                  />
                </Form.Item>
              )}
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue }) => {
                const background = getFieldValue(['background'])

                if ('image' in background) {
                  return <ImageBackgroundConfiguration />
                } else if ('video' in background) {
                  return <VideoBackgroundConfiguration />
                } else if ('color' in background) {
                  return <ColorBackgroundConfiguration />
                }

                return null
              }}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
