import { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import {
  CaretDownOutlined,
  EditFilled,
  EllipsisOutlined,
  FunnelPlotTwoTone,
  UndoOutlined,
} from '@ant-design/icons'
import {
  Button,
  Col,
  Dropdown,
  Input,
  Layout,
  Menu,
  Modal,
  notification,
  PageHeader,
  Popover,
  Row,
  Table,
  Tag,
  Tree,
} from 'antd'
import moment from 'moment'

import {
  LeadsFormsServerV1FormListItem,
  LeadsFormsServerV1FormSort,
} from '@app/api'
import { api } from '@app/config'
import { applySort } from '@app/helpers/applySort'
import { useDebounce } from '@app/hooks/useDebounce/useDebounce'
import { useForms } from '@app/hooks/useForms/useForms.hook'
import { useInterests } from '@app/hooks/useInterests/useInterests.hook'
import { routePaths } from '@app/Router/constants'

import { availableFilters } from './constants'
import {
  LeadsActivityV1Activity,
  LeadsActivityV1Status,
  LeadsLeadsServerV1Brand,
} from '../../api/api'

const { confirm } = Modal
const showSuccessNotification = (description: string) => {
  notification.success({
    message: 'Успешно',
    description,
  })
}
const showErrorNotification = (description: string) => {
  notification.error({
    message: 'Ошибка',
    description,
  })
}

export const FormListPage = () => {
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce<string>(search, 200)
  const [sort, setSort] = useState(LeadsFormsServerV1FormSort.None)
  const [statusFilter, setStatusFilter] = useState<LeadsActivityV1Status[]>([
    LeadsActivityV1Status.None,
    LeadsActivityV1Status.Active,
    LeadsActivityV1Status.Disabled,
  ])
  const [brandFilter, setBrandFilter] = useState<LeadsLeadsServerV1Brand[]>([])

  const {
    isLoading,
    data,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
  } = useForms({
    search: debouncedSearch,
    sort,
    brands: brandFilter,
    statuses: statusFilter,
  })
  const { data: interests } = useInterests({})
  const history = useHistory()

  const handleArchive = (formId: string) => {
    confirm({
      title: 'Архивирование формы',
      content: 'Вы уверены что хотите архивировать форму?',
      okText: 'Архивировать',
      onOk() {
        api.FormsApi.formsAPIUpdateFormStatus(formId, {
          status: LeadsActivityV1Status.Archived,
        })
          .then(() => refetch())
          .then(() => showSuccessNotification('Форма успешно заархивирована'))
          .catch(() => {
            showErrorNotification('При архивировании произошла ошибка')
          })
      },
      cancelText: 'Отменить',
      maskClosable: true,
    })
  }

  const handleUnarchive = (formId: string) => {
    confirm({
      title: 'Разархивирование формы',
      content: 'Вы уверены что хотите разархивировать форму?',
      okText: 'Разархивировать',
      onOk() {
        api.FormsApi.formsAPIUpdateFormStatus(formId, {
          status: LeadsActivityV1Status.Active,
        })
          .then(() => refetch())
          .then(() => showSuccessNotification('Форма успешно разархивирована'))
          .catch(() => {
            showErrorNotification('При разархивировании произошла ошибка')
          })
      },
      cancelText: 'Отменить',
      maskClosable: true,
    })
  }

  if (!data?.pages.length || !interests) return null

  return (
    <Layout.Content>
      <PageHeader
        title="Формы"
        ghost={false}
        extra={[
          <Input.Search
            style={{ width: 272 }}
            key="1"
            placeholder="input search text"
            enterButton
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />,
          <Button
            key="2"
            type="primary"
            onClick={() => history.push('/forms/edit')}
          >
            Новая форма
          </Button>,

          <Popover
            key="3"
            content={() => (
              <>
                <Tree
                  checkable
                  style={{ width: 256 }}
                  showIcon={false}
                  switcherIcon={<CaretDownOutlined />}
                  treeData={[availableFilters.status]}
                  checkedKeys={statusFilter}
                  onCheck={(checked) =>
                    setStatusFilter(checked as LeadsActivityV1Status[])
                  }
                />
                <Tree
                  checkable
                  style={{ width: 256 }}
                  showIcon={false}
                  switcherIcon={<CaretDownOutlined />}
                  treeData={[availableFilters.brand]}
                  onCheck={(checked) =>
                    setBrandFilter(checked as LeadsLeadsServerV1Brand[])
                  }
                />
              </>
            )}
            trigger="click"
          >
            <Button type="primary" icon={<FunnelPlotTwoTone />} />
          </Popover>,
        ]}
      />
      <Row justify="space-between" style={{ padding: '16px 24px 0' }}>
        <Col>
          {[].map((filter) => (
            <Tag key={filter} closable id={filter}>
              {filter}
            </Tag>
          ))}
        </Col>
        <Col>
          {Boolean([].length) && (
            <Button
              key="2"
              onClick={() => null}
              type="link"
              icon={<UndoOutlined />}
            >
              Очистить
            </Button>
          )}
        </Col>
      </Row>

      <Table
        dataSource={data.pages.flatMap(
          ({ forms }) => forms as unknown as LeadsFormsServerV1FormListItem
        )}
        pagination={false}
        loading={isLoading}
        style={{ margin: '0 24px' }}
        rowKey={(rec) => `${rec.id}-${rec.version.number}`}
      >
        <Table.Column
          title="Название"
          key="title"
          dataIndex="title"
          width={'16%'}
          sorter={() => 0}
          onHeaderCell={() => ({
            onClick: () =>
              setSort(
                applySort([
                  LeadsFormsServerV1FormSort.TitleAsc,
                  LeadsFormsServerV1FormSort.TitleDesc,
                  LeadsFormsServerV1FormSort.None,
                ])
              ),
          })}
        />
        <Table.Column
          title="Версия"
          key="version"
          dataIndex={['version', 'number']}
          width={'8%'}
        />
        <Table.Column
          title="Бренд"
          key="brand"
          dataIndex={['brand', 'title']}
          width={'8%'}
          sorter={() => 0}
          onHeaderCell={() => ({
            onClick: () =>
              setSort(
                applySort([
                  LeadsFormsServerV1FormSort.BrandAsc,
                  LeadsFormsServerV1FormSort.BrandDesc,
                  LeadsFormsServerV1FormSort.None,
                ])
              ),
          })}
        />
        <Table.Column
          title="Кампания"
          key="campaign"
          dataIndex={['campaign', 'title']}
          width={'16%'}
          sorter={() => 0}
          onHeaderCell={() => ({
            onClick: () =>
              setSort(
                applySort([
                  LeadsFormsServerV1FormSort.CampaignAsc,
                  LeadsFormsServerV1FormSort.CampaignDesc,
                  LeadsFormsServerV1FormSort.None,
                ])
              ),
          })}
        />
        <Table.Column
          title="Интерес"
          key="interest"
          dataIndex={['interest', 'title']}
          width={'16%'}
          sorter={() => 0}
          onHeaderCell={() => ({
            onClick: () =>
              setSort(
                applySort([
                  LeadsFormsServerV1FormSort.InterestAsc,
                  LeadsFormsServerV1FormSort.InterestDesc,
                  LeadsFormsServerV1FormSort.None,
                ])
              ),
          })}
        />
        <Table.Column
          title="Статус"
          key="status"
          dataIndex={['activity', 'status']}
          render={(status) => (
            <Tag color="blue" key={status}>
              {status.toUpperCase()}
            </Tag>
          )}
          width={'8%'}
        />
        <Table.Column
          title="Начало активности"
          key="startTime"
          dataIndex={['activity', 'period', 'startTime']}
          render={(startTime) => moment(startTime).format('DD.MM.YYYY')}
          width={'10%'}
          sorter={() => 0}
          onHeaderCell={() => ({
            onClick: () =>
              setSort(
                applySort([
                  LeadsFormsServerV1FormSort.ActiveFromAsc,
                  LeadsFormsServerV1FormSort.ActiveFromDesc,
                  LeadsFormsServerV1FormSort.None,
                ])
              ),
          })}
        />
        <Table.Column
          title="Окончание активности"
          key="endTime"
          dataIndex={['activity', 'period', 'endTime']}
          render={(endTime) =>
            Number(endTime) > 0 ? moment(endTime).format('DD.MM.YYYY') : ''
          }
          width={'10%'}
          sorter={() => 0}
          onHeaderCell={() => ({
            onClick: () =>
              setSort(
                applySort([
                  LeadsFormsServerV1FormSort.ActiveToAsc,
                  LeadsFormsServerV1FormSort.ActiveToDesc,
                  LeadsFormsServerV1FormSort.None,
                ])
              ),
          })}
        />
        <Table.Column
          key="controls"
          render={({
            id,
            key,
            version,
            activity,
          }: {
            id: string
            key: string
            version: { number: number }
            activity: LeadsActivityV1Activity
          }) => {
            const isArchived =
              activity.status === LeadsActivityV1Status.Archived

            return (
              <Row gutter={16}>
                <Col>
                  <Button
                    type="link"
                    href={`/forms/${key}/${version.number}/edit`}
                    icon={
                      <EditFilled style={{ fontSize: 18, color: '#8C8C8C' }} />
                    }
                  />
                </Col>

                <Col>
                  <Dropdown
                    overlay={
                      <Menu defaultSelectedKeys={['unarchive', 'archive']}>
                        <Menu.Item key="1">
                          <Link to={`${routePaths.forms}/${key}/placement`}>
                            Размещение
                          </Link>
                        </Menu.Item>
                        <Menu.Item key="2">Копировать</Menu.Item>
                        {isArchived ? (
                          <Menu.Item
                            onClick={() => handleUnarchive(id)}
                            key="unarchive"
                          >
                            Разархивировать
                          </Menu.Item>
                        ) : (
                          <Menu.Item
                            onClick={() => handleArchive(id)}
                            key="archive"
                          >
                            Архивировать
                          </Menu.Item>
                        )}
                        <Menu.Item key="4">
                          <Link to={`${routePaths.forms}/${key}/logs`}>
                            Лог изменений
                          </Link>
                        </Menu.Item>
                      </Menu>
                    }
                    trigger={['click']}
                    placement="bottomCenter"
                  >
                    <Button
                      type="text"
                      icon={
                        <EllipsisOutlined
                          style={{ fontSize: 18, color: '#8C8C8C' }}
                        />
                      }
                    />
                  </Dropdown>
                </Col>
              </Row>
            )
          }}
        />
      </Table>

      {hasNextPage && (
        <Row justify="center" style={{ margin: '24px 0' }}>
          <Col>
            <Button
              onClick={() => fetchNextPage()}
              loading={isFetchingNextPage}
            >
              Загрузить ещё
            </Button>
          </Col>
        </Row>
      )}
    </Layout.Content>
  )
}
