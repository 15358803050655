import { FC, useCallback, useState } from 'react'
import { useHistory } from 'react-router'
import { Button, Form, FormInstance, notification, PageHeader } from 'antd'
import debounce from 'lodash/debounce'

import { PreviewStep } from '@app/pages/FormEditPage/PreviewStep/PreviewStep'
import { PublishStep } from '@app/pages/FormEditPage/PublishStep'

import { STEPS } from './constants'
import { EditStep } from './EditStep'
import { FormContext } from './formContext'
import { Steps } from './styled'
import { LeadsActivityV1Status, LeadsFormsServerV1FormProps } from '../../api'
import { api } from '../../config'
import { useForm } from '../../hooks/useForm/useForm.hook'

export type Steps = typeof STEPS[number]

export interface FormEditFormProps {
  formId?: string
  version?: string
  mode: Steps
  onDirtyChange: (value: boolean) => void
  form: FormInstance<LeadsFormsServerV1FormProps>
  initialValues: LeadsFormsServerV1FormProps
}

const formatFormUrl = ({
  formId,
  version,
  mode,
}: {
  formId?: string
  version?: string | number
  mode: Steps
}) =>
  `/forms${formId ? `/${formId}` : ''}${version ? `/${version}` : ''}/${mode}`

export const FormEditForm: FC<FormEditFormProps> = ({
  formId,
  version,
  mode,
  onDirtyChange,
  form,
  initialValues,
}) => {
  const history = useHistory()

  const [savePending, setSavePending] = useState(false)

  const { data } = useForm(formId, Number(version))
  const getStatus = useCallback(
    (current: number) => (STEPS.indexOf(mode) === current ? 'process' : 'wait'),
    [mode]
  )

  const [formState, setFormState] = useState(initialValues)

  const handleSave = async (values: LeadsFormsServerV1FormProps) => {
    setSavePending(true)
    onDirtyChange(false)

    try {
      let res
      if (formId) {
        res = await api.FormsApi.formsAPIUpdateForm(formId, values)
      } else {
        values.activity.status = LeadsActivityV1Status.Active
        res = await api.FormsApi.formsAPICreateForm(values)
      }

      history.push(
        formatFormUrl({
          formId: res.data.id,
          version: res.data.version.number,
          mode,
        })
      )
      notification.success({
        message: 'Успешно',
        description: formId
          ? 'Форма успешно обновлена'
          : 'Форма успешно сохранена',
      })
    } catch (e) {
      console.error(e)

      notification.error({
        message: 'Ошибка',
        description: 'При сохранении произошла ошибка',
      })
    } finally {
      setSavePending(false)
    }
  }

  const handleValidationErrors = ({ errorFields }: any) => {
    history.push(formatFormUrl({ formId, version, mode: 'edit' }))
    form.scrollToField(errorFields[0].name)
  }

  return (
    <FormContext.Provider
      value={{
        ...form,
        formState,
        setFieldsValue: (value) => {
          form.setFieldsValue(value)
          setFormState(value as any)
        },
      }}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={initialValues}
        onFinish={handleSave}
        onFinishFailed={handleValidationErrors}
        onFieldsChange={(field) => {
          onDirtyChange(Boolean(field[0]?.touched))
        }}
        onValuesChange={debounce((value, state) => {
          setFormState(state)
        }, 500)}
      >
        <PageHeader
          title={
            data
              ? `${data.props.title} (Версия: ${data.version.number})`
              : 'Новая форма'
          }
          onBack={() => history.push('/forms')}
          ghost={false}
          extra={
            <Button type="primary" htmlType="submit" loading={savePending}>
              Сохранить изменения
            </Button>
          }
        />
        <Steps
          type="navigation"
          size="small"
          current={STEPS.indexOf(mode)}
          onChange={(current) => {
            history.push(
              formatFormUrl({
                formId,
                version,
                mode: STEPS[current],
              })
            )
          }}
        >
          <Steps.Step
            title="Редактирование"
            status={getStatus(STEPS.indexOf('edit'))}
          />
          <Steps.Step
            title="Предпросмотр"
            status={getStatus(STEPS.indexOf('preview'))}
          />
          <Steps.Step
            title="Публикация"
            status={getStatus(STEPS.indexOf('publish'))}
          />
        </Steps>

        <EditStep visible={mode === 'edit'} />
        <PublishStep visible={mode === 'publish'} />
      </Form>

      {mode === 'preview' && <PreviewStep />}
    </FormContext.Provider>
  )
}
