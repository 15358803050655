import { FC } from 'react'
import { Col, Form, Input, Row } from 'antd'

import { MediaAPIApi } from '@app/api'
import { SingleUpload } from '@app/components/SingleUpload/SingleUpload'

export const VideoBackgroundConfiguration: FC = () => (
  <Form.Item noStyle shouldUpdate>
    {({ getFieldValue, getFieldsValue, setFieldsValue }) => (
      <Row gutter={24}>
        <Col flex={'1 1'}>
          <Form.Item
            name={['background', 'video']}
            fieldKey={['background', 'video']}
            label="Добавление видео"
            extra="Допускаются форматы MP4, MOV, весом не более 5 МБ"
          >
            <Input placeholder="Загрузите видео" />
          </Form.Item>
        </Col>
        <Col style={{ display: 'flex', alignItems: 'flex-end' }}>
          <SingleUpload
            photoUrl={getFieldValue(['background', 'video'])}
            customRequest={async ({ file, onSuccess }) => {
              const mediaApi = new MediaAPIApi()

              const res = await mediaApi.mediaAPICreateImage(file)
              const value = getFieldsValue(true)
              value.background.video = `${process.env.REACT_APP_CDN_URL}${res.data.mediaId}`
              setFieldsValue(value)

              onSuccess?.({}, new XMLHttpRequest())
            }}
            onRemove={() => {
              const value = getFieldsValue(true)
              value.background.video = null
              setFieldsValue(value)
            }}
          />
        </Col>
      </Row>
    )}
  </Form.Item>
)
