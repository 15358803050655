import { useQuery } from 'react-query'

import { api } from '@app/config'

export const useDealerDepartments = () =>
  useQuery('departments', () => api.DealersApi.dealersAPIGetDepartments(), {
    select({ data }) {
      return data.departments
    },
  })
