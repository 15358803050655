import { useQuery } from 'react-query'

import { api } from '@app/config'

export const useCampaigns = () =>
  useQuery('campaigns', () => api.LeadsApi.leadsAPIGetCampaign(), {
    select({ data }) {
      return data.campaigns
    },
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  })
