import { useContext } from 'react'
import {
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Typography,
} from 'antd'
import moment from 'moment'

import { LeadsLeadsServerV1Brand, LeadsLeadsServerV1Category } from '@app/api'
import { useCampaigns } from '@app/hooks/useCampaigns/useCampaigns.hook'
import { useDealerDepartments } from '@app/hooks/useDealerDepartments/useDealerDepartments.hook'
import { useInterests } from '@app/hooks/useInterests/useInterests.hook'
import { useLeadChannels } from '@app/hooks/useLeadChannels/useLeadChannels.hook'

import { filterOption } from '../../../helpers/filterOption'
import { sortBy } from '../../../helpers/sort'
import { vgrBrands } from '../../../hooks/useBrands/useBrands.hook'
import { FormContext } from '../formContext'

const interestsCategoryIdOptions = [
  { value: LeadsLeadsServerV1Category.Sale, label: 'Продажи' },
  { value: LeadsLeadsServerV1Category.Service, label: 'Сервис' },
  { value: LeadsLeadsServerV1Category.Other, label: 'Другое' },
  { value: LeadsLeadsServerV1Category.Feedback, label: 'Обратная связь' },
  { value: LeadsLeadsServerV1Category.Event, label: 'Мероприятия' },
  { value: LeadsLeadsServerV1Category.Hotline, label: 'Горячая линия' },
  { value: LeadsLeadsServerV1Category.Subscribe, label: 'Подписка' },
  { value: LeadsLeadsServerV1Category.Fleet, label: 'Fleet' },
].sort(sortBy('value'))

export const BaseSettings = () => {
  const { formState } = useContext(FormContext)

  const { data: campaigns } = useCampaigns()
  const { data: interests } = useInterests({
    category: formState.interestCategoryId
      ? [formState.interestCategoryId]
      : [],
  })
  const { data: channels } = useLeadChannels()
  const { data: departments } = useDealerDepartments()

  return (
    <Card
      title={<Typography.Title level={4}>Настройка</Typography.Title>}
      bordered={false}
    >
      <Row>
        <Col span={24}>
          <Form.Item
            name="title"
            label="Внутреннее наименование формы"
            extra="Внутреннее название формы помогает быстрее находить форму в консоли. Не видно пользователю"
            rules={[
              {
                required: true,
                message: 'Введите наименование формы',
              },
            ]}
          >
            <Input placeholder="Введите название формы" />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            name="description"
            label="Публичное описание формы"
            rules={[
              {
                required: true,
                message: 'Введите описание формы',
              },
            ]}
          >
            <Input.TextArea placeholder="Введите описание формы" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item name="slug" label="Slug">
            <Input placeholder="Введите slug" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="brandId"
            label="Бренд VGR"
            rules={[{ required: true, message: 'Выберите бренд' }]}
          >
            <Select
              allowClear
              placeholder="Выберите бренд VGR"
              options={vgrBrands.map(({ name, id }) => ({
                label: name,
                value: id,
              }))}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="campaignId" label="Маркетинговая кампания">
            <Select
              allowClear
              placeholder="Выберите маркетинговую кампанию"
              options={campaigns?.map(({ name, id }) => ({
                label: name,
                value: id,
              }))}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldsValue, setFieldsValue }) => (
              <Form.Item
                name="interestCategoryId"
                label="Категория интереса"
                rules={[
                  {
                    required: true,
                    message: 'Выберите категорию интереса',
                  },
                ]}
              >
                <Select
                  allowClear
                  placeholder="Выберите категорию интереса"
                  options={interestsCategoryIdOptions}
                  onChange={(value: string) => {
                    const formState = getFieldsValue(true)

                    if (formState.interestId) {
                      const interestOption = interests?.find(
                        ({ id }) => id === formState.interestId
                      )

                      if (interestOption?.category !== value) {
                        formState.interestId = null
                        setFieldsValue(formState)
                      }
                    }
                  }}
                />
              </Form.Item>
            )}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="leadChannelId" label="Канал интереса" required>
            <Select
              allowClear
              showSearch
              filterOption={filterOption}
              placeholder="Выберите канал интереса"
              options={channels?.map(({ name, id }) => ({
                label: name,
                value: id,
              }))}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldsValue, setFieldsValue }) => (
              <Form.Item
                name="interestId"
                label="Тип интереса"
                rules={[
                  {
                    required: true,
                    message: 'Выберите тип интереса',
                  },
                ]}
              >
                <Select
                  allowClear
                  placeholder="Выберите тип интереса"
                  showSearch
                  filterOption={filterOption}
                  options={interests?.map(({ name, id }) => ({
                    label: name,
                    value: id,
                  }))}
                  onChange={(val: string) => {
                    const formState = getFieldsValue(true)

                    const interestOption = interests?.find(
                      ({ id }) => id === val
                    )
                    const interestsCategoryIdOption =
                      interestsCategoryIdOptions.find(
                        ({ value }) => interestOption?.category === value
                      )

                    if (interestsCategoryIdOption) {
                      formState.interestCategoryId =
                        interestsCategoryIdOption.value
                    }

                    setFieldsValue(formState)
                  }}
                />
              </Form.Item>
            )}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="replaceMainInterest"
            label=" "
            valuePropName="checked"
          >
            <Checkbox>
              Отправлять заявки только с дополнительными типами интересов
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item
            name={['activity', 'period', 'startTime']}
            label="Начало активности"
            rules={[
              {
                required: true,
                message: 'Выберите дату начала активности',
              },
            ]}
            getValueProps={(value) => ({
              value: value ? moment(value) : undefined,
            })}
            initialValue={moment()}
            data-testid="startTime-form-item"
          >
            <DatePicker
              placeholder="Выберите дату активации формы"
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['activity', 'period', 'endTime']}
            label="Конец активности"
            getValueProps={(value) => ({
              value: value ? moment(value) : undefined,
            })}
          >
            <DatePicker
              placeholder="Выберите дату деактивации формы"
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            name="testEmail"
            label="Получатель тестовой формы"
            extra="Введите e-mail через enter"
          >
            <Select mode="tags" placeholder="Введите email через запятую" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item name="tags" label="Теги">
            <Select mode="tags" placeholder="Введите теги" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => (
              <Form.Item name="departmentId" label="Подразделения">
                <Select
                  allowClear
                  mode={'multiple'}
                  maxTagCount={5}
                  disabled={
                    getFieldValue('brandId') !== LeadsLeadsServerV1Brand.Nfz &&
                    getFieldValue('brandId') !== LeadsLeadsServerV1Brand.Pkw
                  }
                  options={departments?.map(({ name, id }) => ({
                    label: name,
                    value: id as string,
                  }))}
                  placeholder="Выберите подразделения"
                />
              </Form.Item>
            )}
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="buttonTitle"
            label="Текст кнопки отправки формы"
            rules={[
              {
                required: true,
                message: 'Введите текст кнопки',
              },
            ]}
            initialValue="Отправить"
          >
            <Input placeholder="Введите текст кнопки" />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  )
}
