import { FC, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { matchPath } from 'react-router-dom'
import { Form, Layout } from 'antd'

import { useForm } from '@app/hooks/useForm/useForm.hook'
import { routePaths } from '@app/Router/constants'

import { FormEditForm, Steps } from './FormEditForm'
import {
  LeadsFormsServerV1FormProps,
  LeadsFormsServerV1PublishOn,
} from '../../api/api'

export const FormEditPage: FC = () => {
  const { formId, version, mode } = useParams<{
    formId?: string
    version?: string
    mode: Steps
  }>()
  const history = useHistory()
  const { data } = useForm(formId, Number(version))
  const [form] = Form.useForm<LeadsFormsServerV1FormProps>()

  const [isDirty, setIsDirty] = useState(form.isFieldsTouched())

  useEffect(() => {
    const unblock = history.block((tx) => {
      if (isDirty && !matchPath(tx.pathname, { path: routePaths.formEdit }))
        return 'Формы была изменена. Выйти без сохранения?'
    })

    const onBeforeUnload = (e: BeforeUnloadEvent) => {
      if (isDirty) {
        e.preventDefault()
        e.returnValue = 'Формы была изменена. Выйти без сохранения?'
        return e
      }
    }

    window.addEventListener('beforeunload', onBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload)
      unblock()
    }
  }, [isDirty])

  if (formId && version && !data) return null

  const initialValues = data
    ? data.props
    : ({
        fields: [],
        publishOn: [
          LeadsFormsServerV1PublishOn.Vdp,
          LeadsFormsServerV1PublishOn.FeatureApp,
        ],
      } as unknown as LeadsFormsServerV1FormProps)

  return (
    <Layout.Content>
      <FormEditForm
        form={form}
        onDirtyChange={setIsDirty}
        formId={formId}
        version={version}
        mode={mode}
        initialValues={initialValues}
      />
    </Layout.Content>
  )
}
