import { useInfiniteQuery } from 'react-query'

import { LeadsModelsServerV1ModelSort } from '@app/api'
import { api } from '@app/config'

export interface useInfiniteModelsProps {
  search?: string
  sort?: LeadsModelsServerV1ModelSort
}

export const useInfiniteModels = (props: useInfiniteModelsProps) => {
  const { search, sort } = props

  return useInfiniteQuery(
    ['models-infinite', props],
    ({ pageParam: pageToken }) =>
      api.ModelsApi.modelsAPIGetModel(
        undefined,
        undefined,
        undefined,
        undefined,
        search ? [search] : undefined,
        undefined,
        sort ? [sort] : [LeadsModelsServerV1ModelSort.NameAsc],
        10,
        pageToken
      ),
    {
      getNextPageParam: (lastPage) => lastPage.data.count?.token || undefined,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  )
}
