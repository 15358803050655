import { FC, useContext } from 'react'
import { DeleteFilled, PlusOutlined } from '@ant-design/icons'
import { Button, Col, Form, Input, Radio, Row, Select } from 'antd'
import { get } from 'lodash'

import { LeadsFormsServerV1Disclaimer } from '@app/api'
import { SingleUpload } from '@app/components/SingleUpload/SingleUpload'
import { api } from '@app/config'
import { filterOption } from '@app/helpers/filterOption'
import { useDisclaimers } from '@app/hooks/useDisclaimers/useDisclaimers.hook'
import { useInterests } from '@app/hooks/useInterests/useInterests.hook'
import {
  FieldCard,
  FieldCardProps,
} from '@app/pages/FormEditPage/Fields/FieldCard/FieldCard'
import { FormContext } from '@app/pages/FormEditPage/formContext'

export const InterestsField: FC<FieldCardProps> = ({
  name,
  fieldKey,
  path,
  ...props
}) => {
  const { getFieldValue, setFieldsValue, getFieldsValue, formState } =
    useContext(FormContext)
  const { data: disclaimers } = useDisclaimers({
    filterBrandIdEq: formState.brandId ? [formState.brandId] : [],
  })
  const { data: interests } = useInterests({})

  const checkInterestSelected = (id: string) => {
    const selectedInterests =
      getFieldValue(['fields', name, path, 'items']) || []

    return selectedInterests.some(
      ({ value }: { value: string }) => value === id
    )
  }

  const getValueFromEvent = (ids: string[]) =>
    (disclaimers || [])
      .filter((dc) => ids.includes(dc.id as string))
      .map((dc) => ({ ...dc, title: dc.props.title, value: dc.id }))?.[0]

  const getValueProps = (value?: LeadsFormsServerV1Disclaimer) => value?.id

  return (
    <FieldCard
      name={name}
      fieldKey={fieldKey}
      path={path}
      hideDisclaimer
      justify={'start'}
      {...props}
    >
      <Col span={12}>
        <Form.Item
          {...props}
          name={[name, path, 'showSelect']}
          fieldKey={[fieldKey, path, 'showSelect']}
          label="Способ отображения"
        >
          <Radio.Group>
            <Radio value={true}>Список в селекте</Radio>
            <Radio value={false}>Карточки</Radio>
          </Radio.Group>
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item
          {...props}
          name={[name, path, 'title']}
          fieldKey={[fieldKey, path, 'title']}
          label="Заголовок поля"
        >
          <Input
            disabled={!get(formState, ['fields', name, path, 'showSelect'])}
            placeholder="Введите заголовок поля"
          />
        </Form.Item>
      </Col>

      <Form.List name={[name, path, 'items']}>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => {
              const photoUrl = getFieldValue([
                'fields',
                name,
                path,
                'items',
                field.name,
                'photo',
              ])
              return (
                <Form.Item noStyle key={field.key}>
                  <Col span={24}>
                    <Form.Item
                      {...field}
                      name={[field.name, 'value']}
                      fieldKey={[field.fieldKey, 'value']}
                      label="Тип интереса"
                    >
                      <Select
                        allowClear
                        placeholder="Выберите тип интереса"
                        filterOption={filterOption}
                        options={interests?.map(({ name, id }) => ({
                          label: name,
                          value: id,
                          disabled: checkInterestSelected(id),
                        }))}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      {...field}
                      name={[field.name, 'title']}
                      fieldKey={[field.fieldKey, 'title']}
                      label="Название интереса"
                    >
                      <Input placeholder="Введите название интереса" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Row gutter={24}>
                      <Col flex={'auto'}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'photo']}
                          fieldKey={[field.fieldKey, 'photo']}
                          label="Изображение интереса"
                        >
                          <Input
                            disabled={get(formState, [
                              'fields',
                              name,
                              path,
                              'showSelect',
                            ])}
                            placeholder="Загрузите свое изображение"
                          />
                        </Form.Item>
                      </Col>
                      <Col style={{ display: 'flex', alignItems: 'flex-end' }}>
                        <SingleUpload
                          disabled={get(formState, [
                            'fields',
                            name,
                            path,
                            'showSelect',
                          ])}
                          photoUrl={photoUrl}
                          customRequest={async ({ file, onSuccess }) => {
                            const res = await api.MediaApi.mediaAPICreateImage(
                              file
                            )
                            const value = getFieldsValue(true)
                            value.fields[name][path].items[
                              field.name
                            ].photo = `${process.env.REACT_APP_CDN_URL}${res.data.mediaId}`
                            setFieldsValue(value)

                            onSuccess?.({}, new XMLHttpRequest())
                          }}
                          onRemove={() => {
                            const value = getFieldsValue(true)
                            value.fields[name][path].items[field.name].photo =
                              null
                            setFieldsValue(value)
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      {...field}
                      name={[field.name, 'disclaimer']}
                      fieldKey={[field.fieldKey, 'disclaimer']}
                      label="Дисклеймер"
                      getValueFromEvent={getValueFromEvent}
                      getValueProps={getValueProps as any}
                    >
                      <Select
                        allowClear
                        placeholder="Выберите дисклеймер из справочника"
                        defaultValue={
                          getFieldValue([
                            'fields',
                            name,
                            path,
                            'items',
                            field.name,
                            'disclaimer',
                          ])?.id
                        }
                        options={disclaimers?.map(
                          ({ id, props: { title } }) => ({
                            label: title,
                            value: id,
                          })
                        )}
                      />
                    </Form.Item>
                  </Col>
                  {fields.length > 1 && (
                    <Form.Item>
                      <Button
                        type="text"
                        onClick={() => {
                          remove(index)
                        }}
                        icon={<DeleteFilled />}
                        danger
                      >
                        Удалить пожелание
                      </Button>
                    </Form.Item>
                  )}
                </Form.Item>
              )
            })}
            <Form.Item>
              <Button
                type="link"
                onClick={() =>
                  add({
                    title: null,
                    photo: null,
                    value: null,
                    disclaimer: null,
                  })
                }
                icon={<PlusOutlined />}
              >
                Добавить пожелание
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </FieldCard>
  )
}
