import React, { FC } from 'react'
import { Col, Form, Input, Radio } from 'antd'

import { LeadsFormsServerV1ViewMode } from '@app/api'
import {
  FieldCard,
  FieldCardProps,
} from '@app/pages/FormEditPage/Fields/FieldCard/FieldCard'

export const CommentField: FC<FieldCardProps> = ({
  name,
  fieldKey,
  path,
  ...props
}) => (
  <FieldCard name={name} fieldKey={fieldKey} path={path} {...props}>
    <Col span={12}>
      <Form.Item
        {...props}
        name={[name, path, 'viewMode']}
        fieldKey={[fieldKey, path, 'viewMode']}
        label="Способ отображения"
      >
        <Radio.Group>
          <Radio value={LeadsFormsServerV1ViewMode.Textarea}>
            Текстовая область
          </Radio>
          <Radio value={LeadsFormsServerV1ViewMode.Input}>
            Строка для ввода
          </Radio>
        </Radio.Group>
      </Form.Item>
    </Col>
    <Col span={24}>
      <Form.Item
        {...props}
        name={[name, path, 'title']}
        fieldKey={[fieldKey, path, 'title']}
        label="Наименование"
      >
        <Input placeholder="Введите наименование" />
      </Form.Item>
    </Col>
    <Col span={24}>
      <Form.Item
        {...props}
        name={[name, path, 'defaultValue']}
        fieldKey={[fieldKey, path, 'defaultValue']}
        label="Значение комментария по умолчанию"
      >
        <Input placeholder="Введите значение комментария по умолчанию" />
      </Form.Item>
    </Col>
  </FieldCard>
)
