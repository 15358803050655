import { useState } from 'react'
import { Upload as AntdUpload } from 'antd'
import { UploadProps as AntdUploadProps } from 'antd/lib/upload/interface'
import styled from 'styled-components'

export interface SingleUploadProps extends AntdUploadProps {
  photoUrl: string
}

export const SingleUpload: React.FC<SingleUploadProps> = ({
  photoUrl,
  ...props
}) => {
  const [loading, setLoading] = useState(false)

  return (
    <StyledAntdUpload
      showUploadList={{ showPreviewIcon: false, showRemoveIcon: true }}
      listType="picture-card"
      defaultFileList={
        photoUrl
          ? [
              {
                uid: '-1',
                name: 'image.png',
                status: 'done',
                url: photoUrl,
              },
            ]
          : []
      }
      onChange={(info) => {
        if (info.file.status === 'uploading') {
          setLoading(true)
        }
        if (info.file.status === 'done') {
          setLoading(false)
        }
      }}
      {...props}
    >
      {!photoUrl && !loading ? 'Загрузить' : null}
    </StyledAntdUpload>
  )
}

const StyledAntdUpload = styled(AntdUpload)({
  '.ant-upload.ant-upload-select-picture-card': { margin: 0 },
  '.ant-upload-list': { minWidth: 104 },
})
