import { FormListFieldData } from 'antd/es/form/FormList'

import {
  BirthdateField,
  BoughtAtOfficialField,
  CaptionField as CaptionFieldType,
  CarOwnerField,
  CommentField as CommentFieldType,
  CommunicationChannelField as CommunicationChannelFieldType,
  ConsentsGroupField,
  CurrentCarField,
  DealerCenterField as DealerCenterFieldType,
  DesiredCarField,
  DesiredCarWithPhotoField,
  DesiredPurchaseDateField,
  DesiredPurchasePlannedPeriodField,
  EmailField,
  FamilyTestDriveField as FamilyTestDriveFieldType,
  FirstNameField,
  GenderField as GenderFieldType,
  HeaderField as HeaderFieldType,
  InterestsField as InterestsFieldType,
  LastNameField,
  LeadsFormsServerV1Field,
  LeadsFormsServerV1FormProps,
  LeadSourceField as LeadSourceFieldType,
  ManufactureDateField,
  ManufactureYearField,
  MiddleNameField,
  MileageField,
  ModelYearField,
  MultiUploadField as MultiUploadFieldType,
  OrganizationField,
  PhoneField,
  PlateField,
  PurchaseDateField,
  RegistrationDateField,
  ResidenceField,
  RichTextField as RichTextFieldType,
  StepBreakField as StepBreakFieldType,
  TermField,
  TestDriveDateField,
  TestDrivePlannedPeriodField,
  TestDriveTimeField,
  TestDriveTimeOfDayField,
  VinField,
} from '@app/api'
import {
  CaptionField,
  CheckboxField,
  CommentField,
  CommunicationChannelField,
  ConsentsField,
  DateField,
  DealerCenterField,
  FamilyTestDriveField,
  GenderField,
  HeaderField,
  InterestsField,
  LeadSourceField,
  ModificationField,
  MultiUploadField,
  PlannedPeriodField,
  RichTextField,
  StepBreakField,
  TextField,
  TimeField,
  TimeOfDayField,
  UnknownField,
} from '@app/pages/FormEditPage/Fields'

export const isField = <T extends LeadsFormsServerV1Field>(
  field: LeadsFormsServerV1Field,
  path: keyof T
): field is T => (field as T)[path] !== undefined

export const mapFields =
  (
    formValues: LeadsFormsServerV1FormProps,
    remove: (index: number | number[]) => void
  ) =>
  (field: FormListFieldData) => {
    const current = formValues.fields[field.name]

    if (isField<FirstNameField>(current, 'firstName')) {
      return (
        <TextField
          remove={remove}
          title={'Имя'}
          path={'firstName'}
          {...field}
        />
      )
    } else if (isField<LastNameField>(current, 'lastName')) {
      return (
        <TextField
          remove={remove}
          title={'Фамилия'}
          path={'lastName'}
          {...field}
        />
      )
    } else if (isField<MiddleNameField>(current, 'middleName')) {
      return (
        <TextField
          remove={remove}
          title={'Отчество'}
          path={'middleName'}
          {...field}
        />
      )
    } else if (isField<ResidenceField>(current, 'residence')) {
      return (
        <TextField
          remove={remove}
          title={'Город проживания'}
          path={'residence'}
          {...field}
        />
      )
    } else if (isField<EmailField>(current, 'email')) {
      return (
        <TextField remove={remove} title={'E-mail'} path={'email'} {...field} />
      )
    } else if (isField<PhoneField>(current, 'phone')) {
      return (
        <TextField
          remove={remove}
          title={'Телефон'}
          path={'phone'}
          {...field}
        />
      )
    } else if (isField<ModelYearField>(current, 'modelYear')) {
      return (
        <TextField
          remove={remove}
          title={'Год выпуска'}
          path={'modelYear'}
          {...field}
        />
      )
    } else if (isField<MileageField>(current, 'mileage')) {
      return (
        <TextField
          remove={remove}
          title={'Пробег'}
          path={'mileage'}
          {...field}
        />
      )
    } else if (isField<VinField>(current, 'vin')) {
      return <TextField remove={remove} title={'VIN'} path={'vin'} {...field} />
    } else if (isField<PlateField>(current, 'plateNumber')) {
      return (
        <TextField
          remove={remove}
          title={'Регистрационный номер'}
          path={'plateNumber'}
          {...field}
        />
      )
    } else if (isField<ManufactureYearField>(current, 'manufactureYear')) {
      return (
        <TextField
          remove={remove}
          title={'Год производства автомобиля'}
          path={'manufactureYear'}
          {...field}
        />
      )
    } else if (isField<TermField>(current, 'term')) {
      return (
        <TextField
          remove={remove}
          title={'Срок эксплуатации'}
          path={'term'}
          {...field}
        />
      )
    } else if (isField<OrganizationField>(current, 'organization')) {
      return (
        <TextField
          remove={remove}
          title={'Организация'}
          path={'organization'}
          {...field}
        />
      )
    } else if (isField<BirthdateField>(current, 'birthdate')) {
      return (
        <DateField
          remove={remove}
          title={'Дата рождения'}
          path={'birthdate'}
          {...field}
        />
      )
    } else if (isField<ManufactureDateField>(current, 'manufactureDate')) {
      return (
        <DateField
          remove={remove}
          title={'Дата производства автомобиля'}
          path={'manufactureDate'}
          {...field}
        />
      )
    } else if (isField<RegistrationDateField>(current, 'registrationDate')) {
      return (
        <DateField
          remove={remove}
          title={'Дата регистрации '}
          path={'registrationDate'}
          {...field}
        />
      )
    } else if (isField<PurchaseDateField>(current, 'purchaseDate')) {
      return (
        <DateField
          remove={remove}
          title={'Дата покупки автомобиля'}
          path={'purchaseDate'}
          {...field}
        />
      )
    } else if (isField<GenderFieldType>(current, 'gender')) {
      return (
        <GenderField remove={remove} title={'Пол'} path={'gender'} {...field} />
      )
    } else if (isField<FamilyTestDriveFieldType>(current, 'familyTestDrive')) {
      return (
        <FamilyTestDriveField
          remove={remove}
          title={'Семейный тест-драйв'}
          path={'familyTestDrive'}
          {...field}
        />
      )
    } else if (isField<BoughtAtOfficialField>(current, 'boughtAtOfficial')) {
      return (
        <CheckboxField
          remove={remove}
          title={'Куплен в официальном дилерском центре'}
          path={'boughtAtOfficial'}
          {...field}
        />
      )
    } else if (isField<CarOwnerField>(current, 'carOwner')) {
      return (
        <CheckboxField
          remove={remove}
          title={'Владелец автомобильной марки'}
          path={'carOwner'}
          {...field}
        />
      )
    } else if (isField<CommentFieldType>(current, 'comment')) {
      return (
        <CommentField
          remove={remove}
          title={'Комментарий'}
          path={'comment'}
          {...field}
        />
      )
    } else if (isField<RichTextFieldType>(current, 'richText')) {
      return (
        <RichTextField
          remove={remove}
          title={'Текстовая область с поддержкой rich-текста'}
          path={'richText'}
          {...field}
        />
      )
    } else if (isField<DealerCenterFieldType>(current, 'dealerCenter')) {
      return (
        <DealerCenterField
          remove={remove}
          title={'Выбор дилерского центра'}
          path={'dealerCenter'}
          {...field}
        />
      )
    } else if (isField<StepBreakFieldType>(current, 'stepBreak')) {
      return (
        <StepBreakField
          remove={remove}
          title={'Шаг'}
          path={'stepBreak'}
          {...field}
        />
      )
    } else if (isField<MultiUploadFieldType>(current, 'multiUpload')) {
      return (
        <MultiUploadField
          remove={remove}
          title={'Добавление файлов'}
          path={'multiUpload'}
          {...field}
        />
      )
    } else if (isField<LeadSourceFieldType>(current, 'leadSource')) {
      return (
        <LeadSourceField
          remove={remove}
          title={'Откуда о нас узнали'}
          path={'leadSource'}
          {...field}
        />
      )
    } else if (
      isField<CommunicationChannelFieldType>(current, 'communicationChannel')
    ) {
      return (
        <CommunicationChannelField
          remove={remove}
          title={'Желаемый способ связи'}
          path={'communicationChannel'}
          {...field}
        />
      )
    } else if (isField<CurrentCarField>(current, 'currentCar')) {
      return (
        <ModificationField
          remove={remove}
          title={'Данные текущего автомобиля'}
          path={'currentCar'}
          {...field}
        />
      )
    } else if (isField<DesiredCarField>(current, 'desiredCar')) {
      return (
        <ModificationField
          remove={remove}
          title={'Данные желаемого автомобиля'}
          path={'desiredCar'}
          {...field}
        />
      )
    } else if (
      isField<DesiredCarWithPhotoField>(current, 'desiredCarWithPhoto')
    ) {
      return (
        <ModificationField
          remove={remove}
          title={'Желаемый автомобиль с фото'}
          path={'desiredCarWithPhoto'}
          {...field}
        />
      )
    } else if (isField<ConsentsGroupField>(current, 'consents')) {
      return (
        <ConsentsField
          remove={remove}
          title={'Согласия'}
          path={'consents'}
          {...field}
        />
      )
    } else if (isField<CaptionFieldType>(current, 'caption')) {
      return (
        <CaptionField
          remove={remove}
          title={'Подпись'}
          path={'caption'}
          {...field}
        />
      )
    } else if (isField<TestDriveDateField>(current, 'testDriveDate')) {
      return (
        <DateField
          remove={remove}
          title={'Желаемая дата тест-драйва'}
          path={'testDriveDate'}
          {...field}
        />
      )
    } else if (
      isField<DesiredPurchaseDateField>(current, 'desiredPurchaseDate')
    ) {
      return (
        <DateField
          remove={remove}
          title={'Желаемая дата покупки'}
          path={'desiredPurchaseDate'}
          {...field}
        />
      )
    } else if (isField<TestDriveTimeField>(current, 'testDriveTime')) {
      return (
        <TimeField
          remove={remove}
          title={'Желаемое время тест-драйва'}
          path={'testDriveTime'}
          {...field}
        />
      )
    } else if (
      isField<TestDriveTimeOfDayField>(current, 'testDriveTimeOfDay')
    ) {
      return (
        <TimeOfDayField
          remove={remove}
          title={'Желаемое время дня тест-драйва'}
          path={'testDriveTimeOfDay'}
          {...field}
        />
      )
    } else if (isField<InterestsFieldType>(current, 'interests')) {
      return (
        <InterestsField
          remove={remove}
          title={'Типы интересов'}
          path={'interests'}
          {...field}
        />
      )
    } else if (isField<HeaderFieldType>(current, 'header')) {
      return (
        <HeaderField
          remove={remove}
          title={'Шапка'}
          path={'header'}
          {...field}
        />
      )
    } else if (
      isField<DesiredPurchasePlannedPeriodField>(
        current,
        'desiredPurchasePlannedPeriod'
      )
    ) {
      return (
        <PlannedPeriodField
          remove={remove}
          title={'Планируемая дата покупки автомобиля'}
          path={'desiredPurchasePlannedPeriod'}
          {...field}
        />
      )
    } else if (
      isField<TestDrivePlannedPeriodField>(current, 'testDrivePlannedPeriod')
    ) {
      return (
        <PlannedPeriodField
          remove={remove}
          title={'Планируемая дата тест-драйва'}
          path={'testDrivePlannedPeriod'}
          {...field}
        />
      )
    }

    return (
      <UnknownField
        remove={remove}
        title={'Неизвестное поле'}
        path={Object.keys(current)[0]}
        {...field}
      />
    )
  }
