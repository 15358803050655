import { useQuery } from 'react-query'

import { api } from '@app/config'

import { LeadsLeadsServerV1Brand } from '../../api'

export interface useDealersProps {
  brandId?: Array<`${LeadsLeadsServerV1Brand}`>
}

export const useDealers = ({ brandId }: useDealersProps) =>
  useQuery(
    ['dealers', brandId],
    () =>
      api.DealersApi.dealersAPIGetDealerCenters(undefined, undefined, brandId),
    {
      select({ data }) {
        return {
          ...data,
          dealerCenters: data.dealerCenters?.map((dc) => ({
            ...dc,
            value: dc.code,
          })),
        }
      },
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  )
