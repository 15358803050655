import { useQuery } from 'react-query'

import { api } from '@app/config'

import {
  LeadsFormsServerV1DisclaimerSort,
  LeadsLeadsServerV1Brand,
} from '../../api/api'

export interface UseDisclaimersProps {
  filterBrandIdEq?: LeadsLeadsServerV1Brand[]
  search?: string
  sort?: LeadsFormsServerV1DisclaimerSort
}

export const useDisclaimers = (props: UseDisclaimersProps) => {
  const { filterBrandIdEq, search, sort } = props

  return useQuery(
    ['disclaimers', props],
    () =>
      api.DisclaimersApi.disclaimersAPIGetDisclaimer(
        undefined,
        undefined,
        filterBrandIdEq,
        undefined,
        search ? [search] : undefined,
        undefined,
        undefined,
        undefined,
        sort ? [sort] : undefined
      ),
    {
      select({ data }) {
        return data.disclaimers
      },
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  )
}
