/* tslint:disable */
/* eslint-disable */
/**
 * Leads Gateway
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: unknown on e79d1c0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface BirthdateField
 */
export interface BirthdateField {
    /**
     * 
     * @type {LeadsFormsServerV1DateField}
     * @memberof BirthdateField
     */
    birthdate: LeadsFormsServerV1DateField;
}
/**
 * 
 * @export
 * @interface BoughtAtOfficialField
 */
export interface BoughtAtOfficialField {
    /**
     * 
     * @type {LeadsFormsServerV1BoolField}
     * @memberof BoughtAtOfficialField
     */
    boughtAtOfficial: LeadsFormsServerV1BoolField;
}
/**
 * 
 * @export
 * @interface CaptionField
 */
export interface CaptionField {
    /**
     * 
     * @type {LeadsFormsServerV1Caption}
     * @memberof CaptionField
     */
    caption: LeadsFormsServerV1Caption;
}
/**
 * 
 * @export
 * @interface CarOwnerField
 */
export interface CarOwnerField {
    /**
     * 
     * @type {LeadsFormsServerV1BoolField}
     * @memberof CarOwnerField
     */
    carOwner: LeadsFormsServerV1BoolField;
}
/**
 * 
 * @export
 * @interface Color
 */
export interface Color {
    /**
     * Represent background color.
     * @type {string}
     * @memberof Color
     */
    color?: string;
}
/**
 * 
 * @export
 * @interface CommentField
 */
export interface CommentField {
    /**
     * 
     * @type {LeadsFormsServerV1CommentField}
     * @memberof CommentField
     */
    comment: LeadsFormsServerV1CommentField;
}
/**
 * 
 * @export
 * @interface CommunicationChannelField
 */
export interface CommunicationChannelField {
    /**
     * 
     * @type {LeadsFormsServerV1CommunicationChannelField}
     * @memberof CommunicationChannelField
     */
    communicationChannel: LeadsFormsServerV1CommunicationChannelField;
}
/**
 * 
 * @export
 * @interface ConsentsGroupField
 */
export interface ConsentsGroupField {
    /**
     * 
     * @type {LeadsFormsServerV1ConsentsGroupField}
     * @memberof ConsentsGroupField
     */
    consents: LeadsFormsServerV1ConsentsGroupField;
}
/**
 * 
 * @export
 * @interface CurrentCarField
 */
export interface CurrentCarField {
    /**
     * 
     * @type {LeadsFormsServerV1ModificationField}
     * @memberof CurrentCarField
     */
    currentCar: LeadsFormsServerV1ModificationField;
}
/**
 * 
 * @export
 * @interface DealerCenterField
 */
export interface DealerCenterField {
    /**
     * 
     * @type {LeadsFormsServerV1DealerCenterField}
     * @memberof DealerCenterField
     */
    dealerCenter: LeadsFormsServerV1DealerCenterField;
}
/**
 * 
 * @export
 * @interface DesiredCarField
 */
export interface DesiredCarField {
    /**
     * 
     * @type {LeadsFormsServerV1ModificationField}
     * @memberof DesiredCarField
     */
    desiredCar: LeadsFormsServerV1ModificationField;
}
/**
 * 
 * @export
 * @interface DesiredCarWithPhotoField
 */
export interface DesiredCarWithPhotoField {
    /**
     * 
     * @type {LeadsFormsServerV1ModificationField}
     * @memberof DesiredCarWithPhotoField
     */
    desiredCarWithPhoto: LeadsFormsServerV1ModificationField;
}
/**
 * 
 * @export
 * @interface DesiredPurchaseDateField
 */
export interface DesiredPurchaseDateField {
    /**
     * 
     * @type {LeadsFormsServerV1DateField}
     * @memberof DesiredPurchaseDateField
     */
    desiredPurchaseDate: LeadsFormsServerV1DateField;
}
/**
 * 
 * @export
 * @interface DesiredPurchasePlannedPeriodField
 */
export interface DesiredPurchasePlannedPeriodField {
    /**
     * 
     * @type {LeadsFormsServerV1PlannedPeriodField}
     * @memberof DesiredPurchasePlannedPeriodField
     */
    desiredPurchasePlannedPeriod?: LeadsFormsServerV1PlannedPeriodField;
}
/**
 * 
 * @export
 * @interface DesiredPurchaseTimeField
 */
export interface DesiredPurchaseTimeField {
    /**
     * 
     * @type {LeadsFormsServerV1TimeOfDayField}
     * @memberof DesiredPurchaseTimeField
     */
    desiredPurchaseTime: LeadsFormsServerV1TimeOfDayField;
}
/**
 * 
 * @export
 * @interface EmailField
 */
export interface EmailField {
    /**
     * 
     * @type {LeadsFormsServerV1EmailField}
     * @memberof EmailField
     */
    email: LeadsFormsServerV1EmailField;
}
/**
 * 
 * @export
 * @interface FamilyTestDriveField
 */
export interface FamilyTestDriveField {
    /**
     * 
     * @type {LeadsFormsServerV1FamilyTestDriveField}
     * @memberof FamilyTestDriveField
     */
    familyTestDrive: LeadsFormsServerV1FamilyTestDriveField;
}
/**
 * 
 * @export
 * @interface FirstNameField
 */
export interface FirstNameField {
    /**
     * 
     * @type {LeadsFormsServerV1FirstNameField}
     * @memberof FirstNameField
     */
    firstName: LeadsFormsServerV1FirstNameField;
}
/**
 * 
 * @export
 * @interface GenderField
 */
export interface GenderField {
    /**
     * 
     * @type {LeadsFormsServerV1GenderField}
     * @memberof GenderField
     */
    gender: LeadsFormsServerV1GenderField;
}
/**
 * 
 * @export
 * @interface GoogleProtobufAny
 */
export interface GoogleProtobufAny {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof GoogleProtobufAny
     */
    type?: string;
}
/**
 * `NullValue` is a singleton enumeration to represent the null value for the `Value` type union.   The JSON representation for `NullValue` is JSON `null`.   - NULL_VALUE: Null value.
 * @export
 * @enum {string}
 */

export enum GoogleProtobufNullValue {
    NullValue = 'NULL_VALUE'
}

/**
 * 
 * @export
 * @interface GoogleRpcStatus
 */
export interface GoogleRpcStatus {
    /**
     * 
     * @type {number}
     * @memberof GoogleRpcStatus
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof GoogleRpcStatus
     */
    message?: string;
    /**
     * 
     * @type {Array<GoogleProtobufAny>}
     * @memberof GoogleRpcStatus
     */
    details?: Array<GoogleProtobufAny>;
}
/**
 * 
 * @export
 * @interface HeaderField
 */
export interface HeaderField {
    /**
     * 
     * @type {LeadsFormsServerV1HeaderField}
     * @memberof HeaderField
     */
    header: LeadsFormsServerV1HeaderField;
}
/**
 * 
 * @export
 * @interface Image
 */
export interface Image {
    /**
     * Represent link to background image.
     * @type {string}
     * @memberof Image
     */
    image?: string;
}
/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200
     */
    mediaId: string;
}
/**
 * 
 * @export
 * @interface InterestsField
 */
export interface InterestsField {
    /**
     * 
     * @type {LeadsFormsServerV1InterestsField}
     * @memberof InterestsField
     */
    interests: LeadsFormsServerV1InterestsField;
}
/**
 * 
 * @export
 * @interface LastNameField
 */
export interface LastNameField {
    /**
     * 
     * @type {LeadsFormsServerV1LastNameField}
     * @memberof LastNameField
     */
    lastName: LeadsFormsServerV1LastNameField;
}
/**
 * 
 * @export
 * @interface LeadSourceField
 */
export interface LeadSourceField {
    /**
     * 
     * @type {LeadsFormsServerV1LeadSourceField}
     * @memberof LeadSourceField
     */
    leadSource: LeadsFormsServerV1LeadSourceField;
}
/**
 * Constraints of the resource activity.
 * @export
 * @interface LeadsActivityV1Activity
 */
export interface LeadsActivityV1Activity {
    /**
     * 
     * @type {LeadsActivityV1Status}
     * @memberof LeadsActivityV1Activity
     */
    status: LeadsActivityV1Status;
    /**
     * 
     * @type {LeadsActivityV1Period}
     * @memberof LeadsActivityV1Activity
     */
    period?: LeadsActivityV1Period;
}
/**
 * The activity period of the resource.
 * @export
 * @interface LeadsActivityV1Period
 */
export interface LeadsActivityV1Period {
    /**
     * The start time of the activity period.
     * @type {string}
     * @memberof LeadsActivityV1Period
     */
    startTime?: string;
    /**
     * The end time of the activity period.
     * @type {string}
     * @memberof LeadsActivityV1Period
     */
    endTime?: string;
}
/**
 * The status of the resource activity.
 * @export
 * @enum {string}
 */

export enum LeadsActivityV1Status {
    None = 'STATUS_NONE',
    Active = 'STATUS_ACTIVE',
    Disabled = 'STATUS_DISABLED',
    Archived = 'STATUS_ARCHIVED'
}

/**
 * External entity relationship.
 * @export
 * @interface LeadsAliasesV1Alias
 */
export interface LeadsAliasesV1Alias {
    /**
     * Name of the external system.
     * @type {string}
     * @memberof LeadsAliasesV1Alias
     */
    name: string;
    /**
     * Identifiers from the external system.
     * @type {Set<string>}
     * @memberof LeadsAliasesV1Alias
     */
    values: Set<string>;
}
/**
 * Dealer item properties.
 * @export
 * @interface LeadsDealersServerV1DealerCenter
 */
export interface LeadsDealersServerV1DealerCenter {
    /**
     * 
     * @type {string}
     * @memberof LeadsDealersServerV1DealerCenter
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadsDealersServerV1DealerCenter
     */
    code?: string;
    /**
     * Dealer address.
     * @type {string}
     * @memberof LeadsDealersServerV1DealerCenter
     */
    address: string;
    /**
     * Dealer company name.
     * @type {string}
     * @memberof LeadsDealersServerV1DealerCenter
     */
    name: string;
    /**
     * The list of the dealers\'s phone numbers.
     * @type {Array<string>}
     * @memberof LeadsDealersServerV1DealerCenter
     */
    phones: Array<string>;
    /**
     * Dealer site link.
     * @type {string}
     * @memberof LeadsDealersServerV1DealerCenter
     */
    site: string;
    /**
     * Longitude value.
     * @type {number}
     * @memberof LeadsDealersServerV1DealerCenter
     */
    longitude?: number;
    /**
     * Longitude value.
     * @type {number}
     * @memberof LeadsDealersServerV1DealerCenter
     */
    latitude?: number;
    /**
     * 
     * @type {string}
     * @memberof LeadsDealersServerV1DealerCenter
     */
    cityId?: string;
    /**
     * 
     * @type {LeadsLeadsServerV1Brand}
     * @memberof LeadsDealersServerV1DealerCenter
     */
    brandId?: LeadsLeadsServerV1Brand;
}
/**
 * Properties of the field that represents dealer center city.
 * @export
 * @interface LeadsDealersServerV1DealerCenterCity
 */
export interface LeadsDealersServerV1DealerCenterCity {
    /**
     * 
     * @type {string}
     * @memberof LeadsDealersServerV1DealerCenterCity
     */
    id: string;
    /**
     * Dealer city name.
     * @type {string}
     * @memberof LeadsDealersServerV1DealerCenterCity
     */
    name: string;
}
/**
 * Contains filter parameters for dealer center.
 * @export
 * @interface LeadsDealersServerV1DealerCenterFilter
 */
export interface LeadsDealersServerV1DealerCenterFilter {
    /**
     * 
     * @type {LeadsFiltersV1IDFilter}
     * @memberof LeadsDealersServerV1DealerCenterFilter
     */
    id?: LeadsFiltersV1IDFilter;
    /**
     * 
     * @type {LeadsLeadsServerV1BrandFilter}
     * @memberof LeadsDealersServerV1DealerCenterFilter
     */
    brandId?: LeadsLeadsServerV1BrandFilter;
    /**
     * 
     * @type {LeadsFiltersV1IDFilter}
     * @memberof LeadsDealersServerV1DealerCenterFilter
     */
    cityId?: LeadsFiltersV1IDFilter;
    /**
     * 
     * @type {LeadsFiltersV1StringFilter}
     * @memberof LeadsDealersServerV1DealerCenterFilter
     */
    code?: LeadsFiltersV1StringFilter;
}
/**
 * Available values for dealer sorting.
 * @export
 * @enum {string}
 */

export enum LeadsDealersServerV1DealerCenterSort {
    None = 'DEALER_CENTER_SORT_NONE',
    NameAsc = 'DEALER_CENTER_SORT_NAME_ASC',
    NameDesc = 'DEALER_CENTER_SORT_NAME_DESC'
}

/**
 * Department item properties.
 * @export
 * @interface LeadsDealersServerV1Department
 */
export interface LeadsDealersServerV1Department {
    /**
     * 
     * @type {string}
     * @memberof LeadsDealersServerV1Department
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof LeadsDealersServerV1Department
     */
    name?: string;
}
/**
 * Contains filter parameters for department.
 * @export
 * @interface LeadsDealersServerV1DepartmentFilter
 */
export interface LeadsDealersServerV1DepartmentFilter {
    /**
     * 
     * @type {LeadsFiltersV1IDFilter}
     * @memberof LeadsDealersServerV1DepartmentFilter
     */
    id?: LeadsFiltersV1IDFilter;
}
/**
 * Available values for department sorting.
 * @export
 * @enum {string}
 */

export enum LeadsDealersServerV1DepartmentSort {
    None = 'DEPARTMENT_SORT_NONE',
    NameAsc = 'DEPARTMENT_SORT_NAME_ASC',
    NameDesc = 'DEPARTMENT_SORT_NAME_DESC'
}

/**
 * GetDealerCentersResponse describes a successful response to the request to list dealer centers.
 * @export
 * @interface LeadsDealersServerV1GetDealerCentersResponse
 */
export interface LeadsDealersServerV1GetDealerCentersResponse {
    /**
     * 
     * @type {Array<LeadsDealersServerV1DealerCenter>}
     * @memberof LeadsDealersServerV1GetDealerCentersResponse
     */
    dealerCenters?: Array<LeadsDealersServerV1DealerCenter>;
    /**
     * 
     * @type {Array<LeadsDealersServerV1DealerCenterCity>}
     * @memberof LeadsDealersServerV1GetDealerCentersResponse
     */
    cities?: Array<LeadsDealersServerV1DealerCenterCity>;
}
/**
 * GetDepartmentsResponse describes a successful response to the request to list dealer departments.
 * @export
 * @interface LeadsDealersServerV1GetDepartmentsResponse
 */
export interface LeadsDealersServerV1GetDepartmentsResponse {
    /**
     * 
     * @type {Array<LeadsDealersServerV1Department>}
     * @memberof LeadsDealersServerV1GetDepartmentsResponse
     */
    departments?: Array<LeadsDealersServerV1Department>;
}
/**
 * Filter criteria for the activity.
 * @export
 * @interface LeadsFiltersV1ActivityFilter
 */
export interface LeadsFiltersV1ActivityFilter {
    /**
     * The \"equality\" condition of the activity. With multiple provided values, it transforms into the \"in\" condition.
     * @type {Array<LeadsActivityV1Status>}
     * @memberof LeadsFiltersV1ActivityFilter
     */
    eq?: Array<LeadsActivityV1Status>;
    /**
     * The \"inequality\" condition of the activity. With multiple provided values, it transforms into the \"not in\" condition.
     * @type {Array<LeadsActivityV1Status>}
     * @memberof LeadsFiltersV1ActivityFilter
     */
    neq?: Array<LeadsActivityV1Status>;
}
/**
 * Filter criteria for identifiers.
 * @export
 * @interface LeadsFiltersV1IDFilter
 */
export interface LeadsFiltersV1IDFilter {
    /**
     * The \"equality\" condition. With multiple provided values, it transforms into the \"in\" condition.
     * @type {Array<string>}
     * @memberof LeadsFiltersV1IDFilter
     */
    eq?: Array<string>;
    /**
     * The \"inequality\" condition. With multiple provided values, it transforms into the \"not in\" condition.
     * @type {Array<string>}
     * @memberof LeadsFiltersV1IDFilter
     */
    neq?: Array<string>;
}
/**
 * Filter criteria for string values.
 * @export
 * @interface LeadsFiltersV1StringFilter
 */
export interface LeadsFiltersV1StringFilter {
    /**
     * The \"equality\" condition. With multiple provided values, it transforms into the \"in\" condition. The given values may contain the following wildcard characters: an asterisk \"*\" matches any sequence of zero or more characters, a question mark \"?\" matches any single character.
     * @type {Array<string>}
     * @memberof LeadsFiltersV1StringFilter
     */
    eq?: Array<string>;
    /**
     * The \"inequality\" condition. With multiple provided values, it transforms into the \"in\" condition. The given values may contain the following wildcard characters: an asterisk \"*\" matches any sequence of zero or more characters, a question mark \"?\" matches any single character.
     * @type {Array<string>}
     * @memberof LeadsFiltersV1StringFilter
     */
    neq?: Array<string>;
}
/**
 * Align describes the position of the field.
 * @export
 * @enum {string}
 */

export enum LeadsFormsServerV1Align {
    None = 'ALIGN_NONE',
    Left = 'ALIGN_LEFT',
    Right = 'ALIGN_RIGHT',
    Center = 'ALIGN_CENTER'
}

/**
 * Properties of the field that represents boolean value.
 * @export
 * @interface LeadsFormsServerV1BoolField
 */
export interface LeadsFormsServerV1BoolField {
    /**
     * 
     * @type {LeadsFormsServerV1Align}
     * @memberof LeadsFormsServerV1BoolField
     */
    align?: LeadsFormsServerV1Align;
    /**
     * Comment contains path to submit form field.
     * @type {string}
     * @memberof LeadsFormsServerV1BoolField
     */
    comment: string;
    /**
     * Default value for the field.
     * @type {boolean}
     * @memberof LeadsFormsServerV1BoolField
     */
    defaultValue?: boolean;
    /**
     * 
     * @type {LeadsFormsServerV1Disclaimer}
     * @memberof LeadsFormsServerV1BoolField
     */
    disclaimer?: LeadsFormsServerV1Disclaimer;
    /**
     * A flag indicating that the field is required.
     * @type {boolean}
     * @memberof LeadsFormsServerV1BoolField
     */
    required: boolean;
    /**
     * Public name of the field. The value of this field is used as the field label on the client side.
     * @type {string}
     * @memberof LeadsFormsServerV1BoolField
     */
    title: string;
    /**
     * Hint field place indicator.
     * @type {string}
     * @memberof LeadsFormsServerV1BoolField
     */
    hint?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LeadsFormsServerV1BoolField
     */
    displayed?: boolean;
}
/**
 * Properties of the form field that represents select value.
 * @export
 * @interface LeadsFormsServerV1BrandField
 */
export interface LeadsFormsServerV1BrandField {
    /**
     * 
     * @type {LeadsFormsServerV1Align}
     * @memberof LeadsFormsServerV1BrandField
     */
    align?: LeadsFormsServerV1Align;
    /**
     * Comment contains path to submit form field.
     * @type {string}
     * @memberof LeadsFormsServerV1BrandField
     */
    comment: string;
    /**
     * 
     * @type {LeadsFormsServerV1Disclaimer}
     * @memberof LeadsFormsServerV1BrandField
     */
    disclaimer?: LeadsFormsServerV1Disclaimer;
    /**
     * A flag indicating that the field is required.
     * @type {boolean}
     * @memberof LeadsFormsServerV1BrandField
     */
    required: boolean;
    /**
     * Public name of the field. The value of this field is used as the field label on the client side.
     * @type {string}
     * @memberof LeadsFormsServerV1BrandField
     */
    title: string;
    /**
     * A flag indicating that the field is displayed.
     * @type {boolean}
     * @memberof LeadsFormsServerV1BrandField
     */
    displayed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1BrandField
     */
    defaultValue?: string;
    /**
     * A flag indicating that the field contains all available brands without filtration.
     * @type {boolean}
     * @memberof LeadsFormsServerV1BrandField
     */
    allItems?: boolean;
    /**
     * List of select items.
     * @type {Array<LeadsFormsServerV1BrandItem>}
     * @memberof LeadsFormsServerV1BrandField
     */
    items: Array<LeadsFormsServerV1BrandItem>;
}
/**
 * Describes select item.
 * @export
 * @interface LeadsFormsServerV1BrandItem
 */
export interface LeadsFormsServerV1BrandItem {
    /**
     * Title name of the select item.
     * @type {string}
     * @memberof LeadsFormsServerV1BrandItem
     */
    title: string;
    /**
     * Select item value.
     * @type {string}
     * @memberof LeadsFormsServerV1BrandItem
     */
    value: string;
}
/**
 * Lead source properties
 * @export
 * @interface LeadsFormsServerV1Campaign
 */
export interface LeadsFormsServerV1Campaign {
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1Campaign
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1Campaign
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1Campaign
     */
    codeName: string;
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1Campaign
     */
    webName?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1Campaign
     */
    startTime?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1Campaign
     */
    endTime?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1Campaign
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1Campaign
     */
    brandId?: string;
}
/**
 * Contains filter parameters for campaigns.
 * @export
 * @interface LeadsFormsServerV1CampaignFilter
 */
export interface LeadsFormsServerV1CampaignFilter {
    /**
     * 
     * @type {LeadsFiltersV1IDFilter}
     * @memberof LeadsFormsServerV1CampaignFilter
     */
    id?: LeadsFiltersV1IDFilter;
    /**
     * 
     * @type {LeadsFiltersV1StringFilter}
     * @memberof LeadsFormsServerV1CampaignFilter
     */
    codeName?: LeadsFiltersV1StringFilter;
    /**
     * 
     * @type {LeadsFiltersV1StringFilter}
     * @memberof LeadsFormsServerV1CampaignFilter
     */
    webName?: LeadsFiltersV1StringFilter;
    /**
     * 
     * @type {LeadsFiltersV1ActivityFilter}
     * @memberof LeadsFormsServerV1CampaignFilter
     */
    activity?: LeadsFiltersV1ActivityFilter;
    /**
     * 
     * @type {LeadsLeadsServerV1BrandFilter}
     * @memberof LeadsFormsServerV1CampaignFilter
     */
    brandId?: LeadsLeadsServerV1BrandFilter;
}
/**
 * Available values for campaign sorting.
 * @export
 * @enum {string}
 */

export enum LeadsFormsServerV1CampaignSort {
    None = 'CAMPAIGN_SORT_NONE',
    NameAsc = 'CAMPAIGN_SORT_NAME_ASC',
    NameDesc = 'CAMPAIGN_SORT_NAME_DESC'
}

/**
 * Properties of the field that represents form caption.
 * @export
 * @interface LeadsFormsServerV1Caption
 */
export interface LeadsFormsServerV1Caption {
    /**
     * 
     * @type {LeadsFormsServerV1Align}
     * @memberof LeadsFormsServerV1Caption
     */
    align?: LeadsFormsServerV1Align;
    /**
     * 
     * @type {LeadsFormsServerV1Disclaimer}
     * @memberof LeadsFormsServerV1Caption
     */
    disclaimer?: LeadsFormsServerV1Disclaimer;
    /**
     * A flag indicating that the field is required.
     * @type {boolean}
     * @memberof LeadsFormsServerV1Caption
     */
    required: boolean;
    /**
     * Public name of the field. The value of this field is used as the field label on the client side.
     * @type {string}
     * @memberof LeadsFormsServerV1Caption
     */
    text?: string;
}
/**
 * Properties of the field that represents comment value.
 * @export
 * @interface LeadsFormsServerV1CommentField
 */
export interface LeadsFormsServerV1CommentField {
    /**
     * 
     * @type {LeadsFormsServerV1Align}
     * @memberof LeadsFormsServerV1CommentField
     */
    align?: LeadsFormsServerV1Align;
    /**
     * Comment contains path to submit form field.
     * @type {string}
     * @memberof LeadsFormsServerV1CommentField
     */
    comment: string;
    /**
     * 
     * @type {LeadsFormsServerV1Disclaimer}
     * @memberof LeadsFormsServerV1CommentField
     */
    disclaimer?: LeadsFormsServerV1Disclaimer;
    /**
     * Regular expression pattern for the field value validation.
     * @type {string}
     * @memberof LeadsFormsServerV1CommentField
     */
    pattern?: string;
    /**
     * Hint field.
     * @type {string}
     * @memberof LeadsFormsServerV1CommentField
     */
    hint?: string;
    /**
     * A flag indicating that the field is required.
     * @type {boolean}
     * @memberof LeadsFormsServerV1CommentField
     */
    required: boolean;
    /**
     * Public name of the field. The value of this field is used as the field label on the client side.
     * @type {string}
     * @memberof LeadsFormsServerV1CommentField
     */
    title: string;
    /**
     * Public name of the field. The value of this field is used as the field label on the client side.
     * @type {number}
     * @memberof LeadsFormsServerV1CommentField
     */
    maxLength?: number;
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1CommentField
     */
    defaultValue?: string;
    /**
     * A flag indicating that the field is displayed.
     * @type {boolean}
     * @memberof LeadsFormsServerV1CommentField
     */
    displayed?: boolean;
    /**
     * 
     * @type {LeadsFormsServerV1ViewMode}
     * @memberof LeadsFormsServerV1CommentField
     */
    viewMode?: LeadsFormsServerV1ViewMode;
}
/**
 * Properties of the form field that represents select value.
 * @export
 * @interface LeadsFormsServerV1CommunicationChannelField
 */
export interface LeadsFormsServerV1CommunicationChannelField {
    /**
     * 
     * @type {LeadsFormsServerV1Align}
     * @memberof LeadsFormsServerV1CommunicationChannelField
     */
    align?: LeadsFormsServerV1Align;
    /**
     * Comment contains path to submit form field.
     * @type {string}
     * @memberof LeadsFormsServerV1CommunicationChannelField
     */
    comment: string;
    /**
     * 
     * @type {LeadsFormsServerV1Disclaimer}
     * @memberof LeadsFormsServerV1CommunicationChannelField
     */
    disclaimer?: LeadsFormsServerV1Disclaimer;
    /**
     * A flag indicating that the field is required.
     * @type {boolean}
     * @memberof LeadsFormsServerV1CommunicationChannelField
     */
    required: boolean;
    /**
     * Public name of the field. The value of this field is used as the field label on the client side.
     * @type {string}
     * @memberof LeadsFormsServerV1CommunicationChannelField
     */
    title: string;
    /**
     * 
     * @type {LeadsLeadsServerV1CommunicationMethod}
     * @memberof LeadsFormsServerV1CommunicationChannelField
     */
    defaultValue?: LeadsLeadsServerV1CommunicationMethod;
    /**
     * List of select items.
     * @type {Array<LeadsFormsServerV1CommunicationChannelItem>}
     * @memberof LeadsFormsServerV1CommunicationChannelField
     */
    items: Array<LeadsFormsServerV1CommunicationChannelItem>;
    /**
     * A flag indicating that the field is displayed.
     * @type {boolean}
     * @memberof LeadsFormsServerV1CommunicationChannelField
     */
    displayed?: boolean;
}
/**
 * Describes select item.
 * @export
 * @interface LeadsFormsServerV1CommunicationChannelItem
 */
export interface LeadsFormsServerV1CommunicationChannelItem {
    /**
     * Title name of the select item.
     * @type {string}
     * @memberof LeadsFormsServerV1CommunicationChannelItem
     */
    title: string;
    /**
     * 
     * @type {LeadsLeadsServerV1CommunicationMethod}
     * @memberof LeadsFormsServerV1CommunicationChannelItem
     */
    value: LeadsLeadsServerV1CommunicationMethod;
}
/**
 * Properties of the field that represents boolean value.
 * @export
 * @interface LeadsFormsServerV1ConsentField
 */
export interface LeadsFormsServerV1ConsentField {
    /**
     * 
     * @type {LeadsFormsServerV1Align}
     * @memberof LeadsFormsServerV1ConsentField
     */
    align?: LeadsFormsServerV1Align;
    /**
     * Comment contains path to submit form field.
     * @type {string}
     * @memberof LeadsFormsServerV1ConsentField
     */
    comment: string;
    /**
     * Default value for the field.
     * @type {boolean}
     * @memberof LeadsFormsServerV1ConsentField
     */
    defaultValue?: boolean;
    /**
     * 
     * @type {LeadsFormsServerV1Disclaimer}
     * @memberof LeadsFormsServerV1ConsentField
     */
    disclaimer?: LeadsFormsServerV1Disclaimer;
    /**
     * A flag indicating that the field is required.
     * @type {boolean}
     * @memberof LeadsFormsServerV1ConsentField
     */
    required: boolean;
    /**
     * Public name of the field. The value of this field is used as the field label on the client side.
     * @type {string}
     * @memberof LeadsFormsServerV1ConsentField
     */
    title: string;
    /**
     * Hint field place indicator.
     * @type {string}
     * @memberof LeadsFormsServerV1ConsentField
     */
    hint?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1ConsentField
     */
    agreementLink?: string;
    /**
     * A flag indicating that the field is displayed.
     * @type {boolean}
     * @memberof LeadsFormsServerV1ConsentField
     */
    displayed?: boolean;
}
/**
 * ConsentsGroupField contains communication and request consents.
 * @export
 * @interface LeadsFormsServerV1ConsentsGroupField
 */
export interface LeadsFormsServerV1ConsentsGroupField {
    /**
     * 
     * @type {LeadsFormsServerV1ConsentField}
     * @memberof LeadsFormsServerV1ConsentsGroupField
     */
    communicationConsent?: LeadsFormsServerV1ConsentField;
    /**
     * 
     * @type {LeadsFormsServerV1ConsentField}
     * @memberof LeadsFormsServerV1ConsentsGroupField
     */
    requestConsent?: LeadsFormsServerV1ConsentField;
}
/**
 * CreateDisclaimerResponse contains created disclaimer object.
 * @export
 * @interface LeadsFormsServerV1CreateDisclaimerResponse
 */
export interface LeadsFormsServerV1CreateDisclaimerResponse {
    /**
     * 
     * @type {LeadsFormsServerV1Disclaimer}
     * @memberof LeadsFormsServerV1CreateDisclaimerResponse
     */
    disclaimer?: LeadsFormsServerV1Disclaimer;
}
/**
 * CreateDisplayResponse contains created Display object.
 * @export
 * @interface LeadsFormsServerV1CreateDisplayResponse
 */
export interface LeadsFormsServerV1CreateDisplayResponse {
    /**
     * 
     * @type {LeadsFormsServerV1Display}
     * @memberof LeadsFormsServerV1CreateDisplayResponse
     */
    display?: LeadsFormsServerV1Display;
}
/**
 * Successful response to the create form request
 * @export
 * @interface LeadsFormsServerV1CreateFormResponse
 */
export interface LeadsFormsServerV1CreateFormResponse {
    /**
     * 
     * @type {LeadsFormsServerV1Form}
     * @memberof LeadsFormsServerV1CreateFormResponse
     */
    form: LeadsFormsServerV1Form;
}
/**
 * Parameters of the current car.
 * @export
 * @interface LeadsFormsServerV1CurrentCar
 */
export interface LeadsFormsServerV1CurrentCar {
    /**
     * 
     * @type {LeadsFormsServerV1Vehicle}
     * @memberof LeadsFormsServerV1CurrentCar
     */
    vehicle?: LeadsFormsServerV1Vehicle;
    /**
     * Identifies that the client is a owner of the brand\'s car.
     * @type {boolean}
     * @memberof LeadsFormsServerV1CurrentCar
     */
    brandOwner?: boolean;
    /**
     * Identifier of the engine of the current car.
     * @type {string}
     * @memberof LeadsFormsServerV1CurrentCar
     */
    engineId?: string;
    /**
     * Manufacturing date of the current car. Cannot be greater then the current date.
     * @type {string}
     * @memberof LeadsFormsServerV1CurrentCar
     */
    manufactureDate?: string;
    /**
     * Mileage of the current car, kilometers.
     * @type {number}
     * @memberof LeadsFormsServerV1CurrentCar
     */
    mileage?: number;
    /**
     * Manufacturing year of the current car.
     * @type {number}
     * @memberof LeadsFormsServerV1CurrentCar
     */
    modelYear?: number;
    /**
     * Date of the registration of the current car. Cannot be greater then the current date.
     * @type {string}
     * @memberof LeadsFormsServerV1CurrentCar
     */
    plateDate?: string;
    /**
     * Plate number of the current car.
     * @type {string}
     * @memberof LeadsFormsServerV1CurrentCar
     */
    plateNumber?: string;
    /**
     * Date of the purchase of the current car.
     * @type {string}
     * @memberof LeadsFormsServerV1CurrentCar
     */
    purchaseDate?: string;
    /**
     * Usage term of the current car, months.
     * @type {number}
     * @memberof LeadsFormsServerV1CurrentCar
     */
    term?: number;
    /**
     * Identifier of the transmission of the current car.
     * @type {string}
     * @memberof LeadsFormsServerV1CurrentCar
     */
    transmissionId?: string;
    /**
     * Vehicle identification number of the current car.
     * @type {string}
     * @memberof LeadsFormsServerV1CurrentCar
     */
    vin?: string;
    /**
     * Photo link list.
     * @type {Array<string>}
     * @memberof LeadsFormsServerV1CurrentCar
     */
    photos?: Array<string>;
}
/**
 * Properties of the field that represents date value.
 * @export
 * @interface LeadsFormsServerV1DateField
 */
export interface LeadsFormsServerV1DateField {
    /**
     * 
     * @type {LeadsFormsServerV1Align}
     * @memberof LeadsFormsServerV1DateField
     */
    align?: LeadsFormsServerV1Align;
    /**
     * Comment contains path to submit form field.
     * @type {string}
     * @memberof LeadsFormsServerV1DateField
     */
    comment: string;
    /**
     * 
     * @type {LeadsFormsServerV1Disclaimer}
     * @memberof LeadsFormsServerV1DateField
     */
    disclaimer?: LeadsFormsServerV1Disclaimer;
    /**
     * A flag indicating that the field is required.
     * @type {boolean}
     * @memberof LeadsFormsServerV1DateField
     */
    required: boolean;
    /**
     * Public name of the field. The value of this field is used as the field label on the client side.
     * @type {string}
     * @memberof LeadsFormsServerV1DateField
     */
    title?: string;
    /**
     * Hint field place indicator.
     * @type {string}
     * @memberof LeadsFormsServerV1DateField
     */
    hint?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1DateField
     */
    defaultValue?: string;
    /**
     * A flag indicating that the field is displayed.
     * @type {boolean}
     * @memberof LeadsFormsServerV1DateField
     */
    displayed?: boolean;
}
/**
 * Parameters of the dealer.
 * @export
 * @interface LeadsFormsServerV1Dealer
 */
export interface LeadsFormsServerV1Dealer {
    /**
     * Identifier of the dealer.
     * @type {string}
     * @memberof LeadsFormsServerV1Dealer
     */
    dealerId?: string;
    /**
     * Identifier of the dealer center.
     * @type {string}
     * @memberof LeadsFormsServerV1Dealer
     */
    dealerCenterId?: string;
}
/**
 * Properties of the field that represents dealer center.
 * @export
 * @interface LeadsFormsServerV1DealerCenterField
 */
export interface LeadsFormsServerV1DealerCenterField {
    /**
     * 
     * @type {LeadsFormsServerV1Align}
     * @memberof LeadsFormsServerV1DealerCenterField
     */
    align?: LeadsFormsServerV1Align;
    /**
     * 
     * @type {LeadsFormsServerV1Disclaimer}
     * @memberof LeadsFormsServerV1DealerCenterField
     */
    disclaimer?: LeadsFormsServerV1Disclaimer;
    /**
     * A flag indicating that the field is required.
     * @type {boolean}
     * @memberof LeadsFormsServerV1DealerCenterField
     */
    required: boolean;
    /**
     * Public name of the field. The value of this field is used as the field label on the client side.
     * @type {string}
     * @memberof LeadsFormsServerV1DealerCenterField
     */
    title: string;
    /**
     * A flag indicating that the field is with map.
     * @type {boolean}
     * @memberof LeadsFormsServerV1DealerCenterField
     */
    showMap?: boolean;
    /**
     * Comment contains path to submit form field.
     * @type {string}
     * @memberof LeadsFormsServerV1DealerCenterField
     */
    comment: string;
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1DealerCenterField
     */
    defaultValue?: string;
    /**
     * List of dealer items.
     * @type {Array<LeadsFormsServerV1DealerItem>}
     * @memberof LeadsFormsServerV1DealerCenterField
     */
    items?: Array<LeadsFormsServerV1DealerItem>;
    /**
     * 
     * @type {Array<LeadsDealersServerV1DealerCenterCity>}
     * @memberof LeadsFormsServerV1DealerCenterField
     */
    cities?: Array<LeadsDealersServerV1DealerCenterCity>;
    /**
     * A flag indicating that the field is displayed.
     * @type {boolean}
     * @memberof LeadsFormsServerV1DealerCenterField
     */
    displayed?: boolean;
    /**
     * A flag indicating that the field contains all available dealers without filtration.
     * @type {boolean}
     * @memberof LeadsFormsServerV1DealerCenterField
     */
    allItems?: boolean;
}
/**
 * Dealer item properties.
 * @export
 * @interface LeadsFormsServerV1DealerItem
 */
export interface LeadsFormsServerV1DealerItem {
    /**
     * Dealer address.
     * @type {string}
     * @memberof LeadsFormsServerV1DealerItem
     */
    address: string;
    /**
     * Dealer company name.
     * @type {string}
     * @memberof LeadsFormsServerV1DealerItem
     */
    name: string;
    /**
     * The list of the dealers\'s phone numbers.
     * @type {Array<string>}
     * @memberof LeadsFormsServerV1DealerItem
     */
    phones: Array<string>;
    /**
     * Dealer site link.
     * @type {string}
     * @memberof LeadsFormsServerV1DealerItem
     */
    site: string;
    /**
     * Longitude value.
     * @type {number}
     * @memberof LeadsFormsServerV1DealerItem
     */
    longitude?: number;
    /**
     * Longitude value.
     * @type {number}
     * @memberof LeadsFormsServerV1DealerItem
     */
    latitude?: number;
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1DealerItem
     */
    value?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1DealerItem
     */
    cityId?: string;
    /**
     * 
     * @type {LeadsLeadsServerV1Brand}
     * @memberof LeadsFormsServerV1DealerItem
     */
    brandId?: LeadsLeadsServerV1Brand;
}
/**
 * Parameters of the desired car.
 * @export
 * @interface LeadsFormsServerV1DesiredCar
 */
export interface LeadsFormsServerV1DesiredCar {
    /**
     * 
     * @type {LeadsFormsServerV1Vehicle}
     * @memberof LeadsFormsServerV1DesiredCar
     */
    vehicle?: LeadsFormsServerV1Vehicle;
    /**
     * Estimated date of the purchase.
     * @type {string}
     * @memberof LeadsFormsServerV1DesiredCar
     */
    estimateDate?: string;
}
/**
 * Describes disclaimer item.
 * @export
 * @interface LeadsFormsServerV1Disclaimer
 */
export interface LeadsFormsServerV1Disclaimer {
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1Disclaimer
     */
    id: string;
    /**
     * 
     * @type {LeadsFormsServerV1DisclaimerProps}
     * @memberof LeadsFormsServerV1Disclaimer
     */
    props: LeadsFormsServerV1DisclaimerProps;
}
/**
 * Contains filter parameters for disclaimers.
 * @export
 * @interface LeadsFormsServerV1DisclaimerFilter
 */
export interface LeadsFormsServerV1DisclaimerFilter {
    /**
     * 
     * @type {LeadsFiltersV1IDFilter}
     * @memberof LeadsFormsServerV1DisclaimerFilter
     */
    id?: LeadsFiltersV1IDFilter;
    /**
     * 
     * @type {LeadsLeadsServerV1BrandFilter}
     * @memberof LeadsFormsServerV1DisclaimerFilter
     */
    brandId?: LeadsLeadsServerV1BrandFilter;
    /**
     * 
     * @type {LeadsFiltersV1StringFilter}
     * @memberof LeadsFormsServerV1DisclaimerFilter
     */
    title?: LeadsFiltersV1StringFilter;
}
/**
 * DisclaimerProps describes User-defined properties of the disclaimer.
 * @export
 * @interface LeadsFormsServerV1DisclaimerProps
 */
export interface LeadsFormsServerV1DisclaimerProps {
    /**
     * 
     * @type {LeadsLeadsServerV1Brand}
     * @memberof LeadsFormsServerV1DisclaimerProps
     */
    brandId?: LeadsLeadsServerV1Brand;
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1DisclaimerProps
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1DisclaimerProps
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1DisclaimerProps
     */
    text?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1DisclaimerProps
     */
    url?: string;
}
/**
 * Available values for disclaimer sorting.
 * @export
 * @enum {string}
 */

export enum LeadsFormsServerV1DisclaimerSort {
    None = 'DISCLAIMER_SORT_NONE',
    TitleAsc = 'DISCLAIMER_SORT_TITLE_ASC',
    TitleDesc = 'DISCLAIMER_SORT_TITLE_DESC',
    BrandAsc = 'DISCLAIMER_SORT_BRAND_ASC',
    BrandDesc = 'DISCLAIMER_SORT_BRAND_DESC'
}

/**
 * Describes display item.
 * @export
 * @interface LeadsFormsServerV1Display
 */
export interface LeadsFormsServerV1Display {
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1Display
     */
    id: string;
    /**
     * 
     * @type {LeadsFormsServerV1DisplayProps}
     * @memberof LeadsFormsServerV1Display
     */
    props: LeadsFormsServerV1DisplayProps;
}
/**
 * DisplayButton contains button parameters.
 * @export
 * @interface LeadsFormsServerV1DisplayButton
 */
export interface LeadsFormsServerV1DisplayButton {
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1DisplayButton
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1DisplayButton
     */
    url?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LeadsFormsServerV1DisplayButton
     */
    newTab?: boolean;
}
/**
 * Contains filter parameters for displays.
 * @export
 * @interface LeadsFormsServerV1DisplayFilter
 */
export interface LeadsFormsServerV1DisplayFilter {
    /**
     * 
     * @type {LeadsFiltersV1IDFilter}
     * @memberof LeadsFormsServerV1DisplayFilter
     */
    id?: LeadsFiltersV1IDFilter;
    /**
     * 
     * @type {LeadsLeadsServerV1BrandFilter}
     * @memberof LeadsFormsServerV1DisplayFilter
     */
    brandId?: LeadsLeadsServerV1BrandFilter;
    /**
     * 
     * @type {LeadsFiltersV1StringFilter}
     * @memberof LeadsFormsServerV1DisplayFilter
     */
    title?: LeadsFiltersV1StringFilter;
}
/**
 * DisplayProps describes User-defined properties of the disclaimer.
 * @export
 * @interface LeadsFormsServerV1DisplayProps
 */
export interface LeadsFormsServerV1DisplayProps {
    /**
     * 
     * @type {LeadsLeadsServerV1Brand}
     * @memberof LeadsFormsServerV1DisplayProps
     */
    brandId?: LeadsLeadsServerV1Brand;
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1DisplayProps
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1DisplayProps
     */
    text?: string;
    /**
     * 
     * @type {LeadsFormsServerV1DisplayButton}
     * @memberof LeadsFormsServerV1DisplayProps
     */
    mainButton?: LeadsFormsServerV1DisplayButton;
    /**
     * 
     * @type {LeadsFormsServerV1DisplayButton}
     * @memberof LeadsFormsServerV1DisplayProps
     */
    additionalButton?: LeadsFormsServerV1DisplayButton;
}
/**
 * Available values for display sorting.
 * @export
 * @enum {string}
 */

export enum LeadsFormsServerV1DisplaySort {
    None = 'DISPLAY_SORT_NONE',
    TitleAsc = 'DISPLAY_SORT_TITLE_ASC',
    TitleDesc = 'DISPLAY_SORT_TITLE_DESC',
    BrandAsc = 'DISPLAY_SORT_BRAND_ASC',
    BrandDesc = 'DISPLAY_SORT_BRAND_DESC'
}

/**
 * Properties of the field that represents email value.
 * @export
 * @interface LeadsFormsServerV1EmailField
 */
export interface LeadsFormsServerV1EmailField {
    /**
     * 
     * @type {LeadsFormsServerV1Align}
     * @memberof LeadsFormsServerV1EmailField
     */
    align?: LeadsFormsServerV1Align;
    /**
     * Comment contains path to submit form field.
     * @type {string}
     * @memberof LeadsFormsServerV1EmailField
     */
    comment: string;
    /**
     * 
     * @type {LeadsFormsServerV1Disclaimer}
     * @memberof LeadsFormsServerV1EmailField
     */
    disclaimer?: LeadsFormsServerV1Disclaimer;
    /**
     * Regular expression pattern for the field value validation.
     * @type {string}
     * @memberof LeadsFormsServerV1EmailField
     */
    pattern?: string;
    /**
     * Hint field place indicator.
     * @type {string}
     * @memberof LeadsFormsServerV1EmailField
     */
    hint?: string;
    /**
     * A flag indicating that the field is required.
     * @type {boolean}
     * @memberof LeadsFormsServerV1EmailField
     */
    required: boolean;
    /**
     * Public name of the field. The value of this field is used as the field label on the client side.
     * @type {string}
     * @memberof LeadsFormsServerV1EmailField
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1EmailField
     */
    defaultValue?: string;
    /**
     * A flag indicating that the field is displayed.
     * @type {boolean}
     * @memberof LeadsFormsServerV1EmailField
     */
    displayed?: boolean;
}
/**
 * Properties of the field that represents family test drive.
 * @export
 * @interface LeadsFormsServerV1FamilyTestDriveField
 */
export interface LeadsFormsServerV1FamilyTestDriveField {
    /**
     * 
     * @type {LeadsFormsServerV1Align}
     * @memberof LeadsFormsServerV1FamilyTestDriveField
     */
    align?: LeadsFormsServerV1Align;
    /**
     * Comment contains path to submit form field.
     * @type {string}
     * @memberof LeadsFormsServerV1FamilyTestDriveField
     */
    comment: string;
    /**
     * Description of the field.
     * @type {string}
     * @memberof LeadsFormsServerV1FamilyTestDriveField
     */
    description: string;
    /**
     * 
     * @type {LeadsFormsServerV1Disclaimer}
     * @memberof LeadsFormsServerV1FamilyTestDriveField
     */
    disclaimer?: LeadsFormsServerV1Disclaimer;
    /**
     * Regular expression pattern for the field value validation.
     * @type {string}
     * @memberof LeadsFormsServerV1FamilyTestDriveField
     */
    pattern?: string;
    /**
     * A flag indicating that the field is required.
     * @type {boolean}
     * @memberof LeadsFormsServerV1FamilyTestDriveField
     */
    required: boolean;
    /**
     * Public name of the field. The value of this field is used as the field label on the client side.
     * @type {string}
     * @memberof LeadsFormsServerV1FamilyTestDriveField
     */
    title: string;
    /**
     * Hint field place indicator.
     * @type {string}
     * @memberof LeadsFormsServerV1FamilyTestDriveField
     */
    hint?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1FamilyTestDriveField
     */
    defaultValue?: string;
    /**
     * A flag indicating that the field is displayed.
     * @type {boolean}
     * @memberof LeadsFormsServerV1FamilyTestDriveField
     */
    displayed?: boolean;
}
/**
 * @type LeadsFormsServerV1Field
 * Describes field of the form.
 * @export
 */
export type LeadsFormsServerV1Field = BirthdateField | BoughtAtOfficialField | CaptionField | CarOwnerField | CommentField | CommunicationChannelField | ConsentsGroupField | CurrentCarField | DealerCenterField | DesiredCarField | DesiredCarWithPhotoField | DesiredPurchaseDateField | DesiredPurchasePlannedPeriodField | DesiredPurchaseTimeField | EmailField | FamilyTestDriveField | FirstNameField | GenderField | HeaderField | InterestsField | LastNameField | LeadSourceField | ManufactureDateField | ManufactureYearField | MiddleNameField | MileageField | ModelYearField | MultiUploadField | OrganizationField | PhoneField | PlateField | PurchaseDateField | RegistrationDateField | ResidenceField | RichTextField | StepBreakField | TermField | TestDriveDateField | TestDrivePlannedPeriodField | TestDriveTimeField | TestDriveTimeOfDayField | VinField;

/**
 * Properties of the field that represents first name value.
 * @export
 * @interface LeadsFormsServerV1FirstNameField
 */
export interface LeadsFormsServerV1FirstNameField {
    /**
     * 
     * @type {LeadsFormsServerV1Align}
     * @memberof LeadsFormsServerV1FirstNameField
     */
    align?: LeadsFormsServerV1Align;
    /**
     * Comment contains path to submit form field.
     * @type {string}
     * @memberof LeadsFormsServerV1FirstNameField
     */
    comment: string;
    /**
     * 
     * @type {LeadsFormsServerV1Disclaimer}
     * @memberof LeadsFormsServerV1FirstNameField
     */
    disclaimer?: LeadsFormsServerV1Disclaimer;
    /**
     * Regular expression pattern for the field value validation.
     * @type {string}
     * @memberof LeadsFormsServerV1FirstNameField
     */
    pattern?: string;
    /**
     * Hint field place indicator.
     * @type {string}
     * @memberof LeadsFormsServerV1FirstNameField
     */
    hint?: string;
    /**
     * A flag indicating that the field is required.
     * @type {boolean}
     * @memberof LeadsFormsServerV1FirstNameField
     */
    required: boolean;
    /**
     * Public name of the field. The value of this field is used as the field label on the client side.
     * @type {string}
     * @memberof LeadsFormsServerV1FirstNameField
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1FirstNameField
     */
    defaultValue?: string;
    /**
     * A flag indicating that the field is displayed.
     * @type {boolean}
     * @memberof LeadsFormsServerV1FirstNameField
     */
    displayed?: boolean;
}
/**
 * Parameters of the form.
 * @export
 * @interface LeadsFormsServerV1Form
 */
export interface LeadsFormsServerV1Form {
    /**
     * Form activity flag. The value of this field equal to false means that the form is in the archive. A form in the archive should not be displayed to end-customers, but it can still be submitted.
     * @type {boolean}
     * @memberof LeadsFormsServerV1Form
     */
    active: boolean;
    /**
     * Form identifier. The value of this field is unique across the system.
     * @type {string}
     * @memberof LeadsFormsServerV1Form
     */
    id: string;
    /**
     * 
     * @type {LeadsVersionV1Version}
     * @memberof LeadsFormsServerV1Form
     */
    version: LeadsVersionV1Version;
    /**
     * 
     * @type {LeadsFormsServerV1FormProps}
     * @memberof LeadsFormsServerV1Form
     */
    props: LeadsFormsServerV1FormProps;
}
/**
 * Contains filter parameters for form.
 * @export
 * @interface LeadsFormsServerV1FormFilter
 */
export interface LeadsFormsServerV1FormFilter {
    /**
     * 
     * @type {LeadsFiltersV1IDFilter}
     * @memberof LeadsFormsServerV1FormFilter
     */
    id?: LeadsFiltersV1IDFilter;
    /**
     * 
     * @type {LeadsLeadsServerV1BrandFilter}
     * @memberof LeadsFormsServerV1FormFilter
     */
    brandId?: LeadsLeadsServerV1BrandFilter;
    /**
     * 
     * @type {LeadsFiltersV1IDFilter}
     * @memberof LeadsFormsServerV1FormFilter
     */
    campaignId?: LeadsFiltersV1IDFilter;
    /**
     * 
     * @type {LeadsFiltersV1IDFilter}
     * @memberof LeadsFormsServerV1FormFilter
     */
    interestId?: LeadsFiltersV1IDFilter;
    /**
     * 
     * @type {LeadsFiltersV1ActivityFilter}
     * @memberof LeadsFormsServerV1FormFilter
     */
    status?: LeadsFiltersV1ActivityFilter;
    /**
     * 
     * @type {LeadsFormsServerV1PublishOnFilter}
     * @memberof LeadsFormsServerV1FormFilter
     */
    publishOn?: LeadsFormsServerV1PublishOnFilter;
    /**
     * 
     * @type {LeadsFiltersV1StringFilter}
     * @memberof LeadsFormsServerV1FormFilter
     */
    title?: LeadsFiltersV1StringFilter;
}
/**
 * Parameters of the form.
 * @export
 * @interface LeadsFormsServerV1FormListBrand
 */
export interface LeadsFormsServerV1FormListBrand {
    /**
     * 
     * @type {LeadsLeadsServerV1Brand}
     * @memberof LeadsFormsServerV1FormListBrand
     */
    id: LeadsLeadsServerV1Brand;
    /**
     * Public name of the form.
     * @type {string}
     * @memberof LeadsFormsServerV1FormListBrand
     */
    title?: string;
}
/**
 * Parameters of the form.
 * @export
 * @interface LeadsFormsServerV1FormListCampaign
 */
export interface LeadsFormsServerV1FormListCampaign {
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1FormListCampaign
     */
    id: string;
    /**
     * Public name of the campaign.
     * @type {string}
     * @memberof LeadsFormsServerV1FormListCampaign
     */
    title?: string;
}
/**
 * Parameters of the form.
 * @export
 * @interface LeadsFormsServerV1FormListInterest
 */
export interface LeadsFormsServerV1FormListInterest {
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1FormListInterest
     */
    id: string;
    /**
     * Public name of the interest.
     * @type {string}
     * @memberof LeadsFormsServerV1FormListInterest
     */
    title?: string;
}
/**
 * Parameters of the form.
 * @export
 * @interface LeadsFormsServerV1FormListItem
 */
export interface LeadsFormsServerV1FormListItem {
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1FormListItem
     */
    id: string;
    /**
     * Public name of the form. The value of this field is in some cases displayed to the end-customers.
     * @type {string}
     * @memberof LeadsFormsServerV1FormListItem
     */
    title?: string;
    /**
     * 
     * @type {LeadsFormsServerV1FormListBrand}
     * @memberof LeadsFormsServerV1FormListItem
     */
    brand: LeadsFormsServerV1FormListBrand;
    /**
     * 
     * @type {LeadsFormsServerV1FormListCampaign}
     * @memberof LeadsFormsServerV1FormListItem
     */
    campaign: LeadsFormsServerV1FormListCampaign;
    /**
     * 
     * @type {LeadsFormsServerV1FormListInterest}
     * @memberof LeadsFormsServerV1FormListItem
     */
    interest: LeadsFormsServerV1FormListInterest;
    /**
     * 
     * @type {LeadsActivityV1Activity}
     * @memberof LeadsFormsServerV1FormListItem
     */
    activity: LeadsActivityV1Activity;
    /**
     * 
     * @type {LeadsVersionV1Version}
     * @memberof LeadsFormsServerV1FormListItem
     */
    version: LeadsVersionV1Version;
}
/**
 * Describes user-defined properties of the form.
 * @export
 * @interface LeadsFormsServerV1FormProps
 */
export interface LeadsFormsServerV1FormProps {
    /**
     * 
     * @type {LeadsActivityV1Activity}
     * @memberof LeadsFormsServerV1FormProps
     */
    activity: LeadsActivityV1Activity;
    /**
     * Marketing campaign identifier.
     * @type {string}
     * @memberof LeadsFormsServerV1FormProps
     */
    campaignId: string;
    /**
     * 
     * @type {LeadsLeadsServerV1Brand}
     * @memberof LeadsFormsServerV1FormProps
     */
    brandId?: LeadsLeadsServerV1Brand;
    /**
     * Public description of the form. The value of this field is in some cases displayed to the end-customers.
     * @type {string}
     * @memberof LeadsFormsServerV1FormProps
     */
    description: string;
    /**
     * Public name of the form. The value of this field is in some cases displayed to the end-customers.
     * @type {string}
     * @memberof LeadsFormsServerV1FormProps
     */
    title: string;
    /**
     * 
     * @type {LeadsFormsServerV1Display}
     * @memberof LeadsFormsServerV1FormProps
     */
    displaySuccess?: LeadsFormsServerV1Display;
    /**
     * 
     * @type {LeadsFormsServerV1Display}
     * @memberof LeadsFormsServerV1FormProps
     */
    displayError?: LeadsFormsServerV1Display;
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1FormProps
     */
    slug?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1FormProps
     */
    interestId?: string;
    /**
     * 
     * @type {LeadsLeadsServerV1Category}
     * @memberof LeadsFormsServerV1FormProps
     */
    interestCategoryId?: LeadsLeadsServerV1Category;
    /**
     * 
     * @type {Array<string>}
     * @memberof LeadsFormsServerV1FormProps
     */
    testEmail?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1FormProps
     */
    leadChannelId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LeadsFormsServerV1FormProps
     */
    departmentId?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof LeadsFormsServerV1FormProps
     */
    tags?: Array<string>;
    /**
     * Ordered list of the form fields.
     * @type {Array<LeadsFormsServerV1Field>}
     * @memberof LeadsFormsServerV1FormProps
     */
    fields: Array<LeadsFormsServerV1Field>;
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1FormProps
     */
    buttonTitle?: string;
    /**
     * 
     * @type {LeadsFormsServerV1LeadInitiator}
     * @memberof LeadsFormsServerV1FormProps
     */
    defaultInitiator?: LeadsFormsServerV1LeadInitiator;
    /**
     * 
     * @type {boolean}
     * @memberof LeadsFormsServerV1FormProps
     */
    replaceMainInterest?: boolean;
    /**
     * 
     * @type {Array<LeadsFormsServerV1PublishOn>}
     * @memberof LeadsFormsServerV1FormProps
     */
    publishOn?: Array<LeadsFormsServerV1PublishOn>;
}
/**
 * Available values for form sorting.
 * @export
 * @enum {string}
 */

export enum LeadsFormsServerV1FormSort {
    None = 'FORM_SORT_NONE',
    TitleAsc = 'FORM_SORT_TITLE_ASC',
    TitleDesc = 'FORM_SORT_TITLE_DESC',
    BrandAsc = 'FORM_SORT_BRAND_ASC',
    BrandDesc = 'FORM_SORT_BRAND_DESC',
    CampaignAsc = 'FORM_SORT_CAMPAIGN_ASC',
    CampaignDesc = 'FORM_SORT_CAMPAIGN_DESC',
    InterestAsc = 'FORM_SORT_INTEREST_ASC',
    InterestDesc = 'FORM_SORT_INTEREST_DESC',
    ActiveFromAsc = 'FORM_SORT_ACTIVE_FROM_ASC',
    ActiveFromDesc = 'FORM_SORT_ACTIVE_FROM_DESC',
    ActiveToAsc = 'FORM_SORT_ACTIVE_TO_ASC',
    ActiveToDesc = 'FORM_SORT_ACTIVE_TO_DESC'
}

/**
 * Describes fields of the submitted form.
 * @export
 * @interface LeadsFormsServerV1FormSubmitProps
 */
export interface LeadsFormsServerV1FormSubmitProps {
    /**
     * 
     * @type {LeadsLeadsServerV1Gender}
     * @memberof LeadsFormsServerV1FormSubmitProps
     */
    gender?: LeadsLeadsServerV1Gender;
    /**
     * Lead source properties.
     * @type {string}
     * @memberof LeadsFormsServerV1FormSubmitProps
     */
    leadSource?: string;
    /**
     * First name of the client.
     * @type {string}
     * @memberof LeadsFormsServerV1FormSubmitProps
     */
    firstName?: string;
    /**
     * Last name of the client.
     * @type {string}
     * @memberof LeadsFormsServerV1FormSubmitProps
     */
    lastName?: string;
    /**
     * Middle name of the client.
     * @type {string}
     * @memberof LeadsFormsServerV1FormSubmitProps
     */
    middleName?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1FormSubmitProps
     */
    birthdate?: string;
    /**
     * Residence of client.
     * @type {string}
     * @memberof LeadsFormsServerV1FormSubmitProps
     */
    residence?: string;
    /**
     * 
     * @type {LeadsLeadsServerV1CommunicationMethod}
     * @memberof LeadsFormsServerV1FormSubmitProps
     */
    communicationChannel?: LeadsLeadsServerV1CommunicationMethod;
    /**
     * Email of the client.
     * @type {string}
     * @memberof LeadsFormsServerV1FormSubmitProps
     */
    email?: string;
    /**
     * Phone of the client.
     * @type {string}
     * @memberof LeadsFormsServerV1FormSubmitProps
     */
    phone?: string;
    /**
     * Family test drive.
     * @type {boolean}
     * @memberof LeadsFormsServerV1FormSubmitProps
     */
    familyTestDrive?: boolean;
    /**
     * Multi upload properties.
     * @type {Array<string>}
     * @memberof LeadsFormsServerV1FormSubmitProps
     */
    multiUpload?: Array<string>;
    /**
     * A flag of bought.
     * @type {boolean}
     * @memberof LeadsFormsServerV1FormSubmitProps
     */
    boughtAtOfficial?: boolean;
    /**
     * A flag of owner.
     * @type {boolean}
     * @memberof LeadsFormsServerV1FormSubmitProps
     */
    carOwner?: boolean;
    /**
     * 
     * @type {LeadsFormsServerV1Vehicle}
     * @memberof LeadsFormsServerV1FormSubmitProps
     */
    currentCar?: LeadsFormsServerV1Vehicle;
    /**
     * Model year properties of the current car.
     * @type {number}
     * @memberof LeadsFormsServerV1FormSubmitProps
     */
    modelYear?: number;
    /**
     * 
     * @type {number}
     * @memberof LeadsFormsServerV1FormSubmitProps
     */
    mileage?: number;
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1FormSubmitProps
     */
    vin?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1FormSubmitProps
     */
    plateNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1FormSubmitProps
     */
    manufactureDate?: string;
    /**
     * 
     * @type {number}
     * @memberof LeadsFormsServerV1FormSubmitProps
     */
    manufactureYear?: number;
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1FormSubmitProps
     */
    registrationDate?: string;
    /**
     * Term properties of the current car.
     * @type {number}
     * @memberof LeadsFormsServerV1FormSubmitProps
     */
    term?: number;
    /**
     * Purchase date properties of the current car.
     * @type {string}
     * @memberof LeadsFormsServerV1FormSubmitProps
     */
    purchaseDate?: string;
    /**
     * 
     * @type {LeadsFormsServerV1Vehicle}
     * @memberof LeadsFormsServerV1FormSubmitProps
     */
    desiredCar?: LeadsFormsServerV1Vehicle;
    /**
     * Desired date properties.
     * @type {string}
     * @memberof LeadsFormsServerV1FormSubmitProps
     */
    desiredPurchaseDate?: string;
    /**
     * 
     * @type {LeadsFormsServerV1TimeOfDay}
     * @memberof LeadsFormsServerV1FormSubmitProps
     */
    desiredPurchaseTime?: LeadsFormsServerV1TimeOfDay;
    /**
     * 
     * @type {LeadsFormsServerV1PlannedPeriod}
     * @memberof LeadsFormsServerV1FormSubmitProps
     */
    desiredPurchasePlannedPeriod?: LeadsFormsServerV1PlannedPeriod;
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1FormSubmitProps
     */
    comment?: string;
    /**
     * Dealer center properties.
     * @type {string}
     * @memberof LeadsFormsServerV1FormSubmitProps
     */
    dealerCenter?: string;
    /**
     * Interest properties.
     * @type {Array<string>}
     * @memberof LeadsFormsServerV1FormSubmitProps
     */
    interest?: Array<string>;
    /**
     * Organization name properties.
     * @type {string}
     * @memberof LeadsFormsServerV1FormSubmitProps
     */
    organization?: string;
    /**
     * Consent request properties.
     * @type {boolean}
     * @memberof LeadsFormsServerV1FormSubmitProps
     */
    requestConsent?: boolean;
    /**
     * Communication consent properties.
     * @type {boolean}
     * @memberof LeadsFormsServerV1FormSubmitProps
     */
    communicationConsent?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1FormSubmitProps
     */
    testDriveDate?: string;
    /**
     * 
     * @type {LeadsFormsServerV1TimeOfDay}
     * @memberof LeadsFormsServerV1FormSubmitProps
     */
    testDriveTimeOfDay?: LeadsFormsServerV1TimeOfDay;
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1FormSubmitProps
     */
    testDriveTime?: string;
    /**
     * 
     * @type {LeadsFormsServerV1PlannedPeriod}
     * @memberof LeadsFormsServerV1FormSubmitProps
     */
    testDrivePlannedPeriod?: LeadsFormsServerV1PlannedPeriod;
    /**
     * 
     * @type {LeadsLeadsServerV1UTM}
     * @memberof LeadsFormsServerV1FormSubmitProps
     */
    utm?: LeadsLeadsServerV1UTM;
}
/**
 * Successful response to the submit form request
 * @export
 * @interface LeadsFormsServerV1FormSubmitResponse
 */
export interface LeadsFormsServerV1FormSubmitResponse {
    /**
     * 
     * @type {LeadsFormsServerV1Result}
     * @memberof LeadsFormsServerV1FormSubmitResponse
     */
    result: LeadsFormsServerV1Result;
}
/**
 * Parameters of the submit form request
 * @export
 * @interface LeadsFormsServerV1FormSubmitTestRequest
 */
export interface LeadsFormsServerV1FormSubmitTestRequest {
    /**
     * 
     * @type {LeadsFormsServerV1FormProps}
     * @memberof LeadsFormsServerV1FormSubmitTestRequest
     */
    form: LeadsFormsServerV1FormProps;
    /**
     * 
     * @type {LeadsFormsServerV1FormSubmitProps}
     * @memberof LeadsFormsServerV1FormSubmitTestRequest
     */
    result: LeadsFormsServerV1FormSubmitProps;
}
/**
 * Format describes format of the files.
 * @export
 * @enum {string}
 */

export enum LeadsFormsServerV1Format {
    None = 'FORMAT_NONE',
    Png = 'FORMAT_PNG',
    Jpeg = 'FORMAT_JPEG',
    Jpg = 'FORMAT_JPG',
    Tiff = 'FORMAT_TIFF',
    Doc = 'FORMAT_DOC',
    Docx = 'FORMAT_DOCX',
    Xls = 'FORMAT_XLS',
    Xlsx = 'FORMAT_XLSX',
    Ppt = 'FORMAT_PPT'
}

/**
 * Describes format item.
 * @export
 * @interface LeadsFormsServerV1FormatItem
 */
export interface LeadsFormsServerV1FormatItem {
    /**
     * Title name of the item.
     * @type {string}
     * @memberof LeadsFormsServerV1FormatItem
     */
    title: string;
    /**
     * 
     * @type {LeadsFormsServerV1Format}
     * @memberof LeadsFormsServerV1FormatItem
     */
    value: LeadsFormsServerV1Format;
}
/**
 * Properties of the form field that represents gender.
 * @export
 * @interface LeadsFormsServerV1GenderField
 */
export interface LeadsFormsServerV1GenderField {
    /**
     * 
     * @type {LeadsFormsServerV1Align}
     * @memberof LeadsFormsServerV1GenderField
     */
    align?: LeadsFormsServerV1Align;
    /**
     * Comment contains path to submit form field.
     * @type {string}
     * @memberof LeadsFormsServerV1GenderField
     */
    comment: string;
    /**
     * 
     * @type {LeadsFormsServerV1Disclaimer}
     * @memberof LeadsFormsServerV1GenderField
     */
    disclaimer?: LeadsFormsServerV1Disclaimer;
    /**
     * A flag indicating that the field is required.
     * @type {boolean}
     * @memberof LeadsFormsServerV1GenderField
     */
    required: boolean;
    /**
     * Public name of the field. The value of this field is used as the field label on the client side.
     * @type {string}
     * @memberof LeadsFormsServerV1GenderField
     */
    title: string;
    /**
     * 
     * @type {LeadsLeadsServerV1Gender}
     * @memberof LeadsFormsServerV1GenderField
     */
    defaultValue?: LeadsLeadsServerV1Gender;
    /**
     * List of gender items.
     * @type {Array<LeadsFormsServerV1GenderItem>}
     * @memberof LeadsFormsServerV1GenderField
     */
    items?: Array<LeadsFormsServerV1GenderItem>;
    /**
     * A flag indicating that the field is displayed.
     * @type {boolean}
     * @memberof LeadsFormsServerV1GenderField
     */
    displayed?: boolean;
}
/**
 * Describes gender item.
 * @export
 * @interface LeadsFormsServerV1GenderItem
 */
export interface LeadsFormsServerV1GenderItem {
    /**
     * Title name of the item.
     * @type {string}
     * @memberof LeadsFormsServerV1GenderItem
     */
    title: string;
    /**
     * 
     * @type {LeadsLeadsServerV1Gender}
     * @memberof LeadsFormsServerV1GenderItem
     */
    value: LeadsLeadsServerV1Gender;
}
/**
 * Properties of the form field that represents select value.
 * @export
 * @interface LeadsFormsServerV1GenerationField
 */
export interface LeadsFormsServerV1GenerationField {
    /**
     * 
     * @type {LeadsFormsServerV1Align}
     * @memberof LeadsFormsServerV1GenerationField
     */
    align?: LeadsFormsServerV1Align;
    /**
     * Comment contains path to submit form field.
     * @type {string}
     * @memberof LeadsFormsServerV1GenerationField
     */
    comment: string;
    /**
     * 
     * @type {LeadsFormsServerV1Disclaimer}
     * @memberof LeadsFormsServerV1GenerationField
     */
    disclaimer?: LeadsFormsServerV1Disclaimer;
    /**
     * A flag indicating that the field is required.
     * @type {boolean}
     * @memberof LeadsFormsServerV1GenerationField
     */
    required: boolean;
    /**
     * Public name of the field. The value of this field is used as the field label on the client side.
     * @type {string}
     * @memberof LeadsFormsServerV1GenerationField
     */
    title: string;
    /**
     * A flag indicating that the field is displayed.
     * @type {boolean}
     * @memberof LeadsFormsServerV1GenerationField
     */
    displayed?: boolean;
    /**
     * A flag indicating that the field photos is displayed.
     * @type {boolean}
     * @memberof LeadsFormsServerV1GenerationField
     */
    showPhoto?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1GenerationField
     */
    defaultValue?: string;
    /**
     * A flag indicating that the field contains all available generations without filtration.
     * @type {boolean}
     * @memberof LeadsFormsServerV1GenerationField
     */
    allItems?: boolean;
    /**
     * List of select items.
     * @type {Array<LeadsFormsServerV1GenerationItem>}
     * @memberof LeadsFormsServerV1GenerationField
     */
    items: Array<LeadsFormsServerV1GenerationItem>;
    /**
     * 
     * @type {LeadsFormsServerV1ModelField}
     * @memberof LeadsFormsServerV1GenerationField
     */
    model?: LeadsFormsServerV1ModelField;
}
/**
 * Describes select item.
 * @export
 * @interface LeadsFormsServerV1GenerationItem
 */
export interface LeadsFormsServerV1GenerationItem {
    /**
     * Title name of the select item.
     * @type {string}
     * @memberof LeadsFormsServerV1GenerationItem
     */
    title: string;
    /**
     * Select item value.
     * @type {string}
     * @memberof LeadsFormsServerV1GenerationItem
     */
    value: string;
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1GenerationItem
     */
    modelId?: string;
}
/**
 * Successful response to the get latest form request
 * @export
 * @interface LeadsFormsServerV1GetActualFormResponse
 */
export interface LeadsFormsServerV1GetActualFormResponse {
    /**
     * 
     * @type {LeadsFormsServerV1Form}
     * @memberof LeadsFormsServerV1GetActualFormResponse
     */
    form: LeadsFormsServerV1Form;
}
/**
 * GetCampaignResponse contains campaigns list.
 * @export
 * @interface LeadsFormsServerV1GetCampaignResponse
 */
export interface LeadsFormsServerV1GetCampaignResponse {
    /**
     * 
     * @type {Array<LeadsFormsServerV1Campaign>}
     * @memberof LeadsFormsServerV1GetCampaignResponse
     */
    campaigns?: Array<LeadsFormsServerV1Campaign>;
    /**
     * 
     * @type {LeadsPagingV1Paging}
     * @memberof LeadsFormsServerV1GetCampaignResponse
     */
    count?: LeadsPagingV1Paging;
}
/**
 * GetDisclaimerResponse contains disclaimers list.
 * @export
 * @interface LeadsFormsServerV1GetDisclaimerResponse
 */
export interface LeadsFormsServerV1GetDisclaimerResponse {
    /**
     * 
     * @type {LeadsPagingV1Paging}
     * @memberof LeadsFormsServerV1GetDisclaimerResponse
     */
    count?: LeadsPagingV1Paging;
    /**
     * 
     * @type {Array<LeadsFormsServerV1Disclaimer>}
     * @memberof LeadsFormsServerV1GetDisclaimerResponse
     */
    disclaimers?: Array<LeadsFormsServerV1Disclaimer>;
}
/**
 * GetDisplayResponse contains displays list.
 * @export
 * @interface LeadsFormsServerV1GetDisplayResponse
 */
export interface LeadsFormsServerV1GetDisplayResponse {
    /**
     * 
     * @type {LeadsPagingV1Paging}
     * @memberof LeadsFormsServerV1GetDisplayResponse
     */
    count?: LeadsPagingV1Paging;
    /**
     * 
     * @type {Array<LeadsFormsServerV1Display>}
     * @memberof LeadsFormsServerV1GetDisplayResponse
     */
    displays?: Array<LeadsFormsServerV1Display>;
}
/**
 * Successful response to the get form request
 * @export
 * @interface LeadsFormsServerV1GetFormResponse
 */
export interface LeadsFormsServerV1GetFormResponse {
    /**
     * 
     * @type {LeadsFormsServerV1Form}
     * @memberof LeadsFormsServerV1GetFormResponse
     */
    form: LeadsFormsServerV1Form;
}
/**
 * GetFormsResponse describes a successful response to the request to list forms.
 * @export
 * @interface LeadsFormsServerV1GetFormsResponse
 */
export interface LeadsFormsServerV1GetFormsResponse {
    /**
     * 
     * @type {LeadsPagingV1Paging}
     * @memberof LeadsFormsServerV1GetFormsResponse
     */
    count?: LeadsPagingV1Paging;
    /**
     * 
     * @type {Array<LeadsFormsServerV1FormListItem>}
     * @memberof LeadsFormsServerV1GetFormsResponse
     */
    forms?: Array<LeadsFormsServerV1FormListItem>;
}
/**
 * GetInterestResponse contains interests list.
 * @export
 * @interface LeadsFormsServerV1GetInterestResponse
 */
export interface LeadsFormsServerV1GetInterestResponse {
    /**
     * 
     * @type {Array<LeadsFormsServerV1Interest>}
     * @memberof LeadsFormsServerV1GetInterestResponse
     */
    interests?: Array<LeadsFormsServerV1Interest>;
    /**
     * 
     * @type {LeadsPagingV1Paging}
     * @memberof LeadsFormsServerV1GetInterestResponse
     */
    count?: LeadsPagingV1Paging;
}
/**
 * GetLeadChannelResponse contains lead channels list.
 * @export
 * @interface LeadsFormsServerV1GetLeadChannelResponse
 */
export interface LeadsFormsServerV1GetLeadChannelResponse {
    /**
     * 
     * @type {Array<LeadsFormsServerV1LeadChannel>}
     * @memberof LeadsFormsServerV1GetLeadChannelResponse
     */
    leadChannels?: Array<LeadsFormsServerV1LeadChannel>;
    /**
     * 
     * @type {LeadsPagingV1Paging}
     * @memberof LeadsFormsServerV1GetLeadChannelResponse
     */
    count?: LeadsPagingV1Paging;
}
/**
 * GetLeadSourceResponse contains lead sources list.
 * @export
 * @interface LeadsFormsServerV1GetLeadSourceResponse
 */
export interface LeadsFormsServerV1GetLeadSourceResponse {
    /**
     * 
     * @type {Array<LeadsFormsServerV1LeadSource>}
     * @memberof LeadsFormsServerV1GetLeadSourceResponse
     */
    leadSources?: Array<LeadsFormsServerV1LeadSource>;
    /**
     * 
     * @type {LeadsPagingV1Paging}
     * @memberof LeadsFormsServerV1GetLeadSourceResponse
     */
    count?: LeadsPagingV1Paging;
}
/**
 * Properties of the form field that represents header.
 * @export
 * @interface LeadsFormsServerV1HeaderField
 */
export interface LeadsFormsServerV1HeaderField {
    /**
     * 
     * @type {object}
     * @memberof LeadsFormsServerV1HeaderField
     */
    background: object;
    /**
     * 
     * @type {LeadsFormsServerV1Disclaimer}
     * @memberof LeadsFormsServerV1HeaderField
     */
    disclaimer?: LeadsFormsServerV1Disclaimer;
    /**
     * Public name of the field. The value of this field is used as the field label on the client side.
     * @type {string}
     * @memberof LeadsFormsServerV1HeaderField
     */
    title?: string;
    /**
     * Description of the header field.
     * @type {string}
     * @memberof LeadsFormsServerV1HeaderField
     */
    description?: string;
    /**
     * Model photo link.
     * @type {string}
     * @memberof LeadsFormsServerV1HeaderField
     */
    modelPhoto?: string;
}
/**
 * Interest properties
 * @export
 * @interface LeadsFormsServerV1Interest
 */
export interface LeadsFormsServerV1Interest {
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1Interest
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1Interest
     */
    code: string;
    /**
     * Interest name.
     * @type {string}
     * @memberof LeadsFormsServerV1Interest
     */
    name: string;
    /**
     * 
     * @type {LeadsLeadsServerV1Category}
     * @memberof LeadsFormsServerV1Interest
     */
    category?: LeadsLeadsServerV1Category;
}
/**
 * Contains filter parameters for interests.
 * @export
 * @interface LeadsFormsServerV1InterestFilter
 */
export interface LeadsFormsServerV1InterestFilter {
    /**
     * 
     * @type {LeadsFiltersV1IDFilter}
     * @memberof LeadsFormsServerV1InterestFilter
     */
    id?: LeadsFiltersV1IDFilter;
    /**
     * 
     * @type {LeadsFiltersV1StringFilter}
     * @memberof LeadsFormsServerV1InterestFilter
     */
    code?: LeadsFiltersV1StringFilter;
    /**
     * 
     * @type {LeadsLeadsServerV1CategoryFilter}
     * @memberof LeadsFormsServerV1InterestFilter
     */
    category?: LeadsLeadsServerV1CategoryFilter;
    /**
     * 
     * @type {LeadsFiltersV1StringFilter}
     * @memberof LeadsFormsServerV1InterestFilter
     */
    name?: LeadsFiltersV1StringFilter;
}
/**
 * Describes select item.
 * @export
 * @interface LeadsFormsServerV1InterestItem
 */
export interface LeadsFormsServerV1InterestItem {
    /**
     * 
     * @type {LeadsFormsServerV1Disclaimer}
     * @memberof LeadsFormsServerV1InterestItem
     */
    disclaimer?: LeadsFormsServerV1Disclaimer;
    /**
     * Photo link.
     * @type {string}
     * @memberof LeadsFormsServerV1InterestItem
     */
    photo?: string;
    /**
     * Title name of the select item.
     * @type {string}
     * @memberof LeadsFormsServerV1InterestItem
     */
    title: string;
    /**
     * Select item value.
     * @type {string}
     * @memberof LeadsFormsServerV1InterestItem
     */
    value: string;
}
/**
 * Available values for interest sorting.
 * @export
 * @enum {string}
 */

export enum LeadsFormsServerV1InterestSort {
    None = 'INTEREST_SORT_NONE',
    NameAsc = 'INTEREST_SORT_NAME_ASC',
    NameDesc = 'INTEREST_SORT_NAME_DESC',
    CodeAsc = 'INTEREST_SORT_CODE_ASC',
    CodeDesc = 'INTEREST_SORT_CODE_DESC'
}

/**
 * Properties of the field that represents interests.
 * @export
 * @interface LeadsFormsServerV1InterestsField
 */
export interface LeadsFormsServerV1InterestsField {
    /**
     * 
     * @type {LeadsFormsServerV1Align}
     * @memberof LeadsFormsServerV1InterestsField
     */
    align?: LeadsFormsServerV1Align;
    /**
     * Comment contains path to submit form field.
     * @type {string}
     * @memberof LeadsFormsServerV1InterestsField
     */
    comment: string;
    /**
     * Public name of the field. The value of this field is used as the field label on the client side.
     * @type {string}
     * @memberof LeadsFormsServerV1InterestsField
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1InterestsField
     */
    defaultValue?: string;
    /**
     * List of interest items.
     * @type {Array<LeadsFormsServerV1InterestItem>}
     * @memberof LeadsFormsServerV1InterestsField
     */
    items: Array<LeadsFormsServerV1InterestItem>;
    /**
     * A flag indicating that the field is displayed.
     * @type {boolean}
     * @memberof LeadsFormsServerV1InterestsField
     */
    displayed?: boolean;
    /**
     * A flag indicating that the field view is select.
     * @type {boolean}
     * @memberof LeadsFormsServerV1InterestsField
     */
    showSelect?: boolean;
    /**
     * A flag indicating that the field must be selected.
     * @type {boolean}
     * @memberof LeadsFormsServerV1InterestsField
     */
    required?: boolean;
}
/**
 * Properties of the field that represents last name value.
 * @export
 * @interface LeadsFormsServerV1LastNameField
 */
export interface LeadsFormsServerV1LastNameField {
    /**
     * 
     * @type {LeadsFormsServerV1Align}
     * @memberof LeadsFormsServerV1LastNameField
     */
    align?: LeadsFormsServerV1Align;
    /**
     * Comment contains path to submit form field.
     * @type {string}
     * @memberof LeadsFormsServerV1LastNameField
     */
    comment: string;
    /**
     * 
     * @type {LeadsFormsServerV1Disclaimer}
     * @memberof LeadsFormsServerV1LastNameField
     */
    disclaimer?: LeadsFormsServerV1Disclaimer;
    /**
     * Regular expression pattern for the field value validation.
     * @type {string}
     * @memberof LeadsFormsServerV1LastNameField
     */
    pattern?: string;
    /**
     * Hint field place indicator.
     * @type {string}
     * @memberof LeadsFormsServerV1LastNameField
     */
    hint?: string;
    /**
     * A flag indicating that the field is required.
     * @type {boolean}
     * @memberof LeadsFormsServerV1LastNameField
     */
    required: boolean;
    /**
     * Public name of the field. The value of this field is used as the field label on the client side.
     * @type {string}
     * @memberof LeadsFormsServerV1LastNameField
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1LastNameField
     */
    defaultValue?: string;
    /**
     * A flag indicating that the field is displayed.
     * @type {boolean}
     * @memberof LeadsFormsServerV1LastNameField
     */
    displayed?: boolean;
}
/**
 * Lead channel properties
 * @export
 * @interface LeadsFormsServerV1LeadChannel
 */
export interface LeadsFormsServerV1LeadChannel {
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1LeadChannel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1LeadChannel
     */
    code: string;
    /**
     * Lead channel name.
     * @type {string}
     * @memberof LeadsFormsServerV1LeadChannel
     */
    name: string;
}
/**
 * Contains filter parameters for lead channels.
 * @export
 * @interface LeadsFormsServerV1LeadChannelFilter
 */
export interface LeadsFormsServerV1LeadChannelFilter {
    /**
     * 
     * @type {LeadsFiltersV1IDFilter}
     * @memberof LeadsFormsServerV1LeadChannelFilter
     */
    id?: LeadsFiltersV1IDFilter;
    /**
     * 
     * @type {LeadsFiltersV1StringFilter}
     * @memberof LeadsFormsServerV1LeadChannelFilter
     */
    code?: LeadsFiltersV1StringFilter;
}
/**
 * Available values for lead channels sorting.
 * @export
 * @enum {string}
 */

export enum LeadsFormsServerV1LeadChannelSort {
    None = 'LEAD_CHANNEL_SORT_NONE',
    NameAsc = 'LEAD_CHANNEL_SORT_NAME_ASC',
    NameDesc = 'LEAD_CHANNEL_SORT_NAME_DESC'
}

/**
 * LeadInitiator represents lead\'s initial ownership.
 * @export
 * @enum {string}
 */

export enum LeadsFormsServerV1LeadInitiator {
    None = 'LEAD_INITIATOR_NONE',
    Brand = 'LEAD_INITIATOR_BRAND',
    Dealer = 'LEAD_INITIATOR_DEALER'
}

/**
 * Lead source properties
 * @export
 * @interface LeadsFormsServerV1LeadSource
 */
export interface LeadsFormsServerV1LeadSource {
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1LeadSource
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1LeadSource
     */
    code: string;
    /**
     * Lead source name.
     * @type {string}
     * @memberof LeadsFormsServerV1LeadSource
     */
    name: string;
}
/**
 * Properties of the form field that represents select value.
 * @export
 * @interface LeadsFormsServerV1LeadSourceField
 */
export interface LeadsFormsServerV1LeadSourceField {
    /**
     * 
     * @type {LeadsFormsServerV1Align}
     * @memberof LeadsFormsServerV1LeadSourceField
     */
    align?: LeadsFormsServerV1Align;
    /**
     * Comment contains path to submit form field.
     * @type {string}
     * @memberof LeadsFormsServerV1LeadSourceField
     */
    comment: string;
    /**
     * 
     * @type {LeadsFormsServerV1Disclaimer}
     * @memberof LeadsFormsServerV1LeadSourceField
     */
    disclaimer?: LeadsFormsServerV1Disclaimer;
    /**
     * A flag indicating that the field is required.
     * @type {boolean}
     * @memberof LeadsFormsServerV1LeadSourceField
     */
    required: boolean;
    /**
     * Public name of the field. The value of this field is used as the field label on the client side.
     * @type {string}
     * @memberof LeadsFormsServerV1LeadSourceField
     */
    title: string;
    /**
     * Select item value.
     * @type {string}
     * @memberof LeadsFormsServerV1LeadSourceField
     */
    defaultValue?: string;
    /**
     * List of select items.
     * @type {Array<LeadsFormsServerV1LeadSourceItem>}
     * @memberof LeadsFormsServerV1LeadSourceField
     */
    items: Array<LeadsFormsServerV1LeadSourceItem>;
}
/**
 * Contains filter parameters for lead source.
 * @export
 * @interface LeadsFormsServerV1LeadSourceFilter
 */
export interface LeadsFormsServerV1LeadSourceFilter {
    /**
     * 
     * @type {LeadsFiltersV1IDFilter}
     * @memberof LeadsFormsServerV1LeadSourceFilter
     */
    id?: LeadsFiltersV1IDFilter;
    /**
     * 
     * @type {LeadsFiltersV1StringFilter}
     * @memberof LeadsFormsServerV1LeadSourceFilter
     */
    code?: LeadsFiltersV1StringFilter;
}
/**
 * Describes select item.
 * @export
 * @interface LeadsFormsServerV1LeadSourceItem
 */
export interface LeadsFormsServerV1LeadSourceItem {
    /**
     * Title name of the select item.
     * @type {string}
     * @memberof LeadsFormsServerV1LeadSourceItem
     */
    title: string;
    /**
     * Select item value.
     * @type {string}
     * @memberof LeadsFormsServerV1LeadSourceItem
     */
    value: string;
}
/**
 * Available values for lead source sorting.
 * @export
 * @enum {string}
 */

export enum LeadsFormsServerV1LeadSourceSort {
    None = 'LEAD_SOURCE_SORT_NONE',
    NameAsc = 'LEAD_SOURCE_SORT_NAME_ASC',
    NameDesc = 'LEAD_SOURCE_SORT_NAME_DESC'
}

/**
 * Properties of the field that represents middle name value.
 * @export
 * @interface LeadsFormsServerV1MiddleNameField
 */
export interface LeadsFormsServerV1MiddleNameField {
    /**
     * 
     * @type {LeadsFormsServerV1Align}
     * @memberof LeadsFormsServerV1MiddleNameField
     */
    align?: LeadsFormsServerV1Align;
    /**
     * Comment contains path to submit form field.
     * @type {string}
     * @memberof LeadsFormsServerV1MiddleNameField
     */
    comment: string;
    /**
     * 
     * @type {LeadsFormsServerV1Disclaimer}
     * @memberof LeadsFormsServerV1MiddleNameField
     */
    disclaimer?: LeadsFormsServerV1Disclaimer;
    /**
     * Regular expression pattern for the field value validation.
     * @type {string}
     * @memberof LeadsFormsServerV1MiddleNameField
     */
    pattern?: string;
    /**
     * Hint field place indicator.
     * @type {string}
     * @memberof LeadsFormsServerV1MiddleNameField
     */
    hint?: string;
    /**
     * A flag indicating that the field is required.
     * @type {boolean}
     * @memberof LeadsFormsServerV1MiddleNameField
     */
    required: boolean;
    /**
     * Public name of the field. The value of this field is used as the field label on the client side.
     * @type {string}
     * @memberof LeadsFormsServerV1MiddleNameField
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1MiddleNameField
     */
    defaultValue?: string;
    /**
     * A flag indicating that the field is displayed.
     * @type {boolean}
     * @memberof LeadsFormsServerV1MiddleNameField
     */
    displayed?: boolean;
}
/**
 * Properties of the field that represents milage value.
 * @export
 * @interface LeadsFormsServerV1MileageField
 */
export interface LeadsFormsServerV1MileageField {
    /**
     * 
     * @type {LeadsFormsServerV1Align}
     * @memberof LeadsFormsServerV1MileageField
     */
    align?: LeadsFormsServerV1Align;
    /**
     * Comment contains path to submit form field.
     * @type {string}
     * @memberof LeadsFormsServerV1MileageField
     */
    comment: string;
    /**
     * 
     * @type {LeadsFormsServerV1Disclaimer}
     * @memberof LeadsFormsServerV1MileageField
     */
    disclaimer?: LeadsFormsServerV1Disclaimer;
    /**
     * Regular expression pattern for the field value validation.
     * @type {string}
     * @memberof LeadsFormsServerV1MileageField
     */
    pattern?: string;
    /**
     * Hint field place indicator.
     * @type {string}
     * @memberof LeadsFormsServerV1MileageField
     */
    hint?: string;
    /**
     * A flag indicating that the field is required.
     * @type {boolean}
     * @memberof LeadsFormsServerV1MileageField
     */
    required: boolean;
    /**
     * Public name of the field. The value of this field is used as the field label on the client side.
     * @type {string}
     * @memberof LeadsFormsServerV1MileageField
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1MileageField
     */
    defaultValue?: string;
    /**
     * A flag indicating that the field is displayed.
     * @type {boolean}
     * @memberof LeadsFormsServerV1MileageField
     */
    displayed?: boolean;
}
/**
 * Properties of the form field that represents select value.
 * @export
 * @interface LeadsFormsServerV1ModelField
 */
export interface LeadsFormsServerV1ModelField {
    /**
     * 
     * @type {LeadsFormsServerV1Align}
     * @memberof LeadsFormsServerV1ModelField
     */
    align?: LeadsFormsServerV1Align;
    /**
     * Comment contains path to submit form field.
     * @type {string}
     * @memberof LeadsFormsServerV1ModelField
     */
    comment: string;
    /**
     * 
     * @type {LeadsFormsServerV1Disclaimer}
     * @memberof LeadsFormsServerV1ModelField
     */
    disclaimer?: LeadsFormsServerV1Disclaimer;
    /**
     * A flag indicating that the field is required.
     * @type {boolean}
     * @memberof LeadsFormsServerV1ModelField
     */
    required: boolean;
    /**
     * Public name of the field. The value of this field is used as the field label on the client side.
     * @type {string}
     * @memberof LeadsFormsServerV1ModelField
     */
    title: string;
    /**
     * A flag indicating that the field is displayed.
     * @type {boolean}
     * @memberof LeadsFormsServerV1ModelField
     */
    displayed?: boolean;
    /**
     * A flag indicating that the field photos is displayed.
     * @type {boolean}
     * @memberof LeadsFormsServerV1ModelField
     */
    showPhoto?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1ModelField
     */
    defaultValue?: string;
    /**
     * A flag indicating that the field contains all available models without filtration.
     * @type {boolean}
     * @memberof LeadsFormsServerV1ModelField
     */
    allItems?: boolean;
    /**
     * List of select items.
     * @type {Array<LeadsFormsServerV1ModelItem>}
     * @memberof LeadsFormsServerV1ModelField
     */
    items: Array<LeadsFormsServerV1ModelItem>;
    /**
     * 
     * @type {LeadsFormsServerV1BrandField}
     * @memberof LeadsFormsServerV1ModelField
     */
    brand?: LeadsFormsServerV1BrandField;
}
/**
 * Describes select item.
 * @export
 * @interface LeadsFormsServerV1ModelItem
 */
export interface LeadsFormsServerV1ModelItem {
    /**
     * Title name of the select item.
     * @type {string}
     * @memberof LeadsFormsServerV1ModelItem
     */
    title: string;
    /**
     * Select item value.
     * @type {string}
     * @memberof LeadsFormsServerV1ModelItem
     */
    value: string;
    /**
     * Photo link list.
     * @type {Array<string>}
     * @memberof LeadsFormsServerV1ModelItem
     */
    photos?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1ModelItem
     */
    brandId?: string;
}
/**
 * Properties of the form field that represents select value.
 * @export
 * @interface LeadsFormsServerV1ModificationField
 */
export interface LeadsFormsServerV1ModificationField {
    /**
     * 
     * @type {LeadsFormsServerV1Align}
     * @memberof LeadsFormsServerV1ModificationField
     */
    align?: LeadsFormsServerV1Align;
    /**
     * Comment contains path to submit form field.
     * @type {string}
     * @memberof LeadsFormsServerV1ModificationField
     */
    comment: string;
    /**
     * 
     * @type {LeadsFormsServerV1Disclaimer}
     * @memberof LeadsFormsServerV1ModificationField
     */
    disclaimer?: LeadsFormsServerV1Disclaimer;
    /**
     * A flag indicating that the field is required.
     * @type {boolean}
     * @memberof LeadsFormsServerV1ModificationField
     */
    required: boolean;
    /**
     * A flag indicating that the field is displayed.
     * @type {boolean}
     * @memberof LeadsFormsServerV1ModificationField
     */
    displayed?: boolean;
    /**
     * A flag indicating that the field photos is displayed.
     * @type {boolean}
     * @memberof LeadsFormsServerV1ModificationField
     */
    showPhoto?: boolean;
    /**
     * Public name of the field. The value of this field is used as the field label on the client side.
     * @type {string}
     * @memberof LeadsFormsServerV1ModificationField
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1ModificationField
     */
    defaultValue?: string;
    /**
     * A flag indicating that the field contains all available modifications without filtration.
     * @type {boolean}
     * @memberof LeadsFormsServerV1ModificationField
     */
    allItems?: boolean;
    /**
     * List of select items.
     * @type {Array<LeadsFormsServerV1ModificationItem>}
     * @memberof LeadsFormsServerV1ModificationField
     */
    items: Array<LeadsFormsServerV1ModificationItem>;
    /**
     * 
     * @type {LeadsFormsServerV1TrimField}
     * @memberof LeadsFormsServerV1ModificationField
     */
    trim?: LeadsFormsServerV1TrimField;
}
/**
 * Describes select item.
 * @export
 * @interface LeadsFormsServerV1ModificationItem
 */
export interface LeadsFormsServerV1ModificationItem {
    /**
     * Title name of the select item.
     * @type {string}
     * @memberof LeadsFormsServerV1ModificationItem
     */
    title: string;
    /**
     * Select item value.
     * @type {string}
     * @memberof LeadsFormsServerV1ModificationItem
     */
    value: string;
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1ModificationItem
     */
    trimId?: string;
}
/**
 * Properties of the form filed that represents multi upload.
 * @export
 * @interface LeadsFormsServerV1MultiUploadField
 */
export interface LeadsFormsServerV1MultiUploadField {
    /**
     * 
     * @type {LeadsFormsServerV1Disclaimer}
     * @memberof LeadsFormsServerV1MultiUploadField
     */
    disclaimer?: LeadsFormsServerV1Disclaimer;
    /**
     * Comment contains path to submit form field.
     * @type {string}
     * @memberof LeadsFormsServerV1MultiUploadField
     */
    comment: string;
    /**
     * Max number of upload files.
     * @type {number}
     * @memberof LeadsFormsServerV1MultiUploadField
     */
    maxFiles: number;
    /**
     * Max size of upload files in bytes.
     * @type {number}
     * @memberof LeadsFormsServerV1MultiUploadField
     */
    maxFileSize: number;
    /**
     * Max size of upload files in bytes.
     * @type {string}
     * @memberof LeadsFormsServerV1MultiUploadField
     */
    text?: string;
    /**
     * List of format items.
     * @type {Array<LeadsFormsServerV1FormatItem>}
     * @memberof LeadsFormsServerV1MultiUploadField
     */
    items: Array<LeadsFormsServerV1FormatItem>;
    /**
     * A flag indicating that the field is displayed.
     * @type {boolean}
     * @memberof LeadsFormsServerV1MultiUploadField
     */
    displayed?: boolean;
}
/**
 * Properties of the field that represents residence value.
 * @export
 * @interface LeadsFormsServerV1OrganizationField
 */
export interface LeadsFormsServerV1OrganizationField {
    /**
     * 
     * @type {LeadsFormsServerV1Align}
     * @memberof LeadsFormsServerV1OrganizationField
     */
    align?: LeadsFormsServerV1Align;
    /**
     * Comment contains path to submit form field.
     * @type {string}
     * @memberof LeadsFormsServerV1OrganizationField
     */
    comment: string;
    /**
     * 
     * @type {LeadsFormsServerV1Disclaimer}
     * @memberof LeadsFormsServerV1OrganizationField
     */
    disclaimer?: LeadsFormsServerV1Disclaimer;
    /**
     * Regular expression pattern for the field value validation.
     * @type {string}
     * @memberof LeadsFormsServerV1OrganizationField
     */
    pattern?: string;
    /**
     * Hint field place indicator.
     * @type {string}
     * @memberof LeadsFormsServerV1OrganizationField
     */
    hint?: string;
    /**
     * A flag indicating that the field is required.
     * @type {boolean}
     * @memberof LeadsFormsServerV1OrganizationField
     */
    required: boolean;
    /**
     * Public name of the field. The value of this field is used as the field label on the client side.
     * @type {string}
     * @memberof LeadsFormsServerV1OrganizationField
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1OrganizationField
     */
    defaultValue?: string;
    /**
     * A flag indicating that the field is displayed.
     * @type {boolean}
     * @memberof LeadsFormsServerV1OrganizationField
     */
    displayed?: boolean;
}
/**
 * Properties of the field that represents phone value.
 * @export
 * @interface LeadsFormsServerV1PhoneField
 */
export interface LeadsFormsServerV1PhoneField {
    /**
     * 
     * @type {LeadsFormsServerV1Align}
     * @memberof LeadsFormsServerV1PhoneField
     */
    align?: LeadsFormsServerV1Align;
    /**
     * Comment contains path to submit form field.
     * @type {string}
     * @memberof LeadsFormsServerV1PhoneField
     */
    comment: string;
    /**
     * 
     * @type {LeadsFormsServerV1Disclaimer}
     * @memberof LeadsFormsServerV1PhoneField
     */
    disclaimer?: LeadsFormsServerV1Disclaimer;
    /**
     * Regular expression pattern for the field value validation.
     * @type {string}
     * @memberof LeadsFormsServerV1PhoneField
     */
    pattern?: string;
    /**
     * Hint field place indicator.
     * @type {string}
     * @memberof LeadsFormsServerV1PhoneField
     */
    hint?: string;
    /**
     * A flag indicating that the field is required.
     * @type {boolean}
     * @memberof LeadsFormsServerV1PhoneField
     */
    required: boolean;
    /**
     * Public name of the field. The value of this field is used as the field label on the client side.
     * @type {string}
     * @memberof LeadsFormsServerV1PhoneField
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1PhoneField
     */
    defaultValue?: string;
    /**
     * A flag indicating that the field is displayed.
     * @type {boolean}
     * @memberof LeadsFormsServerV1PhoneField
     */
    displayed?: boolean;
}
/**
 * PlannedPeriod of the purchase, test-drive, etc.
 * @export
 * @enum {string}
 */

export enum LeadsFormsServerV1PlannedPeriod {
    None = 'PLANNED_PERIOD_NONE',
    TwoWeeksLater = 'PLANNED_PERIOD_TWO_WEEKS_LATER',
    MonthLater = 'PLANNED_PERIOD_MONTH_LATER',
    ThreeMonthLater = 'PLANNED_PERIOD_THREE_MONTH_LATER',
    SixMonthLater = 'PLANNED_PERIOD_SIX_MONTH_LATER',
    YearLater = 'PLANNED_PERIOD_YEAR_LATER',
    MoreThenYear = 'PLANNED_PERIOD_MORE_THEN_YEAR'
}

/**
 * Properties of the field that represents date value.
 * @export
 * @interface LeadsFormsServerV1PlannedPeriodField
 */
export interface LeadsFormsServerV1PlannedPeriodField {
    /**
     * 
     * @type {LeadsFormsServerV1Align}
     * @memberof LeadsFormsServerV1PlannedPeriodField
     */
    align?: LeadsFormsServerV1Align;
    /**
     * Comment contains path to submit form field.
     * @type {string}
     * @memberof LeadsFormsServerV1PlannedPeriodField
     */
    comment: string;
    /**
     * 
     * @type {LeadsFormsServerV1Disclaimer}
     * @memberof LeadsFormsServerV1PlannedPeriodField
     */
    disclaimer?: LeadsFormsServerV1Disclaimer;
    /**
     * A flag indicating that the field is required.
     * @type {boolean}
     * @memberof LeadsFormsServerV1PlannedPeriodField
     */
    required: boolean;
    /**
     * Public name of the field. The value of this field is used as the field label on the client side.
     * @type {string}
     * @memberof LeadsFormsServerV1PlannedPeriodField
     */
    title?: string;
    /**
     * 
     * @type {LeadsFormsServerV1PlannedPeriod}
     * @memberof LeadsFormsServerV1PlannedPeriodField
     */
    defaultValue?: LeadsFormsServerV1PlannedPeriod;
    /**
     * A flag indicating that the field is displayed.
     * @type {boolean}
     * @memberof LeadsFormsServerV1PlannedPeriodField
     */
    displayed?: boolean;
    /**
     * List of the planned period items.
     * @type {Array<LeadsFormsServerV1PlannedPeriodItem>}
     * @memberof LeadsFormsServerV1PlannedPeriodField
     */
    items?: Array<LeadsFormsServerV1PlannedPeriodItem>;
}
/**
 * Describes planned period item.
 * @export
 * @interface LeadsFormsServerV1PlannedPeriodItem
 */
export interface LeadsFormsServerV1PlannedPeriodItem {
    /**
     * Title name of the item.
     * @type {string}
     * @memberof LeadsFormsServerV1PlannedPeriodItem
     */
    title: string;
    /**
     * 
     * @type {LeadsFormsServerV1PlannedPeriod}
     * @memberof LeadsFormsServerV1PlannedPeriodItem
     */
    value: LeadsFormsServerV1PlannedPeriod;
}
/**
 * Properties of the field that represents plate value.
 * @export
 * @interface LeadsFormsServerV1PlateField
 */
export interface LeadsFormsServerV1PlateField {
    /**
     * 
     * @type {LeadsFormsServerV1Align}
     * @memberof LeadsFormsServerV1PlateField
     */
    align?: LeadsFormsServerV1Align;
    /**
     * Comment contains path to submit form field.
     * @type {string}
     * @memberof LeadsFormsServerV1PlateField
     */
    comment: string;
    /**
     * 
     * @type {LeadsFormsServerV1Disclaimer}
     * @memberof LeadsFormsServerV1PlateField
     */
    disclaimer?: LeadsFormsServerV1Disclaimer;
    /**
     * Regular expression pattern for the field value validation.
     * @type {string}
     * @memberof LeadsFormsServerV1PlateField
     */
    pattern?: string;
    /**
     * Hint field place indicator.
     * @type {string}
     * @memberof LeadsFormsServerV1PlateField
     */
    hint?: string;
    /**
     * A flag indicating that the field is required.
     * @type {boolean}
     * @memberof LeadsFormsServerV1PlateField
     */
    required: boolean;
    /**
     * Public name of the field. The value of this field is used as the field label on the client side.
     * @type {string}
     * @memberof LeadsFormsServerV1PlateField
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1PlateField
     */
    defaultValue?: string;
    /**
     * A flag indicating that the field is displayed.
     * @type {boolean}
     * @memberof LeadsFormsServerV1PlateField
     */
    displayed?: boolean;
}
/**
 * Available digital platforms for form publishing.
 * @export
 * @enum {string}
 */

export enum LeadsFormsServerV1PublishOn {
    None = 'PUBLISH_ON_NONE',
    Vdp = 'PUBLISH_ON_VDP',
    FeatureApp = 'PUBLISH_ON_FEATURE_APP'
}

/**
 * Filter criteria for PublishOn values.
 * @export
 * @interface LeadsFormsServerV1PublishOnFilter
 */
export interface LeadsFormsServerV1PublishOnFilter {
    /**
     * The \"equality\" condition. With multiple provided values, it transforms into the \"in\" condition. The given values may contain the following wildcard characters: an asterisk \"*\" matches any sequence of zero or more characters, a question mark \"?\" matches any single character.
     * @type {Array<LeadsFormsServerV1PublishOn>}
     * @memberof LeadsFormsServerV1PublishOnFilter
     */
    eq?: Array<LeadsFormsServerV1PublishOn>;
    /**
     * The \"inequality\" condition. With multiple provided values, it transforms into the \"in\" condition. The given values may contain the following wildcard characters: an asterisk \"*\" matches any sequence of zero or more characters, a question mark \"?\" matches any single character.
     * @type {Array<LeadsFormsServerV1PublishOn>}
     * @memberof LeadsFormsServerV1PublishOnFilter
     */
    neq?: Array<LeadsFormsServerV1PublishOn>;
}
/**
 * Information about the source of the form result.
 * @export
 * @interface LeadsFormsServerV1Referer
 */
export interface LeadsFormsServerV1Referer {
    /**
     * Identifier of the submitted form.
     * @type {string}
     * @memberof LeadsFormsServerV1Referer
     */
    id: string;
    /**
     * The serial number of the version of the submitted form.
     * @type {number}
     * @memberof LeadsFormsServerV1Referer
     */
    version: number;
    /**
     * The address of the webpage, which contains the submitted form.
     * @type {string}
     * @memberof LeadsFormsServerV1Referer
     */
    url?: string;
}
/**
 * Properties of the field that represents residence value.
 * @export
 * @interface LeadsFormsServerV1ResidenceField
 */
export interface LeadsFormsServerV1ResidenceField {
    /**
     * 
     * @type {LeadsFormsServerV1Align}
     * @memberof LeadsFormsServerV1ResidenceField
     */
    align?: LeadsFormsServerV1Align;
    /**
     * Comment contains path to submit form field.
     * @type {string}
     * @memberof LeadsFormsServerV1ResidenceField
     */
    comment: string;
    /**
     * 
     * @type {LeadsFormsServerV1Disclaimer}
     * @memberof LeadsFormsServerV1ResidenceField
     */
    disclaimer?: LeadsFormsServerV1Disclaimer;
    /**
     * Regular expression pattern for the field value validation.
     * @type {string}
     * @memberof LeadsFormsServerV1ResidenceField
     */
    pattern?: string;
    /**
     * Hint field place indicator.
     * @type {string}
     * @memberof LeadsFormsServerV1ResidenceField
     */
    hint?: string;
    /**
     * A flag indicating that the field is required.
     * @type {boolean}
     * @memberof LeadsFormsServerV1ResidenceField
     */
    required: boolean;
    /**
     * Public name of the field. The value of this field is used as the field label on the client side.
     * @type {string}
     * @memberof LeadsFormsServerV1ResidenceField
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1ResidenceField
     */
    defaultValue?: string;
    /**
     * A flag indicating that the field is displayed.
     * @type {boolean}
     * @memberof LeadsFormsServerV1ResidenceField
     */
    displayed?: boolean;
}
/**
 * Result of submitting form.
 * @export
 * @interface LeadsFormsServerV1Result
 */
export interface LeadsFormsServerV1Result {
    /**
     * Result identifier. The value of this field is unique across the system.
     * @type {string}
     * @memberof LeadsFormsServerV1Result
     */
    id: string;
    /**
     * 
     * @type {LeadsVersionV1Version}
     * @memberof LeadsFormsServerV1Result
     */
    version: LeadsVersionV1Version;
    /**
     * 
     * @type {LeadsFormsServerV1Referer}
     * @memberof LeadsFormsServerV1Result
     */
    referer: LeadsFormsServerV1Referer;
}
/**
 * Client-provided properties of the form result.
 * @export
 * @interface LeadsFormsServerV1ResultProps
 */
export interface LeadsFormsServerV1ResultProps {
    /**
     * 
     * @type {LeadsLeadsServerV1ClientProps}
     * @memberof LeadsFormsServerV1ResultProps
     */
    client: LeadsLeadsServerV1ClientProps;
    /**
     * Internal comments.
     * @type {string}
     * @memberof LeadsFormsServerV1ResultProps
     */
    comments?: string;
    /**
     * 
     * @type {LeadsLeadsServerV1Consents}
     * @memberof LeadsFormsServerV1ResultProps
     */
    consents: LeadsLeadsServerV1Consents;
    /**
     * 
     * @type {LeadsFormsServerV1CurrentCar}
     * @memberof LeadsFormsServerV1ResultProps
     */
    currentCar?: LeadsFormsServerV1CurrentCar;
    /**
     * The address of the webpage, which contains credit calculation link.
     * @type {string}
     * @memberof LeadsFormsServerV1ResultProps
     */
    credit?: string;
    /**
     * 
     * @type {LeadsFormsServerV1Dealer}
     * @memberof LeadsFormsServerV1ResultProps
     */
    dealer?: LeadsFormsServerV1Dealer;
    /**
     * 
     * @type {LeadsFormsServerV1DesiredCar}
     * @memberof LeadsFormsServerV1ResultProps
     */
    desiredCar?: LeadsFormsServerV1DesiredCar;
    /**
     * Comments left by the client.
     * @type {string}
     * @memberof LeadsFormsServerV1ResultProps
     */
    description?: string;
    /**
     * 
     * @type {LeadsFormsServerV1TestDrive}
     * @memberof LeadsFormsServerV1ResultProps
     */
    testDrive?: LeadsFormsServerV1TestDrive;
    /**
     * The list of interests identifiers.
     * @type {Array<string>}
     * @memberof LeadsFormsServerV1ResultProps
     */
    interestId?: Array<string>;
    /**
     * 
     * @type {LeadsLeadsServerV1UTM}
     * @memberof LeadsFormsServerV1ResultProps
     */
    utm?: LeadsLeadsServerV1UTM;
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1ResultProps
     */
    campaign?: string;
}
/**
 * Properties of the form field that represents rich text.
 * @export
 * @interface LeadsFormsServerV1RichTextField
 */
export interface LeadsFormsServerV1RichTextField {
    /**
     * Content contains rich text content.
     * @type {string}
     * @memberof LeadsFormsServerV1RichTextField
     */
    content?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1RichTextField
     */
    defaultValue?: string;
    /**
     * 
     * @type {LeadsFormsServerV1Disclaimer}
     * @memberof LeadsFormsServerV1RichTextField
     */
    disclaimer?: LeadsFormsServerV1Disclaimer;
}
/**
 * Properties of the field that represents step break.
 * @export
 * @interface LeadsFormsServerV1StepBreakField
 */
export interface LeadsFormsServerV1StepBreakField {
    /**
     * 
     * @type {LeadsFormsServerV1Align}
     * @memberof LeadsFormsServerV1StepBreakField
     */
    align?: LeadsFormsServerV1Align;
    /**
     * Name of the field button.
     * @type {string}
     * @memberof LeadsFormsServerV1StepBreakField
     */
    buttonName: string;
    /**
     * A flag indicating visibility of the steps.
     * @type {boolean}
     * @memberof LeadsFormsServerV1StepBreakField
     */
    showSteps?: boolean;
}
/**
 * Successful response to the submit form request
 * @export
 * @interface LeadsFormsServerV1SubmitFormResponse
 */
export interface LeadsFormsServerV1SubmitFormResponse {
    /**
     * 
     * @type {LeadsFormsServerV1Result}
     * @memberof LeadsFormsServerV1SubmitFormResponse
     */
    result: LeadsFormsServerV1Result;
}
/**
 * Properties of the field that represents term value.
 * @export
 * @interface LeadsFormsServerV1TermField
 */
export interface LeadsFormsServerV1TermField {
    /**
     * 
     * @type {LeadsFormsServerV1Align}
     * @memberof LeadsFormsServerV1TermField
     */
    align?: LeadsFormsServerV1Align;
    /**
     * Comment contains path to submit form field.
     * @type {string}
     * @memberof LeadsFormsServerV1TermField
     */
    comment: string;
    /**
     * 
     * @type {LeadsFormsServerV1Disclaimer}
     * @memberof LeadsFormsServerV1TermField
     */
    disclaimer?: LeadsFormsServerV1Disclaimer;
    /**
     * Regular expression pattern for the field value validation.
     * @type {string}
     * @memberof LeadsFormsServerV1TermField
     */
    pattern?: string;
    /**
     * Hint field place indicator.
     * @type {string}
     * @memberof LeadsFormsServerV1TermField
     */
    hint?: string;
    /**
     * A flag indicating that the field is required.
     * @type {boolean}
     * @memberof LeadsFormsServerV1TermField
     */
    required: boolean;
    /**
     * Public name of the field. The value of this field is used as the field label on the client side.
     * @type {string}
     * @memberof LeadsFormsServerV1TermField
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1TermField
     */
    defaultValue?: string;
    /**
     * A flag indicating that the field is displayed.
     * @type {boolean}
     * @memberof LeadsFormsServerV1TermField
     */
    displayed?: boolean;
}
/**
 * Parameters of the test-drive request.
 * @export
 * @interface LeadsFormsServerV1TestDrive
 */
export interface LeadsFormsServerV1TestDrive {
    /**
     * Estimated time of the test drive.
     * @type {string}
     * @memberof LeadsFormsServerV1TestDrive
     */
    estimateTime?: string;
    /**
     * Specifies whether the test-drive car should contains a child seat.
     * @type {boolean}
     * @memberof LeadsFormsServerV1TestDrive
     */
    childSeat?: boolean;
}
/**
 * Properties of the field that represents time value.
 * @export
 * @interface LeadsFormsServerV1TimeField
 */
export interface LeadsFormsServerV1TimeField {
    /**
     * 
     * @type {LeadsFormsServerV1Align}
     * @memberof LeadsFormsServerV1TimeField
     */
    align?: LeadsFormsServerV1Align;
    /**
     * Comment contains path to submit form field.
     * @type {string}
     * @memberof LeadsFormsServerV1TimeField
     */
    comment: string;
    /**
     * 
     * @type {LeadsFormsServerV1Disclaimer}
     * @memberof LeadsFormsServerV1TimeField
     */
    disclaimer?: LeadsFormsServerV1Disclaimer;
    /**
     * Regular expression pattern for the field value validation.
     * @type {string}
     * @memberof LeadsFormsServerV1TimeField
     */
    pattern?: string;
    /**
     * Hint field place indicator.
     * @type {string}
     * @memberof LeadsFormsServerV1TimeField
     */
    hint?: string;
    /**
     * A flag indicating that the field is required.
     * @type {boolean}
     * @memberof LeadsFormsServerV1TimeField
     */
    required: boolean;
    /**
     * Public name of the field. The value of this field is used as the field label on the client side.
     * @type {string}
     * @memberof LeadsFormsServerV1TimeField
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1TimeField
     */
    defaultValue?: string;
    /**
     * A flag indicating that the field is displayed.
     * @type {boolean}
     * @memberof LeadsFormsServerV1TimeField
     */
    displayed?: boolean;
}
/**
 * TimeOfDay of the client.
 * @export
 * @enum {string}
 */

export enum LeadsFormsServerV1TimeOfDay {
    None = 'TIME_OF_DAY_NONE',
    Morning = 'TIME_OF_DAY_MORNING',
    Afternoon = 'TIME_OF_DAY_AFTERNOON',
    Evening = 'TIME_OF_DAY_EVENING'
}

/**
 * Properties of the form field that represents gender.
 * @export
 * @interface LeadsFormsServerV1TimeOfDayField
 */
export interface LeadsFormsServerV1TimeOfDayField {
    /**
     * 
     * @type {LeadsFormsServerV1Align}
     * @memberof LeadsFormsServerV1TimeOfDayField
     */
    align?: LeadsFormsServerV1Align;
    /**
     * Comment contains path to submit form field.
     * @type {string}
     * @memberof LeadsFormsServerV1TimeOfDayField
     */
    comment: string;
    /**
     * 
     * @type {LeadsFormsServerV1Disclaimer}
     * @memberof LeadsFormsServerV1TimeOfDayField
     */
    disclaimer?: LeadsFormsServerV1Disclaimer;
    /**
     * A flag indicating that the field is required.
     * @type {boolean}
     * @memberof LeadsFormsServerV1TimeOfDayField
     */
    required: boolean;
    /**
     * Public name of the field. The value of this field is used as the field label on the client side.
     * @type {string}
     * @memberof LeadsFormsServerV1TimeOfDayField
     */
    title: string;
    /**
     * 
     * @type {LeadsFormsServerV1TimeOfDay}
     * @memberof LeadsFormsServerV1TimeOfDayField
     */
    defaultValue?: LeadsFormsServerV1TimeOfDay;
    /**
     * List of time of the day items.
     * @type {Array<LeadsFormsServerV1TimeOfDayItem>}
     * @memberof LeadsFormsServerV1TimeOfDayField
     */
    items?: Array<LeadsFormsServerV1TimeOfDayItem>;
    /**
     * A flag indicating that the field is displayed.
     * @type {boolean}
     * @memberof LeadsFormsServerV1TimeOfDayField
     */
    displayed?: boolean;
}
/**
 * Describes time of the day item.
 * @export
 * @interface LeadsFormsServerV1TimeOfDayItem
 */
export interface LeadsFormsServerV1TimeOfDayItem {
    /**
     * Title name of the item.
     * @type {string}
     * @memberof LeadsFormsServerV1TimeOfDayItem
     */
    title: string;
    /**
     * 
     * @type {LeadsFormsServerV1TimeOfDay}
     * @memberof LeadsFormsServerV1TimeOfDayItem
     */
    value: LeadsFormsServerV1TimeOfDay;
}
/**
 * Properties of the form field that represents select value.
 * @export
 * @interface LeadsFormsServerV1TrimField
 */
export interface LeadsFormsServerV1TrimField {
    /**
     * 
     * @type {LeadsFormsServerV1Align}
     * @memberof LeadsFormsServerV1TrimField
     */
    align?: LeadsFormsServerV1Align;
    /**
     * Comment contains path to submit form field.
     * @type {string}
     * @memberof LeadsFormsServerV1TrimField
     */
    comment: string;
    /**
     * 
     * @type {LeadsFormsServerV1Disclaimer}
     * @memberof LeadsFormsServerV1TrimField
     */
    disclaimer?: LeadsFormsServerV1Disclaimer;
    /**
     * A flag indicating that the field is required.
     * @type {boolean}
     * @memberof LeadsFormsServerV1TrimField
     */
    required: boolean;
    /**
     * Public name of the field. The value of this field is used as the field label on the client side.
     * @type {string}
     * @memberof LeadsFormsServerV1TrimField
     */
    title: string;
    /**
     * A flag indicating that the field is displayed.
     * @type {boolean}
     * @memberof LeadsFormsServerV1TrimField
     */
    displayed?: boolean;
    /**
     * A flag indicating that the field photos is displayed.
     * @type {boolean}
     * @memberof LeadsFormsServerV1TrimField
     */
    showPhoto?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1TrimField
     */
    defaultValue?: string;
    /**
     * A flag indicating that the field contains all available trims without filtration.
     * @type {boolean}
     * @memberof LeadsFormsServerV1TrimField
     */
    allItems?: boolean;
    /**
     * List of select items.
     * @type {Array<LeadsFormsServerV1TrimItem>}
     * @memberof LeadsFormsServerV1TrimField
     */
    items: Array<LeadsFormsServerV1TrimItem>;
    /**
     * 
     * @type {LeadsFormsServerV1GenerationField}
     * @memberof LeadsFormsServerV1TrimField
     */
    generation?: LeadsFormsServerV1GenerationField;
}
/**
 * Describes select item.
 * @export
 * @interface LeadsFormsServerV1TrimItem
 */
export interface LeadsFormsServerV1TrimItem {
    /**
     * Title name of the select item.
     * @type {string}
     * @memberof LeadsFormsServerV1TrimItem
     */
    title: string;
    /**
     * Select item value.
     * @type {string}
     * @memberof LeadsFormsServerV1TrimItem
     */
    value: string;
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1TrimItem
     */
    generationId?: string;
    /**
     * Photo link list.
     * @type {Array<string>}
     * @memberof LeadsFormsServerV1TrimItem
     */
    photos?: Array<string>;
}
/**
 * UpdateDisclaimerResponse contains updated disclaimer object.
 * @export
 * @interface LeadsFormsServerV1UpdateDisclaimerResponse
 */
export interface LeadsFormsServerV1UpdateDisclaimerResponse {
    /**
     * 
     * @type {LeadsFormsServerV1Disclaimer}
     * @memberof LeadsFormsServerV1UpdateDisclaimerResponse
     */
    disclaimer?: LeadsFormsServerV1Disclaimer;
}
/**
 * UpdateDisplayResponse contains updated Display object.
 * @export
 * @interface LeadsFormsServerV1UpdateDisplayResponse
 */
export interface LeadsFormsServerV1UpdateDisplayResponse {
    /**
     * 
     * @type {LeadsFormsServerV1Display}
     * @memberof LeadsFormsServerV1UpdateDisplayResponse
     */
    display?: LeadsFormsServerV1Display;
}
/**
 * Successful response to the create form request
 * @export
 * @interface LeadsFormsServerV1UpdateFormResponse
 */
export interface LeadsFormsServerV1UpdateFormResponse {
    /**
     * 
     * @type {LeadsFormsServerV1Form}
     * @memberof LeadsFormsServerV1UpdateFormResponse
     */
    form: LeadsFormsServerV1Form;
}
/**
 * Successful response to the update form status request
 * @export
 * @interface LeadsFormsServerV1UpdateFormStatusResponse
 */
export interface LeadsFormsServerV1UpdateFormStatusResponse {
    /**
     * Result of the create form.
     * @type {Array<LeadsFormsServerV1FormListItem>}
     * @memberof LeadsFormsServerV1UpdateFormStatusResponse
     */
    forms: Array<LeadsFormsServerV1FormListItem>;
}
/**
 * Vehicle identifier.
 * @export
 * @interface LeadsFormsServerV1Vehicle
 */
export interface LeadsFormsServerV1Vehicle {
    /**
     * Identifier of the vehicle\'s brand.
     * @type {string}
     * @memberof LeadsFormsServerV1Vehicle
     */
    brandId?: string;
    /**
     * Identifier of the vehicle\'s model.
     * @type {string}
     * @memberof LeadsFormsServerV1Vehicle
     */
    modelId?: string;
    /**
     * Identifier of the vehicle\'s generation.
     * @type {string}
     * @memberof LeadsFormsServerV1Vehicle
     */
    generationId?: string;
    /**
     * Identifier of the vehicle\'s trim.
     * @type {string}
     * @memberof LeadsFormsServerV1Vehicle
     */
    trimId?: string;
    /**
     * Identifier of the vehicle\'s modification.
     * @type {string}
     * @memberof LeadsFormsServerV1Vehicle
     */
    modificationId?: string;
}
/**
 * ViewMode describes input element for field view.
 * @export
 * @enum {string}
 */

export enum LeadsFormsServerV1ViewMode {
    None = 'VIEW_MODE_NONE',
    Input = 'VIEW_MODE_INPUT',
    Textarea = 'VIEW_MODE_TEXTAREA'
}

/**
 * Properties of the field that represents VIN value.
 * @export
 * @interface LeadsFormsServerV1VinField
 */
export interface LeadsFormsServerV1VinField {
    /**
     * 
     * @type {LeadsFormsServerV1Align}
     * @memberof LeadsFormsServerV1VinField
     */
    align?: LeadsFormsServerV1Align;
    /**
     * Comment contains path to submit form field.
     * @type {string}
     * @memberof LeadsFormsServerV1VinField
     */
    comment: string;
    /**
     * 
     * @type {LeadsFormsServerV1Disclaimer}
     * @memberof LeadsFormsServerV1VinField
     */
    disclaimer?: LeadsFormsServerV1Disclaimer;
    /**
     * Regular expression pattern for the field value validation.
     * @type {string}
     * @memberof LeadsFormsServerV1VinField
     */
    pattern?: string;
    /**
     * Hint field place indicator.
     * @type {string}
     * @memberof LeadsFormsServerV1VinField
     */
    hint?: string;
    /**
     * A flag indicating that the field is required.
     * @type {boolean}
     * @memberof LeadsFormsServerV1VinField
     */
    required: boolean;
    /**
     * Public name of the field. The value of this field is used as the field label on the client side.
     * @type {string}
     * @memberof LeadsFormsServerV1VinField
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof LeadsFormsServerV1VinField
     */
    defaultValue?: string;
    /**
     * A flag indicating that the field is displayed.
     * @type {boolean}
     * @memberof LeadsFormsServerV1VinField
     */
    displayed?: boolean;
}
/**
 * Properties of the field that represents year value.
 * @export
 * @interface LeadsFormsServerV1YearField
 */
export interface LeadsFormsServerV1YearField {
    /**
     * 
     * @type {LeadsFormsServerV1Align}
     * @memberof LeadsFormsServerV1YearField
     */
    align?: LeadsFormsServerV1Align;
    /**
     * Comment contains path to submit form field.
     * @type {string}
     * @memberof LeadsFormsServerV1YearField
     */
    comment: string;
    /**
     * 
     * @type {LeadsFormsServerV1Disclaimer}
     * @memberof LeadsFormsServerV1YearField
     */
    disclaimer?: LeadsFormsServerV1Disclaimer;
    /**
     * Regular expression pattern for the field value validation.
     * @type {string}
     * @memberof LeadsFormsServerV1YearField
     */
    pattern?: string;
    /**
     * Hint field place indicator.
     * @type {string}
     * @memberof LeadsFormsServerV1YearField
     */
    hint?: string;
    /**
     * A flag indicating that the field is required.
     * @type {boolean}
     * @memberof LeadsFormsServerV1YearField
     */
    required: boolean;
    /**
     * Public name of the field. The value of this field is used as the field label on the client side.
     * @type {string}
     * @memberof LeadsFormsServerV1YearField
     */
    title: string;
    /**
     * 
     * @type {number}
     * @memberof LeadsFormsServerV1YearField
     */
    defaultValue?: number;
    /**
     * A flag indicating that the field is displayed.
     * @type {boolean}
     * @memberof LeadsFormsServerV1YearField
     */
    displayed?: boolean;
}
/**
 * GlobalFormField describes global form field.
 * @export
 * @interface LeadsGlobalServerV1GlobalFormField
 */
export interface LeadsGlobalServerV1GlobalFormField {
    /**
     * 
     * @type {string}
     * @memberof LeadsGlobalServerV1GlobalFormField
     */
    cid?: string;
    /**
     * 
     * @type {object}
     * @memberof LeadsGlobalServerV1GlobalFormField
     */
    value?: object;
}
/**
 * GlobalFormProps contains global form result data.
 * @export
 * @interface LeadsGlobalServerV1GlobalFormProps
 */
export interface LeadsGlobalServerV1GlobalFormProps {
    /**
     * Comment contains path to submit form field.
     * @type {string}
     * @memberof LeadsGlobalServerV1GlobalFormProps
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadsGlobalServerV1GlobalFormProps
     */
    tenant?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadsGlobalServerV1GlobalFormProps
     */
    form?: string;
    /**
     * 
     * @type {Array<LeadsGlobalServerV1GlobalFormField>}
     * @memberof LeadsGlobalServerV1GlobalFormProps
     */
    fields?: Array<LeadsGlobalServerV1GlobalFormField>;
}
/**
 * Result of submitting form.
 * @export
 * @interface LeadsGlobalServerV1GlobalFormResponse
 */
export interface LeadsGlobalServerV1GlobalFormResponse {
    /**
     * Result identifier. The value of this field is unique across the system.
     * @type {string}
     * @memberof LeadsGlobalServerV1GlobalFormResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof LeadsGlobalServerV1GlobalFormResponse
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadsGlobalServerV1GlobalFormResponse
     */
    timestamp?: string;
}
/**
 * Brand identifier.
 * @export
 * @enum {string}
 */

export enum LeadsLeadsServerV1Brand {
    None = 'BRAND_NONE',
    Audi = 'BRAND_AUDI',
    Ducati = 'BRAND_DUCATI',
    Nfz = 'BRAND_NFZ',
    Pkw = 'BRAND_PKW',
    Skoda = 'BRAND_SKODA'
}

/**
 * Filter criteria for brand enum values.
 * @export
 * @interface LeadsLeadsServerV1BrandFilter
 */
export interface LeadsLeadsServerV1BrandFilter {
    /**
     * 
     * @type {Array<LeadsLeadsServerV1Brand>}
     * @memberof LeadsLeadsServerV1BrandFilter
     */
    eq?: Array<LeadsLeadsServerV1Brand>;
    /**
     * 
     * @type {Array<LeadsLeadsServerV1Brand>}
     * @memberof LeadsLeadsServerV1BrandFilter
     */
    neq?: Array<LeadsLeadsServerV1Brand>;
}
/**
 * Category of the lead.
 * @export
 * @enum {string}
 */

export enum LeadsLeadsServerV1Category {
    None = 'CATEGORY_NONE',
    Sale = 'CATEGORY_SALE',
    Service = 'CATEGORY_SERVICE',
    Other = 'CATEGORY_OTHER',
    Feedback = 'CATEGORY_FEEDBACK',
    Event = 'CATEGORY_EVENT',
    Hotline = 'CATEGORY_HOTLINE',
    Subscribe = 'CATEGORY_SUBSCRIBE',
    Fleet = 'CATEGORY_FLEET'
}

/**
 * Filter criteria for category enum values.
 * @export
 * @interface LeadsLeadsServerV1CategoryFilter
 */
export interface LeadsLeadsServerV1CategoryFilter {
    /**
     * 
     * @type {Array<LeadsLeadsServerV1Category>}
     * @memberof LeadsLeadsServerV1CategoryFilter
     */
    eq?: Array<LeadsLeadsServerV1Category>;
    /**
     * 
     * @type {Array<LeadsLeadsServerV1Category>}
     * @memberof LeadsLeadsServerV1CategoryFilter
     */
    neq?: Array<LeadsLeadsServerV1Category>;
}
/**
 * ClientKind describes the kind of the client.
 * @export
 * @enum {string}
 */

export enum LeadsLeadsServerV1ClientKind {
    None = 'CLIENT_KIND_NONE',
    Person = 'CLIENT_KIND_PERSON',
    Company = 'CLIENT_KIND_COMPANY'
}

/**
 * User-defined properties of the client. The client must contains at least one email address or phone number.
 * @export
 * @interface LeadsLeadsServerV1ClientProps
 */
export interface LeadsLeadsServerV1ClientProps {
    /**
     * 
     * @type {LeadsLeadsServerV1ClientKind}
     * @memberof LeadsLeadsServerV1ClientProps
     */
    kind: LeadsLeadsServerV1ClientKind;
    /**
     * First name of the client.
     * @type {string}
     * @memberof LeadsLeadsServerV1ClientProps
     */
    firstName?: string;
    /**
     * Last name of the client.
     * @type {string}
     * @memberof LeadsLeadsServerV1ClientProps
     */
    lastName?: string;
    /**
     * Middle name of the client.
     * @type {string}
     * @memberof LeadsLeadsServerV1ClientProps
     */
    middleName?: string;
    /**
     * 
     * @type {LeadsLeadsServerV1Gender}
     * @memberof LeadsLeadsServerV1ClientProps
     */
    gender?: LeadsLeadsServerV1Gender;
    /**
     * Birthdate of the client. Cannot be greater then the current date.
     * @type {string}
     * @memberof LeadsLeadsServerV1ClientProps
     */
    birthDate?: string;
    /**
     * Client\'s place of the residence.
     * @type {string}
     * @memberof LeadsLeadsServerV1ClientProps
     */
    residence?: string;
    /**
     * How did the client hear about us? Identifier of the source category.
     * @type {string}
     * @memberof LeadsLeadsServerV1ClientProps
     */
    sourceId?: string;
    /**
     * Name of the company.
     * @type {string}
     * @memberof LeadsLeadsServerV1ClientProps
     */
    company?: string;
    /**
     * Job title of the client.
     * @type {string}
     * @memberof LeadsLeadsServerV1ClientProps
     */
    jobTitle?: string;
    /**
     * 
     * @type {LeadsLeadsServerV1CommunicationMethod}
     * @memberof LeadsLeadsServerV1ClientProps
     */
    communication?: LeadsLeadsServerV1CommunicationMethod;
    /**
     * The list of the client\'s email addresses.
     * @type {Array<string>}
     * @memberof LeadsLeadsServerV1ClientProps
     */
    emails?: Array<string>;
    /**
     * The list of the client\'s phone numbers.
     * @type {Array<string>}
     * @memberof LeadsLeadsServerV1ClientProps
     */
    phones?: Array<string>;
    /**
     * External client identifiers.
     * @type {Set<LeadsAliasesV1Alias>}
     * @memberof LeadsLeadsServerV1ClientProps
     */
    aliases?: Set<LeadsAliasesV1Alias>;
}
/**
 * ContactMethod describes preferred communication method of the client.
 * @export
 * @enum {string}
 */

export enum LeadsLeadsServerV1CommunicationMethod {
    None = 'COMMUNICATION_METHOD_NONE',
    Phone = 'COMMUNICATION_METHOD_PHONE',
    Email = 'COMMUNICATION_METHOD_EMAIL',
    Messenger = 'COMMUNICATION_METHOD_MESSENGER'
}

/**
 * Client\'s consents.
 * @export
 * @interface LeadsLeadsServerV1Consents
 */
export interface LeadsLeadsServerV1Consents {
    /**
     * Client\'s consent to receive promotional materials.
     * @type {boolean}
     * @memberof LeadsLeadsServerV1Consents
     */
    advertising: boolean;
    /**
     * Client\'s consent to personal data processing.
     * @type {boolean}
     * @memberof LeadsLeadsServerV1Consents
     */
    personal: boolean;
}
/**
 * Gender of the client.
 * @export
 * @enum {string}
 */

export enum LeadsLeadsServerV1Gender {
    None = 'GENDER_NONE',
    Male = 'GENDER_MALE',
    Female = 'GENDER_FEMALE'
}

/**
 * UTM parameters.
 * @export
 * @interface LeadsLeadsServerV1UTM
 */
export interface LeadsLeadsServerV1UTM {
    /**
     * Source identifies which site sent the traffic.
     * @type {string}
     * @memberof LeadsLeadsServerV1UTM
     */
    source?: string;
    /**
     * Identifies what type of link was used, such as cost per click or email.
     * @type {string}
     * @memberof LeadsLeadsServerV1UTM
     */
    medium?: string;
    /**
     * A specific product promotion or strategic campaign.
     * @type {string}
     * @memberof LeadsLeadsServerV1UTM
     */
    campaign?: string;
    /**
     * Term identifies search terms.
     * @type {string}
     * @memberof LeadsLeadsServerV1UTM
     */
    term?: string;
    /**
     * Identifies what specifically was clicked to bring the user to the site, such as a banner ad or a text link.
     * @type {string}
     * @memberof LeadsLeadsServerV1UTM
     */
    content?: string;
}
/**
 * Parameters of the market.
 * @export
 * @interface LeadsModelsServerV1Brand
 */
export interface LeadsModelsServerV1Brand {
    /**
     * 
     * @type {string}
     * @memberof LeadsModelsServerV1Brand
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof LeadsModelsServerV1Brand
     */
    marketId: string;
    /**
     * 
     * @type {string}
     * @memberof LeadsModelsServerV1Brand
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof LeadsModelsServerV1Brand
     */
    description?: string;
    /**
     * 
     * @type {LeadsActivityV1Activity}
     * @memberof LeadsModelsServerV1Brand
     */
    activity?: LeadsActivityV1Activity;
    /**
     * 
     * @type {Array<LeadsAliasesV1Alias>}
     * @memberof LeadsModelsServerV1Brand
     */
    aliases?: Array<LeadsAliasesV1Alias>;
    /**
     * 
     * @type {Array<string>}
     * @memberof LeadsModelsServerV1Brand
     */
    tags?: Array<string>;
    /**
     * BrandProps contains editable fields of brand.
     * @type {object}
     * @memberof LeadsModelsServerV1Brand
     */
    props?: object;
}
/**
 * Contains filter parameters for brands.
 * @export
 * @interface LeadsModelsServerV1BrandFilter
 */
export interface LeadsModelsServerV1BrandFilter {
    /**
     * 
     * @type {LeadsFiltersV1IDFilter}
     * @memberof LeadsModelsServerV1BrandFilter
     */
    id?: LeadsFiltersV1IDFilter;
    /**
     * 
     * @type {LeadsFiltersV1IDFilter}
     * @memberof LeadsModelsServerV1BrandFilter
     */
    marketId?: LeadsFiltersV1IDFilter;
}
/**
 * Available values for brand sorting.
 * @export
 * @enum {string}
 */

export enum LeadsModelsServerV1BrandSort {
    None = 'BRAND_SORT_NONE',
    NameAsc = 'BRAND_SORT_NAME_ASC',
    NameDesc = 'BRAND_SORT_NAME_DESC'
}

/**
 * Parameters of the market.
 * @export
 * @interface LeadsModelsServerV1Generation
 */
export interface LeadsModelsServerV1Generation {
    /**
     * 
     * @type {string}
     * @memberof LeadsModelsServerV1Generation
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof LeadsModelsServerV1Generation
     */
    modelId: string;
    /**
     * 
     * @type {string}
     * @memberof LeadsModelsServerV1Generation
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof LeadsModelsServerV1Generation
     */
    description?: string;
    /**
     * 
     * @type {LeadsActivityV1Activity}
     * @memberof LeadsModelsServerV1Generation
     */
    activity?: LeadsActivityV1Activity;
    /**
     * 
     * @type {Array<LeadsAliasesV1Alias>}
     * @memberof LeadsModelsServerV1Generation
     */
    aliases?: Array<LeadsAliasesV1Alias>;
    /**
     * 
     * @type {Array<string>}
     * @memberof LeadsModelsServerV1Generation
     */
    tags?: Array<string>;
    /**
     * GenerationProps contains editable fields of generation.
     * @type {object}
     * @memberof LeadsModelsServerV1Generation
     */
    props?: object;
}
/**
 * Contains filter parameters for generations.
 * @export
 * @interface LeadsModelsServerV1GenerationFilter
 */
export interface LeadsModelsServerV1GenerationFilter {
    /**
     * 
     * @type {LeadsFiltersV1IDFilter}
     * @memberof LeadsModelsServerV1GenerationFilter
     */
    id?: LeadsFiltersV1IDFilter;
    /**
     * 
     * @type {LeadsFiltersV1IDFilter}
     * @memberof LeadsModelsServerV1GenerationFilter
     */
    modelId?: LeadsFiltersV1IDFilter;
}
/**
 * Available values for generations sorting.
 * @export
 * @enum {string}
 */

export enum LeadsModelsServerV1GenerationSort {
    None = 'GENERATION_SORT_NONE',
    NameAsc = 'GENERATION_SORT_NAME_ASC',
    NameDesc = 'GENERATION_SORT_NAME_DESC'
}

/**
 * GetBrandResponse contains brands list.
 * @export
 * @interface LeadsModelsServerV1GetBrandResponse
 */
export interface LeadsModelsServerV1GetBrandResponse {
    /**
     * 
     * @type {LeadsPagingV1Paging}
     * @memberof LeadsModelsServerV1GetBrandResponse
     */
    count?: LeadsPagingV1Paging;
    /**
     * 
     * @type {Array<LeadsModelsServerV1Brand>}
     * @memberof LeadsModelsServerV1GetBrandResponse
     */
    brands?: Array<LeadsModelsServerV1Brand>;
}
/**
 * GetGenerationResponse contains generations list.
 * @export
 * @interface LeadsModelsServerV1GetGenerationResponse
 */
export interface LeadsModelsServerV1GetGenerationResponse {
    /**
     * 
     * @type {LeadsPagingV1Paging}
     * @memberof LeadsModelsServerV1GetGenerationResponse
     */
    count?: LeadsPagingV1Paging;
    /**
     * 
     * @type {Array<LeadsModelsServerV1Generation>}
     * @memberof LeadsModelsServerV1GetGenerationResponse
     */
    generations?: Array<LeadsModelsServerV1Generation>;
}
/**
 * GetMarketResponse contains markets list.
 * @export
 * @interface LeadsModelsServerV1GetMarketResponse
 */
export interface LeadsModelsServerV1GetMarketResponse {
    /**
     * 
     * @type {LeadsPagingV1Paging}
     * @memberof LeadsModelsServerV1GetMarketResponse
     */
    count?: LeadsPagingV1Paging;
    /**
     * 
     * @type {Array<LeadsModelsServerV1Market>}
     * @memberof LeadsModelsServerV1GetMarketResponse
     */
    markets?: Array<LeadsModelsServerV1Market>;
}
/**
 * GetModelResponse contains models list.
 * @export
 * @interface LeadsModelsServerV1GetModelResponse
 */
export interface LeadsModelsServerV1GetModelResponse {
    /**
     * 
     * @type {LeadsPagingV1Paging}
     * @memberof LeadsModelsServerV1GetModelResponse
     */
    count?: LeadsPagingV1Paging;
    /**
     * 
     * @type {Array<LeadsModelsServerV1Model>}
     * @memberof LeadsModelsServerV1GetModelResponse
     */
    models?: Array<LeadsModelsServerV1Model>;
}
/**
 * Parameters of the market.
 * @export
 * @interface LeadsModelsServerV1Market
 */
export interface LeadsModelsServerV1Market {
    /**
     * 
     * @type {string}
     * @memberof LeadsModelsServerV1Market
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof LeadsModelsServerV1Market
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof LeadsModelsServerV1Market
     */
    description?: string;
    /**
     * 
     * @type {LeadsActivityV1Activity}
     * @memberof LeadsModelsServerV1Market
     */
    activity?: LeadsActivityV1Activity;
    /**
     * 
     * @type {Array<LeadsAliasesV1Alias>}
     * @memberof LeadsModelsServerV1Market
     */
    aliases?: Array<LeadsAliasesV1Alias>;
    /**
     * 
     * @type {Array<string>}
     * @memberof LeadsModelsServerV1Market
     */
    tags?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof LeadsModelsServerV1Market
     */
    defaultCurrency?: string;
    /**
     * MarketProps contains editable fields of market.
     * @type {object}
     * @memberof LeadsModelsServerV1Market
     */
    props?: object;
}
/**
 * Contains filter parameters for markets.
 * @export
 * @interface LeadsModelsServerV1MarketFilter
 */
export interface LeadsModelsServerV1MarketFilter {
    /**
     * 
     * @type {LeadsFiltersV1IDFilter}
     * @memberof LeadsModelsServerV1MarketFilter
     */
    id?: LeadsFiltersV1IDFilter;
}
/**
 * Available values for market sorting.
 * @export
 * @enum {string}
 */

export enum LeadsModelsServerV1MarketSort {
    None = 'MARKET_SORT_NONE',
    NameAsc = 'MARKET_SORT_NAME_ASC',
    NameDesc = 'MARKET_SORT_NAME_DESC'
}

/**
 * Parameters of the market.
 * @export
 * @interface LeadsModelsServerV1Model
 */
export interface LeadsModelsServerV1Model {
    /**
     * 
     * @type {string}
     * @memberof LeadsModelsServerV1Model
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof LeadsModelsServerV1Model
     */
    brandId: string;
    /**
     * 
     * @type {string}
     * @memberof LeadsModelsServerV1Model
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof LeadsModelsServerV1Model
     */
    description?: string;
    /**
     * 
     * @type {LeadsActivityV1Activity}
     * @memberof LeadsModelsServerV1Model
     */
    activity?: LeadsActivityV1Activity;
    /**
     * 
     * @type {Array<LeadsAliasesV1Alias>}
     * @memberof LeadsModelsServerV1Model
     */
    aliases?: Array<LeadsAliasesV1Alias>;
    /**
     * 
     * @type {Array<string>}
     * @memberof LeadsModelsServerV1Model
     */
    tags?: Array<string>;
    /**
     * 
     * @type {LeadsModelsServerV1ModelProps}
     * @memberof LeadsModelsServerV1Model
     */
    props: LeadsModelsServerV1ModelProps;
}
/**
 * @type LeadsModelsServerV1ModelBackground
 * describes properties of model background.
 * @export
 */
export type LeadsModelsServerV1ModelBackground = Color | Image | Video;

/**
 * Contains filter parameters for brands.
 * @export
 * @interface LeadsModelsServerV1ModelFilter
 */
export interface LeadsModelsServerV1ModelFilter {
    /**
     * 
     * @type {LeadsFiltersV1IDFilter}
     * @memberof LeadsModelsServerV1ModelFilter
     */
    id?: LeadsFiltersV1IDFilter;
    /**
     * 
     * @type {LeadsFiltersV1IDFilter}
     * @memberof LeadsModelsServerV1ModelFilter
     */
    brandId?: LeadsFiltersV1IDFilter;
    /**
     * 
     * @type {LeadsFiltersV1StringFilter}
     * @memberof LeadsModelsServerV1ModelFilter
     */
    name?: LeadsFiltersV1StringFilter;
}
/**
 * Parameters of the model.
 * @export
 * @interface LeadsModelsServerV1ModelProps
 */
export interface LeadsModelsServerV1ModelProps {
    /**
     * Photo link list.
     * @type {Array<string>}
     * @memberof LeadsModelsServerV1ModelProps
     */
    photos?: Array<string>;
    /**
     * 
     * @type {LeadsModelsServerV1ModelBackground}
     * @memberof LeadsModelsServerV1ModelProps
     */
    background?: LeadsModelsServerV1ModelBackground;
}
/**
 * Available values for brand sorting.
 * @export
 * @enum {string}
 */

export enum LeadsModelsServerV1ModelSort {
    None = 'MODEL_SORT_NONE',
    NameAsc = 'MODEL_SORT_NAME_ASC',
    NameDesc = 'MODEL_SORT_NAME_DESC',
    BrandAsc = 'MODEL_SORT_BRAND_ASC',
    BrandDesc = 'MODEL_SORT_BRAND_DESC'
}

/**
 * Successful response to the update model request
 * @export
 * @interface LeadsModelsServerV1UpdateModelResponse
 */
export interface LeadsModelsServerV1UpdateModelResponse {
    /**
     * 
     * @type {LeadsModelsServerV1Model}
     * @memberof LeadsModelsServerV1UpdateModelResponse
     */
    model: LeadsModelsServerV1Model;
}
/**
 * Information to page through result.
 * @export
 * @interface LeadsPagingV1Paging
 */
export interface LeadsPagingV1Paging {
    /**
     * The total number of resources with the given criteria.
     * @type {number}
     * @memberof LeadsPagingV1Paging
     */
    total: number;
    /**
     * The continuation token, used to page through result. Provide this value in a subsequent request to return the next page.
     * @type {string}
     * @memberof LeadsPagingV1Paging
     */
    token?: string;
}
/**
 * Pagination parameters.
 * @export
 * @interface LeadsPagingV1PagingProps
 */
export interface LeadsPagingV1PagingProps {
    /**
     * The maximum number of resources to return in a single page.
     * @type {number}
     * @memberof LeadsPagingV1PagingProps
     */
    size?: number;
    /**
     * A previously returned token to get the next page.
     * @type {string}
     * @memberof LeadsPagingV1PagingProps
     */
    token?: string;
}
/**
 * ShopsterFormProps contains shopster form result data.
 * @export
 * @interface LeadsShopsterServerV1ShopsterFormProps
 */
export interface LeadsShopsterServerV1ShopsterFormProps {
    /**
     * Comment contains path to submit form field.
     * @type {string}
     * @memberof LeadsShopsterServerV1ShopsterFormProps
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadsShopsterServerV1ShopsterFormProps
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadsShopsterServerV1ShopsterFormProps
     */
    activityCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LeadsShopsterServerV1ShopsterFormProps
     */
    agreementPd?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LeadsShopsterServerV1ShopsterFormProps
     */
    agreementComm?: boolean;
}
/**
 * Point-in-time version of the resource.
 * @export
 * @interface LeadsVersionV1Version
 */
export interface LeadsVersionV1Version {
    /**
     * Number contains a monotonically increasing version of the resource.
     * @type {number}
     * @memberof LeadsVersionV1Version
     */
    number: number;
    /**
     * Version creation time.
     * @type {string}
     * @memberof LeadsVersionV1Version
     */
    time: string;
    /**
     * The identifier of the user who modified the resource. The value of this field may be empty if the resource is changed by the service itself.
     * @type {string}
     * @memberof LeadsVersionV1Version
     */
    userId?: string;
    /**
     * A short description of the changes that come with the resource version.
     * @type {string}
     * @memberof LeadsVersionV1Version
     */
    description?: string;
}
/**
 * 
 * @export
 * @interface ManufactureDateField
 */
export interface ManufactureDateField {
    /**
     * 
     * @type {LeadsFormsServerV1DateField}
     * @memberof ManufactureDateField
     */
    manufactureDate: LeadsFormsServerV1DateField;
}
/**
 * 
 * @export
 * @interface ManufactureYearField
 */
export interface ManufactureYearField {
    /**
     * 
     * @type {LeadsFormsServerV1YearField}
     * @memberof ManufactureYearField
     */
    manufactureYear: LeadsFormsServerV1YearField;
}
/**
 * 
 * @export
 * @interface MiddleNameField
 */
export interface MiddleNameField {
    /**
     * 
     * @type {LeadsFormsServerV1MiddleNameField}
     * @memberof MiddleNameField
     */
    middleName: LeadsFormsServerV1MiddleNameField;
}
/**
 * 
 * @export
 * @interface MileageField
 */
export interface MileageField {
    /**
     * 
     * @type {LeadsFormsServerV1MileageField}
     * @memberof MileageField
     */
    mileage: LeadsFormsServerV1MileageField;
}
/**
 * 
 * @export
 * @interface ModelYearField
 */
export interface ModelYearField {
    /**
     * 
     * @type {LeadsFormsServerV1YearField}
     * @memberof ModelYearField
     */
    modelYear: LeadsFormsServerV1YearField;
}
/**
 * 
 * @export
 * @interface MultiUploadField
 */
export interface MultiUploadField {
    /**
     * 
     * @type {LeadsFormsServerV1MultiUploadField}
     * @memberof MultiUploadField
     */
    multiUpload: LeadsFormsServerV1MultiUploadField;
}
/**
 * 
 * @export
 * @interface OrganizationField
 */
export interface OrganizationField {
    /**
     * 
     * @type {LeadsFormsServerV1OrganizationField}
     * @memberof OrganizationField
     */
    organization: LeadsFormsServerV1OrganizationField;
}
/**
 * 
 * @export
 * @interface PhoneField
 */
export interface PhoneField {
    /**
     * 
     * @type {LeadsFormsServerV1PhoneField}
     * @memberof PhoneField
     */
    phone: LeadsFormsServerV1PhoneField;
}
/**
 * 
 * @export
 * @interface PlateField
 */
export interface PlateField {
    /**
     * 
     * @type {LeadsFormsServerV1PlateField}
     * @memberof PlateField
     */
    plateNumber: LeadsFormsServerV1PlateField;
}
/**
 * 
 * @export
 * @interface PurchaseDateField
 */
export interface PurchaseDateField {
    /**
     * 
     * @type {LeadsFormsServerV1DateField}
     * @memberof PurchaseDateField
     */
    purchaseDate: LeadsFormsServerV1DateField;
}
/**
 * 
 * @export
 * @interface RegistrationDateField
 */
export interface RegistrationDateField {
    /**
     * 
     * @type {LeadsFormsServerV1DateField}
     * @memberof RegistrationDateField
     */
    registrationDate: LeadsFormsServerV1DateField;
}
/**
 * 
 * @export
 * @interface ResidenceField
 */
export interface ResidenceField {
    /**
     * 
     * @type {LeadsFormsServerV1ResidenceField}
     * @memberof ResidenceField
     */
    residence: LeadsFormsServerV1ResidenceField;
}
/**
 * 
 * @export
 * @interface RichTextField
 */
export interface RichTextField {
    /**
     * 
     * @type {LeadsFormsServerV1RichTextField}
     * @memberof RichTextField
     */
    richText: LeadsFormsServerV1RichTextField;
}
/**
 * 
 * @export
 * @interface StepBreakField
 */
export interface StepBreakField {
    /**
     * 
     * @type {LeadsFormsServerV1StepBreakField}
     * @memberof StepBreakField
     */
    stepBreak: LeadsFormsServerV1StepBreakField;
}
/**
 * 
 * @export
 * @interface TermField
 */
export interface TermField {
    /**
     * 
     * @type {LeadsFormsServerV1TermField}
     * @memberof TermField
     */
    term: LeadsFormsServerV1TermField;
}
/**
 * 
 * @export
 * @interface TestDriveDateField
 */
export interface TestDriveDateField {
    /**
     * 
     * @type {LeadsFormsServerV1DateField}
     * @memberof TestDriveDateField
     */
    testDriveDate: LeadsFormsServerV1DateField;
}
/**
 * 
 * @export
 * @interface TestDrivePlannedPeriodField
 */
export interface TestDrivePlannedPeriodField {
    /**
     * 
     * @type {LeadsFormsServerV1PlannedPeriodField}
     * @memberof TestDrivePlannedPeriodField
     */
    testDrivePlannedPeriod: LeadsFormsServerV1PlannedPeriodField;
}
/**
 * 
 * @export
 * @interface TestDriveTimeField
 */
export interface TestDriveTimeField {
    /**
     * 
     * @type {LeadsFormsServerV1TimeField}
     * @memberof TestDriveTimeField
     */
    testDriveTime: LeadsFormsServerV1TimeField;
}
/**
 * 
 * @export
 * @interface TestDriveTimeOfDayField
 */
export interface TestDriveTimeOfDayField {
    /**
     * 
     * @type {LeadsFormsServerV1TimeOfDayField}
     * @memberof TestDriveTimeOfDayField
     */
    testDriveTimeOfDay: LeadsFormsServerV1TimeOfDayField;
}
/**
 * 
 * @export
 * @interface Video
 */
export interface Video {
    /**
     * Represent link to background video.
     * @type {string}
     * @memberof Video
     */
    video?: string;
}
/**
 * 
 * @export
 * @interface VinField
 */
export interface VinField {
    /**
     * 
     * @type {LeadsFormsServerV1VinField}
     * @memberof VinField
     */
    vin: LeadsFormsServerV1VinField;
}

/**
 * DealersAPIApi - axios parameter creator
 * @export
 */
export const DealersAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns dealer center list with the given criteria.
         * @summary Get dealer centers
         * @param {Array<string>} [filterIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<string>} [filterIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>} [filterBrandIdEq] 
         * @param {Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>} [filterBrandIdNeq] 
         * @param {Array<string>} [filterCityIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<string>} [filterCityIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<string>} [filterCodeEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {Array<string>} [filterCodeNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {Array<'DEALER_CENTER_SORT_NONE' | 'DEALER_CENTER_SORT_NAME_ASC' | 'DEALER_CENTER_SORT_NAME_DESC'>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealersAPIGetDealerCenters: async (filterIdEq?: Array<string>, filterIdNeq?: Array<string>, filterBrandIdEq?: Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>, filterBrandIdNeq?: Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>, filterCityIdEq?: Array<string>, filterCityIdNeq?: Array<string>, filterCodeEq?: Array<string>, filterCodeNeq?: Array<string>, sort?: Array<'DEALER_CENTER_SORT_NONE' | 'DEALER_CENTER_SORT_NAME_ASC' | 'DEALER_CENTER_SORT_NAME_DESC'>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/dealers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filterIdEq) {
                localVarQueryParameter['filter.id.eq'] = filterIdEq;
            }

            if (filterIdNeq) {
                localVarQueryParameter['filter.id.neq'] = filterIdNeq;
            }

            if (filterBrandIdEq) {
                localVarQueryParameter['filter.brandId.eq'] = filterBrandIdEq;
            }

            if (filterBrandIdNeq) {
                localVarQueryParameter['filter.brandId.neq'] = filterBrandIdNeq;
            }

            if (filterCityIdEq) {
                localVarQueryParameter['filter.cityId.eq'] = filterCityIdEq;
            }

            if (filterCityIdNeq) {
                localVarQueryParameter['filter.cityId.neq'] = filterCityIdNeq;
            }

            if (filterCodeEq) {
                localVarQueryParameter['filter.code.eq'] = filterCodeEq;
            }

            if (filterCodeNeq) {
                localVarQueryParameter['filter.code.neq'] = filterCodeNeq;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns dealer departments list with the given criteria.
         * @summary Get dealer departments
         * @param {Array<string>} [filterIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<string>} [filterIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<'DEPARTMENT_SORT_NONE' | 'DEPARTMENT_SORT_NAME_ASC' | 'DEPARTMENT_SORT_NAME_DESC'>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealersAPIGetDepartments: async (filterIdEq?: Array<string>, filterIdNeq?: Array<string>, sort?: Array<'DEPARTMENT_SORT_NONE' | 'DEPARTMENT_SORT_NAME_ASC' | 'DEPARTMENT_SORT_NAME_DESC'>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/dealers/departments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filterIdEq) {
                localVarQueryParameter['filter.id.eq'] = filterIdEq;
            }

            if (filterIdNeq) {
                localVarQueryParameter['filter.id.neq'] = filterIdNeq;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DealersAPIApi - functional programming interface
 * @export
 */
export const DealersAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DealersAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns dealer center list with the given criteria.
         * @summary Get dealer centers
         * @param {Array<string>} [filterIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<string>} [filterIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>} [filterBrandIdEq] 
         * @param {Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>} [filterBrandIdNeq] 
         * @param {Array<string>} [filterCityIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<string>} [filterCityIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<string>} [filterCodeEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {Array<string>} [filterCodeNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {Array<'DEALER_CENTER_SORT_NONE' | 'DEALER_CENTER_SORT_NAME_ASC' | 'DEALER_CENTER_SORT_NAME_DESC'>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dealersAPIGetDealerCenters(filterIdEq?: Array<string>, filterIdNeq?: Array<string>, filterBrandIdEq?: Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>, filterBrandIdNeq?: Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>, filterCityIdEq?: Array<string>, filterCityIdNeq?: Array<string>, filterCodeEq?: Array<string>, filterCodeNeq?: Array<string>, sort?: Array<'DEALER_CENTER_SORT_NONE' | 'DEALER_CENTER_SORT_NAME_ASC' | 'DEALER_CENTER_SORT_NAME_DESC'>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadsDealersServerV1GetDealerCentersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dealersAPIGetDealerCenters(filterIdEq, filterIdNeq, filterBrandIdEq, filterBrandIdNeq, filterCityIdEq, filterCityIdNeq, filterCodeEq, filterCodeNeq, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns dealer departments list with the given criteria.
         * @summary Get dealer departments
         * @param {Array<string>} [filterIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<string>} [filterIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<'DEPARTMENT_SORT_NONE' | 'DEPARTMENT_SORT_NAME_ASC' | 'DEPARTMENT_SORT_NAME_DESC'>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dealersAPIGetDepartments(filterIdEq?: Array<string>, filterIdNeq?: Array<string>, sort?: Array<'DEPARTMENT_SORT_NONE' | 'DEPARTMENT_SORT_NAME_ASC' | 'DEPARTMENT_SORT_NAME_DESC'>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadsDealersServerV1GetDepartmentsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dealersAPIGetDepartments(filterIdEq, filterIdNeq, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DealersAPIApi - factory interface
 * @export
 */
export const DealersAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DealersAPIApiFp(configuration)
    return {
        /**
         * Returns dealer center list with the given criteria.
         * @summary Get dealer centers
         * @param {Array<string>} [filterIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<string>} [filterIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>} [filterBrandIdEq] 
         * @param {Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>} [filterBrandIdNeq] 
         * @param {Array<string>} [filterCityIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<string>} [filterCityIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<string>} [filterCodeEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {Array<string>} [filterCodeNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {Array<'DEALER_CENTER_SORT_NONE' | 'DEALER_CENTER_SORT_NAME_ASC' | 'DEALER_CENTER_SORT_NAME_DESC'>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealersAPIGetDealerCenters(filterIdEq?: Array<string>, filterIdNeq?: Array<string>, filterBrandIdEq?: Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>, filterBrandIdNeq?: Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>, filterCityIdEq?: Array<string>, filterCityIdNeq?: Array<string>, filterCodeEq?: Array<string>, filterCodeNeq?: Array<string>, sort?: Array<'DEALER_CENTER_SORT_NONE' | 'DEALER_CENTER_SORT_NAME_ASC' | 'DEALER_CENTER_SORT_NAME_DESC'>, options?: any): AxiosPromise<LeadsDealersServerV1GetDealerCentersResponse> {
            return localVarFp.dealersAPIGetDealerCenters(filterIdEq, filterIdNeq, filterBrandIdEq, filterBrandIdNeq, filterCityIdEq, filterCityIdNeq, filterCodeEq, filterCodeNeq, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns dealer departments list with the given criteria.
         * @summary Get dealer departments
         * @param {Array<string>} [filterIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<string>} [filterIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<'DEPARTMENT_SORT_NONE' | 'DEPARTMENT_SORT_NAME_ASC' | 'DEPARTMENT_SORT_NAME_DESC'>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealersAPIGetDepartments(filterIdEq?: Array<string>, filterIdNeq?: Array<string>, sort?: Array<'DEPARTMENT_SORT_NONE' | 'DEPARTMENT_SORT_NAME_ASC' | 'DEPARTMENT_SORT_NAME_DESC'>, options?: any): AxiosPromise<LeadsDealersServerV1GetDepartmentsResponse> {
            return localVarFp.dealersAPIGetDepartments(filterIdEq, filterIdNeq, sort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DealersAPIApi - object-oriented interface
 * @export
 * @class DealersAPIApi
 * @extends {BaseAPI}
 */
export class DealersAPIApi extends BaseAPI {
    /**
     * Returns dealer center list with the given criteria.
     * @summary Get dealer centers
     * @param {Array<string>} [filterIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
     * @param {Array<string>} [filterIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
     * @param {Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>} [filterBrandIdEq] 
     * @param {Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>} [filterBrandIdNeq] 
     * @param {Array<string>} [filterCityIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
     * @param {Array<string>} [filterCityIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
     * @param {Array<string>} [filterCodeEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
     * @param {Array<string>} [filterCodeNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
     * @param {Array<'DEALER_CENTER_SORT_NONE' | 'DEALER_CENTER_SORT_NAME_ASC' | 'DEALER_CENTER_SORT_NAME_DESC'>} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealersAPIApi
     */
    public dealersAPIGetDealerCenters(filterIdEq?: Array<string>, filterIdNeq?: Array<string>, filterBrandIdEq?: Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>, filterBrandIdNeq?: Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>, filterCityIdEq?: Array<string>, filterCityIdNeq?: Array<string>, filterCodeEq?: Array<string>, filterCodeNeq?: Array<string>, sort?: Array<'DEALER_CENTER_SORT_NONE' | 'DEALER_CENTER_SORT_NAME_ASC' | 'DEALER_CENTER_SORT_NAME_DESC'>, options?: any) {
        return DealersAPIApiFp(this.configuration).dealersAPIGetDealerCenters(filterIdEq, filterIdNeq, filterBrandIdEq, filterBrandIdNeq, filterCityIdEq, filterCityIdNeq, filterCodeEq, filterCodeNeq, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns dealer departments list with the given criteria.
     * @summary Get dealer departments
     * @param {Array<string>} [filterIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
     * @param {Array<string>} [filterIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
     * @param {Array<'DEPARTMENT_SORT_NONE' | 'DEPARTMENT_SORT_NAME_ASC' | 'DEPARTMENT_SORT_NAME_DESC'>} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealersAPIApi
     */
    public dealersAPIGetDepartments(filterIdEq?: Array<string>, filterIdNeq?: Array<string>, sort?: Array<'DEPARTMENT_SORT_NONE' | 'DEPARTMENT_SORT_NAME_ASC' | 'DEPARTMENT_SORT_NAME_DESC'>, options?: any) {
        return DealersAPIApiFp(this.configuration).dealersAPIGetDepartments(filterIdEq, filterIdNeq, sort, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DisclaimersAPIApi - axios parameter creator
 * @export
 */
export const DisclaimersAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates new disclaimer and returns created object
         * @summary Create disclaimer
         * @param {LeadsFormsServerV1DisclaimerProps} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disclaimersAPICreateDisclaimer: async (body: LeadsFormsServerV1DisclaimerProps, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('disclaimersAPICreateDisclaimer', 'body', body)
            const localVarPath = `/v1/disclaimers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes disclaimer
         * @summary Delete disclaimer
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disclaimersAPIDeleteDisclaimer: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('disclaimersAPIDeleteDisclaimer', 'id', id)
            const localVarPath = `/v1/disclaimers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns disclaimers with the given criteria.
         * @summary Get disclaimers
         * @param {Array<string>} [filterIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<string>} [filterIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>} [filterBrandIdEq] 
         * @param {Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>} [filterBrandIdNeq] 
         * @param {Array<string>} [filterTitleEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {Array<string>} [filterTitleNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {number} [pageSize] The maximum number of resources to return in a single page.
         * @param {string} [pageToken] A previously returned token to get the next page.
         * @param {Array<'DISCLAIMER_SORT_NONE' | 'DISCLAIMER_SORT_TITLE_ASC' | 'DISCLAIMER_SORT_TITLE_DESC' | 'DISCLAIMER_SORT_BRAND_ASC' | 'DISCLAIMER_SORT_BRAND_DESC'>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disclaimersAPIGetDisclaimer: async (filterIdEq?: Array<string>, filterIdNeq?: Array<string>, filterBrandIdEq?: Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>, filterBrandIdNeq?: Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>, filterTitleEq?: Array<string>, filterTitleNeq?: Array<string>, pageSize?: number, pageToken?: string, sort?: Array<'DISCLAIMER_SORT_NONE' | 'DISCLAIMER_SORT_TITLE_ASC' | 'DISCLAIMER_SORT_TITLE_DESC' | 'DISCLAIMER_SORT_BRAND_ASC' | 'DISCLAIMER_SORT_BRAND_DESC'>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/disclaimers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filterIdEq) {
                localVarQueryParameter['filter.id.eq'] = filterIdEq;
            }

            if (filterIdNeq) {
                localVarQueryParameter['filter.id.neq'] = filterIdNeq;
            }

            if (filterBrandIdEq) {
                localVarQueryParameter['filter.brandId.eq'] = filterBrandIdEq;
            }

            if (filterBrandIdNeq) {
                localVarQueryParameter['filter.brandId.neq'] = filterBrandIdNeq;
            }

            if (filterTitleEq) {
                localVarQueryParameter['filter.title.eq'] = filterTitleEq;
            }

            if (filterTitleNeq) {
                localVarQueryParameter['filter.title.neq'] = filterTitleNeq;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page.size'] = pageSize;
            }

            if (pageToken !== undefined) {
                localVarQueryParameter['page.token'] = pageToken;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates disclaimer and returns updated object
         * @summary Update disclaimer
         * @param {string} id 
         * @param {LeadsFormsServerV1DisclaimerProps} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disclaimersAPIUpdateDisclaimer: async (id: string, body: LeadsFormsServerV1DisclaimerProps, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('disclaimersAPIUpdateDisclaimer', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('disclaimersAPIUpdateDisclaimer', 'body', body)
            const localVarPath = `/v1/disclaimers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DisclaimersAPIApi - functional programming interface
 * @export
 */
export const DisclaimersAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DisclaimersAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates new disclaimer and returns created object
         * @summary Create disclaimer
         * @param {LeadsFormsServerV1DisclaimerProps} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async disclaimersAPICreateDisclaimer(body: LeadsFormsServerV1DisclaimerProps, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadsFormsServerV1Disclaimer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.disclaimersAPICreateDisclaimer(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes disclaimer
         * @summary Delete disclaimer
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async disclaimersAPIDeleteDisclaimer(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.disclaimersAPIDeleteDisclaimer(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns disclaimers with the given criteria.
         * @summary Get disclaimers
         * @param {Array<string>} [filterIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<string>} [filterIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>} [filterBrandIdEq] 
         * @param {Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>} [filterBrandIdNeq] 
         * @param {Array<string>} [filterTitleEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {Array<string>} [filterTitleNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {number} [pageSize] The maximum number of resources to return in a single page.
         * @param {string} [pageToken] A previously returned token to get the next page.
         * @param {Array<'DISCLAIMER_SORT_NONE' | 'DISCLAIMER_SORT_TITLE_ASC' | 'DISCLAIMER_SORT_TITLE_DESC' | 'DISCLAIMER_SORT_BRAND_ASC' | 'DISCLAIMER_SORT_BRAND_DESC'>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async disclaimersAPIGetDisclaimer(filterIdEq?: Array<string>, filterIdNeq?: Array<string>, filterBrandIdEq?: Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>, filterBrandIdNeq?: Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>, filterTitleEq?: Array<string>, filterTitleNeq?: Array<string>, pageSize?: number, pageToken?: string, sort?: Array<'DISCLAIMER_SORT_NONE' | 'DISCLAIMER_SORT_TITLE_ASC' | 'DISCLAIMER_SORT_TITLE_DESC' | 'DISCLAIMER_SORT_BRAND_ASC' | 'DISCLAIMER_SORT_BRAND_DESC'>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadsFormsServerV1GetDisclaimerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.disclaimersAPIGetDisclaimer(filterIdEq, filterIdNeq, filterBrandIdEq, filterBrandIdNeq, filterTitleEq, filterTitleNeq, pageSize, pageToken, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates disclaimer and returns updated object
         * @summary Update disclaimer
         * @param {string} id 
         * @param {LeadsFormsServerV1DisclaimerProps} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async disclaimersAPIUpdateDisclaimer(id: string, body: LeadsFormsServerV1DisclaimerProps, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadsFormsServerV1Disclaimer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.disclaimersAPIUpdateDisclaimer(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DisclaimersAPIApi - factory interface
 * @export
 */
export const DisclaimersAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DisclaimersAPIApiFp(configuration)
    return {
        /**
         * Creates new disclaimer and returns created object
         * @summary Create disclaimer
         * @param {LeadsFormsServerV1DisclaimerProps} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disclaimersAPICreateDisclaimer(body: LeadsFormsServerV1DisclaimerProps, options?: any): AxiosPromise<LeadsFormsServerV1Disclaimer> {
            return localVarFp.disclaimersAPICreateDisclaimer(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes disclaimer
         * @summary Delete disclaimer
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disclaimersAPIDeleteDisclaimer(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.disclaimersAPIDeleteDisclaimer(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns disclaimers with the given criteria.
         * @summary Get disclaimers
         * @param {Array<string>} [filterIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<string>} [filterIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>} [filterBrandIdEq] 
         * @param {Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>} [filterBrandIdNeq] 
         * @param {Array<string>} [filterTitleEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {Array<string>} [filterTitleNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {number} [pageSize] The maximum number of resources to return in a single page.
         * @param {string} [pageToken] A previously returned token to get the next page.
         * @param {Array<'DISCLAIMER_SORT_NONE' | 'DISCLAIMER_SORT_TITLE_ASC' | 'DISCLAIMER_SORT_TITLE_DESC' | 'DISCLAIMER_SORT_BRAND_ASC' | 'DISCLAIMER_SORT_BRAND_DESC'>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disclaimersAPIGetDisclaimer(filterIdEq?: Array<string>, filterIdNeq?: Array<string>, filterBrandIdEq?: Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>, filterBrandIdNeq?: Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>, filterTitleEq?: Array<string>, filterTitleNeq?: Array<string>, pageSize?: number, pageToken?: string, sort?: Array<'DISCLAIMER_SORT_NONE' | 'DISCLAIMER_SORT_TITLE_ASC' | 'DISCLAIMER_SORT_TITLE_DESC' | 'DISCLAIMER_SORT_BRAND_ASC' | 'DISCLAIMER_SORT_BRAND_DESC'>, options?: any): AxiosPromise<LeadsFormsServerV1GetDisclaimerResponse> {
            return localVarFp.disclaimersAPIGetDisclaimer(filterIdEq, filterIdNeq, filterBrandIdEq, filterBrandIdNeq, filterTitleEq, filterTitleNeq, pageSize, pageToken, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates disclaimer and returns updated object
         * @summary Update disclaimer
         * @param {string} id 
         * @param {LeadsFormsServerV1DisclaimerProps} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disclaimersAPIUpdateDisclaimer(id: string, body: LeadsFormsServerV1DisclaimerProps, options?: any): AxiosPromise<LeadsFormsServerV1Disclaimer> {
            return localVarFp.disclaimersAPIUpdateDisclaimer(id, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DisclaimersAPIApi - object-oriented interface
 * @export
 * @class DisclaimersAPIApi
 * @extends {BaseAPI}
 */
export class DisclaimersAPIApi extends BaseAPI {
    /**
     * Creates new disclaimer and returns created object
     * @summary Create disclaimer
     * @param {LeadsFormsServerV1DisclaimerProps} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DisclaimersAPIApi
     */
    public disclaimersAPICreateDisclaimer(body: LeadsFormsServerV1DisclaimerProps, options?: any) {
        return DisclaimersAPIApiFp(this.configuration).disclaimersAPICreateDisclaimer(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes disclaimer
     * @summary Delete disclaimer
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DisclaimersAPIApi
     */
    public disclaimersAPIDeleteDisclaimer(id: string, options?: any) {
        return DisclaimersAPIApiFp(this.configuration).disclaimersAPIDeleteDisclaimer(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns disclaimers with the given criteria.
     * @summary Get disclaimers
     * @param {Array<string>} [filterIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
     * @param {Array<string>} [filterIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
     * @param {Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>} [filterBrandIdEq] 
     * @param {Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>} [filterBrandIdNeq] 
     * @param {Array<string>} [filterTitleEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
     * @param {Array<string>} [filterTitleNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
     * @param {number} [pageSize] The maximum number of resources to return in a single page.
     * @param {string} [pageToken] A previously returned token to get the next page.
     * @param {Array<'DISCLAIMER_SORT_NONE' | 'DISCLAIMER_SORT_TITLE_ASC' | 'DISCLAIMER_SORT_TITLE_DESC' | 'DISCLAIMER_SORT_BRAND_ASC' | 'DISCLAIMER_SORT_BRAND_DESC'>} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DisclaimersAPIApi
     */
    public disclaimersAPIGetDisclaimer(filterIdEq?: Array<string>, filterIdNeq?: Array<string>, filterBrandIdEq?: Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>, filterBrandIdNeq?: Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>, filterTitleEq?: Array<string>, filterTitleNeq?: Array<string>, pageSize?: number, pageToken?: string, sort?: Array<'DISCLAIMER_SORT_NONE' | 'DISCLAIMER_SORT_TITLE_ASC' | 'DISCLAIMER_SORT_TITLE_DESC' | 'DISCLAIMER_SORT_BRAND_ASC' | 'DISCLAIMER_SORT_BRAND_DESC'>, options?: any) {
        return DisclaimersAPIApiFp(this.configuration).disclaimersAPIGetDisclaimer(filterIdEq, filterIdNeq, filterBrandIdEq, filterBrandIdNeq, filterTitleEq, filterTitleNeq, pageSize, pageToken, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates disclaimer and returns updated object
     * @summary Update disclaimer
     * @param {string} id 
     * @param {LeadsFormsServerV1DisclaimerProps} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DisclaimersAPIApi
     */
    public disclaimersAPIUpdateDisclaimer(id: string, body: LeadsFormsServerV1DisclaimerProps, options?: any) {
        return DisclaimersAPIApiFp(this.configuration).disclaimersAPIUpdateDisclaimer(id, body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DisplaysAPIApi - axios parameter creator
 * @export
 */
export const DisplaysAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates new display and returns created object
         * @summary Create display
         * @param {LeadsFormsServerV1DisplayProps} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        displaysAPICreateDisplay: async (body: LeadsFormsServerV1DisplayProps, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('displaysAPICreateDisplay', 'body', body)
            const localVarPath = `/v1/displays`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes display
         * @summary Delete display
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        displaysAPIDeleteDisplay: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('displaysAPIDeleteDisplay', 'id', id)
            const localVarPath = `/v1/displays/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns displays with the given criteria.
         * @summary Get displays
         * @param {Array<string>} [filterIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<string>} [filterIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>} [filterBrandIdEq] 
         * @param {Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>} [filterBrandIdNeq] 
         * @param {Array<string>} [filterTitleEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {Array<string>} [filterTitleNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {number} [pageSize] The maximum number of resources to return in a single page.
         * @param {string} [pageToken] A previously returned token to get the next page.
         * @param {Array<'DISPLAY_SORT_NONE' | 'DISPLAY_SORT_TITLE_ASC' | 'DISPLAY_SORT_TITLE_DESC' | 'DISPLAY_SORT_BRAND_ASC' | 'DISPLAY_SORT_BRAND_DESC'>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        displaysAPIGetDisplay: async (filterIdEq?: Array<string>, filterIdNeq?: Array<string>, filterBrandIdEq?: Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>, filterBrandIdNeq?: Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>, filterTitleEq?: Array<string>, filterTitleNeq?: Array<string>, pageSize?: number, pageToken?: string, sort?: Array<'DISPLAY_SORT_NONE' | 'DISPLAY_SORT_TITLE_ASC' | 'DISPLAY_SORT_TITLE_DESC' | 'DISPLAY_SORT_BRAND_ASC' | 'DISPLAY_SORT_BRAND_DESC'>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/displays`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filterIdEq) {
                localVarQueryParameter['filter.id.eq'] = filterIdEq;
            }

            if (filterIdNeq) {
                localVarQueryParameter['filter.id.neq'] = filterIdNeq;
            }

            if (filterBrandIdEq) {
                localVarQueryParameter['filter.brandId.eq'] = filterBrandIdEq;
            }

            if (filterBrandIdNeq) {
                localVarQueryParameter['filter.brandId.neq'] = filterBrandIdNeq;
            }

            if (filterTitleEq) {
                localVarQueryParameter['filter.title.eq'] = filterTitleEq;
            }

            if (filterTitleNeq) {
                localVarQueryParameter['filter.title.neq'] = filterTitleNeq;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page.size'] = pageSize;
            }

            if (pageToken !== undefined) {
                localVarQueryParameter['page.token'] = pageToken;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates display and returns updated object
         * @summary Update display
         * @param {string} id 
         * @param {LeadsFormsServerV1DisplayProps} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        displaysAPIUpdateDisplay: async (id: string, body: LeadsFormsServerV1DisplayProps, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('displaysAPIUpdateDisplay', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('displaysAPIUpdateDisplay', 'body', body)
            const localVarPath = `/v1/displays/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DisplaysAPIApi - functional programming interface
 * @export
 */
export const DisplaysAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DisplaysAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates new display and returns created object
         * @summary Create display
         * @param {LeadsFormsServerV1DisplayProps} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async displaysAPICreateDisplay(body: LeadsFormsServerV1DisplayProps, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadsFormsServerV1Display>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.displaysAPICreateDisplay(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes display
         * @summary Delete display
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async displaysAPIDeleteDisplay(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.displaysAPIDeleteDisplay(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns displays with the given criteria.
         * @summary Get displays
         * @param {Array<string>} [filterIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<string>} [filterIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>} [filterBrandIdEq] 
         * @param {Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>} [filterBrandIdNeq] 
         * @param {Array<string>} [filterTitleEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {Array<string>} [filterTitleNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {number} [pageSize] The maximum number of resources to return in a single page.
         * @param {string} [pageToken] A previously returned token to get the next page.
         * @param {Array<'DISPLAY_SORT_NONE' | 'DISPLAY_SORT_TITLE_ASC' | 'DISPLAY_SORT_TITLE_DESC' | 'DISPLAY_SORT_BRAND_ASC' | 'DISPLAY_SORT_BRAND_DESC'>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async displaysAPIGetDisplay(filterIdEq?: Array<string>, filterIdNeq?: Array<string>, filterBrandIdEq?: Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>, filterBrandIdNeq?: Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>, filterTitleEq?: Array<string>, filterTitleNeq?: Array<string>, pageSize?: number, pageToken?: string, sort?: Array<'DISPLAY_SORT_NONE' | 'DISPLAY_SORT_TITLE_ASC' | 'DISPLAY_SORT_TITLE_DESC' | 'DISPLAY_SORT_BRAND_ASC' | 'DISPLAY_SORT_BRAND_DESC'>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadsFormsServerV1GetDisplayResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.displaysAPIGetDisplay(filterIdEq, filterIdNeq, filterBrandIdEq, filterBrandIdNeq, filterTitleEq, filterTitleNeq, pageSize, pageToken, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates display and returns updated object
         * @summary Update display
         * @param {string} id 
         * @param {LeadsFormsServerV1DisplayProps} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async displaysAPIUpdateDisplay(id: string, body: LeadsFormsServerV1DisplayProps, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadsFormsServerV1Display>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.displaysAPIUpdateDisplay(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DisplaysAPIApi - factory interface
 * @export
 */
export const DisplaysAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DisplaysAPIApiFp(configuration)
    return {
        /**
         * Creates new display and returns created object
         * @summary Create display
         * @param {LeadsFormsServerV1DisplayProps} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        displaysAPICreateDisplay(body: LeadsFormsServerV1DisplayProps, options?: any): AxiosPromise<LeadsFormsServerV1Display> {
            return localVarFp.displaysAPICreateDisplay(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes display
         * @summary Delete display
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        displaysAPIDeleteDisplay(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.displaysAPIDeleteDisplay(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns displays with the given criteria.
         * @summary Get displays
         * @param {Array<string>} [filterIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<string>} [filterIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>} [filterBrandIdEq] 
         * @param {Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>} [filterBrandIdNeq] 
         * @param {Array<string>} [filterTitleEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {Array<string>} [filterTitleNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {number} [pageSize] The maximum number of resources to return in a single page.
         * @param {string} [pageToken] A previously returned token to get the next page.
         * @param {Array<'DISPLAY_SORT_NONE' | 'DISPLAY_SORT_TITLE_ASC' | 'DISPLAY_SORT_TITLE_DESC' | 'DISPLAY_SORT_BRAND_ASC' | 'DISPLAY_SORT_BRAND_DESC'>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        displaysAPIGetDisplay(filterIdEq?: Array<string>, filterIdNeq?: Array<string>, filterBrandIdEq?: Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>, filterBrandIdNeq?: Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>, filterTitleEq?: Array<string>, filterTitleNeq?: Array<string>, pageSize?: number, pageToken?: string, sort?: Array<'DISPLAY_SORT_NONE' | 'DISPLAY_SORT_TITLE_ASC' | 'DISPLAY_SORT_TITLE_DESC' | 'DISPLAY_SORT_BRAND_ASC' | 'DISPLAY_SORT_BRAND_DESC'>, options?: any): AxiosPromise<LeadsFormsServerV1GetDisplayResponse> {
            return localVarFp.displaysAPIGetDisplay(filterIdEq, filterIdNeq, filterBrandIdEq, filterBrandIdNeq, filterTitleEq, filterTitleNeq, pageSize, pageToken, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates display and returns updated object
         * @summary Update display
         * @param {string} id 
         * @param {LeadsFormsServerV1DisplayProps} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        displaysAPIUpdateDisplay(id: string, body: LeadsFormsServerV1DisplayProps, options?: any): AxiosPromise<LeadsFormsServerV1Display> {
            return localVarFp.displaysAPIUpdateDisplay(id, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DisplaysAPIApi - object-oriented interface
 * @export
 * @class DisplaysAPIApi
 * @extends {BaseAPI}
 */
export class DisplaysAPIApi extends BaseAPI {
    /**
     * Creates new display and returns created object
     * @summary Create display
     * @param {LeadsFormsServerV1DisplayProps} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DisplaysAPIApi
     */
    public displaysAPICreateDisplay(body: LeadsFormsServerV1DisplayProps, options?: any) {
        return DisplaysAPIApiFp(this.configuration).displaysAPICreateDisplay(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes display
     * @summary Delete display
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DisplaysAPIApi
     */
    public displaysAPIDeleteDisplay(id: string, options?: any) {
        return DisplaysAPIApiFp(this.configuration).displaysAPIDeleteDisplay(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns displays with the given criteria.
     * @summary Get displays
     * @param {Array<string>} [filterIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
     * @param {Array<string>} [filterIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
     * @param {Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>} [filterBrandIdEq] 
     * @param {Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>} [filterBrandIdNeq] 
     * @param {Array<string>} [filterTitleEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
     * @param {Array<string>} [filterTitleNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
     * @param {number} [pageSize] The maximum number of resources to return in a single page.
     * @param {string} [pageToken] A previously returned token to get the next page.
     * @param {Array<'DISPLAY_SORT_NONE' | 'DISPLAY_SORT_TITLE_ASC' | 'DISPLAY_SORT_TITLE_DESC' | 'DISPLAY_SORT_BRAND_ASC' | 'DISPLAY_SORT_BRAND_DESC'>} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DisplaysAPIApi
     */
    public displaysAPIGetDisplay(filterIdEq?: Array<string>, filterIdNeq?: Array<string>, filterBrandIdEq?: Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>, filterBrandIdNeq?: Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>, filterTitleEq?: Array<string>, filterTitleNeq?: Array<string>, pageSize?: number, pageToken?: string, sort?: Array<'DISPLAY_SORT_NONE' | 'DISPLAY_SORT_TITLE_ASC' | 'DISPLAY_SORT_TITLE_DESC' | 'DISPLAY_SORT_BRAND_ASC' | 'DISPLAY_SORT_BRAND_DESC'>, options?: any) {
        return DisplaysAPIApiFp(this.configuration).displaysAPIGetDisplay(filterIdEq, filterIdNeq, filterBrandIdEq, filterBrandIdNeq, filterTitleEq, filterTitleNeq, pageSize, pageToken, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates display and returns updated object
     * @summary Update display
     * @param {string} id 
     * @param {LeadsFormsServerV1DisplayProps} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DisplaysAPIApi
     */
    public displaysAPIUpdateDisplay(id: string, body: LeadsFormsServerV1DisplayProps, options?: any) {
        return DisplaysAPIApiFp(this.configuration).displaysAPIUpdateDisplay(id, body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FormsAPIApi - axios parameter creator
 * @export
 */
export const FormsAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates new form and returns created form object
         * @summary Create form
         * @param {LeadsFormsServerV1FormProps} body Client-provided properties to create from.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsAPICreateForm: async (body: LeadsFormsServerV1FormProps, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('formsAPICreateForm', 'body', body)
            const localVarPath = `/v1/forms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Submits form with the given name.
         * @summary Submit form actual
         * @param {string} name Name of the form to submit.
         * @param {LeadsFormsServerV1FormSubmitProps} body Client-provided properties to submit.
         * @param {number} [version] The serial number of the form version to submit. If no version is specified the latest one will be used.
         * @param {string} [referer] The address of the webpage, which contains the submitted form.
         * @param {'LEAD_INITIATOR_NONE' | 'LEAD_INITIATOR_BRAND' | 'LEAD_INITIATOR_DEALER'} [leadInitiator] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsAPIFormSubmit: async (name: string, body: LeadsFormsServerV1FormSubmitProps, version?: number, referer?: string, leadInitiator?: 'LEAD_INITIATOR_NONE' | 'LEAD_INITIATOR_BRAND' | 'LEAD_INITIATOR_DEALER', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('formsAPIFormSubmit', 'name', name)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('formsAPIFormSubmit', 'body', body)
            const localVarPath = `/v1/forms/submit/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (referer !== undefined) {
                localVarQueryParameter['referer'] = referer;
            }

            if (leadInitiator !== undefined) {
                localVarQueryParameter['leadInitiator'] = leadInitiator;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Submits form with the given name.
         * @summary Submit form test
         * @param {LeadsFormsServerV1FormSubmitTestRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsAPIFormSubmitTest: async (body: LeadsFormsServerV1FormSubmitTestRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('formsAPIFormSubmitTest', 'body', body)
            const localVarPath = `/v1/forms/test`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns form with the latest version.
         * @summary Get latest form
         * @param {string} name Name of the form to get.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsAPIGetActualForm: async (name: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('formsAPIGetActualForm', 'name', name)
            const localVarPath = `/v1/forms/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns form with the given criteria.
         * @summary Get form
         * @param {string} name Name of the form to get.
         * @param {number} version Identifier of the form.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsAPIGetForm: async (name: string, version: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('formsAPIGetForm', 'name', name)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('formsAPIGetForm', 'version', version)
            const localVarPath = `/v1/forms/{name}/{version}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns form list with the given criteria.
         * @summary Get forms
         * @param {Array<string>} [filterIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<string>} [filterIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>} [filterBrandIdEq] 
         * @param {Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>} [filterBrandIdNeq] 
         * @param {Array<string>} [filterCampaignIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<string>} [filterCampaignIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<string>} [filterInterestIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<string>} [filterInterestIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<'STATUS_NONE' | 'STATUS_ACTIVE' | 'STATUS_DISABLED' | 'STATUS_ARCHIVED'>} [filterStatusEq] The \&quot;equality\&quot; condition of the activity. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<'STATUS_NONE' | 'STATUS_ACTIVE' | 'STATUS_DISABLED' | 'STATUS_ARCHIVED'>} [filterStatusNeq] The \&quot;inequality\&quot; condition of the activity. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<'PUBLISH_ON_NONE' | 'PUBLISH_ON_VDP' | 'PUBLISH_ON_FEATURE_APP'>} [filterPublishOnEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {Array<'PUBLISH_ON_NONE' | 'PUBLISH_ON_VDP' | 'PUBLISH_ON_FEATURE_APP'>} [filterPublishOnNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {Array<string>} [filterTitleEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {Array<string>} [filterTitleNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {number} [pageSize] The maximum number of resources to return in a single page.
         * @param {string} [pageToken] A previously returned token to get the next page.
         * @param {Array<'FORM_SORT_NONE' | 'FORM_SORT_TITLE_ASC' | 'FORM_SORT_TITLE_DESC' | 'FORM_SORT_BRAND_ASC' | 'FORM_SORT_BRAND_DESC' | 'FORM_SORT_CAMPAIGN_ASC' | 'FORM_SORT_CAMPAIGN_DESC' | 'FORM_SORT_INTEREST_ASC' | 'FORM_SORT_INTEREST_DESC' | 'FORM_SORT_ACTIVE_FROM_ASC' | 'FORM_SORT_ACTIVE_FROM_DESC' | 'FORM_SORT_ACTIVE_TO_ASC' | 'FORM_SORT_ACTIVE_TO_DESC'>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsAPIGetForms: async (filterIdEq?: Array<string>, filterIdNeq?: Array<string>, filterBrandIdEq?: Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>, filterBrandIdNeq?: Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>, filterCampaignIdEq?: Array<string>, filterCampaignIdNeq?: Array<string>, filterInterestIdEq?: Array<string>, filterInterestIdNeq?: Array<string>, filterStatusEq?: Array<'STATUS_NONE' | 'STATUS_ACTIVE' | 'STATUS_DISABLED' | 'STATUS_ARCHIVED'>, filterStatusNeq?: Array<'STATUS_NONE' | 'STATUS_ACTIVE' | 'STATUS_DISABLED' | 'STATUS_ARCHIVED'>, filterPublishOnEq?: Array<'PUBLISH_ON_NONE' | 'PUBLISH_ON_VDP' | 'PUBLISH_ON_FEATURE_APP'>, filterPublishOnNeq?: Array<'PUBLISH_ON_NONE' | 'PUBLISH_ON_VDP' | 'PUBLISH_ON_FEATURE_APP'>, filterTitleEq?: Array<string>, filterTitleNeq?: Array<string>, pageSize?: number, pageToken?: string, sort?: Array<'FORM_SORT_NONE' | 'FORM_SORT_TITLE_ASC' | 'FORM_SORT_TITLE_DESC' | 'FORM_SORT_BRAND_ASC' | 'FORM_SORT_BRAND_DESC' | 'FORM_SORT_CAMPAIGN_ASC' | 'FORM_SORT_CAMPAIGN_DESC' | 'FORM_SORT_INTEREST_ASC' | 'FORM_SORT_INTEREST_DESC' | 'FORM_SORT_ACTIVE_FROM_ASC' | 'FORM_SORT_ACTIVE_FROM_DESC' | 'FORM_SORT_ACTIVE_TO_ASC' | 'FORM_SORT_ACTIVE_TO_DESC'>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/forms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filterIdEq) {
                localVarQueryParameter['filter.id.eq'] = filterIdEq;
            }

            if (filterIdNeq) {
                localVarQueryParameter['filter.id.neq'] = filterIdNeq;
            }

            if (filterBrandIdEq) {
                localVarQueryParameter['filter.brandId.eq'] = filterBrandIdEq;
            }

            if (filterBrandIdNeq) {
                localVarQueryParameter['filter.brandId.neq'] = filterBrandIdNeq;
            }

            if (filterCampaignIdEq) {
                localVarQueryParameter['filter.campaignId.eq'] = filterCampaignIdEq;
            }

            if (filterCampaignIdNeq) {
                localVarQueryParameter['filter.campaignId.neq'] = filterCampaignIdNeq;
            }

            if (filterInterestIdEq) {
                localVarQueryParameter['filter.interestId.eq'] = filterInterestIdEq;
            }

            if (filterInterestIdNeq) {
                localVarQueryParameter['filter.interestId.neq'] = filterInterestIdNeq;
            }

            if (filterStatusEq) {
                localVarQueryParameter['filter.status.eq'] = filterStatusEq;
            }

            if (filterStatusNeq) {
                localVarQueryParameter['filter.status.neq'] = filterStatusNeq;
            }

            if (filterPublishOnEq) {
                localVarQueryParameter['filter.publishOn.eq'] = filterPublishOnEq;
            }

            if (filterPublishOnNeq) {
                localVarQueryParameter['filter.publishOn.neq'] = filterPublishOnNeq;
            }

            if (filterTitleEq) {
                localVarQueryParameter['filter.title.eq'] = filterTitleEq;
            }

            if (filterTitleNeq) {
                localVarQueryParameter['filter.title.neq'] = filterTitleNeq;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page.size'] = pageSize;
            }

            if (pageToken !== undefined) {
                localVarQueryParameter['page.token'] = pageToken;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Submits form with the given name.
         * @summary Submit form
         * @param {string} name Name of the form to submit.
         * @param {LeadsFormsServerV1ResultProps} body Client-provided properties to submit.
         * @param {number} [version] The serial number of the form version to submit. If no version is specified the latest one will be used.
         * @param {string} [referer] The address of the webpage, which contains the submitted form.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsAPISubmitForm: async (name: string, body: LeadsFormsServerV1ResultProps, version?: number, referer?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('formsAPISubmitForm', 'name', name)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('formsAPISubmitForm', 'body', body)
            const localVarPath = `/v1/forms/{name}/submit`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (referer !== undefined) {
                localVarQueryParameter['referer'] = referer;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates new form and returns updated form object
         * @summary Updates form
         * @param {string} name 
         * @param {LeadsFormsServerV1FormProps} body Client-provided properties to create from.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsAPIUpdateForm: async (name: string, body: LeadsFormsServerV1FormProps, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('formsAPIUpdateForm', 'name', name)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('formsAPIUpdateForm', 'body', body)
            const localVarPath = `/v1/forms/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates form status and returns updated form object
         * @summary Updates form status
         * @param {string} name 
         * @param {LeadsActivityV1Activity} body Client-provided properties to create from.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsAPIUpdateFormStatus: async (name: string, body: LeadsActivityV1Activity, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('formsAPIUpdateFormStatus', 'name', name)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('formsAPIUpdateFormStatus', 'body', body)
            const localVarPath = `/v1/forms/{name}/status`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FormsAPIApi - functional programming interface
 * @export
 */
export const FormsAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FormsAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates new form and returns created form object
         * @summary Create form
         * @param {LeadsFormsServerV1FormProps} body Client-provided properties to create from.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async formsAPICreateForm(body: LeadsFormsServerV1FormProps, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadsFormsServerV1Form>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.formsAPICreateForm(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Submits form with the given name.
         * @summary Submit form actual
         * @param {string} name Name of the form to submit.
         * @param {LeadsFormsServerV1FormSubmitProps} body Client-provided properties to submit.
         * @param {number} [version] The serial number of the form version to submit. If no version is specified the latest one will be used.
         * @param {string} [referer] The address of the webpage, which contains the submitted form.
         * @param {'LEAD_INITIATOR_NONE' | 'LEAD_INITIATOR_BRAND' | 'LEAD_INITIATOR_DEALER'} [leadInitiator] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async formsAPIFormSubmit(name: string, body: LeadsFormsServerV1FormSubmitProps, version?: number, referer?: string, leadInitiator?: 'LEAD_INITIATOR_NONE' | 'LEAD_INITIATOR_BRAND' | 'LEAD_INITIATOR_DEALER', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadsFormsServerV1Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.formsAPIFormSubmit(name, body, version, referer, leadInitiator, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Submits form with the given name.
         * @summary Submit form test
         * @param {LeadsFormsServerV1FormSubmitTestRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async formsAPIFormSubmitTest(body: LeadsFormsServerV1FormSubmitTestRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.formsAPIFormSubmitTest(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns form with the latest version.
         * @summary Get latest form
         * @param {string} name Name of the form to get.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async formsAPIGetActualForm(name: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadsFormsServerV1GetActualFormResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.formsAPIGetActualForm(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns form with the given criteria.
         * @summary Get form
         * @param {string} name Name of the form to get.
         * @param {number} version Identifier of the form.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async formsAPIGetForm(name: string, version: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadsFormsServerV1GetFormResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.formsAPIGetForm(name, version, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns form list with the given criteria.
         * @summary Get forms
         * @param {Array<string>} [filterIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<string>} [filterIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>} [filterBrandIdEq] 
         * @param {Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>} [filterBrandIdNeq] 
         * @param {Array<string>} [filterCampaignIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<string>} [filterCampaignIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<string>} [filterInterestIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<string>} [filterInterestIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<'STATUS_NONE' | 'STATUS_ACTIVE' | 'STATUS_DISABLED' | 'STATUS_ARCHIVED'>} [filterStatusEq] The \&quot;equality\&quot; condition of the activity. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<'STATUS_NONE' | 'STATUS_ACTIVE' | 'STATUS_DISABLED' | 'STATUS_ARCHIVED'>} [filterStatusNeq] The \&quot;inequality\&quot; condition of the activity. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<'PUBLISH_ON_NONE' | 'PUBLISH_ON_VDP' | 'PUBLISH_ON_FEATURE_APP'>} [filterPublishOnEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {Array<'PUBLISH_ON_NONE' | 'PUBLISH_ON_VDP' | 'PUBLISH_ON_FEATURE_APP'>} [filterPublishOnNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {Array<string>} [filterTitleEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {Array<string>} [filterTitleNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {number} [pageSize] The maximum number of resources to return in a single page.
         * @param {string} [pageToken] A previously returned token to get the next page.
         * @param {Array<'FORM_SORT_NONE' | 'FORM_SORT_TITLE_ASC' | 'FORM_SORT_TITLE_DESC' | 'FORM_SORT_BRAND_ASC' | 'FORM_SORT_BRAND_DESC' | 'FORM_SORT_CAMPAIGN_ASC' | 'FORM_SORT_CAMPAIGN_DESC' | 'FORM_SORT_INTEREST_ASC' | 'FORM_SORT_INTEREST_DESC' | 'FORM_SORT_ACTIVE_FROM_ASC' | 'FORM_SORT_ACTIVE_FROM_DESC' | 'FORM_SORT_ACTIVE_TO_ASC' | 'FORM_SORT_ACTIVE_TO_DESC'>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async formsAPIGetForms(filterIdEq?: Array<string>, filterIdNeq?: Array<string>, filterBrandIdEq?: Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>, filterBrandIdNeq?: Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>, filterCampaignIdEq?: Array<string>, filterCampaignIdNeq?: Array<string>, filterInterestIdEq?: Array<string>, filterInterestIdNeq?: Array<string>, filterStatusEq?: Array<'STATUS_NONE' | 'STATUS_ACTIVE' | 'STATUS_DISABLED' | 'STATUS_ARCHIVED'>, filterStatusNeq?: Array<'STATUS_NONE' | 'STATUS_ACTIVE' | 'STATUS_DISABLED' | 'STATUS_ARCHIVED'>, filterPublishOnEq?: Array<'PUBLISH_ON_NONE' | 'PUBLISH_ON_VDP' | 'PUBLISH_ON_FEATURE_APP'>, filterPublishOnNeq?: Array<'PUBLISH_ON_NONE' | 'PUBLISH_ON_VDP' | 'PUBLISH_ON_FEATURE_APP'>, filterTitleEq?: Array<string>, filterTitleNeq?: Array<string>, pageSize?: number, pageToken?: string, sort?: Array<'FORM_SORT_NONE' | 'FORM_SORT_TITLE_ASC' | 'FORM_SORT_TITLE_DESC' | 'FORM_SORT_BRAND_ASC' | 'FORM_SORT_BRAND_DESC' | 'FORM_SORT_CAMPAIGN_ASC' | 'FORM_SORT_CAMPAIGN_DESC' | 'FORM_SORT_INTEREST_ASC' | 'FORM_SORT_INTEREST_DESC' | 'FORM_SORT_ACTIVE_FROM_ASC' | 'FORM_SORT_ACTIVE_FROM_DESC' | 'FORM_SORT_ACTIVE_TO_ASC' | 'FORM_SORT_ACTIVE_TO_DESC'>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadsFormsServerV1GetFormsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.formsAPIGetForms(filterIdEq, filterIdNeq, filterBrandIdEq, filterBrandIdNeq, filterCampaignIdEq, filterCampaignIdNeq, filterInterestIdEq, filterInterestIdNeq, filterStatusEq, filterStatusNeq, filterPublishOnEq, filterPublishOnNeq, filterTitleEq, filterTitleNeq, pageSize, pageToken, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Submits form with the given name.
         * @summary Submit form
         * @param {string} name Name of the form to submit.
         * @param {LeadsFormsServerV1ResultProps} body Client-provided properties to submit.
         * @param {number} [version] The serial number of the form version to submit. If no version is specified the latest one will be used.
         * @param {string} [referer] The address of the webpage, which contains the submitted form.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async formsAPISubmitForm(name: string, body: LeadsFormsServerV1ResultProps, version?: number, referer?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadsFormsServerV1Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.formsAPISubmitForm(name, body, version, referer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates new form and returns updated form object
         * @summary Updates form
         * @param {string} name 
         * @param {LeadsFormsServerV1FormProps} body Client-provided properties to create from.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async formsAPIUpdateForm(name: string, body: LeadsFormsServerV1FormProps, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadsFormsServerV1Form>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.formsAPIUpdateForm(name, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates form status and returns updated form object
         * @summary Updates form status
         * @param {string} name 
         * @param {LeadsActivityV1Activity} body Client-provided properties to create from.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async formsAPIUpdateFormStatus(name: string, body: LeadsActivityV1Activity, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadsFormsServerV1UpdateFormStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.formsAPIUpdateFormStatus(name, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FormsAPIApi - factory interface
 * @export
 */
export const FormsAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FormsAPIApiFp(configuration)
    return {
        /**
         * Creates new form and returns created form object
         * @summary Create form
         * @param {LeadsFormsServerV1FormProps} body Client-provided properties to create from.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsAPICreateForm(body: LeadsFormsServerV1FormProps, options?: any): AxiosPromise<LeadsFormsServerV1Form> {
            return localVarFp.formsAPICreateForm(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Submits form with the given name.
         * @summary Submit form actual
         * @param {string} name Name of the form to submit.
         * @param {LeadsFormsServerV1FormSubmitProps} body Client-provided properties to submit.
         * @param {number} [version] The serial number of the form version to submit. If no version is specified the latest one will be used.
         * @param {string} [referer] The address of the webpage, which contains the submitted form.
         * @param {'LEAD_INITIATOR_NONE' | 'LEAD_INITIATOR_BRAND' | 'LEAD_INITIATOR_DEALER'} [leadInitiator] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsAPIFormSubmit(name: string, body: LeadsFormsServerV1FormSubmitProps, version?: number, referer?: string, leadInitiator?: 'LEAD_INITIATOR_NONE' | 'LEAD_INITIATOR_BRAND' | 'LEAD_INITIATOR_DEALER', options?: any): AxiosPromise<LeadsFormsServerV1Result> {
            return localVarFp.formsAPIFormSubmit(name, body, version, referer, leadInitiator, options).then((request) => request(axios, basePath));
        },
        /**
         * Submits form with the given name.
         * @summary Submit form test
         * @param {LeadsFormsServerV1FormSubmitTestRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsAPIFormSubmitTest(body: LeadsFormsServerV1FormSubmitTestRequest, options?: any): AxiosPromise<object> {
            return localVarFp.formsAPIFormSubmitTest(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns form with the latest version.
         * @summary Get latest form
         * @param {string} name Name of the form to get.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsAPIGetActualForm(name: string, options?: any): AxiosPromise<LeadsFormsServerV1GetActualFormResponse> {
            return localVarFp.formsAPIGetActualForm(name, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns form with the given criteria.
         * @summary Get form
         * @param {string} name Name of the form to get.
         * @param {number} version Identifier of the form.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsAPIGetForm(name: string, version: number, options?: any): AxiosPromise<LeadsFormsServerV1GetFormResponse> {
            return localVarFp.formsAPIGetForm(name, version, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns form list with the given criteria.
         * @summary Get forms
         * @param {Array<string>} [filterIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<string>} [filterIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>} [filterBrandIdEq] 
         * @param {Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>} [filterBrandIdNeq] 
         * @param {Array<string>} [filterCampaignIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<string>} [filterCampaignIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<string>} [filterInterestIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<string>} [filterInterestIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<'STATUS_NONE' | 'STATUS_ACTIVE' | 'STATUS_DISABLED' | 'STATUS_ARCHIVED'>} [filterStatusEq] The \&quot;equality\&quot; condition of the activity. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<'STATUS_NONE' | 'STATUS_ACTIVE' | 'STATUS_DISABLED' | 'STATUS_ARCHIVED'>} [filterStatusNeq] The \&quot;inequality\&quot; condition of the activity. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<'PUBLISH_ON_NONE' | 'PUBLISH_ON_VDP' | 'PUBLISH_ON_FEATURE_APP'>} [filterPublishOnEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {Array<'PUBLISH_ON_NONE' | 'PUBLISH_ON_VDP' | 'PUBLISH_ON_FEATURE_APP'>} [filterPublishOnNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {Array<string>} [filterTitleEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {Array<string>} [filterTitleNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {number} [pageSize] The maximum number of resources to return in a single page.
         * @param {string} [pageToken] A previously returned token to get the next page.
         * @param {Array<'FORM_SORT_NONE' | 'FORM_SORT_TITLE_ASC' | 'FORM_SORT_TITLE_DESC' | 'FORM_SORT_BRAND_ASC' | 'FORM_SORT_BRAND_DESC' | 'FORM_SORT_CAMPAIGN_ASC' | 'FORM_SORT_CAMPAIGN_DESC' | 'FORM_SORT_INTEREST_ASC' | 'FORM_SORT_INTEREST_DESC' | 'FORM_SORT_ACTIVE_FROM_ASC' | 'FORM_SORT_ACTIVE_FROM_DESC' | 'FORM_SORT_ACTIVE_TO_ASC' | 'FORM_SORT_ACTIVE_TO_DESC'>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsAPIGetForms(filterIdEq?: Array<string>, filterIdNeq?: Array<string>, filterBrandIdEq?: Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>, filterBrandIdNeq?: Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>, filterCampaignIdEq?: Array<string>, filterCampaignIdNeq?: Array<string>, filterInterestIdEq?: Array<string>, filterInterestIdNeq?: Array<string>, filterStatusEq?: Array<'STATUS_NONE' | 'STATUS_ACTIVE' | 'STATUS_DISABLED' | 'STATUS_ARCHIVED'>, filterStatusNeq?: Array<'STATUS_NONE' | 'STATUS_ACTIVE' | 'STATUS_DISABLED' | 'STATUS_ARCHIVED'>, filterPublishOnEq?: Array<'PUBLISH_ON_NONE' | 'PUBLISH_ON_VDP' | 'PUBLISH_ON_FEATURE_APP'>, filterPublishOnNeq?: Array<'PUBLISH_ON_NONE' | 'PUBLISH_ON_VDP' | 'PUBLISH_ON_FEATURE_APP'>, filterTitleEq?: Array<string>, filterTitleNeq?: Array<string>, pageSize?: number, pageToken?: string, sort?: Array<'FORM_SORT_NONE' | 'FORM_SORT_TITLE_ASC' | 'FORM_SORT_TITLE_DESC' | 'FORM_SORT_BRAND_ASC' | 'FORM_SORT_BRAND_DESC' | 'FORM_SORT_CAMPAIGN_ASC' | 'FORM_SORT_CAMPAIGN_DESC' | 'FORM_SORT_INTEREST_ASC' | 'FORM_SORT_INTEREST_DESC' | 'FORM_SORT_ACTIVE_FROM_ASC' | 'FORM_SORT_ACTIVE_FROM_DESC' | 'FORM_SORT_ACTIVE_TO_ASC' | 'FORM_SORT_ACTIVE_TO_DESC'>, options?: any): AxiosPromise<LeadsFormsServerV1GetFormsResponse> {
            return localVarFp.formsAPIGetForms(filterIdEq, filterIdNeq, filterBrandIdEq, filterBrandIdNeq, filterCampaignIdEq, filterCampaignIdNeq, filterInterestIdEq, filterInterestIdNeq, filterStatusEq, filterStatusNeq, filterPublishOnEq, filterPublishOnNeq, filterTitleEq, filterTitleNeq, pageSize, pageToken, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Submits form with the given name.
         * @summary Submit form
         * @param {string} name Name of the form to submit.
         * @param {LeadsFormsServerV1ResultProps} body Client-provided properties to submit.
         * @param {number} [version] The serial number of the form version to submit. If no version is specified the latest one will be used.
         * @param {string} [referer] The address of the webpage, which contains the submitted form.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsAPISubmitForm(name: string, body: LeadsFormsServerV1ResultProps, version?: number, referer?: string, options?: any): AxiosPromise<LeadsFormsServerV1Result> {
            return localVarFp.formsAPISubmitForm(name, body, version, referer, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates new form and returns updated form object
         * @summary Updates form
         * @param {string} name 
         * @param {LeadsFormsServerV1FormProps} body Client-provided properties to create from.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsAPIUpdateForm(name: string, body: LeadsFormsServerV1FormProps, options?: any): AxiosPromise<LeadsFormsServerV1Form> {
            return localVarFp.formsAPIUpdateForm(name, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates form status and returns updated form object
         * @summary Updates form status
         * @param {string} name 
         * @param {LeadsActivityV1Activity} body Client-provided properties to create from.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        formsAPIUpdateFormStatus(name: string, body: LeadsActivityV1Activity, options?: any): AxiosPromise<LeadsFormsServerV1UpdateFormStatusResponse> {
            return localVarFp.formsAPIUpdateFormStatus(name, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FormsAPIApi - object-oriented interface
 * @export
 * @class FormsAPIApi
 * @extends {BaseAPI}
 */
export class FormsAPIApi extends BaseAPI {
    /**
     * Creates new form and returns created form object
     * @summary Create form
     * @param {LeadsFormsServerV1FormProps} body Client-provided properties to create from.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsAPIApi
     */
    public formsAPICreateForm(body: LeadsFormsServerV1FormProps, options?: any) {
        return FormsAPIApiFp(this.configuration).formsAPICreateForm(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Submits form with the given name.
     * @summary Submit form actual
     * @param {string} name Name of the form to submit.
     * @param {LeadsFormsServerV1FormSubmitProps} body Client-provided properties to submit.
     * @param {number} [version] The serial number of the form version to submit. If no version is specified the latest one will be used.
     * @param {string} [referer] The address of the webpage, which contains the submitted form.
     * @param {'LEAD_INITIATOR_NONE' | 'LEAD_INITIATOR_BRAND' | 'LEAD_INITIATOR_DEALER'} [leadInitiator] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsAPIApi
     */
    public formsAPIFormSubmit(name: string, body: LeadsFormsServerV1FormSubmitProps, version?: number, referer?: string, leadInitiator?: 'LEAD_INITIATOR_NONE' | 'LEAD_INITIATOR_BRAND' | 'LEAD_INITIATOR_DEALER', options?: any) {
        return FormsAPIApiFp(this.configuration).formsAPIFormSubmit(name, body, version, referer, leadInitiator, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Submits form with the given name.
     * @summary Submit form test
     * @param {LeadsFormsServerV1FormSubmitTestRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsAPIApi
     */
    public formsAPIFormSubmitTest(body: LeadsFormsServerV1FormSubmitTestRequest, options?: any) {
        return FormsAPIApiFp(this.configuration).formsAPIFormSubmitTest(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns form with the latest version.
     * @summary Get latest form
     * @param {string} name Name of the form to get.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsAPIApi
     */
    public formsAPIGetActualForm(name: string, options?: any) {
        return FormsAPIApiFp(this.configuration).formsAPIGetActualForm(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns form with the given criteria.
     * @summary Get form
     * @param {string} name Name of the form to get.
     * @param {number} version Identifier of the form.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsAPIApi
     */
    public formsAPIGetForm(name: string, version: number, options?: any) {
        return FormsAPIApiFp(this.configuration).formsAPIGetForm(name, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns form list with the given criteria.
     * @summary Get forms
     * @param {Array<string>} [filterIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
     * @param {Array<string>} [filterIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
     * @param {Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>} [filterBrandIdEq] 
     * @param {Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>} [filterBrandIdNeq] 
     * @param {Array<string>} [filterCampaignIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
     * @param {Array<string>} [filterCampaignIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
     * @param {Array<string>} [filterInterestIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
     * @param {Array<string>} [filterInterestIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
     * @param {Array<'STATUS_NONE' | 'STATUS_ACTIVE' | 'STATUS_DISABLED' | 'STATUS_ARCHIVED'>} [filterStatusEq] The \&quot;equality\&quot; condition of the activity. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
     * @param {Array<'STATUS_NONE' | 'STATUS_ACTIVE' | 'STATUS_DISABLED' | 'STATUS_ARCHIVED'>} [filterStatusNeq] The \&quot;inequality\&quot; condition of the activity. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
     * @param {Array<'PUBLISH_ON_NONE' | 'PUBLISH_ON_VDP' | 'PUBLISH_ON_FEATURE_APP'>} [filterPublishOnEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
     * @param {Array<'PUBLISH_ON_NONE' | 'PUBLISH_ON_VDP' | 'PUBLISH_ON_FEATURE_APP'>} [filterPublishOnNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
     * @param {Array<string>} [filterTitleEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
     * @param {Array<string>} [filterTitleNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
     * @param {number} [pageSize] The maximum number of resources to return in a single page.
     * @param {string} [pageToken] A previously returned token to get the next page.
     * @param {Array<'FORM_SORT_NONE' | 'FORM_SORT_TITLE_ASC' | 'FORM_SORT_TITLE_DESC' | 'FORM_SORT_BRAND_ASC' | 'FORM_SORT_BRAND_DESC' | 'FORM_SORT_CAMPAIGN_ASC' | 'FORM_SORT_CAMPAIGN_DESC' | 'FORM_SORT_INTEREST_ASC' | 'FORM_SORT_INTEREST_DESC' | 'FORM_SORT_ACTIVE_FROM_ASC' | 'FORM_SORT_ACTIVE_FROM_DESC' | 'FORM_SORT_ACTIVE_TO_ASC' | 'FORM_SORT_ACTIVE_TO_DESC'>} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsAPIApi
     */
    public formsAPIGetForms(filterIdEq?: Array<string>, filterIdNeq?: Array<string>, filterBrandIdEq?: Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>, filterBrandIdNeq?: Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>, filterCampaignIdEq?: Array<string>, filterCampaignIdNeq?: Array<string>, filterInterestIdEq?: Array<string>, filterInterestIdNeq?: Array<string>, filterStatusEq?: Array<'STATUS_NONE' | 'STATUS_ACTIVE' | 'STATUS_DISABLED' | 'STATUS_ARCHIVED'>, filterStatusNeq?: Array<'STATUS_NONE' | 'STATUS_ACTIVE' | 'STATUS_DISABLED' | 'STATUS_ARCHIVED'>, filterPublishOnEq?: Array<'PUBLISH_ON_NONE' | 'PUBLISH_ON_VDP' | 'PUBLISH_ON_FEATURE_APP'>, filterPublishOnNeq?: Array<'PUBLISH_ON_NONE' | 'PUBLISH_ON_VDP' | 'PUBLISH_ON_FEATURE_APP'>, filterTitleEq?: Array<string>, filterTitleNeq?: Array<string>, pageSize?: number, pageToken?: string, sort?: Array<'FORM_SORT_NONE' | 'FORM_SORT_TITLE_ASC' | 'FORM_SORT_TITLE_DESC' | 'FORM_SORT_BRAND_ASC' | 'FORM_SORT_BRAND_DESC' | 'FORM_SORT_CAMPAIGN_ASC' | 'FORM_SORT_CAMPAIGN_DESC' | 'FORM_SORT_INTEREST_ASC' | 'FORM_SORT_INTEREST_DESC' | 'FORM_SORT_ACTIVE_FROM_ASC' | 'FORM_SORT_ACTIVE_FROM_DESC' | 'FORM_SORT_ACTIVE_TO_ASC' | 'FORM_SORT_ACTIVE_TO_DESC'>, options?: any) {
        return FormsAPIApiFp(this.configuration).formsAPIGetForms(filterIdEq, filterIdNeq, filterBrandIdEq, filterBrandIdNeq, filterCampaignIdEq, filterCampaignIdNeq, filterInterestIdEq, filterInterestIdNeq, filterStatusEq, filterStatusNeq, filterPublishOnEq, filterPublishOnNeq, filterTitleEq, filterTitleNeq, pageSize, pageToken, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Submits form with the given name.
     * @summary Submit form
     * @param {string} name Name of the form to submit.
     * @param {LeadsFormsServerV1ResultProps} body Client-provided properties to submit.
     * @param {number} [version] The serial number of the form version to submit. If no version is specified the latest one will be used.
     * @param {string} [referer] The address of the webpage, which contains the submitted form.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsAPIApi
     */
    public formsAPISubmitForm(name: string, body: LeadsFormsServerV1ResultProps, version?: number, referer?: string, options?: any) {
        return FormsAPIApiFp(this.configuration).formsAPISubmitForm(name, body, version, referer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates new form and returns updated form object
     * @summary Updates form
     * @param {string} name 
     * @param {LeadsFormsServerV1FormProps} body Client-provided properties to create from.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsAPIApi
     */
    public formsAPIUpdateForm(name: string, body: LeadsFormsServerV1FormProps, options?: any) {
        return FormsAPIApiFp(this.configuration).formsAPIUpdateForm(name, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates form status and returns updated form object
     * @summary Updates form status
     * @param {string} name 
     * @param {LeadsActivityV1Activity} body Client-provided properties to create from.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsAPIApi
     */
    public formsAPIUpdateFormStatus(name: string, body: LeadsActivityV1Activity, options?: any) {
        return FormsAPIApiFp(this.configuration).formsAPIUpdateFormStatus(name, body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GlobalsAPIApi - axios parameter creator
 * @export
 */
export const GlobalsAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates global form result
         * @summary Create global form result
         * @param {LeadsGlobalServerV1GlobalFormProps} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalsAPIGlobalForm: async (body: LeadsGlobalServerV1GlobalFormProps, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('globalsAPIGlobalForm', 'body', body)
            const localVarPath = `/v1/global`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GlobalsAPIApi - functional programming interface
 * @export
 */
export const GlobalsAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GlobalsAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates global form result
         * @summary Create global form result
         * @param {LeadsGlobalServerV1GlobalFormProps} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalsAPIGlobalForm(body: LeadsGlobalServerV1GlobalFormProps, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadsGlobalServerV1GlobalFormResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.globalsAPIGlobalForm(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GlobalsAPIApi - factory interface
 * @export
 */
export const GlobalsAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GlobalsAPIApiFp(configuration)
    return {
        /**
         * Creates global form result
         * @summary Create global form result
         * @param {LeadsGlobalServerV1GlobalFormProps} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalsAPIGlobalForm(body: LeadsGlobalServerV1GlobalFormProps, options?: any): AxiosPromise<LeadsGlobalServerV1GlobalFormResponse> {
            return localVarFp.globalsAPIGlobalForm(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GlobalsAPIApi - object-oriented interface
 * @export
 * @class GlobalsAPIApi
 * @extends {BaseAPI}
 */
export class GlobalsAPIApi extends BaseAPI {
    /**
     * Creates global form result
     * @summary Create global form result
     * @param {LeadsGlobalServerV1GlobalFormProps} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalsAPIApi
     */
    public globalsAPIGlobalForm(body: LeadsGlobalServerV1GlobalFormProps, options?: any) {
        return GlobalsAPIApiFp(this.configuration).globalsAPIGlobalForm(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LeadsAPIApi - axios parameter creator
 * @export
 */
export const LeadsAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns campaigns with the given criteria.
         * @summary Get campaigns
         * @param {Array<string>} [filterIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<string>} [filterIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<string>} [filterCodeNameEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {Array<string>} [filterCodeNameNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {Array<string>} [filterWebNameEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {Array<string>} [filterWebNameNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {Array<'STATUS_NONE' | 'STATUS_ACTIVE' | 'STATUS_DISABLED' | 'STATUS_ARCHIVED'>} [filterActivityEq] The \&quot;equality\&quot; condition of the activity. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<'STATUS_NONE' | 'STATUS_ACTIVE' | 'STATUS_DISABLED' | 'STATUS_ARCHIVED'>} [filterActivityNeq] The \&quot;inequality\&quot; condition of the activity. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>} [filterBrandIdEq] 
         * @param {Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>} [filterBrandIdNeq] 
         * @param {number} [pageSize] The maximum number of resources to return in a single page.
         * @param {string} [pageToken] A previously returned token to get the next page.
         * @param {Array<'CAMPAIGN_SORT_NONE' | 'CAMPAIGN_SORT_NAME_ASC' | 'CAMPAIGN_SORT_NAME_DESC'>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsAPIGetCampaign: async (filterIdEq?: Array<string>, filterIdNeq?: Array<string>, filterCodeNameEq?: Array<string>, filterCodeNameNeq?: Array<string>, filterWebNameEq?: Array<string>, filterWebNameNeq?: Array<string>, filterActivityEq?: Array<'STATUS_NONE' | 'STATUS_ACTIVE' | 'STATUS_DISABLED' | 'STATUS_ARCHIVED'>, filterActivityNeq?: Array<'STATUS_NONE' | 'STATUS_ACTIVE' | 'STATUS_DISABLED' | 'STATUS_ARCHIVED'>, filterBrandIdEq?: Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>, filterBrandIdNeq?: Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>, pageSize?: number, pageToken?: string, sort?: Array<'CAMPAIGN_SORT_NONE' | 'CAMPAIGN_SORT_NAME_ASC' | 'CAMPAIGN_SORT_NAME_DESC'>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/leads/campaigns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filterIdEq) {
                localVarQueryParameter['filter.id.eq'] = filterIdEq;
            }

            if (filterIdNeq) {
                localVarQueryParameter['filter.id.neq'] = filterIdNeq;
            }

            if (filterCodeNameEq) {
                localVarQueryParameter['filter.codeName.eq'] = filterCodeNameEq;
            }

            if (filterCodeNameNeq) {
                localVarQueryParameter['filter.codeName.neq'] = filterCodeNameNeq;
            }

            if (filterWebNameEq) {
                localVarQueryParameter['filter.webName.eq'] = filterWebNameEq;
            }

            if (filterWebNameNeq) {
                localVarQueryParameter['filter.webName.neq'] = filterWebNameNeq;
            }

            if (filterActivityEq) {
                localVarQueryParameter['filter.activity.eq'] = filterActivityEq;
            }

            if (filterActivityNeq) {
                localVarQueryParameter['filter.activity.neq'] = filterActivityNeq;
            }

            if (filterBrandIdEq) {
                localVarQueryParameter['filter.brandId.eq'] = filterBrandIdEq;
            }

            if (filterBrandIdNeq) {
                localVarQueryParameter['filter.brandId.neq'] = filterBrandIdNeq;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page.size'] = pageSize;
            }

            if (pageToken !== undefined) {
                localVarQueryParameter['page.token'] = pageToken;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns interests with the given criteria.
         * @summary Get interests
         * @param {Array<string>} [filterIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<string>} [filterIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<string>} [filterCodeEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {Array<string>} [filterCodeNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {Array<'CATEGORY_NONE' | 'CATEGORY_SALE' | 'CATEGORY_SERVICE' | 'CATEGORY_OTHER' | 'CATEGORY_FEEDBACK' | 'CATEGORY_EVENT' | 'CATEGORY_HOTLINE' | 'CATEGORY_SUBSCRIBE' | 'CATEGORY_FLEET'>} [filterCategoryEq] 
         * @param {Array<'CATEGORY_NONE' | 'CATEGORY_SALE' | 'CATEGORY_SERVICE' | 'CATEGORY_OTHER' | 'CATEGORY_FEEDBACK' | 'CATEGORY_EVENT' | 'CATEGORY_HOTLINE' | 'CATEGORY_SUBSCRIBE' | 'CATEGORY_FLEET'>} [filterCategoryNeq] 
         * @param {Array<string>} [filterNameEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {Array<string>} [filterNameNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {number} [pageSize] The maximum number of resources to return in a single page.
         * @param {string} [pageToken] A previously returned token to get the next page.
         * @param {Array<'INTEREST_SORT_NONE' | 'INTEREST_SORT_NAME_ASC' | 'INTEREST_SORT_NAME_DESC' | 'INTEREST_SORT_CODE_ASC' | 'INTEREST_SORT_CODE_DESC'>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsAPIGetInterest: async (filterIdEq?: Array<string>, filterIdNeq?: Array<string>, filterCodeEq?: Array<string>, filterCodeNeq?: Array<string>, filterCategoryEq?: Array<'CATEGORY_NONE' | 'CATEGORY_SALE' | 'CATEGORY_SERVICE' | 'CATEGORY_OTHER' | 'CATEGORY_FEEDBACK' | 'CATEGORY_EVENT' | 'CATEGORY_HOTLINE' | 'CATEGORY_SUBSCRIBE' | 'CATEGORY_FLEET'>, filterCategoryNeq?: Array<'CATEGORY_NONE' | 'CATEGORY_SALE' | 'CATEGORY_SERVICE' | 'CATEGORY_OTHER' | 'CATEGORY_FEEDBACK' | 'CATEGORY_EVENT' | 'CATEGORY_HOTLINE' | 'CATEGORY_SUBSCRIBE' | 'CATEGORY_FLEET'>, filterNameEq?: Array<string>, filterNameNeq?: Array<string>, pageSize?: number, pageToken?: string, sort?: Array<'INTEREST_SORT_NONE' | 'INTEREST_SORT_NAME_ASC' | 'INTEREST_SORT_NAME_DESC' | 'INTEREST_SORT_CODE_ASC' | 'INTEREST_SORT_CODE_DESC'>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/leads/interests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filterIdEq) {
                localVarQueryParameter['filter.id.eq'] = filterIdEq;
            }

            if (filterIdNeq) {
                localVarQueryParameter['filter.id.neq'] = filterIdNeq;
            }

            if (filterCodeEq) {
                localVarQueryParameter['filter.code.eq'] = filterCodeEq;
            }

            if (filterCodeNeq) {
                localVarQueryParameter['filter.code.neq'] = filterCodeNeq;
            }

            if (filterCategoryEq) {
                localVarQueryParameter['filter.category.eq'] = filterCategoryEq;
            }

            if (filterCategoryNeq) {
                localVarQueryParameter['filter.category.neq'] = filterCategoryNeq;
            }

            if (filterNameEq) {
                localVarQueryParameter['filter.name.eq'] = filterNameEq;
            }

            if (filterNameNeq) {
                localVarQueryParameter['filter.name.neq'] = filterNameNeq;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page.size'] = pageSize;
            }

            if (pageToken !== undefined) {
                localVarQueryParameter['page.token'] = pageToken;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns lead channels with the given criteria.
         * @summary Get lead channels
         * @param {Array<string>} [filterIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<string>} [filterIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<string>} [filterCodeEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {Array<string>} [filterCodeNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {number} [pageSize] The maximum number of resources to return in a single page.
         * @param {string} [pageToken] A previously returned token to get the next page.
         * @param {Array<'LEAD_CHANNEL_SORT_NONE' | 'LEAD_CHANNEL_SORT_NAME_ASC' | 'LEAD_CHANNEL_SORT_NAME_DESC'>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsAPIGetLeadChannel: async (filterIdEq?: Array<string>, filterIdNeq?: Array<string>, filterCodeEq?: Array<string>, filterCodeNeq?: Array<string>, pageSize?: number, pageToken?: string, sort?: Array<'LEAD_CHANNEL_SORT_NONE' | 'LEAD_CHANNEL_SORT_NAME_ASC' | 'LEAD_CHANNEL_SORT_NAME_DESC'>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/leads/lead_channels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filterIdEq) {
                localVarQueryParameter['filter.id.eq'] = filterIdEq;
            }

            if (filterIdNeq) {
                localVarQueryParameter['filter.id.neq'] = filterIdNeq;
            }

            if (filterCodeEq) {
                localVarQueryParameter['filter.code.eq'] = filterCodeEq;
            }

            if (filterCodeNeq) {
                localVarQueryParameter['filter.code.neq'] = filterCodeNeq;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page.size'] = pageSize;
            }

            if (pageToken !== undefined) {
                localVarQueryParameter['page.token'] = pageToken;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns lead sources with the given criteria.
         * @summary Get lead sources
         * @param {Array<string>} [filterIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<string>} [filterIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<string>} [filterCodeEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {Array<string>} [filterCodeNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {number} [pageSize] The maximum number of resources to return in a single page.
         * @param {string} [pageToken] A previously returned token to get the next page.
         * @param {Array<'LEAD_SOURCE_SORT_NONE' | 'LEAD_SOURCE_SORT_NAME_ASC' | 'LEAD_SOURCE_SORT_NAME_DESC'>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsAPIGetLeadSource: async (filterIdEq?: Array<string>, filterIdNeq?: Array<string>, filterCodeEq?: Array<string>, filterCodeNeq?: Array<string>, pageSize?: number, pageToken?: string, sort?: Array<'LEAD_SOURCE_SORT_NONE' | 'LEAD_SOURCE_SORT_NAME_ASC' | 'LEAD_SOURCE_SORT_NAME_DESC'>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/leads/lead_sources`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filterIdEq) {
                localVarQueryParameter['filter.id.eq'] = filterIdEq;
            }

            if (filterIdNeq) {
                localVarQueryParameter['filter.id.neq'] = filterIdNeq;
            }

            if (filterCodeEq) {
                localVarQueryParameter['filter.code.eq'] = filterCodeEq;
            }

            if (filterCodeNeq) {
                localVarQueryParameter['filter.code.neq'] = filterCodeNeq;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page.size'] = pageSize;
            }

            if (pageToken !== undefined) {
                localVarQueryParameter['page.token'] = pageToken;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LeadsAPIApi - functional programming interface
 * @export
 */
export const LeadsAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LeadsAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns campaigns with the given criteria.
         * @summary Get campaigns
         * @param {Array<string>} [filterIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<string>} [filterIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<string>} [filterCodeNameEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {Array<string>} [filterCodeNameNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {Array<string>} [filterWebNameEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {Array<string>} [filterWebNameNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {Array<'STATUS_NONE' | 'STATUS_ACTIVE' | 'STATUS_DISABLED' | 'STATUS_ARCHIVED'>} [filterActivityEq] The \&quot;equality\&quot; condition of the activity. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<'STATUS_NONE' | 'STATUS_ACTIVE' | 'STATUS_DISABLED' | 'STATUS_ARCHIVED'>} [filterActivityNeq] The \&quot;inequality\&quot; condition of the activity. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>} [filterBrandIdEq] 
         * @param {Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>} [filterBrandIdNeq] 
         * @param {number} [pageSize] The maximum number of resources to return in a single page.
         * @param {string} [pageToken] A previously returned token to get the next page.
         * @param {Array<'CAMPAIGN_SORT_NONE' | 'CAMPAIGN_SORT_NAME_ASC' | 'CAMPAIGN_SORT_NAME_DESC'>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async leadsAPIGetCampaign(filterIdEq?: Array<string>, filterIdNeq?: Array<string>, filterCodeNameEq?: Array<string>, filterCodeNameNeq?: Array<string>, filterWebNameEq?: Array<string>, filterWebNameNeq?: Array<string>, filterActivityEq?: Array<'STATUS_NONE' | 'STATUS_ACTIVE' | 'STATUS_DISABLED' | 'STATUS_ARCHIVED'>, filterActivityNeq?: Array<'STATUS_NONE' | 'STATUS_ACTIVE' | 'STATUS_DISABLED' | 'STATUS_ARCHIVED'>, filterBrandIdEq?: Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>, filterBrandIdNeq?: Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>, pageSize?: number, pageToken?: string, sort?: Array<'CAMPAIGN_SORT_NONE' | 'CAMPAIGN_SORT_NAME_ASC' | 'CAMPAIGN_SORT_NAME_DESC'>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadsFormsServerV1GetCampaignResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.leadsAPIGetCampaign(filterIdEq, filterIdNeq, filterCodeNameEq, filterCodeNameNeq, filterWebNameEq, filterWebNameNeq, filterActivityEq, filterActivityNeq, filterBrandIdEq, filterBrandIdNeq, pageSize, pageToken, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns interests with the given criteria.
         * @summary Get interests
         * @param {Array<string>} [filterIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<string>} [filterIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<string>} [filterCodeEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {Array<string>} [filterCodeNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {Array<'CATEGORY_NONE' | 'CATEGORY_SALE' | 'CATEGORY_SERVICE' | 'CATEGORY_OTHER' | 'CATEGORY_FEEDBACK' | 'CATEGORY_EVENT' | 'CATEGORY_HOTLINE' | 'CATEGORY_SUBSCRIBE' | 'CATEGORY_FLEET'>} [filterCategoryEq] 
         * @param {Array<'CATEGORY_NONE' | 'CATEGORY_SALE' | 'CATEGORY_SERVICE' | 'CATEGORY_OTHER' | 'CATEGORY_FEEDBACK' | 'CATEGORY_EVENT' | 'CATEGORY_HOTLINE' | 'CATEGORY_SUBSCRIBE' | 'CATEGORY_FLEET'>} [filterCategoryNeq] 
         * @param {Array<string>} [filterNameEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {Array<string>} [filterNameNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {number} [pageSize] The maximum number of resources to return in a single page.
         * @param {string} [pageToken] A previously returned token to get the next page.
         * @param {Array<'INTEREST_SORT_NONE' | 'INTEREST_SORT_NAME_ASC' | 'INTEREST_SORT_NAME_DESC' | 'INTEREST_SORT_CODE_ASC' | 'INTEREST_SORT_CODE_DESC'>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async leadsAPIGetInterest(filterIdEq?: Array<string>, filterIdNeq?: Array<string>, filterCodeEq?: Array<string>, filterCodeNeq?: Array<string>, filterCategoryEq?: Array<'CATEGORY_NONE' | 'CATEGORY_SALE' | 'CATEGORY_SERVICE' | 'CATEGORY_OTHER' | 'CATEGORY_FEEDBACK' | 'CATEGORY_EVENT' | 'CATEGORY_HOTLINE' | 'CATEGORY_SUBSCRIBE' | 'CATEGORY_FLEET'>, filterCategoryNeq?: Array<'CATEGORY_NONE' | 'CATEGORY_SALE' | 'CATEGORY_SERVICE' | 'CATEGORY_OTHER' | 'CATEGORY_FEEDBACK' | 'CATEGORY_EVENT' | 'CATEGORY_HOTLINE' | 'CATEGORY_SUBSCRIBE' | 'CATEGORY_FLEET'>, filterNameEq?: Array<string>, filterNameNeq?: Array<string>, pageSize?: number, pageToken?: string, sort?: Array<'INTEREST_SORT_NONE' | 'INTEREST_SORT_NAME_ASC' | 'INTEREST_SORT_NAME_DESC' | 'INTEREST_SORT_CODE_ASC' | 'INTEREST_SORT_CODE_DESC'>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadsFormsServerV1GetInterestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.leadsAPIGetInterest(filterIdEq, filterIdNeq, filterCodeEq, filterCodeNeq, filterCategoryEq, filterCategoryNeq, filterNameEq, filterNameNeq, pageSize, pageToken, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns lead channels with the given criteria.
         * @summary Get lead channels
         * @param {Array<string>} [filterIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<string>} [filterIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<string>} [filterCodeEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {Array<string>} [filterCodeNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {number} [pageSize] The maximum number of resources to return in a single page.
         * @param {string} [pageToken] A previously returned token to get the next page.
         * @param {Array<'LEAD_CHANNEL_SORT_NONE' | 'LEAD_CHANNEL_SORT_NAME_ASC' | 'LEAD_CHANNEL_SORT_NAME_DESC'>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async leadsAPIGetLeadChannel(filterIdEq?: Array<string>, filterIdNeq?: Array<string>, filterCodeEq?: Array<string>, filterCodeNeq?: Array<string>, pageSize?: number, pageToken?: string, sort?: Array<'LEAD_CHANNEL_SORT_NONE' | 'LEAD_CHANNEL_SORT_NAME_ASC' | 'LEAD_CHANNEL_SORT_NAME_DESC'>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadsFormsServerV1GetLeadChannelResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.leadsAPIGetLeadChannel(filterIdEq, filterIdNeq, filterCodeEq, filterCodeNeq, pageSize, pageToken, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns lead sources with the given criteria.
         * @summary Get lead sources
         * @param {Array<string>} [filterIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<string>} [filterIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<string>} [filterCodeEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {Array<string>} [filterCodeNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {number} [pageSize] The maximum number of resources to return in a single page.
         * @param {string} [pageToken] A previously returned token to get the next page.
         * @param {Array<'LEAD_SOURCE_SORT_NONE' | 'LEAD_SOURCE_SORT_NAME_ASC' | 'LEAD_SOURCE_SORT_NAME_DESC'>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async leadsAPIGetLeadSource(filterIdEq?: Array<string>, filterIdNeq?: Array<string>, filterCodeEq?: Array<string>, filterCodeNeq?: Array<string>, pageSize?: number, pageToken?: string, sort?: Array<'LEAD_SOURCE_SORT_NONE' | 'LEAD_SOURCE_SORT_NAME_ASC' | 'LEAD_SOURCE_SORT_NAME_DESC'>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadsFormsServerV1GetLeadSourceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.leadsAPIGetLeadSource(filterIdEq, filterIdNeq, filterCodeEq, filterCodeNeq, pageSize, pageToken, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LeadsAPIApi - factory interface
 * @export
 */
export const LeadsAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LeadsAPIApiFp(configuration)
    return {
        /**
         * Returns campaigns with the given criteria.
         * @summary Get campaigns
         * @param {Array<string>} [filterIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<string>} [filterIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<string>} [filterCodeNameEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {Array<string>} [filterCodeNameNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {Array<string>} [filterWebNameEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {Array<string>} [filterWebNameNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {Array<'STATUS_NONE' | 'STATUS_ACTIVE' | 'STATUS_DISABLED' | 'STATUS_ARCHIVED'>} [filterActivityEq] The \&quot;equality\&quot; condition of the activity. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<'STATUS_NONE' | 'STATUS_ACTIVE' | 'STATUS_DISABLED' | 'STATUS_ARCHIVED'>} [filterActivityNeq] The \&quot;inequality\&quot; condition of the activity. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>} [filterBrandIdEq] 
         * @param {Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>} [filterBrandIdNeq] 
         * @param {number} [pageSize] The maximum number of resources to return in a single page.
         * @param {string} [pageToken] A previously returned token to get the next page.
         * @param {Array<'CAMPAIGN_SORT_NONE' | 'CAMPAIGN_SORT_NAME_ASC' | 'CAMPAIGN_SORT_NAME_DESC'>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsAPIGetCampaign(filterIdEq?: Array<string>, filterIdNeq?: Array<string>, filterCodeNameEq?: Array<string>, filterCodeNameNeq?: Array<string>, filterWebNameEq?: Array<string>, filterWebNameNeq?: Array<string>, filterActivityEq?: Array<'STATUS_NONE' | 'STATUS_ACTIVE' | 'STATUS_DISABLED' | 'STATUS_ARCHIVED'>, filterActivityNeq?: Array<'STATUS_NONE' | 'STATUS_ACTIVE' | 'STATUS_DISABLED' | 'STATUS_ARCHIVED'>, filterBrandIdEq?: Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>, filterBrandIdNeq?: Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>, pageSize?: number, pageToken?: string, sort?: Array<'CAMPAIGN_SORT_NONE' | 'CAMPAIGN_SORT_NAME_ASC' | 'CAMPAIGN_SORT_NAME_DESC'>, options?: any): AxiosPromise<LeadsFormsServerV1GetCampaignResponse> {
            return localVarFp.leadsAPIGetCampaign(filterIdEq, filterIdNeq, filterCodeNameEq, filterCodeNameNeq, filterWebNameEq, filterWebNameNeq, filterActivityEq, filterActivityNeq, filterBrandIdEq, filterBrandIdNeq, pageSize, pageToken, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns interests with the given criteria.
         * @summary Get interests
         * @param {Array<string>} [filterIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<string>} [filterIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<string>} [filterCodeEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {Array<string>} [filterCodeNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {Array<'CATEGORY_NONE' | 'CATEGORY_SALE' | 'CATEGORY_SERVICE' | 'CATEGORY_OTHER' | 'CATEGORY_FEEDBACK' | 'CATEGORY_EVENT' | 'CATEGORY_HOTLINE' | 'CATEGORY_SUBSCRIBE' | 'CATEGORY_FLEET'>} [filterCategoryEq] 
         * @param {Array<'CATEGORY_NONE' | 'CATEGORY_SALE' | 'CATEGORY_SERVICE' | 'CATEGORY_OTHER' | 'CATEGORY_FEEDBACK' | 'CATEGORY_EVENT' | 'CATEGORY_HOTLINE' | 'CATEGORY_SUBSCRIBE' | 'CATEGORY_FLEET'>} [filterCategoryNeq] 
         * @param {Array<string>} [filterNameEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {Array<string>} [filterNameNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {number} [pageSize] The maximum number of resources to return in a single page.
         * @param {string} [pageToken] A previously returned token to get the next page.
         * @param {Array<'INTEREST_SORT_NONE' | 'INTEREST_SORT_NAME_ASC' | 'INTEREST_SORT_NAME_DESC' | 'INTEREST_SORT_CODE_ASC' | 'INTEREST_SORT_CODE_DESC'>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsAPIGetInterest(filterIdEq?: Array<string>, filterIdNeq?: Array<string>, filterCodeEq?: Array<string>, filterCodeNeq?: Array<string>, filterCategoryEq?: Array<'CATEGORY_NONE' | 'CATEGORY_SALE' | 'CATEGORY_SERVICE' | 'CATEGORY_OTHER' | 'CATEGORY_FEEDBACK' | 'CATEGORY_EVENT' | 'CATEGORY_HOTLINE' | 'CATEGORY_SUBSCRIBE' | 'CATEGORY_FLEET'>, filterCategoryNeq?: Array<'CATEGORY_NONE' | 'CATEGORY_SALE' | 'CATEGORY_SERVICE' | 'CATEGORY_OTHER' | 'CATEGORY_FEEDBACK' | 'CATEGORY_EVENT' | 'CATEGORY_HOTLINE' | 'CATEGORY_SUBSCRIBE' | 'CATEGORY_FLEET'>, filterNameEq?: Array<string>, filterNameNeq?: Array<string>, pageSize?: number, pageToken?: string, sort?: Array<'INTEREST_SORT_NONE' | 'INTEREST_SORT_NAME_ASC' | 'INTEREST_SORT_NAME_DESC' | 'INTEREST_SORT_CODE_ASC' | 'INTEREST_SORT_CODE_DESC'>, options?: any): AxiosPromise<LeadsFormsServerV1GetInterestResponse> {
            return localVarFp.leadsAPIGetInterest(filterIdEq, filterIdNeq, filterCodeEq, filterCodeNeq, filterCategoryEq, filterCategoryNeq, filterNameEq, filterNameNeq, pageSize, pageToken, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns lead channels with the given criteria.
         * @summary Get lead channels
         * @param {Array<string>} [filterIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<string>} [filterIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<string>} [filterCodeEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {Array<string>} [filterCodeNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {number} [pageSize] The maximum number of resources to return in a single page.
         * @param {string} [pageToken] A previously returned token to get the next page.
         * @param {Array<'LEAD_CHANNEL_SORT_NONE' | 'LEAD_CHANNEL_SORT_NAME_ASC' | 'LEAD_CHANNEL_SORT_NAME_DESC'>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsAPIGetLeadChannel(filterIdEq?: Array<string>, filterIdNeq?: Array<string>, filterCodeEq?: Array<string>, filterCodeNeq?: Array<string>, pageSize?: number, pageToken?: string, sort?: Array<'LEAD_CHANNEL_SORT_NONE' | 'LEAD_CHANNEL_SORT_NAME_ASC' | 'LEAD_CHANNEL_SORT_NAME_DESC'>, options?: any): AxiosPromise<LeadsFormsServerV1GetLeadChannelResponse> {
            return localVarFp.leadsAPIGetLeadChannel(filterIdEq, filterIdNeq, filterCodeEq, filterCodeNeq, pageSize, pageToken, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns lead sources with the given criteria.
         * @summary Get lead sources
         * @param {Array<string>} [filterIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<string>} [filterIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<string>} [filterCodeEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {Array<string>} [filterCodeNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {number} [pageSize] The maximum number of resources to return in a single page.
         * @param {string} [pageToken] A previously returned token to get the next page.
         * @param {Array<'LEAD_SOURCE_SORT_NONE' | 'LEAD_SOURCE_SORT_NAME_ASC' | 'LEAD_SOURCE_SORT_NAME_DESC'>} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsAPIGetLeadSource(filterIdEq?: Array<string>, filterIdNeq?: Array<string>, filterCodeEq?: Array<string>, filterCodeNeq?: Array<string>, pageSize?: number, pageToken?: string, sort?: Array<'LEAD_SOURCE_SORT_NONE' | 'LEAD_SOURCE_SORT_NAME_ASC' | 'LEAD_SOURCE_SORT_NAME_DESC'>, options?: any): AxiosPromise<LeadsFormsServerV1GetLeadSourceResponse> {
            return localVarFp.leadsAPIGetLeadSource(filterIdEq, filterIdNeq, filterCodeEq, filterCodeNeq, pageSize, pageToken, sort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LeadsAPIApi - object-oriented interface
 * @export
 * @class LeadsAPIApi
 * @extends {BaseAPI}
 */
export class LeadsAPIApi extends BaseAPI {
    /**
     * Returns campaigns with the given criteria.
     * @summary Get campaigns
     * @param {Array<string>} [filterIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
     * @param {Array<string>} [filterIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
     * @param {Array<string>} [filterCodeNameEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
     * @param {Array<string>} [filterCodeNameNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
     * @param {Array<string>} [filterWebNameEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
     * @param {Array<string>} [filterWebNameNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
     * @param {Array<'STATUS_NONE' | 'STATUS_ACTIVE' | 'STATUS_DISABLED' | 'STATUS_ARCHIVED'>} [filterActivityEq] The \&quot;equality\&quot; condition of the activity. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
     * @param {Array<'STATUS_NONE' | 'STATUS_ACTIVE' | 'STATUS_DISABLED' | 'STATUS_ARCHIVED'>} [filterActivityNeq] The \&quot;inequality\&quot; condition of the activity. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
     * @param {Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>} [filterBrandIdEq] 
     * @param {Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>} [filterBrandIdNeq] 
     * @param {number} [pageSize] The maximum number of resources to return in a single page.
     * @param {string} [pageToken] A previously returned token to get the next page.
     * @param {Array<'CAMPAIGN_SORT_NONE' | 'CAMPAIGN_SORT_NAME_ASC' | 'CAMPAIGN_SORT_NAME_DESC'>} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadsAPIApi
     */
    public leadsAPIGetCampaign(filterIdEq?: Array<string>, filterIdNeq?: Array<string>, filterCodeNameEq?: Array<string>, filterCodeNameNeq?: Array<string>, filterWebNameEq?: Array<string>, filterWebNameNeq?: Array<string>, filterActivityEq?: Array<'STATUS_NONE' | 'STATUS_ACTIVE' | 'STATUS_DISABLED' | 'STATUS_ARCHIVED'>, filterActivityNeq?: Array<'STATUS_NONE' | 'STATUS_ACTIVE' | 'STATUS_DISABLED' | 'STATUS_ARCHIVED'>, filterBrandIdEq?: Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>, filterBrandIdNeq?: Array<'BRAND_NONE' | 'BRAND_AUDI' | 'BRAND_DUCATI' | 'BRAND_NFZ' | 'BRAND_PKW' | 'BRAND_SKODA'>, pageSize?: number, pageToken?: string, sort?: Array<'CAMPAIGN_SORT_NONE' | 'CAMPAIGN_SORT_NAME_ASC' | 'CAMPAIGN_SORT_NAME_DESC'>, options?: any) {
        return LeadsAPIApiFp(this.configuration).leadsAPIGetCampaign(filterIdEq, filterIdNeq, filterCodeNameEq, filterCodeNameNeq, filterWebNameEq, filterWebNameNeq, filterActivityEq, filterActivityNeq, filterBrandIdEq, filterBrandIdNeq, pageSize, pageToken, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns interests with the given criteria.
     * @summary Get interests
     * @param {Array<string>} [filterIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
     * @param {Array<string>} [filterIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
     * @param {Array<string>} [filterCodeEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
     * @param {Array<string>} [filterCodeNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
     * @param {Array<'CATEGORY_NONE' | 'CATEGORY_SALE' | 'CATEGORY_SERVICE' | 'CATEGORY_OTHER' | 'CATEGORY_FEEDBACK' | 'CATEGORY_EVENT' | 'CATEGORY_HOTLINE' | 'CATEGORY_SUBSCRIBE' | 'CATEGORY_FLEET'>} [filterCategoryEq] 
     * @param {Array<'CATEGORY_NONE' | 'CATEGORY_SALE' | 'CATEGORY_SERVICE' | 'CATEGORY_OTHER' | 'CATEGORY_FEEDBACK' | 'CATEGORY_EVENT' | 'CATEGORY_HOTLINE' | 'CATEGORY_SUBSCRIBE' | 'CATEGORY_FLEET'>} [filterCategoryNeq] 
     * @param {Array<string>} [filterNameEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
     * @param {Array<string>} [filterNameNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
     * @param {number} [pageSize] The maximum number of resources to return in a single page.
     * @param {string} [pageToken] A previously returned token to get the next page.
     * @param {Array<'INTEREST_SORT_NONE' | 'INTEREST_SORT_NAME_ASC' | 'INTEREST_SORT_NAME_DESC' | 'INTEREST_SORT_CODE_ASC' | 'INTEREST_SORT_CODE_DESC'>} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadsAPIApi
     */
    public leadsAPIGetInterest(filterIdEq?: Array<string>, filterIdNeq?: Array<string>, filterCodeEq?: Array<string>, filterCodeNeq?: Array<string>, filterCategoryEq?: Array<'CATEGORY_NONE' | 'CATEGORY_SALE' | 'CATEGORY_SERVICE' | 'CATEGORY_OTHER' | 'CATEGORY_FEEDBACK' | 'CATEGORY_EVENT' | 'CATEGORY_HOTLINE' | 'CATEGORY_SUBSCRIBE' | 'CATEGORY_FLEET'>, filterCategoryNeq?: Array<'CATEGORY_NONE' | 'CATEGORY_SALE' | 'CATEGORY_SERVICE' | 'CATEGORY_OTHER' | 'CATEGORY_FEEDBACK' | 'CATEGORY_EVENT' | 'CATEGORY_HOTLINE' | 'CATEGORY_SUBSCRIBE' | 'CATEGORY_FLEET'>, filterNameEq?: Array<string>, filterNameNeq?: Array<string>, pageSize?: number, pageToken?: string, sort?: Array<'INTEREST_SORT_NONE' | 'INTEREST_SORT_NAME_ASC' | 'INTEREST_SORT_NAME_DESC' | 'INTEREST_SORT_CODE_ASC' | 'INTEREST_SORT_CODE_DESC'>, options?: any) {
        return LeadsAPIApiFp(this.configuration).leadsAPIGetInterest(filterIdEq, filterIdNeq, filterCodeEq, filterCodeNeq, filterCategoryEq, filterCategoryNeq, filterNameEq, filterNameNeq, pageSize, pageToken, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns lead channels with the given criteria.
     * @summary Get lead channels
     * @param {Array<string>} [filterIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
     * @param {Array<string>} [filterIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
     * @param {Array<string>} [filterCodeEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
     * @param {Array<string>} [filterCodeNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
     * @param {number} [pageSize] The maximum number of resources to return in a single page.
     * @param {string} [pageToken] A previously returned token to get the next page.
     * @param {Array<'LEAD_CHANNEL_SORT_NONE' | 'LEAD_CHANNEL_SORT_NAME_ASC' | 'LEAD_CHANNEL_SORT_NAME_DESC'>} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadsAPIApi
     */
    public leadsAPIGetLeadChannel(filterIdEq?: Array<string>, filterIdNeq?: Array<string>, filterCodeEq?: Array<string>, filterCodeNeq?: Array<string>, pageSize?: number, pageToken?: string, sort?: Array<'LEAD_CHANNEL_SORT_NONE' | 'LEAD_CHANNEL_SORT_NAME_ASC' | 'LEAD_CHANNEL_SORT_NAME_DESC'>, options?: any) {
        return LeadsAPIApiFp(this.configuration).leadsAPIGetLeadChannel(filterIdEq, filterIdNeq, filterCodeEq, filterCodeNeq, pageSize, pageToken, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns lead sources with the given criteria.
     * @summary Get lead sources
     * @param {Array<string>} [filterIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
     * @param {Array<string>} [filterIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
     * @param {Array<string>} [filterCodeEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
     * @param {Array<string>} [filterCodeNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
     * @param {number} [pageSize] The maximum number of resources to return in a single page.
     * @param {string} [pageToken] A previously returned token to get the next page.
     * @param {Array<'LEAD_SOURCE_SORT_NONE' | 'LEAD_SOURCE_SORT_NAME_ASC' | 'LEAD_SOURCE_SORT_NAME_DESC'>} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadsAPIApi
     */
    public leadsAPIGetLeadSource(filterIdEq?: Array<string>, filterIdNeq?: Array<string>, filterCodeEq?: Array<string>, filterCodeNeq?: Array<string>, pageSize?: number, pageToken?: string, sort?: Array<'LEAD_SOURCE_SORT_NONE' | 'LEAD_SOURCE_SORT_NAME_ASC' | 'LEAD_SOURCE_SORT_NAME_DESC'>, options?: any) {
        return LeadsAPIApiFp(this.configuration).leadsAPIGetLeadSource(filterIdEq, filterIdNeq, filterCodeEq, filterCodeNeq, pageSize, pageToken, sort, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MediaAPIApi - axios parameter creator
 * @export
 */
export const MediaAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Load image and returns image identifier
         * @summary Load image
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mediaAPICreateImage: async (file?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/media`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MediaAPIApi - functional programming interface
 * @export
 */
export const MediaAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MediaAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * Load image and returns image identifier
         * @summary Load image
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mediaAPICreateImage(file?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mediaAPICreateImage(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MediaAPIApi - factory interface
 * @export
 */
export const MediaAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MediaAPIApiFp(configuration)
    return {
        /**
         * Load image and returns image identifier
         * @summary Load image
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mediaAPICreateImage(file?: any, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.mediaAPICreateImage(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MediaAPIApi - object-oriented interface
 * @export
 * @class MediaAPIApi
 * @extends {BaseAPI}
 */
export class MediaAPIApi extends BaseAPI {
    /**
     * Load image and returns image identifier
     * @summary Load image
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaAPIApi
     */
    public mediaAPICreateImage(file?: any, options?: any) {
        return MediaAPIApiFp(this.configuration).mediaAPICreateImage(file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ModelsAPIApi - axios parameter creator
 * @export
 */
export const ModelsAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns brands with the given criteria.
         * @summary Get brands
         * @param {Array<string>} [filterIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<string>} [filterIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<string>} [filterMarketIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<string>} [filterMarketIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<'BRAND_SORT_NONE' | 'BRAND_SORT_NAME_ASC' | 'BRAND_SORT_NAME_DESC'>} [sort] 
         * @param {number} [pageSize] The maximum number of resources to return in a single page.
         * @param {string} [pageToken] A previously returned token to get the next page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelsAPIGetBrand: async (filterIdEq?: Array<string>, filterIdNeq?: Array<string>, filterMarketIdEq?: Array<string>, filterMarketIdNeq?: Array<string>, sort?: Array<'BRAND_SORT_NONE' | 'BRAND_SORT_NAME_ASC' | 'BRAND_SORT_NAME_DESC'>, pageSize?: number, pageToken?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/models/brands`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filterIdEq) {
                localVarQueryParameter['filter.id.eq'] = filterIdEq;
            }

            if (filterIdNeq) {
                localVarQueryParameter['filter.id.neq'] = filterIdNeq;
            }

            if (filterMarketIdEq) {
                localVarQueryParameter['filter.marketId.eq'] = filterMarketIdEq;
            }

            if (filterMarketIdNeq) {
                localVarQueryParameter['filter.marketId.neq'] = filterMarketIdNeq;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page.size'] = pageSize;
            }

            if (pageToken !== undefined) {
                localVarQueryParameter['page.token'] = pageToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns generations with the given criteria.
         * @summary Get generations
         * @param {Array<string>} [filterIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<string>} [filterIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<string>} [filterModelIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<string>} [filterModelIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<'GENERATION_SORT_NONE' | 'GENERATION_SORT_NAME_ASC' | 'GENERATION_SORT_NAME_DESC'>} [sort] 
         * @param {number} [pageSize] The maximum number of resources to return in a single page.
         * @param {string} [pageToken] A previously returned token to get the next page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelsAPIGetGeneration: async (filterIdEq?: Array<string>, filterIdNeq?: Array<string>, filterModelIdEq?: Array<string>, filterModelIdNeq?: Array<string>, sort?: Array<'GENERATION_SORT_NONE' | 'GENERATION_SORT_NAME_ASC' | 'GENERATION_SORT_NAME_DESC'>, pageSize?: number, pageToken?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/models/generations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filterIdEq) {
                localVarQueryParameter['filter.id.eq'] = filterIdEq;
            }

            if (filterIdNeq) {
                localVarQueryParameter['filter.id.neq'] = filterIdNeq;
            }

            if (filterModelIdEq) {
                localVarQueryParameter['filter.modelId.eq'] = filterModelIdEq;
            }

            if (filterModelIdNeq) {
                localVarQueryParameter['filter.modelId.neq'] = filterModelIdNeq;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page.size'] = pageSize;
            }

            if (pageToken !== undefined) {
                localVarQueryParameter['page.token'] = pageToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns markets with the given criteria.
         * @summary Get markets
         * @param {Array<string>} [filterIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<string>} [filterIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<'MARKET_SORT_NONE' | 'MARKET_SORT_NAME_ASC' | 'MARKET_SORT_NAME_DESC'>} [sort] 
         * @param {number} [pageSize] The maximum number of resources to return in a single page.
         * @param {string} [pageToken] A previously returned token to get the next page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelsAPIGetMarket: async (filterIdEq?: Array<string>, filterIdNeq?: Array<string>, sort?: Array<'MARKET_SORT_NONE' | 'MARKET_SORT_NAME_ASC' | 'MARKET_SORT_NAME_DESC'>, pageSize?: number, pageToken?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/models/markets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filterIdEq) {
                localVarQueryParameter['filter.id.eq'] = filterIdEq;
            }

            if (filterIdNeq) {
                localVarQueryParameter['filter.id.neq'] = filterIdNeq;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page.size'] = pageSize;
            }

            if (pageToken !== undefined) {
                localVarQueryParameter['page.token'] = pageToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns models with the given criteria.
         * @summary Get models
         * @param {Array<string>} [filterIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<string>} [filterIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<string>} [filterBrandIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<string>} [filterBrandIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<string>} [filterNameEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {Array<string>} [filterNameNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {Array<'MODEL_SORT_NONE' | 'MODEL_SORT_NAME_ASC' | 'MODEL_SORT_NAME_DESC' | 'MODEL_SORT_BRAND_ASC' | 'MODEL_SORT_BRAND_DESC'>} [sort] 
         * @param {number} [pageSize] The maximum number of resources to return in a single page.
         * @param {string} [pageToken] A previously returned token to get the next page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelsAPIGetModel: async (filterIdEq?: Array<string>, filterIdNeq?: Array<string>, filterBrandIdEq?: Array<string>, filterBrandIdNeq?: Array<string>, filterNameEq?: Array<string>, filterNameNeq?: Array<string>, sort?: Array<'MODEL_SORT_NONE' | 'MODEL_SORT_NAME_ASC' | 'MODEL_SORT_NAME_DESC' | 'MODEL_SORT_BRAND_ASC' | 'MODEL_SORT_BRAND_DESC'>, pageSize?: number, pageToken?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/models/models`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filterIdEq) {
                localVarQueryParameter['filter.id.eq'] = filterIdEq;
            }

            if (filterIdNeq) {
                localVarQueryParameter['filter.id.neq'] = filterIdNeq;
            }

            if (filterBrandIdEq) {
                localVarQueryParameter['filter.brandId.eq'] = filterBrandIdEq;
            }

            if (filterBrandIdNeq) {
                localVarQueryParameter['filter.brandId.neq'] = filterBrandIdNeq;
            }

            if (filterNameEq) {
                localVarQueryParameter['filter.name.eq'] = filterNameEq;
            }

            if (filterNameNeq) {
                localVarQueryParameter['filter.name.neq'] = filterNameNeq;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page.size'] = pageSize;
            }

            if (pageToken !== undefined) {
                localVarQueryParameter['page.token'] = pageToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates model and returns updated model object
         * @summary Update model
         * @param {string} id 
         * @param {LeadsModelsServerV1ModelProps} body Client-provided properties to update model.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelsAPIUpdateModel: async (id: string, body: LeadsModelsServerV1ModelProps, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('modelsAPIUpdateModel', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('modelsAPIUpdateModel', 'body', body)
            const localVarPath = `/v1/models/models/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ModelsAPIApi - functional programming interface
 * @export
 */
export const ModelsAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ModelsAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns brands with the given criteria.
         * @summary Get brands
         * @param {Array<string>} [filterIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<string>} [filterIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<string>} [filterMarketIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<string>} [filterMarketIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<'BRAND_SORT_NONE' | 'BRAND_SORT_NAME_ASC' | 'BRAND_SORT_NAME_DESC'>} [sort] 
         * @param {number} [pageSize] The maximum number of resources to return in a single page.
         * @param {string} [pageToken] A previously returned token to get the next page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modelsAPIGetBrand(filterIdEq?: Array<string>, filterIdNeq?: Array<string>, filterMarketIdEq?: Array<string>, filterMarketIdNeq?: Array<string>, sort?: Array<'BRAND_SORT_NONE' | 'BRAND_SORT_NAME_ASC' | 'BRAND_SORT_NAME_DESC'>, pageSize?: number, pageToken?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadsModelsServerV1GetBrandResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modelsAPIGetBrand(filterIdEq, filterIdNeq, filterMarketIdEq, filterMarketIdNeq, sort, pageSize, pageToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns generations with the given criteria.
         * @summary Get generations
         * @param {Array<string>} [filterIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<string>} [filterIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<string>} [filterModelIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<string>} [filterModelIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<'GENERATION_SORT_NONE' | 'GENERATION_SORT_NAME_ASC' | 'GENERATION_SORT_NAME_DESC'>} [sort] 
         * @param {number} [pageSize] The maximum number of resources to return in a single page.
         * @param {string} [pageToken] A previously returned token to get the next page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modelsAPIGetGeneration(filterIdEq?: Array<string>, filterIdNeq?: Array<string>, filterModelIdEq?: Array<string>, filterModelIdNeq?: Array<string>, sort?: Array<'GENERATION_SORT_NONE' | 'GENERATION_SORT_NAME_ASC' | 'GENERATION_SORT_NAME_DESC'>, pageSize?: number, pageToken?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadsModelsServerV1GetGenerationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modelsAPIGetGeneration(filterIdEq, filterIdNeq, filterModelIdEq, filterModelIdNeq, sort, pageSize, pageToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns markets with the given criteria.
         * @summary Get markets
         * @param {Array<string>} [filterIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<string>} [filterIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<'MARKET_SORT_NONE' | 'MARKET_SORT_NAME_ASC' | 'MARKET_SORT_NAME_DESC'>} [sort] 
         * @param {number} [pageSize] The maximum number of resources to return in a single page.
         * @param {string} [pageToken] A previously returned token to get the next page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modelsAPIGetMarket(filterIdEq?: Array<string>, filterIdNeq?: Array<string>, sort?: Array<'MARKET_SORT_NONE' | 'MARKET_SORT_NAME_ASC' | 'MARKET_SORT_NAME_DESC'>, pageSize?: number, pageToken?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadsModelsServerV1GetMarketResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modelsAPIGetMarket(filterIdEq, filterIdNeq, sort, pageSize, pageToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns models with the given criteria.
         * @summary Get models
         * @param {Array<string>} [filterIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<string>} [filterIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<string>} [filterBrandIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<string>} [filterBrandIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<string>} [filterNameEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {Array<string>} [filterNameNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {Array<'MODEL_SORT_NONE' | 'MODEL_SORT_NAME_ASC' | 'MODEL_SORT_NAME_DESC' | 'MODEL_SORT_BRAND_ASC' | 'MODEL_SORT_BRAND_DESC'>} [sort] 
         * @param {number} [pageSize] The maximum number of resources to return in a single page.
         * @param {string} [pageToken] A previously returned token to get the next page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modelsAPIGetModel(filterIdEq?: Array<string>, filterIdNeq?: Array<string>, filterBrandIdEq?: Array<string>, filterBrandIdNeq?: Array<string>, filterNameEq?: Array<string>, filterNameNeq?: Array<string>, sort?: Array<'MODEL_SORT_NONE' | 'MODEL_SORT_NAME_ASC' | 'MODEL_SORT_NAME_DESC' | 'MODEL_SORT_BRAND_ASC' | 'MODEL_SORT_BRAND_DESC'>, pageSize?: number, pageToken?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadsModelsServerV1GetModelResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modelsAPIGetModel(filterIdEq, filterIdNeq, filterBrandIdEq, filterBrandIdNeq, filterNameEq, filterNameNeq, sort, pageSize, pageToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates model and returns updated model object
         * @summary Update model
         * @param {string} id 
         * @param {LeadsModelsServerV1ModelProps} body Client-provided properties to update model.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modelsAPIUpdateModel(id: string, body: LeadsModelsServerV1ModelProps, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadsModelsServerV1Model>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modelsAPIUpdateModel(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ModelsAPIApi - factory interface
 * @export
 */
export const ModelsAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ModelsAPIApiFp(configuration)
    return {
        /**
         * Returns brands with the given criteria.
         * @summary Get brands
         * @param {Array<string>} [filterIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<string>} [filterIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<string>} [filterMarketIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<string>} [filterMarketIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<'BRAND_SORT_NONE' | 'BRAND_SORT_NAME_ASC' | 'BRAND_SORT_NAME_DESC'>} [sort] 
         * @param {number} [pageSize] The maximum number of resources to return in a single page.
         * @param {string} [pageToken] A previously returned token to get the next page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelsAPIGetBrand(filterIdEq?: Array<string>, filterIdNeq?: Array<string>, filterMarketIdEq?: Array<string>, filterMarketIdNeq?: Array<string>, sort?: Array<'BRAND_SORT_NONE' | 'BRAND_SORT_NAME_ASC' | 'BRAND_SORT_NAME_DESC'>, pageSize?: number, pageToken?: string, options?: any): AxiosPromise<LeadsModelsServerV1GetBrandResponse> {
            return localVarFp.modelsAPIGetBrand(filterIdEq, filterIdNeq, filterMarketIdEq, filterMarketIdNeq, sort, pageSize, pageToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns generations with the given criteria.
         * @summary Get generations
         * @param {Array<string>} [filterIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<string>} [filterIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<string>} [filterModelIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<string>} [filterModelIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<'GENERATION_SORT_NONE' | 'GENERATION_SORT_NAME_ASC' | 'GENERATION_SORT_NAME_DESC'>} [sort] 
         * @param {number} [pageSize] The maximum number of resources to return in a single page.
         * @param {string} [pageToken] A previously returned token to get the next page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelsAPIGetGeneration(filterIdEq?: Array<string>, filterIdNeq?: Array<string>, filterModelIdEq?: Array<string>, filterModelIdNeq?: Array<string>, sort?: Array<'GENERATION_SORT_NONE' | 'GENERATION_SORT_NAME_ASC' | 'GENERATION_SORT_NAME_DESC'>, pageSize?: number, pageToken?: string, options?: any): AxiosPromise<LeadsModelsServerV1GetGenerationResponse> {
            return localVarFp.modelsAPIGetGeneration(filterIdEq, filterIdNeq, filterModelIdEq, filterModelIdNeq, sort, pageSize, pageToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns markets with the given criteria.
         * @summary Get markets
         * @param {Array<string>} [filterIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<string>} [filterIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<'MARKET_SORT_NONE' | 'MARKET_SORT_NAME_ASC' | 'MARKET_SORT_NAME_DESC'>} [sort] 
         * @param {number} [pageSize] The maximum number of resources to return in a single page.
         * @param {string} [pageToken] A previously returned token to get the next page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelsAPIGetMarket(filterIdEq?: Array<string>, filterIdNeq?: Array<string>, sort?: Array<'MARKET_SORT_NONE' | 'MARKET_SORT_NAME_ASC' | 'MARKET_SORT_NAME_DESC'>, pageSize?: number, pageToken?: string, options?: any): AxiosPromise<LeadsModelsServerV1GetMarketResponse> {
            return localVarFp.modelsAPIGetMarket(filterIdEq, filterIdNeq, sort, pageSize, pageToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns models with the given criteria.
         * @summary Get models
         * @param {Array<string>} [filterIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<string>} [filterIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<string>} [filterBrandIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
         * @param {Array<string>} [filterBrandIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
         * @param {Array<string>} [filterNameEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {Array<string>} [filterNameNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
         * @param {Array<'MODEL_SORT_NONE' | 'MODEL_SORT_NAME_ASC' | 'MODEL_SORT_NAME_DESC' | 'MODEL_SORT_BRAND_ASC' | 'MODEL_SORT_BRAND_DESC'>} [sort] 
         * @param {number} [pageSize] The maximum number of resources to return in a single page.
         * @param {string} [pageToken] A previously returned token to get the next page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelsAPIGetModel(filterIdEq?: Array<string>, filterIdNeq?: Array<string>, filterBrandIdEq?: Array<string>, filterBrandIdNeq?: Array<string>, filterNameEq?: Array<string>, filterNameNeq?: Array<string>, sort?: Array<'MODEL_SORT_NONE' | 'MODEL_SORT_NAME_ASC' | 'MODEL_SORT_NAME_DESC' | 'MODEL_SORT_BRAND_ASC' | 'MODEL_SORT_BRAND_DESC'>, pageSize?: number, pageToken?: string, options?: any): AxiosPromise<LeadsModelsServerV1GetModelResponse> {
            return localVarFp.modelsAPIGetModel(filterIdEq, filterIdNeq, filterBrandIdEq, filterBrandIdNeq, filterNameEq, filterNameNeq, sort, pageSize, pageToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates model and returns updated model object
         * @summary Update model
         * @param {string} id 
         * @param {LeadsModelsServerV1ModelProps} body Client-provided properties to update model.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelsAPIUpdateModel(id: string, body: LeadsModelsServerV1ModelProps, options?: any): AxiosPromise<LeadsModelsServerV1Model> {
            return localVarFp.modelsAPIUpdateModel(id, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ModelsAPIApi - object-oriented interface
 * @export
 * @class ModelsAPIApi
 * @extends {BaseAPI}
 */
export class ModelsAPIApi extends BaseAPI {
    /**
     * Returns brands with the given criteria.
     * @summary Get brands
     * @param {Array<string>} [filterIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
     * @param {Array<string>} [filterIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
     * @param {Array<string>} [filterMarketIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
     * @param {Array<string>} [filterMarketIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
     * @param {Array<'BRAND_SORT_NONE' | 'BRAND_SORT_NAME_ASC' | 'BRAND_SORT_NAME_DESC'>} [sort] 
     * @param {number} [pageSize] The maximum number of resources to return in a single page.
     * @param {string} [pageToken] A previously returned token to get the next page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelsAPIApi
     */
    public modelsAPIGetBrand(filterIdEq?: Array<string>, filterIdNeq?: Array<string>, filterMarketIdEq?: Array<string>, filterMarketIdNeq?: Array<string>, sort?: Array<'BRAND_SORT_NONE' | 'BRAND_SORT_NAME_ASC' | 'BRAND_SORT_NAME_DESC'>, pageSize?: number, pageToken?: string, options?: any) {
        return ModelsAPIApiFp(this.configuration).modelsAPIGetBrand(filterIdEq, filterIdNeq, filterMarketIdEq, filterMarketIdNeq, sort, pageSize, pageToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns generations with the given criteria.
     * @summary Get generations
     * @param {Array<string>} [filterIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
     * @param {Array<string>} [filterIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
     * @param {Array<string>} [filterModelIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
     * @param {Array<string>} [filterModelIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
     * @param {Array<'GENERATION_SORT_NONE' | 'GENERATION_SORT_NAME_ASC' | 'GENERATION_SORT_NAME_DESC'>} [sort] 
     * @param {number} [pageSize] The maximum number of resources to return in a single page.
     * @param {string} [pageToken] A previously returned token to get the next page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelsAPIApi
     */
    public modelsAPIGetGeneration(filterIdEq?: Array<string>, filterIdNeq?: Array<string>, filterModelIdEq?: Array<string>, filterModelIdNeq?: Array<string>, sort?: Array<'GENERATION_SORT_NONE' | 'GENERATION_SORT_NAME_ASC' | 'GENERATION_SORT_NAME_DESC'>, pageSize?: number, pageToken?: string, options?: any) {
        return ModelsAPIApiFp(this.configuration).modelsAPIGetGeneration(filterIdEq, filterIdNeq, filterModelIdEq, filterModelIdNeq, sort, pageSize, pageToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns markets with the given criteria.
     * @summary Get markets
     * @param {Array<string>} [filterIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
     * @param {Array<string>} [filterIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
     * @param {Array<'MARKET_SORT_NONE' | 'MARKET_SORT_NAME_ASC' | 'MARKET_SORT_NAME_DESC'>} [sort] 
     * @param {number} [pageSize] The maximum number of resources to return in a single page.
     * @param {string} [pageToken] A previously returned token to get the next page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelsAPIApi
     */
    public modelsAPIGetMarket(filterIdEq?: Array<string>, filterIdNeq?: Array<string>, sort?: Array<'MARKET_SORT_NONE' | 'MARKET_SORT_NAME_ASC' | 'MARKET_SORT_NAME_DESC'>, pageSize?: number, pageToken?: string, options?: any) {
        return ModelsAPIApiFp(this.configuration).modelsAPIGetMarket(filterIdEq, filterIdNeq, sort, pageSize, pageToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns models with the given criteria.
     * @summary Get models
     * @param {Array<string>} [filterIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
     * @param {Array<string>} [filterIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
     * @param {Array<string>} [filterBrandIdEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition.
     * @param {Array<string>} [filterBrandIdNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;not in\&quot; condition.
     * @param {Array<string>} [filterNameEq] The \&quot;equality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
     * @param {Array<string>} [filterNameNeq] The \&quot;inequality\&quot; condition. With multiple provided values, it transforms into the \&quot;in\&quot; condition. The given values may contain the following wildcard characters: an asterisk \&quot;*\&quot; matches any sequence of zero or more characters, a question mark \&quot;?\&quot; matches any single character.
     * @param {Array<'MODEL_SORT_NONE' | 'MODEL_SORT_NAME_ASC' | 'MODEL_SORT_NAME_DESC' | 'MODEL_SORT_BRAND_ASC' | 'MODEL_SORT_BRAND_DESC'>} [sort] 
     * @param {number} [pageSize] The maximum number of resources to return in a single page.
     * @param {string} [pageToken] A previously returned token to get the next page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelsAPIApi
     */
    public modelsAPIGetModel(filterIdEq?: Array<string>, filterIdNeq?: Array<string>, filterBrandIdEq?: Array<string>, filterBrandIdNeq?: Array<string>, filterNameEq?: Array<string>, filterNameNeq?: Array<string>, sort?: Array<'MODEL_SORT_NONE' | 'MODEL_SORT_NAME_ASC' | 'MODEL_SORT_NAME_DESC' | 'MODEL_SORT_BRAND_ASC' | 'MODEL_SORT_BRAND_DESC'>, pageSize?: number, pageToken?: string, options?: any) {
        return ModelsAPIApiFp(this.configuration).modelsAPIGetModel(filterIdEq, filterIdNeq, filterBrandIdEq, filterBrandIdNeq, filterNameEq, filterNameNeq, sort, pageSize, pageToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates model and returns updated model object
     * @summary Update model
     * @param {string} id 
     * @param {LeadsModelsServerV1ModelProps} body Client-provided properties to update model.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelsAPIApi
     */
    public modelsAPIUpdateModel(id: string, body: LeadsModelsServerV1ModelProps, options?: any) {
        return ModelsAPIApiFp(this.configuration).modelsAPIUpdateModel(id, body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ShopsterAPIApi - axios parameter creator
 * @export
 */
export const ShopsterAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates shopster form result
         * @summary Create shopster form result
         * @param {LeadsShopsterServerV1ShopsterFormProps} body 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopsterAPIShopsterForm: async (body: LeadsShopsterServerV1ShopsterFormProps, token?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('shopsterAPIShopsterForm', 'body', body)
            const localVarPath = `/v1/shopster`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShopsterAPIApi - functional programming interface
 * @export
 */
export const ShopsterAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShopsterAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates shopster form result
         * @summary Create shopster form result
         * @param {LeadsShopsterServerV1ShopsterFormProps} body 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopsterAPIShopsterForm(body: LeadsShopsterServerV1ShopsterFormProps, token?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopsterAPIShopsterForm(body, token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ShopsterAPIApi - factory interface
 * @export
 */
export const ShopsterAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShopsterAPIApiFp(configuration)
    return {
        /**
         * Creates shopster form result
         * @summary Create shopster form result
         * @param {LeadsShopsterServerV1ShopsterFormProps} body 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopsterAPIShopsterForm(body: LeadsShopsterServerV1ShopsterFormProps, token?: string, options?: any): AxiosPromise<object> {
            return localVarFp.shopsterAPIShopsterForm(body, token, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShopsterAPIApi - object-oriented interface
 * @export
 * @class ShopsterAPIApi
 * @extends {BaseAPI}
 */
export class ShopsterAPIApi extends BaseAPI {
    /**
     * Creates shopster form result
     * @summary Create shopster form result
     * @param {LeadsShopsterServerV1ShopsterFormProps} body 
     * @param {string} [token] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopsterAPIApi
     */
    public shopsterAPIShopsterForm(body: LeadsShopsterServerV1ShopsterFormProps, token?: string, options?: any) {
        return ShopsterAPIApiFp(this.configuration).shopsterAPIShopsterForm(body, token, options).then((request) => request(this.axios, this.basePath));
    }
}


