import { useQuery } from 'react-query'

import { LeadsFormsServerV1Display } from '@app/api'
import { api } from '@app/config'

export const useDisplay = (
  id: string,
  onUpdate?: (data?: LeadsFormsServerV1Display) => void
) =>
  useQuery(['display', id], () => api.DisplaysApi.displaysAPIGetDisplay([id]), {
    select({ data }) {
      onUpdate?.(data.displays?.[0])
      return data.displays?.[0]
    },
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  })
