import { useQuery } from 'react-query'

import { api } from '@app/config'

export const useLeadSources = () =>
  useQuery('leadSources', () => api.LeadsApi.leadsAPIGetLeadSource(), {
    select({ data }) {
      return data.leadSources
    },
  })
