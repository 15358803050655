import { useQuery } from 'react-query'

import { api } from '@app/config'

import {
  LeadsFormsServerV1InterestSort,
  LeadsLeadsServerV1Category,
} from '../../api/api'
import { sortBy } from '../../helpers/sort'

export interface UseInterestsProps {
  category?: LeadsLeadsServerV1Category[]
  search?: string
  sort?: LeadsFormsServerV1InterestSort
}

export const useInterests = (props: UseInterestsProps) => {
  const { category, search, sort } = props

  return useQuery(
    ['interests', props],
    () =>
      api.LeadsApi.leadsAPIGetInterest(
        undefined,
        undefined,
        undefined,
        undefined,
        category,
        undefined,
        search ? [search] : undefined,
        undefined,
        undefined,
        undefined,
        sort ? [sort] : undefined
      ),
    {
      select({ data }) {
        return data.interests?.sort(sortBy('value'))
      },
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  )
}
