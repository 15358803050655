import { useEffect, useState } from 'react'
import { Checkbox, Col, Form, Input, message, Modal, Row, Select } from 'antd'

import { LeadsFormsServerV1DisplayProps } from '@app/api'
import { RichText } from '@app/components/RichText'
import { api } from '@app/config'
import { useBrands } from '@app/hooks/useBrands/useBrands.hook'
import { useDisplay } from '@app/hooks/useDisplay/useDisplay.hook'

interface DisplayEditProps {
  onSuccess: () => void
  onCancel: () => void
  visible: boolean
  displayId?: string
}

export const DisplayEdit: React.FC<DisplayEditProps> = ({
  onSuccess,
  onCancel,
  visible,
  displayId,
}) => {
  const [form] = Form.useForm<LeadsFormsServerV1DisplayProps>()
  const { data: brands } = useBrands()

  useDisplay(displayId as string, (display) => {
    if (display?.props && displayId) {
      form.setFieldsValue(display.props)
    }
  })

  const [savePending, setSavePending] = useState(false)

  useEffect(() => {
    if (visible) {
      form.resetFields()
    }
  }, [visible])

  const handleSave = async (values: LeadsFormsServerV1DisplayProps) => {
    setSavePending(true)

    try {
      if (displayId) {
        await api.DisplaysApi.displaysAPIUpdateDisplay(displayId, values)
      } else {
        await api.DisplaysApi.displaysAPICreateDisplay(values)
      }

      onSuccess()
      message.success('Экран успешно сохранен')
    } catch (e) {
      console.error(e)

      message.error('При сохранении произошла ошибка')
    } finally {
      setSavePending(false)
    }
  }

  return (
    <Modal
      title={
        displayId
          ? 'Редактирование экрана отправки формы'
          : 'Новый экран отправки формы'
      }
      visible={visible}
      onCancel={onCancel}
      onOk={() =>
        form.validateFields().then((values) => {
          form.resetFields()
          handleSave(values)
        })
      }
      confirmLoading={savePending}
      width={800}
    >
      <Form form={form} layout="vertical" onFinish={handleSave}>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name="brandId"
              label="Бренд"
              rules={[{ required: true, message: 'Выберите бренд' }]}
            >
              <Select
                allowClear
                filterOption
                disabled={savePending}
                placeholder="Выберите бренд"
                options={brands?.vgr.map(({ name, id }) => ({
                  label: name,
                  value: id,
                }))}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name="title"
              label="Внутреннее название экрана успешной отправки"
              rules={[
                {
                  required: true,
                  message: 'Введите внутреннее название',
                },
              ]}
            >
              <Input
                disabled={savePending}
                placeholder="Введите внутреннее название"
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name="text"
              label="Заголовок и описание"
              rules={[
                {
                  required: true,
                  message: 'Введите заголовок и описание',
                },
              ]}
            >
              <RichText />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name={['mainButton', 'title']} label="Основная кнопка">
              <Input disabled={savePending} placeholder="Введите текст" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name={['mainButton', 'url']}
              label="URL"
              style={{ marginBottom: 8 }}
            >
              <Input disabled={savePending} placeholder="Введите ссылку" />
            </Form.Item>

            <Form.Item name={['mainButton', 'newTab']} valuePropName="checked">
              <Checkbox>Открывать в новой вкладке</Checkbox>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name={['additionalButton', 'title']}
              label="Дополнительная кнопка"
            >
              <Input disabled={savePending} placeholder="Введите текст" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name={['additionalButton', 'url']}
              label="URL"
              style={{ marginBottom: 8 }}
            >
              <Input disabled={savePending} placeholder="Введите ссылку" />
            </Form.Item>

            <Form.Item
              name={['additionalButton', 'newTab']}
              valuePropName="checked"
            >
              <Checkbox>Открывать в новой вкладке</Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
