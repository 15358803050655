import { useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { DeleteFilled, EditFilled } from '@ant-design/icons'
import {
  Button,
  Col,
  Input,
  Layout,
  message,
  PageHeader,
  Row,
  Table,
} from 'antd'

import { LeadsFormsServerV1DisclaimerSort } from '@app/api'
import { api } from '@app/config'
import { applySort } from '@app/helpers/applySort'
import { useDebounce } from '@app/hooks/useDebounce/useDebounce'
import { useInfiniteDisclaimers } from '@app/hooks/useDisclaimers/useInfiniteDisclaimers.hook'

import { DisclaimerEdit } from './DisclaimerEdit'
import { LeadsFormsServerV1Disclaimer } from '../../api/api'

export const DictionaryDisclaimersPage = () => {
  const { edit, disclaimerId } =
    useParams<{ disclaimerId?: string; edit?: string }>()
  const history = useHistory()
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce<string>(search, 200)
  const [sort, setSort] = useState(LeadsFormsServerV1DisclaimerSort.None)

  const {
    data: disclaimers,
    refetch,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteDisclaimers({
    search: debouncedSearch,
    sort,
  })

  const [deletePending, setDeletePending] = useState(false)

  const openModal = (id?: string) => {
    if (id) {
      history.push(`/dictionary/disclaimers/edit/${id}`)
    } else {
      history.push(`/dictionary/disclaimers/edit`)
    }
  }

  const closeModal = () => {
    history.push('/dictionary/disclaimers')
  }

  const handleDelete = async (id: string) => {
    const hide = message.loading('Удаление дисклеймера', 0)
    setDeletePending(true)

    try {
      await api.DisclaimersApi.disclaimersAPIDeleteDisclaimer(id)

      await refetch()
      closeModal()
      message.success('Дисклеймер успешно удалён')
    } catch (e) {
      console.error(e)

      message.error('При удалении произошла ошибка')
    } finally {
      setDeletePending(false)
      hide()
    }
  }

  return (
    <Layout.Content>
      <PageHeader
        title="Дисклеймеры"
        ghost={false}
        extra={[
          <Input.Search
            style={{ width: 272 }}
            key="1"
            placeholder="Введите название экрана"
            enterButton
            onChange={(e) => {
              setSearch(e.target.value)
            }}
            // @ts-ignore
            onSearch={refetch}
            value={search}
          />,
          <Button
            key="2"
            type="primary"
            onClick={() => openModal()}
            data-testid="new-disclaimer-button"
          >
            Новый дисклеймер
          </Button>,
        ]}
      />
      <Row style={{ padding: 24 }}>
        <Col span={24}>
          <Table
            dataSource={disclaimers?.pages.flatMap(
              ({ data }) =>
                data.disclaimers as unknown as LeadsFormsServerV1Disclaimer
            )}
            pagination={false}
            rowKey={(rec) => rec.id}
          >
            <Table.Column
              key="1"
              title="Бренд"
              dataIndex={['props', 'brandId']}
              width={'20%'}
              sorter={() => 0}
              onHeaderCell={() => ({
                onClick: () =>
                  setSort(
                    applySort([
                      LeadsFormsServerV1DisclaimerSort.BrandAsc,
                      LeadsFormsServerV1DisclaimerSort.BrandDesc,
                      LeadsFormsServerV1DisclaimerSort.None,
                    ])
                  ),
              })}
            />
            <Table.Column
              key="2"
              title="Название"
              dataIndex={['props', 'title']}
              width={'35%'}
              sorter={() => 0}
              onHeaderCell={() => ({
                onClick: () =>
                  setSort(
                    applySort([
                      LeadsFormsServerV1DisclaimerSort.TitleAsc,
                      LeadsFormsServerV1DisclaimerSort.TitleDesc,
                      LeadsFormsServerV1DisclaimerSort.None,
                    ])
                  ),
              })}
            />
            <Table.Column
              key="3"
              title="Комментарий"
              width={'35%'}
              dataIndex={['props', 'comment']}
            />
            <Table.Column
              key="4"
              width={'10%'}
              render={({ id }) => (
                <Row gutter={16}>
                  <Col>
                    <Button
                      type="link"
                      onClick={() => openModal(id)}
                      data-testid="edit-disclaimer-button"
                      icon={
                        <EditFilled
                          style={{ fontSize: 18, color: '#8C8C8C' }}
                        />
                      }
                    />
                  </Col>
                  <Col>
                    <Button
                      type="link"
                      data-testid="delete-disclaimer-button"
                      onClick={() => handleDelete(id)}
                      disabled={deletePending}
                      icon={
                        <DeleteFilled
                          style={{ fontSize: 18, color: '#8C8C8C' }}
                        />
                      }
                    />
                  </Col>
                </Row>
              )}
            />
          </Table>

          {hasNextPage && (
            <Row justify="center" style={{ margin: '24px 0' }}>
              <Col>
                <Button
                  onClick={() => fetchNextPage()}
                  loading={isFetchingNextPage}
                >
                  Загрузить ещё
                </Button>
              </Col>
            </Row>
          )}
        </Col>
      </Row>

      <DisclaimerEdit
        onSuccess={async () => {
          await refetch()
          closeModal()
        }}
        onCancel={closeModal}
        visible={Boolean(edit)}
        disclaimerId={disclaimerId}
      />
    </Layout.Content>
  )
}
