import { useInfiniteQuery } from 'react-query'

import { api } from '@app/config'

import { LeadsFormsServerV1DisclaimerSort } from '../../api/api'

export interface useInfiniteDisclaimersProps {
  search?: string
  sort?: LeadsFormsServerV1DisclaimerSort
}

export const useInfiniteDisclaimers = (props: useInfiniteDisclaimersProps) => {
  const { search, sort } = props

  return useInfiniteQuery(
    ['disclaimers-infinite', props],
    ({ pageParam: pageToken }) =>
      api.DisclaimersApi.disclaimersAPIGetDisclaimer(
        undefined,
        undefined,
        undefined,
        undefined,
        search ? [search] : undefined,
        undefined,
        10,
        pageToken,
        sort ? [sort] : undefined
      ),
    {
      getNextPageParam: (lastPage) => lastPage.data.count?.token || undefined,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  )
}
