import { useKeycloak as useKeycloakInner } from 'use-keycloak'

export const useKeycloak = () =>
  useKeycloakInner({
    realm: 'vw',
    url: process.env.REACT_APP_IDP_URL,
    clientId: process.env.REACT_APP_IDP_CLIENT_ID,
    enableLogging: true,
    autoRefresh: true,
    autoRefreshInterval: 2500,
    onLoad: 'login-required',
    scope: 'profile email phone phone_required 2fa',
    redirectUri: window.location.origin,
    ...(process.env.NODE_ENV === 'development' && {
      useDangerousCookies: true,
    }),
  })
