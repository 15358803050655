import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter as Router } from 'react-router-dom'
import { ConfigProvider, Layout } from 'antd'
import ruRU from 'antd/lib/locale/ru_RU'

import { useKeycloak } from '@app/hooks/useKeycloak/useKeycloak.hook'
import { GlobalStyles } from '@app/styles'

import 'moment/locale/ru'

import { Header } from './components/Header'
import { api } from './config/index'
import { AppRouter } from './Router'

const queryClient = new QueryClient()

function App() {
  const authData = useKeycloak()

  if (authData?.authToken && authData.authToken !== api.accessToken) {
    api.setAccessToken(authData?.authToken)
  }

  if (!api.accessToken) return null

  return (
    <ConfigProvider locale={ruRU}>
      <QueryClientProvider client={queryClient}>
        <GlobalStyles />
        <Layout>
          <Router>
            <Header />
            <AppRouter />
          </Router>
        </Layout>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ConfigProvider>
  )
}

export default App
