import { useInfiniteQuery } from 'react-query'

import { api } from '@app/config'

import { LeadsFormsServerV1DisplaySort } from '../../api/api'

export interface useInfiniteDisplaysProps {
  search?: string
  sort?: LeadsFormsServerV1DisplaySort
}

export const useInfiniteDisplays = (props: useInfiniteDisplaysProps) => {
  const { search, sort } = props

  return useInfiniteQuery(
    ['displays-infinite', props],
    ({ pageParam: pageToken }) =>
      api.DisplaysApi.displaysAPIGetDisplay(
        undefined,
        undefined,
        undefined,
        undefined,
        search ? [search] : undefined,
        undefined,
        10,
        pageToken,
        sort ? [sort] : undefined
      ),
    {
      getNextPageParam: (lastPage) => lastPage.data.count?.token || undefined,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  )
}
