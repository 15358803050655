import { useQuery } from 'react-query'

import { api } from '@app/config'

export const useForm = (name?: string, version?: number) =>
  useQuery(
    ['form', name, version],
    () => api.FormsApi.formsAPIGetForm(name as string, version as number),
    {
      select({ data }) {
        return data.form
      },
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      enabled: Boolean(name && version),
    }
  )
