import { Route, Switch } from 'react-router-dom'

import { routes } from './routes'

export const AppRouter = () => (
  <Switch>
    {routes.map(({ Component, ...route }) => (
      <Route key={route.path} {...route}>
        <Component />
      </Route>
    ))}
  </Switch>
)
