import { FC, useContext, useState } from 'react'
import { ReloadOutlined } from '@ant-design/icons'
import { BrandKey, Form } from '@leads/react-sdk'
import leadsReact from '@leads/react-sdk/package.json'
import { Button, Card } from 'antd'
import styled from 'styled-components'

import {
  DealerCenterField,
  LeadsFormsServerV1FormProps,
  LeadsLeadsServerV1Brand,
} from '@app/api'
import { BASE_PATH } from '@app/config'
import { useDealers } from '@app/hooks/useDealers/useDealers.hook'

import { FormContext } from '../formContext'
import { isField } from '../mapFields'

interface PreviewStepProps {}

export const themeMap = {
  [LeadsLeadsServerV1Brand.Pkw]: 'vw',
  [LeadsLeadsServerV1Brand.Nfz]: 'vw',
  [LeadsLeadsServerV1Brand.Skoda]: 'skoda',
  [LeadsLeadsServerV1Brand.Audi]: 'audi',
} as const

export const PreviewStep: FC<PreviewStepProps> = () => {
  const { getFieldsValue } = useContext(FormContext)
  const form = getFieldsValue(true) as LeadsFormsServerV1FormProps
  const { data: dealers } = useDealers({
    brandId: form.brandId ? [form.brandId] : [],
  })

  const themeKey = form.brandId
    ? themeMap[form.brandId as keyof typeof themeMap]
    : 'vw'

  // Using for remount form preview after submit
  const [previewKey, setPreviewKey] = useState(1)
  const handleReloadClick = () => {
    setPreviewKey(previewKey + 1)
  }

  form.fields.forEach((field) => {
    if (
      isField<DealerCenterField>(field, 'dealerCenter') &&
      field.dealerCenter.allItems
    ) {
      field.dealerCenter.items = dealers?.dealerCenters
      field.dealerCenter.cities = dealers?.cities
    }
  })

  return (
    <Container themeKey={themeKey}>
      <Reload>
        <Button onClick={handleReloadClick} icon={<ReloadOutlined />}>
          Перезагрузить
        </Button>
      </Reload>

      <Card key={previewKey}>
        <Form
          name="1"
          version={1}
          theme={themeKey}
          config={form}
          basePath={BASE_PATH}
        />
      </Card>

      <Version>Версия SDK: {leadsReact.version}</Version>
    </Container>
  )
}

const Container = styled.div<{ themeKey: BrandKey }>`
  position: relative;
  padding: 24px 0 0;

  --form-default-font-family: ${({ themeKey }) =>
    themeKey === 'vw'
      ? 'vw_text'
      : themeKey === 'skoda'
      ? 'skoda_text'
      : 'audi_text'};
  --form-head-font-family: ${({ themeKey }) =>
    themeKey === 'vw'
      ? 'vw_head'
      : themeKey === 'skoda'
      ? 'skoda_head'
      : 'audi_head'};
`

const Version = styled.p`
  opacity: 0.5;
  padding: 0 24px;
  text-align: right;
`

const Reload = styled.div({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  paddingBottom: '24px',
})
