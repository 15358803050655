import { FC } from 'react'
import { Col, Form, Input } from 'antd'

import {
  FieldCard,
  FieldCardProps,
} from '@app/pages/FormEditPage/Fields/FieldCard/FieldCard'

export const TimeField: FC<FieldCardProps> = ({
  name,
  fieldKey,
  path,
  ...props
}) => (
  <FieldCard name={name} fieldKey={fieldKey} path={path} {...props}>
    <Col span={12}>
      <Form.Item
        {...props}
        name={[name, path, 'title']}
        fieldKey={[fieldKey, path, 'title']}
        label="Заголовок поля"
      >
        <Input placeholder="Введите заголовок поля" />
      </Form.Item>
    </Col>

    <Col span={12}>
      <Form.Item
        {...props}
        name={[name, path, 'hint']}
        fieldKey={[fieldKey, path, 'hint']}
        label="Подсказка"
      >
        <Input placeholder="Введите подсказку" />
      </Form.Item>
    </Col>
  </FieldCard>
)
