import React from 'react'
import { FormInstance } from 'antd'

import { LeadsFormsServerV1FormProps } from '../../api'

export interface FormContextValue
  extends FormInstance<LeadsFormsServerV1FormProps> {
  formState: LeadsFormsServerV1FormProps
}

export const FormContext = React.createContext<FormContextValue>(
  {} as FormContextValue
)
