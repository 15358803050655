import ReactQuill, { Quill } from 'react-quill'
import styled, { css } from 'styled-components'

import 'react-quill/dist/quill.snow.css'

const SizeCustom = Quill.import('formats/size')

const whiteList = [
  '12px',
  '14px',
  '16px',
  '18px',
  '20px',
  '24px',
  '28px',
  '32px',
  '36px',
  '40px',
  '48px',
]
SizeCustom.whitelist = whiteList
Quill.register(SizeCustom, true)

export const RichText: React.FC<any> = ({ value = '', ...rest }) => (
  <StyledQuill
    modules={{
      toolbar: [
        [{ size: whiteList }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['bold', 'italic', 'underline', 'link'],
        [{ align: [] }],
        [{ color: [] }],
      ],
    }}
    value={value}
    {...rest}
  />
)

const StyledQuill = styled(ReactQuill)`
  .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
    border-color: transparent;
  }

  .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    border-color: transparent;
    padding: 12px;
  }

  .ql-snow.ql-toolbar .ql-picker-item,
  .ql-snow.ql-toolbar .ql-picker-label,
  .ql-snow.ql-toolbar button {
    :hover {
      color: #1890ff;
    }
  }

  .ql-container {
    font-size: 12px;
  }

  ${whiteList.map((size) => {
    return css`
      .ql-size-${size} {
        font-size: ${size};
      }

      .ql-snow
        .ql-picker.ql-size
        .ql-picker-label[data-value='${size}']::before {
        content: '${size}';
      }

      .ql-snow
        .ql-picker.ql-size
        .ql-picker-item[data-value='${size}']::before {
        content: '${size}';
      }
    `
  })}
`
